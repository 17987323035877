export const PathsInFileManager = [
    {
        name: "Documentação Técnica",
        id: "technician"
    },
    {
        name: "Documentação de Recursos Humanos",
        id: "humanResources"
    },
    {
        name: "Documentação Financeira",
        id: "financial"
    },
    {
        name: "Documentação Master",
        id: "master"
    }
];