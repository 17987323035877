import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import Loader from "../../../components/Shared/Loader";
import { api } from "../../../services/axios";
import { v4 as uuidV4 } from 'uuid';
import useModal from "../../../hooks/useModal";
import { Modal as ModalContainer } from 'react-bootstrap';
import { BranchCompanyTable } from "../BranchCompanyTable";
import axios from "axios";
import { Button, InputAdornment, TextField } from "@mui/material";
import './styles.css';
import { useCan } from "../../../hooks/useCan";
import { PatternFormat } from 'react-number-format';
import { CNPJInput } from "../../Shared/CNPJInput";

export interface BranchCompanyProps {
    id: string;
    cnpj: string;
    city: string;
    name: string;
    uf: string;
    region: string;
}

interface Address {
    bairro: string;
    cep: string;
    complemento: string;
    ddd: string;
    gia: string;
    ibge: string;
    localidade: string;
    logradouro: string;
    siafi: string;
    uf: string;
}

export default function BranchCompany({ companyId, companyCnpj, serviceId }: any) {
    const permissionFinancial = useCan({ rolesCan: ["Financial"] });
    const permissionTechnician = useCan({ rolesCan: ["Technician"] });
    const [loading, setLoading] = useState(false);
    const [newBranchCompany, setNewBranchCompany] = useState<BranchCompanyProps[]>([]);
    const [savedBranchCompany, setSavedBranchCompany] = useState<BranchCompanyProps[]>([]);
    const [isVisible, setIsVisible] = useState(true);
    const [cep, setCep] = useState('');
    const [cnpj, setCnpj] = useState<string>(companyCnpj?.substr(0, 8) ?? '');
    const [cnpjRest, setCNPJRest] = useState<string>("");
    const [city, setCity] = useState('');
    const [name, setName] = useState('');
    const [uf, setUf] = useState('');
    const [region, setRegion] = useState('');
    const { isShowing: isShowingDeleteModal, toggle: toggleDeleteModal } = useModal();
    const normalized = require('normalize-strings');

    var hasPermition = false

    if (permissionTechnician || permissionFinancial) {
        hasPermition = true
    }

    const toggleVisibility = () => {
        setIsVisible(!isVisible);
    };

    function isValidBranchCompany(branchCompany: BranchCompanyProps): boolean {
        if (!branchCompany.cnpj) {
            return false;
        }
        if (!branchCompany.city) {
            return false;
        }
        if (!branchCompany.name) {
            return false;
        }
        if (!branchCompany.uf) {
            return false;
        }
        return true;
    }

    function addBranchCompany(branchCompany: BranchCompanyProps) {
        if (isValidBranchCompany(branchCompany)) {
            setNewBranchCompany(branchCompanies => [...branchCompanies, branchCompany]);
        }
    }

    function addSavedBranchCompany(branchCompanies: BranchCompanyProps[]) {
        branchCompanies.forEach(element => {
            setSavedBranchCompany(branchCompanies => [...branchCompanies, element]);
        });
    }

    function cancelOperation() {
        setNewBranchCompany([]);
        var rootCnpj = companyCnpj;
        setCnpj(rootCnpj.substr(0, 8));
        setCNPJRest("");
        setCity('');
        setName('');
        setUf('');
        setRegion('');
        setCep('');
        toggleVisibility();
    }

    function removeBranchCompany(id: string) {
        const branchCompaniesFiltred = newBranchCompany.filter(branchCompany => {
            return branchCompany.id != id;
        });
        toast.success('Empresa Filial removida com sucesso!');
        setNewBranchCompany(branchCompaniesFiltred);
    }

    function removeAllNewBranchCompany() {
        setNewBranchCompany([]);
        toast.success('Empresas filiais deletadas com sucesso!');
        toggleDeleteModal();
    }

    async function removeAllSavedBranchCompany() {
        setLoading(true);
        try {
            const result = await api.delete(`branchCompany/DeleteByCompany/${companyId}/${serviceId}`);
            const response = await result.data

            if (response.statusCode === 200) {
                toast.success('Empresas Filiais deletadas com sucesso!');
                setSavedBranchCompany([]);
                toggleDeleteModal();
            }
        }
        catch (err: any) {
            toast.error(err);
        }
        finally {
            setLoading(false);
        }
    }

    function handleAddLine(e: any) {
        e.preventDefault();

        if (cnpj && city && name && uf && cnpjRest) {
            const object = {
                id: uuidV4(),
                cnpj: cnpj + cnpjRest.replace("-", "").toString(),
                city: city,
                name: name,
                uf: uf,
                region: region
            }
            addBranchCompany(object);
            var rootCnpj = companyCnpj;
            setCnpj(rootCnpj.substr(0, 8));
            setCNPJRest("");
            setCity('');
            setName('');
            setUf('');
            setRegion('');
            setCep('');
        }
    }

    async function handleSaveData() {
        try {
            setLoading(true);

            const object = {
                branchCompany: newBranchCompany,
                companyId: companyId,
                serviceId: serviceId
            }

            if (cnpj != "" && cnpjRest != "" && city != "" && name != "" && name != "" && uf != "") {
                const newObject = {
                    id: uuidV4(),
                    cnpj: cnpj + cnpjRest.replace("-", "").toString(),
                    city: city,
                    name: name,
                    uf: uf,
                    region: region
                };
                
                object.branchCompany = [...object.branchCompany, newObject];
            }

            await api.post('branchCompany/Post', object).then((resp) => {
                if (resp.data.statusCode === 200) {
                    toast.success('Os dados foram salvos com sucesso!');
                    addSavedBranchCompany(newBranchCompany);
                    cancelOperation();
                }
            });           
        }
        catch (err: any) {
            toast.error(err);
        }
        finally {
            setLoading(false);
        }
    }
    async function getDataByCEP() {
        setLoading(true);
        try {
            const response = await axios.get(`https://viacep.com.br/ws/${cep}/json/`);
            if (response.data.hasOwnProperty('erro')) {
                if (response.data.erro === true) {
                    toast.error('CEP não encontrado!');
                }
            }
            if (response.status === 200) {
                const data: Address = await response.data;
                setCity(data.localidade);
                setUf(data.uf);
            }
        }
        catch (err: any) {
            toast.error('Erro ao buscar o endereço solicitado, por favor verifique se o CEP está correto!');
            setCity('');
            setRegion('');
            setUf('');
        }
        finally {
            setLoading(false);
        }

    }

    async function getBranchCompany() {
        setLoading(true);
        try {
            const result = await api.get(`branchCompany/GetAllByCompany/${companyId}`);
            const response = await result.data;

            if (response.statusCode === 200) {
                const data: BranchCompanyProps[] = response.data;
                var rootCnpj = companyCnpj;
                setCnpj(rootCnpj.substr(0, 8));
                setSavedBranchCompany(response.data);
            }
        }
        catch (err: any) {

        }
        finally {
            setLoading(false);
        }
    }

    function generateMask() {
        var mask = '';

        if(cnpj.length > 0) {
            if(cnpj.substring(8) == "") {
                mask = "0" + `${cnpj.substring(0, 2)}.${cnpj.substring(2, 5)}.${cnpj.substring(5, 8)}/`;
            } else {
                mask = `${cnpj.substring(0, 3)}.${cnpj.substring(3, 6)}.${cnpj.substring(6, 9)}/`;
            }
        }

        return mask;
    }

    useEffect(() => {
        getBranchCompany();
    }, []);

    async function getRegion() {
        var normalizedCity = normalized(city).replaceAll(' ', '').toUpperCase();
        await api.get(`companyRegion/getRegion/${normalizedCity}/${uf}`).then(resp => {
            setRegion(resp.data.data.region);
        });
    }

    useEffect(() => {
        if (uf != '' && city != '')
            getRegion();
    }, [uf, city]);

    return (

        <>
            {loading && <Loader />}
            <div>
                <div>
                    <BranchCompanyTable
                        newBranchCompany={newBranchCompany}
                        savedBranchCompany={savedBranchCompany}
                        removeBranchCompany={removeBranchCompany}
                    />
                </div>
                {!hasPermition && isVisible &&
                    <div className='row'>
                        <div className='d-flex justify-content-center mt-4'>
                            <Button variant="contained"
                                className='cursor-pointer col-3 rounded forms-control'
                                onClick={toggleVisibility} type='button'>
                                + Cadastrar Filial
                            </Button>
                        </div>
                    </div>
                }
                <div style={{ display: isVisible ? 'none' : 'block' }}>
                    <div className='mt-4'>
                        <div className='row mb-4'>
                            <div className='col-6'>
                                <label className='form-label'></label>
                                <TextField
                                    label="Razão Social"
                                    type="text"
                                    className='form-control me-4'
                                    placeholder='Digite a Razão Social...'
                                    onChange={(e) => setName(e.target.value)}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    value={name}
                                />
                            </div>
                            <div className='col-6'>
                                <label className='form-label'></label>
                                <TextField
                                    label="CNPJ"
                                    type="text"
                                    className='form-control me-4'
                                    placeholder='Digite o CNPJ...'
                                    onChange={(e) => setCNPJRest(e.target.value)}
                                    value={cnpjRest}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">{generateMask()}</InputAdornment>,
                                        inputComponent: CNPJInput as any
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    defaultValue={companyCnpj}
                                />
                            </div>
                        </div>
                        <div className='row mb-4'>
                            <div className='col-6'>
                                <label className='form-label'></label>
                                <TextField
                                    label="CEP"
                                    type="text"
                                    placeholder="Digite o CEP..."
                                    className='form-control me-4'
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onChange={(e) => setCep(e.target.value)}
                                    value={cep}
                                    onBlur={() => getDataByCEP()}
                                />
                            </div>
                            <div className='col-6'>
                                <label className='form-label'></label>
                                <TextField
                                    label="UF"
                                    type="text"
                                    className='form-control me-4'
                                    placeholder='UF'
                                    disabled
                                    onChange={(e) => setUf(e.target.value)}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    value={uf}
                                />
                            </div>


                        </div>
                        <div className='row mb-4 d-flex justify-content-between'>
                            <div className='col-6'>
                                <label className='form-label'></label>
                                <TextField
                                    label="Região"
                                    type="text"
                                    className='form-control me-4'

                                    disabled
                                    onChange={(e) => setRegion(e.target.value)}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    value={region}
                                />
                            </div>
                            <div className='col-6'>
                                <label className='form-label'></label>
                                <TextField
                                    label="Municipio"
                                    type="text"
                                    className='form-control me-4'
                                    placeholder='Municipio'
                                    disabled
                                    onChange={(e) => setCity(e.target.value)}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    value={city}
                                />
                            </div>
                            <div className='d-flex justify-content-end align-items-end mb-3 mt-4 '>
                                <a onClick={handleAddLine} href="">
                                    Inserir nova filial +
                                </a>
                            </div>
                        </div>
                        <div className="w-100 d-flex justify-content-center align-items-center">
                            <div className='w-50 d-flex justify-content-center align-items-center mb-3 mt-4 '>
                                <Button variant="contained"
                                    className='cursor-pointer w-25 me-2 rounded forms-control'
                                    type='button'
                                    color="info"
                                    onClick={cancelOperation} >
                                    Cancelar
                                </Button>
                                <Button variant="contained"
                                    className='cursor-pointer w-25 ms-2 rounded forms-control'
                                    type='button'
                                    disabled={!(cnpjRest != "" && city != "" && name != "" && name != "" && uf != "") && newBranchCompany.length == 0}
                                    onClick={handleSaveData} >
                                    Salvar
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <ModalContainer show={isShowingDeleteModal} onHide={toggleDeleteModal}>
                <ModalContainer.Header closeButton>
                    <ModalContainer.Title>Exclusão massiva</ModalContainer.Title>
                </ModalContainer.Header>
                <ModalContainer.Body>
                    <div className=' d-flex align-items-center justify-content-center'>
                        <button
                            className='btn btn-success me-2'
                            onClick={removeAllNewBranchCompany}
                        >
                            Empreasas filiais novas
                        </button>
                        <button
                            className='btn btn-success'
                            onClick={removeAllSavedBranchCompany}
                        >
                            Empreasas filiais salvas
                        </button>
                    </div>
                </ModalContainer.Body>
            </ModalContainer >

        </>
    );
}