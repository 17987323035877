import axios, { AxiosError, HeadersDefaults } from 'axios';
import { toast } from 'react-toastify';
import { getSession } from '../utils/storageSession';
import { TokenSessionName } from '../Static/TokenNames';

export interface CommonHeaderProperties extends HeadersDefaults {
    Authorization: string;
}

export const api = getAPIClient();

export function getAPIClient() {
    var token = getSession(TokenSessionName);

    const api = axios.create({
        baseURL: `${process.env.REACT_APP_BASE_URL}/api`,
        headers: {
            'Cache-Control': 'no-cache, no-store, must-revalidate',
            'content-type': 'application/json',
        }
    });

    api.interceptors.request.use(request => {
        if (token) {
            api.defaults.headers = {
                Authorization: `Bearer ${token}`,
            } as CommonHeaderProperties;

        }

        return request;
    }, (error: AxiosError) => {
        toast.error('Erro interno ao processar a sua solicitação!');
        return Promise.reject(error);
    });

    api.interceptors.response.use(function (response) {

        if (response.request?.responseURL.includes("AccessDenied")) {
            window.location.href = `/404`;
        }

        if (response.data.hasOwnProperty('statusCode')) {
            if (response.data.statusCode != 200) {
                response.data.errors.forEach((error: string) => {
                    toast.error(error);
                });
            }
        }


        return response;
    }, (error: AxiosError) => {
        console.log('ERROR RESPONSE', error);
        toast.error('Erro interno ao responder a sua solicitação!');
        return Promise.reject(error);
    });

    if (token) {


        api.defaults.headers = {
            Authorization: `Bearer ${token}`,
        } as CommonHeaderProperties;
    }

    return api;
}