import { FaTrash } from "react-icons/fa";
import useModal from "../../../hooks/useModal";
import DeleteModal from "../../Shared/DeleteModal";

interface BranchCompanyTableActions{
    action: ((id: string) => void) | ((id: string) => Promise<void>);
    id: string;
}

export function BranchCompanyTableActions({id, action}: BranchCompanyTableActions){
    const {isShowing, toggle} = useModal();
    return (
        <>
            <td width='100px'>
                <div className='icon-center' onClick={toggle}>
                    <FaTrash />
                </div>
            </td>
            <DeleteModal
                title={"Excluir Empresa Filial"}
                text={"Tem certeza que deseja excluir a empresa filial selecionada?"}
                buttonSaveTitle='Deletar'
                isShowing={isShowing}
                hide={toggle}
                action={() => action(id)}
            />
        </>
    );
}