export const SeverityEnum = [
    { value: "0", label: "Baixo" },
    { value: "1", label: "Médio" },
    { value: "2", label: "Alto" }
];

export const ActionEnum = [
    { value: "0", label: "Visualização" },
    { value: "1", label: "Criação" },
    { value: "2", label: "Edição" },
    { value: "3", label: "Exclusão" },
    { value: "4", label: "Download" },
    { value: "5", label: "Upload" },
    { value: "6", label: "Vinculação" },
    { value: "7", label: "Justificativa" }
];

export const ScreenEnum = [
    { value: "0", label: "I.1 Produtos e Modelos" },
    { value: "1", label: "II.1 Faturamentos" },
    { value: "2", label: "II.2 Aquisições" },
    { value: "3", label: "V.Credito financeiro" },
    { value: "4", label: "Gerenciamento de Documento" },
    { value: "5", label: "Informações da Empresa" },
    { value: "6", label: "Modal Ano Fiscal" },
    { value: "7", label: "Obrigações" },
    { value: "8", label: "Gestão de Empresas" },
    { value: "9", label: "Contratos de Assunção" },
    { value: "10", label: "Home" },
];

export const ProgressEnum = [
    { value: "0", label: "Processando" },
    { value: "1", label: "Concluído" },
    { value: "2", label: "Erro" }
];