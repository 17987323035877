import { Col } from "react-bootstrap";
import { AiFillFile } from "react-icons/ai";
import { humanFileSize } from "../../../../utils/formatBytes";
import { formatISODate } from "../../../../utils/formatDate";
import { FileActions } from "../../../Files/FileActions";
import { FillTable } from "../../FillTable";
import { TablePaginationFooter } from "../../Pagination";
import { AnexoActions } from "../../../Projects/AnexoTab/AnexoActions";
import { FileProps, FilesPagination } from "../../../../interfaces/Files/IFiles";

interface IFileList {
    setFileSearch: (value: React.SetStateAction<string>) => void,
    searchFiles: (text: string) => Promise<void>,
    handleToggleCheckAll: () => void,
    handleCheckFile: (selectedFile: FileProps) => void,
    changeFilePage: (page: number) => Promise<void>,
    fileSearch: string,
    checkAllFiles: boolean,
    checkedFiles: FileProps[],
    files: FilesPagination,
    hasInternalProjects: boolean;
}

export function FileList(props: IFileList) {
    const { setFileSearch, searchFiles, handleToggleCheckAll, handleCheckFile, changeFilePage, fileSearch, checkAllFiles, checkedFiles, files, hasInternalProjects } = props;
    
    return (
        <Col xs={12} sm={12} md={12} lg={6} className='ps-0'>
            <div className='d-flex justify-content-end'>
                <div className="">
                    <div className="input-group mb-3">
                        <input type="text" className="form-control w-25" onChange={(e) => setFileSearch(e.target.value)} aria-describedby="basic-addon2" />
                        <div className="input-group-append" onClick={() => searchFiles(fileSearch)}>
                            <span className="search-button">Pesquisar</span>
                        </div>
                    </div>
                </div>
            </div>
            <table className='table table-bordered table-striped text-nowrap'>
                <thead>
                    <tr>
                        <th>
                            <input type="checkbox" checked={checkAllFiles} onClick={handleToggleCheckAll} className='form-check-input' />
                        </th>
                        <th>Nome</th>
                        <th>Tamanho</th>
                        <th>Data do Upload</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {files.items && files?.items.map(file => (
                        <tr key={file.id}>
                            <td>
                                <input
                                    type="checkbox"
                                    checked={((checkedFiles.some(x => x.id == file.id) && !checkAllFiles) || (!checkedFiles.some(x => x.id == file.id) && checkAllFiles))}
                                    className='form-check-input'
                                    onClick={() => handleCheckFile(file)}
                                />
                            </td>
                            <td title={file.fileName}>
                                <a href={file.fileAzurePath} className='file-link' >
                                    <AiFillFile className='me-2' />
                                    {file.fileName}
                                </a>
                            </td>
                            <td>{humanFileSize(file.size, true)}</td>
                            <td>{formatISODate(file.created)}</td>
                            <td>
                                {hasInternalProjects
                                    ? <AnexoActions id={file.id} /> 
                                    : <FileActions id={file.id}  />
                                }

                            </td>
                        </tr>
                    ))}
                    {files.items && <FillTable columns={5} rows={files?.items.length} />}
                </tbody>
            </table>
            {files?.items &&
                <TablePaginationFooter
                    totalItems={files?.total}
                    change={changeFilePage}
                />}
        </Col>
    )
}