import { useEffect, useState } from 'react';
import Pagination from 'react-bootstrap/Pagination';

interface TablePaginationFooterProps {
    totalItems: number;
    change: (page: number) => Promise<void>;
}

const TablePaginationFooter = ({ totalItems, change }: TablePaginationFooterProps) => {
    const [itemsPerPage, setItemsPerPage] = useState(7);
    const [totalPages, setTotalPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        if (totalItems > itemsPerPage) {
            const pages = totalItems / itemsPerPage;
            const ceilPages = Math.ceil(pages);
            setTotalPages(ceilPages);
        }
        else {
            setTotalPages(1);
        }

        getPage(1);
    }, [totalItems]);

    async function getPage(page: number) {
        if (page != 0 && page >= 1 && page <= totalPages) {
            await change(page);
            setCurrentPage(page);
        }
    }

    function setPaginationFooter() {
        if (totalPages == 1) {
            return (
                <>
                    <Pagination.Item active>{1}</Pagination.Item>
                </>
            );
        }

        if (totalPages == 2) {
            return (
                <>
                    <Pagination.Item onClick={() => getPage(1)} active={currentPage == 1} >{1}</Pagination.Item>
                    <Pagination.Item onClick={() => getPage(2)} active={currentPage == 2}>{2}</Pagination.Item>
                </>
            );
        }

        if (totalPages >= 3) {
            const firstPageNumber = currentPage - 2 <= 0 ? 1 : currentPage - 2;
            const secondPageNumber = currentPage - 1 <= 1 ? 2 : currentPage - 1;

            return (
                <>
                    <Pagination.Item
                        onClick={() => getPage(firstPageNumber)}
                        active={firstPageNumber == currentPage}
                    >
                        {firstPageNumber}
                    </Pagination.Item>
                    <Pagination.Item
                        onClick={() => getPage(secondPageNumber)}
                        active={secondPageNumber == currentPage}
                    >
                        {secondPageNumber}
                    </Pagination.Item>
                    {currentPage != 1 && currentPage != 2 && currentPage != totalPages &&
                        <Pagination.Item
                            onClick={() => getPage(currentPage)}
                            active={true}
                        >
                            {currentPage}
                        </Pagination.Item>
                    }
                    <Pagination.Ellipsis />
                    <Pagination.Item
                        onClick={() => getPage(totalPages)}
                        active={totalPages == currentPage}
                    >
                        {totalPages}
                    </Pagination.Item>
                </>
            );
        }

        return null;
    }

    return (
        <>
            <Pagination>
                <Pagination.First onClick={() => getPage(1)} />
                <Pagination.Prev onClick={() => getPage(currentPage - 1)} />
                {setPaginationFooter()}
                <Pagination.Next onClick={() => getPage(currentPage + 1)} />
                <Pagination.Last onClick={() => getPage(totalPages)} />
            </Pagination>
        </>
    );
}

export default TablePaginationFooter;