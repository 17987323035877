import { useState } from "react";
import { AiFillSave } from "react-icons/ai";
import { toast } from "react-toastify";
import { api } from "../../../services/axios";
import Loader from "../../Shared/Loader";
import { ServiceYearProps } from "../IdentityCompanyCard";

interface ServiceFormCreateProps {
    tenantCompanyId: string;
    setService: React.Dispatch<React.SetStateAction<ServiceYearProps[]>>;
    name: string;
    cnpj: string;
}

function getYears() {
    const nowDate = Date.now();
    const nowYear = new Date(nowDate).getFullYear();

    const years: number[] = [];

    for (let index = -5; index < 5; index++) {
        const element = nowYear + index;
        years.push(element);
    }
    return years;
}

const brasilianUFs = [
    "AC", "AL", "AM", "AP", "BA", "CE", "DF", "ES", "GO", "MA", "MG", "MS", "MT", "PA", "PB", "PE", "PI", "PR", "RJ", "RN", "RO", "RR", "RS", "SC", "SE", "SP", "TO"
];

export function ServiceFormCreate({ tenantCompanyId, setService, name, cnpj }: ServiceFormCreateProps) {
    const [isEnabled, setIsEnabled] = useState(false);
    const [fiscalYear, setFiscalYear] = useState(getYears()[0]);
    const [counting, setCounting] = useState(0);
    const [uf, setUf] = useState(brasilianUFs[0]);
    const [loading, setLoading] = useState(false);

    async function handleCreateService() {
        setLoading(true);
        try {
            const fiscalYearObject = {
                counting: counting,
                year: fiscalYear,
                uf: uf,
                tenantCompanyId: tenantCompanyId,
                title: name,
                cnpj: cnpj
            }

            const result = await api.post('/serviceYear/post', fiscalYearObject);

            const response = await result.data;

            if (response.statusCode === 200) {
                toast.success('Ano fiscal criado com sucesso!');
                setService(old => [...old, response.data]);
            }
        }
        catch (error: any) {
            toast.error(error);
        }
        finally {
            setLoading(false);
        }
    }

    return (
        <>
            {loading && <Loader />}
            <tr >
                <td>
                    <select
                        className='form-select'
                        disabled={isEnabled}
                        onChange={(e) => setFiscalYear(parseInt(e.target.value))}
                    >
                        {getYears().map(y => (
                            <option key={y} value={y}>{y}</option>
                        ))}
                    </select>
                </td>
                <td>
                    <select
                        className='form-select'
                        disabled={isEnabled}
                        onChange={(e) => setCounting(parseInt(e.target.value))}
                    >
                        <option value='0'>Anual</option>
                        <option value='1'>Trimestral</option>
                        <option value='2'>Mensal</option>
                    </select>
                </td>
                <td>
                    <select
                        className='form-select'
                        disabled={isEnabled}
                        onChange={(e) => setUf(e.target.value)}
                    >
                        {brasilianUFs.map((uf) => (
                            <option value={uf}>{uf}</option>
                        ))}
                    </select>
                </td>
                <td className='d-flex align-items-center justify-content-center tr-200px'>
                    <button className='btn btn-success' type='button' onClick={handleCreateService} title='Salvar ano fiscal'>
                        <AiFillSave size={20} />
                    </button>
                </td>
            </tr>
        </>
    )
}