import { useState } from "react";
import { Collapse } from "reactstrap";
import { AiOutlineArrowDown } from "react-icons/ai";
import './styles.css';
import { ServiceForm } from "../ServiceForm";
import { api } from "../../../services/axios";
import Loader from "../../Shared/Loader";
import { ServiceFormCreate } from "../ServiceFormCreate";

interface IdentityCompanyCardProps {
    name: string;
    number: string;
    id: string;
}

export interface ServiceYearProps {
    year: number;
    uf: string;
    tenantCompanyId: string;
    counting: number;
    id: string;
}

export function IdentityCompanyCard({ name, number, id }: IdentityCompanyCardProps) {
    const [isOpen, setIsOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [serviceList, setServiceList] = useState<ServiceYearProps[]>([]);

    async function getServices() {
        try {
            const result = await api.get(`serviceYear/GetServicesByTenant/${id}`);

            const response = await result.data;
            
            if (response.statusCode === 200) {
                setServiceList(response.data);
            }
        }
        catch (error: any) {
            console.log('Toogle service error', error);
        }
        finally {
            setLoading(false);
        }
    }

    async function toggle() {
        if (!isOpen) {
            setLoading(true);

            getServices()
                .then(() => setIsOpen(!isOpen));
        }
        else {
            setIsOpen(!isOpen);
        }

    }

    return (
        <>
            {loading && <Loader />}
            <tr>
                <td>
                    <div className='card border-radius-0'>
                        <div className='card-body'>
                            <span>{name}</span>
                        </div>
                    </div>
                </td>
                <td>
                    <div className='card border-radius-0'>
                        <div className='card-body'>
                            <span>{number}</span>
                        </div>
                    </div>
                </td>
                <td>
                    <div className='card border-radius-0 align-items-center justify-content-center'>
                        <div
                            className='card-body cursor-pointer'
                            onClick={toggle}
                            title='Criar e ver anos fiscais'
                        >
                            <AiOutlineArrowDown size={20} className='primary text-primary' />
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td colSpan={4}>
                    <Collapse isOpen={isOpen}>
                        <div className='card'>
                            <div className='card-body'>
                                <table className='table table-bordered table-hover'>
                                    <thead>
                                        <tr>
                                            <th scope="col">Ano fiscal</th>
                                            <th scope="col">Apuração</th>
                                            <th scope="col">UF</th>
                                            <th scope="col" className='tr-200px'></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {serviceList.map(service => (
                                            <ServiceForm
                                                key={service.year}
                                                id={service.id}
                                                year={service.year}
                                                countingType={service.counting}
                                                tenantCompanyId={service.tenantCompanyId}
                                                uf={service.uf}
                                                setService={setServiceList}
                                            />
                                        ))}
                                        <ServiceFormCreate
                                            tenantCompanyId={id}
                                            setService={setServiceList}
                                            name={name}
                                            cnpj={number}
                                        />
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </Collapse>
                </td>
            </tr>
        </>
    );
}