import { useContext, useEffect, useState } from 'react';
import './styles.css';
import Loader from '../../components/Shared/Loader';
import { Chart as ChartJS, registerables } from "chart.js";
import { Timeline } from 'react-twitter-widgets';
import { Fade } from 'react-slideshow-image';
import { NewsModel } from '../../models/NewsModel';
import BarChart from '../../components/Home/BarChart';
import GaugeChart from '../../components/Home/GaugeChart';
import LineChart from '../../components/Home/LineChart';
import TableHome from '../../components/Home/TableHome/indes';
import { toast } from 'react-toastify';
import { api } from '../../services/axios';
import { CompanyContext } from '../../contexts/companyContext';
import PersonalizedChartLeft from '../../components/Home/PersonalizedChartLeft';
import PersonalizedChartRight from '../../components/Home/PersonalizedChartRight';
ChartJS.register(...registerables);

export default function Home() {
    const { company, getObligationTotalValue } = useContext(CompanyContext);
    const [loading, setLoading] = useState(false);
    const [imageList, setImageList] = useState<NewsModel[]>([]);
    const [cnpjs, setCNPJS] = useState<string[]>([]);
    const [showChartLeft, setShowChartLeft] = useState<boolean>(false);
    const [showChartRight, setShowChartRight] = useState<boolean>(false);

    async function getAllCNPJsByServiceId() {
        try {
            setLoading(true);

            await api.get(`/Home/GetAllCNPJs?serviceId=${company.serviceId}`).then((resp) => {
                if (resp.status == 200) {
                    setCNPJS(resp.data.data ?? []);
                }
            });
        } catch (error: any) {
            toast.error(error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        getAllCNPJsByServiceId();
        getObligationTotalValue();
    }, []);

    return (
        <div>
            {loading && <Loader />}
            <div className='card w-100 h-100 p-4'>
                <div className='card-body card-shadow-background'>
                    <div className='w-100 d-flex align-items-end'>
                        <BarChart cnpjs={cnpjs} setLoading={setLoading} />
                        {/* <GaugeChart /> */}
                    </div>
                </div>
                <div className='d-flex mt-4'>
                    <LineChart setLoading={setLoading} />
                    <TableHome setLoading={setLoading} cnpjs={cnpjs} />
                </div>
            </div>
            {/* Desenvolvimento Futuro! */}

            {/* <div className="mt-4 card w-100 h-100 p-4">
                <div className='d-flex'>
                    <div className={`${showChartRight ? "d-none" : "w-100"}`}>
                        <PersonalizedChartLeft
                            setLoading={setLoading}
                            showChartLeft={showChartLeft}
                            setShowChartLeft={setShowChartLeft}
                        />
                    </div>
                    <div className={`${showChartLeft ? "d-none" : "w-100"}`}>
                        <PersonalizedChartRight showChartRight={showChartRight} setShowChartRight={setShowChartRight} />
                    </div>
                </div>
            </div> */}
            
            {/* <div className="mt-4 d-flex">
                <div className='w-50 me-2 rounded'>
                    {imageList.length > 0 ?
                        <Fade infinite={imageList.length > 1}>
                            {imageList.map((image, index) => {
                                return (
                                    <div key={index} className="each-fade">
                                        <a href={image.externLink} className="images-wrap rounded" target="_blank">
                                            <img className='rounded' src={image.imageLink} />
                                        </a>
                                    </div>
                                )
                            })}
                        </Fade>
                        :
                        <div className="each-slide-effect image-container rounded h-100">
                            <img className='rounded' src="https://www.defensoria.to.def.br/no_image.jpg" />
                        </div>
                    }
                </div>
                <div className="card-body news-card p-0 ms-2">
                    <Timeline
                        dataSource={{
                            url: "https://twitter.com/FIGroupBR?ref_src=twsrc%5Etfw"
                        }}
                        options={{
                            height: 500
                        }}
                    />
                </div>
            </div> */}
        </div>
    );
}
