import { useContext } from "react";
import { FaTrash } from "react-icons/fa";
import { FileContext } from "../../../contexts/fileContext";
import useModal from "../../../hooks/useModal";
import DeleteModal from "../../Shared/DeleteModal";

interface FileActionsProps {
    id: string;
}

export function FileActions({ id }: FileActionsProps) {
    const { handleRemoveFile } = useContext(FileContext);
    const { isShowing, toggle } = useModal();

    return (
        <>
            <FaTrash className='cursor-pointer' onClick={toggle} />
            <DeleteModal title='Excluir Arquivo' text='Tem certeza que deseja excluir este arquivo?' isShowing={isShowing} hide={toggle} action={() => handleRemoveFile(id)} />
        </>
    )
}