import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from 'yup';
import { useForm } from "react-hook-form";
import { api } from "../../../services/axios";
import { AiFillHome } from 'react-icons/ai';
import { useState, useEffect } from "react";
import { v4 as uuidv4 } from 'uuid';
import Loader from "../../../components/Shared/Loader";
import { toast } from "react-toastify";
import { useNavigate, Link } from "react-router-dom";
import { useCompany } from "../../../hooks/useCompany";

export interface UpdateProductModelProps {
    name: string;
    status: boolean | null;
    description: string | null;
    createdDate: string | null;
    approvalDate: string | null;
    isRegistered: boolean | null;
    companyId: string;
    productId: string | null;
    serviceId: string;
    codes: CodeProps[];
}

export interface CodeProps {
    id: string;
    code: string;
}

interface PreloadProductModelForm {
    productModel: UpdateProductModelProps;
}

const schema = yup.object().shape({
    name: yup.string()
        .required("O Campo Nome do Modelo é obrigatório").trim()
        .max(250, 'Por favor, insira um valor entre 5 e 250 caracteres')
        .min(5, 'Por favor, insira um valor entre 5 e 250 caracteres'),

    status: yup.string().nullable(true),

    description: yup.string()
        .required("O Campo Descrição do Modelo e características básicas é obrigatório").trim()
        .max(400, 'Por favor, insira um valor entre 5 e 400 caracteres')
        .min(5, 'Por favor, insira um valor entre 5 e 400 caracteres'),

    createdDate: yup.string().nullable(true).transform((_, val) => val !== '' ? val : null),

    isRegistered: yup.string().nullable(true),

    approvalDate: yup.string().nullable(true).transform((_, val) => val !== '' ? val : null),

});

export default function UpdateProductModelForm({ productModel }: PreloadProductModelForm) {

    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const { company } = useCompany();
   

    useEffect(() => {
        setValue('status', productModel.status);
        setValue('isRegistered', productModel.isRegistered);
    }, []);

    const { register, handleSubmit, control, formState: { errors }, reset, setValue } = useForm<UpdateProductModelProps>({
        resolver: yupResolver(schema),
        defaultValues: productModel,
        shouldUnregister: false
    });


    async function handleUpdateProjectModel(data: UpdateProductModelProps) {
        setLoading(true);

        //force boolean values in select option html
        productModel.companyId = uuidv4();
        productModel.status = typeof data.status == "string" && data.status != "" ? data.status === "true" ? true : false : null;
        productModel.isRegistered = typeof data.isRegistered == "string" && data.isRegistered != "" ? data.isRegistered === "true" ? true : false : null;
        productModel.serviceId = company.serviceId;

        try {
            const result = await api.put('productmodel/put', productModel);
            const response = await result.data;
            if (response.statusCode === 200) {
                toast.success('Modelo de Produto Editado com Sucesso!');
                navigate(`/${company.serviceId}/products/${productModel.productId}/models`);
            }
        }
        catch (error: any) {
            toast.error(error);
        }
        finally {
            setLoading(false);
        }
    }

    return (
        <>
            {loading && <Loader />}
            <div className='card'>
                <div className='card-header'>
                    <div className='breadCrumb'>
                        <Link to={`/${company.serviceId}/home`} className='breadCrumb-home d-flex align-center'>
                            <AiFillHome className='breadCrumb-home' />
                        </Link>
                        <span className='breadCrumb-separator'>{' > '}</span>
                        <Link className='breadCrumb-item' to={`/${company.serviceId}/models`}>
                            I.0 Modelo de Produtos
                        </Link>
                        <span className='breadCrumb-separator'>{' > '}</span>
                        <span className='breadCrumb-item-actual'>{productModel.name}</span>
                    </div>
                </div>
                <form className='card-body' onSubmit={handleSubmit(handleUpdateProjectModel)}>
                    <div className='mb-3 w-100'>
                        <label className='form-label'>Nome do Modelo</label>
                        <input className='form-control form-control w-100' {...register('name')} />
                        <span className='error-field'>{errors.name?.message}</span>
                    </div>
                    <div className='mb-3 w-100'>
                        <label className='form-label'>Status do Modelo</label>
                        <select className="form-select" {...register('status')}>
                            <option selected value="">Selecione uma opção</option>
                            <option value="true">Habilitado</option>
                            <option value="false">Não Habilitado</option>
                        </select>
                        <span className='error-field'>{errors.status?.message}</span>
                    </div>
                    <div className='mb-3 w-100'>
                        <label className='form-label'>Descrição do Modelo e características básicas</label>
                        <input className='form-control form-control w-100' {...register('description')} />
                        <span className='error-field'>{errors.description?.message}</span>
                    </div>
                    <div className='mb-3 w-100'>
                        <div className='d-flex align-center flex-column'>
                           {/*<label className='form-label'>Códigos e Descrições</label>
                             <div className='d-flex'>
                                <input className='form-control form-control w-25' value={modelCod} onChange={(e) => setModelCod(e.target.value)} />
                                <input className='form-control form-control w-100 ms-2' value={modelName} onChange={(e) => setModelName(e.target.value)} />
                                <button
                                    className='btn btn-success ms-4 white'
                                    type='button'
                                    onClick={handleAddCodes}
                                >
                                    <AiOutlinePlus size={20} className='white' />
                                </button>
                            </div>
                            {codes.map(code => {
                                return (
                                    <div className='d-flex align-items-center mt-4' key={code.id}>
                                        <input className='form-control form-control readonly w-25' value={code.code} disabled />
                                        <input className='form-control form-control readonly ms-2' value={code.description} disabled />
                                        <button
                                            className='btn btn-success ms-4 white'
                                            type='button'
                                            onClick={() => handleRemoveCodes(code.id)}
                                        >
                                            <AiOutlineMinus size={20} className='white' />
                                        </button>
                                    </div>
                                );
                            })} */}
                        </div>
                    </div>
                    <div className='d-flex align-items-center'>
                        <div className='mb-3 w-100'>
                            <label className='form-label'>Data de Criação de Modelo</label>
                            <input className='form-control form-control w-100' type='date' {...register('createdDate')} />
                            <span className='error-field'>{errors.createdDate?.message}</span>
                        </div>
                        <div className='mb-3 w-100 ms-4'>
                            <label className='form-label'>Modelo Cadastrado?</label>
                            <select className="form-select" {...register('isRegistered')}>
                                <option selected value="">Selecione uma opção</option>
                                <option value="true">Sim</option>
                                <option value="false">Não</option>
                            </select>
                            <span className='error-field'>{errors.isRegistered?.message}</span>
                        </div>
                        <div className='mb-3 w-100 ms-4'>
                            <label className='form-label'>Data de Aprovação</label>
                            <input className='form-control form-control w-100' type='date' {...register('approvalDate')} />
                            <span className='error-field'>{errors.approvalDate?.message}</span>
                        </div>
                    </div>
                    <button className='btn btn-success w-100' type='submit'>Salvar Modelo</button>
                </form>
            </div>
        </>
    );
}

