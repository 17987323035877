import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { EconomicDataForms } from "../EconomicDataModal";
import * as yup from 'yup';
import { toast } from "react-toastify";
import { api } from "../../../services/axios";
import { Button, Modal as ModalContainer } from 'react-bootstrap';
import Loader from "../../Shared/Loader";
import { useState } from "react";
import { useCompany } from "../../../hooks/useCompany";
import { CustomControlledCurrencyInput } from "../../Shared/CustomControlledCurrencyInput";

const dataSchema = yup.object().shape({
    quantityProduced: yup.number().nullable(true).transform((_, val) => val !== '' ? parseFloat(val.toString().replace('.', '').replace(',', '.')) : null),
    grossRevenue: yup.number().nullable(true).transform((_, val) => val !== '' ? parseFloat(val.toString().replace('.', '').replace(',', '.')) : null),
    exports: yup.number().nullable(true).transform((_, val) => val !== '' ? parseFloat(val.toString().replace('.', '').replace(',', '.')) : null),
    importedComponents: yup.number().nullable(true).transform((_, val) => val !== '' ? parseFloat(val.toString().replace('.', '').replace(',', '.')) : null),
    additionalInvestments: yup.number().nullable(true).transform((_, val) => val !== '' ? parseFloat(val.toString().replace('.', '').replace(',', '.')) : null),
    additionalQuantity: yup.number().nullable(true).transform((_, val) => parseFloat(val.toString().replace('.', '').replace(',', '.'))),
});

const schema = yup.object().shape({
    lastYear: dataSchema,
    baseYear: dataSchema,
    nextYear: dataSchema
});

interface EconomicTableProps {
    economicData: EconomicDataForms;
    isShowing: boolean;
    hide: () => void;
}

export function EconomicTable({ economicData, hide, isShowing }: EconomicTableProps) {
    const [loading, setLoading] = useState(false);
    const { company } = useCompany();

    const { register, handleSubmit, control, formState: { errors }, getValues } = useForm<EconomicDataForms>({
        resolver: yupResolver(schema),
        defaultValues: economicData
    });

    async function handleCreateEconomicData(data: EconomicDataForms) {
        setLoading(true);
        data.productId = economicData.productId;
        data.serviceId = company.serviceId;

        data.lastYear.yearBased = 0;
        data.baseYear.yearBased = 1;
        data.nextYear.yearBased = 2;

        try {
            const result = await api.post('economicdata/post', data);
            const response = result.data;

            if (response.statusCode === 200) {
                toast.success('Dados Econômicos Lançados com Sucesso!');
            }
        }
        catch (error: any) {
            toast.error(error);
        }
        finally {
            setLoading(false);
        }
    }

    async function handleUpdateEconomicData(data: EconomicDataForms) {
        setLoading(true);
        data.productId = economicData.productId;
        data.serviceId = company.serviceId;

        data.lastYear.yearBased = 0;
        data.baseYear.yearBased = 1;
        data.nextYear.yearBased = 2;

        try {
            const result = await api.put('economicdata/put', data);
            const response = result.data;

            if (response.statusCode === 200) {
                toast.success('Dados Econômicos Lançados com Sucesso!');
            }
        }
        catch (error: any) {
            toast.error(error);
        }
        finally {
            setLoading(false);
        }
    }

    const currencyInputList = [
        { title: 'Quantidade Produzida', property: 'quantityProduced' },
        { title: 'Faturamento Bruto (R$)', property: 'grossRevenue' },
        { title: 'Exportações (U$)', property: 'exports' },
        { title: 'Partes e Componentes Importados (U$)', property: 'importedComponents' },
        { title: 'Investimentos Adicionais para a Produção (R$)', property: 'additionalInvestments' },
        { title: 'Quantidade Adicional de Pessoal Direto na Fábrica', property: 'additionalQuantity' },
    ]
    const yearList = ['lastYear', 'baseYear', 'nextYear']

    return (
        <>
            {loading && <Loader />}
            <ModalContainer show={isShowing} onHide={hide} size='xl'>
                <form id='form-economic' onSubmit={economicData?.nextYear == null ? handleSubmit(handleCreateEconomicData) : handleSubmit(handleUpdateEconomicData)}>
                    <ModalContainer.Header className='d-flex align-center justify-content-center'>
                        <ModalContainer.Title>Dados Econômicos | Produto XPTO</ModalContainer.Title>
                    </ModalContainer.Header>
                    <ModalContainer.Body>
                        <table className='table table-bordered table-striped table-hover mb-4'>
                            <thead>
                                <tr>
                                    <th scope="col"></th>
                                    <th scope="col">Ano anterior</th>
                                    <th scope="col">Ano Base</th>
                                    <th scope="col">Ano Seguinte</th>
                                </tr>
                            </thead>
                            <tbody>
                                {currencyInputList.map((item, index) => {
                                    return (
                                        <div className='d-flex justify-content-end mb-3' key={index}>
                                            <tr>
                                                <th scope="row">
                                                    {item.title}
                                                </th>
                                                {yearList.map((year, i) => {
                                                    return (
                                                        <td className='col-2 p-2' key={i}>
                                                            <CustomControlledCurrencyInput name={year + item.property} register={register} getValue={getValues} />
                                                        </td>
                                                    )
                                                })}
                                            </tr>
                                        </div>
                                    )
                                })}
                                {/* <tr>
                                    <th scope="row">Quantidade Produzida</th>
                                    <td className=''>
                                        <input
                                            className='form form-control'
                                            placeholder='0,00'
                                            {...register('lastYear.quantityProduced')}
                                        />
                                    </td>
                                    <td>
                                        <input
                                            className='form form-control'
                                            placeholder='0,00'
                                            {...register('baseYear.quantityProduced')}
                                        />
                                    </td>
                                    <td>
                                        <input
                                            className='form form-control'
                                            placeholder='0,00'
                                            {...register('nextYear.quantityProduced')}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">Faturamento Bruto</th>
                                    <td>
                                        <input
                                            className='form form-control'
                                            placeholder='0,00'
                                            {...register('lastYear.grossRevenue')}
                                        />
                                    </td>
                                    <td>
                                        <input
                                            className='form form-control'
                                            placeholder='0,00'
                                            {...register('baseYear.grossRevenue')}
                                        />
                                    </td>
                                    <td>
                                        <input
                                            className='form form-control'
                                            placeholder='0,00'
                                            {...register('nextYear.grossRevenue')}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">Exportações(USD)</th>
                                    <td>
                                        <input
                                            className='form form-control'
                                            placeholder='0,00'
                                            {...register('lastYear.exports')}
                                        />
                                    </td>
                                    <td>
                                        <input
                                            className='form form-control'
                                            placeholder='0,00'
                                            {...register('baseYear.exports')}
                                        />
                                    </td>
                                    <td>
                                        <input
                                            className='form form-control'
                                            placeholder='0,00'
                                            {...register('nextYear.exports')}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">Partes e componentes Importados(USD)</th>
                                    <td>
                                        <input
                                            className='form form-control'
                                            placeholder='0,00'
                                            {...register('lastYear.importedComponents')}
                                        />
                                    </td>
                                    <td>
                                        <input
                                            className='form form-control'
                                            placeholder='0,00'
                                            {...register('baseYear.importedComponents')}
                                        />
                                    </td>
                                    <td>
                                        <input
                                            className='form form-control'
                                            placeholder='0,00'
                                            {...register('nextYear.importedComponents')}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">Investimentos Adicionais na Produção</th>
                                    <td>
                                        <input
                                            className='form form-control'
                                            placeholder='0,00'
                                            {...register('lastYear.additionalInvestments')}
                                        />
                                    </td>
                                    <td>
                                        <input
                                            className='form form-control'
                                            placeholder='0,00'
                                            {...register('baseYear.additionalInvestments')}
                                        />
                                    </td>
                                    <td>
                                        <input
                                            className='form form-control'
                                            placeholder='0,00'
                                            {...register('nextYear.additionalInvestments')}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">Quantidade Adicional de Pessoal Direto</th>
                                    <td>
                                        <input
                                            className='form form-control'
                                            placeholder='0,00'
                                            {...register('lastYear.additionalQuantity')}
                                        />
                                    </td>
                                    <td>
                                        <input
                                            className='form form-control'
                                            placeholder='0,00'
                                            {...register('baseYear.additionalQuantity')}
                                        />
                                    </td>
                                    <td>
                                        <input
                                            className='form form-control'
                                            placeholder='0,00'
                                            {...register('nextYear.additionalQuantity')}
                                        />
                                    </td>
                                </tr> */}
                            </tbody>
                        </table>
                    </ModalContainer.Body>
                    <ModalContainer.Footer>
                        <Button className='btn btn-primary w-100' type='submit'>
                            Salvar Dado Economico
                        </Button>
                    </ModalContainer.Footer>
                </form>
            </ModalContainer >
        </>
    )
}