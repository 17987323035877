import { useState } from 'react';
import { Controller } from 'react-hook-form';
import './styles.css';

interface UploadInputProps {
    name: string;
    label: string;
    setFile: any;
    clearValue: boolean;
    setClearValue: any;
    isDisabled: boolean;
}


export function UploadInput({ name, label, setFile, clearValue, setClearValue, isDisabled }: UploadInputProps) {
    const [fileName, setFileName] = useState('Selecione um arquivo');
    const [clearInput, setClearInput] = useState('');

    function handleSelectFile(event: any) {
        setClearValue(false);
        setFile(event.target.files[0]);
        setFileName(event.target.files[0].name);
    }


    return (
        <div className='input-file-container mb-2'>
            <label htmlFor={name} className='input-file-label'>{label}</label>
            <label htmlFor={name} className='input-file-text' title={clearValue ? '' : fileName}>{clearValue ? 'Selecione um arquivo' : fileName}</label>
            <input type="file" disabled={isDisabled} name={name} id={name} onChange={(event) => handleSelectFile(event)} />
        </div>
    )
}