import { useContext, useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import Select from 'react-select';
import { ISelectProps } from "../../../interfaces/ISelectProps";
import { allTypesToSelectHome } from "../../../utils/AllTypesToSelectHome";
import { toast } from "react-toastify";
import { api } from "../../../services/axios";
import { CompanyContext } from "../../../contexts/companyContext";
import { LineChartHomeViewModel } from "../../../models/lineChartHomeViewModel";

interface ILineChartProps {
    setLoading: React.Dispatch<React.SetStateAction<boolean>>
}

export default function LineChart(props: ILineChartProps) {
    const { company } = useContext(CompanyContext);
    const [invoicings, setInvoicings] = useState<LineChartHomeViewModel[]>([]);
    const [obligations, setObligations] = useState<LineChartHomeViewModel[]>([]);
    const [investments, setInvestments] = useState<LineChartHomeViewModel[]>([]);
    const [years, setYears] = useState<number[]>([]);
    const [selecteds, setSelecteds] = useState<string[]>(["invoicing"]);
    const [typesSelect, setTypesSelect] = useState<ISelectProps[]>(() => {
        var types = [] as ISelectProps[];
        if (selecteds.length > 0) {
            allTypesToSelectHome.forEach((type: ISelectProps) => {
                if (selecteds.includes(type.value)) {
                    types.push(type);
                }
            });
        }
        return types;
    });

    const optionsLine = {
        responsive: true,
        plugins: {
            legend: {
                display: false,
            },
        },
    };

    const getYears = () => {
        if (invoicings.length > 0) {
            return invoicings.map(x => x.serviceYear)
        } else if(obligations.length > 0) {
            return obligations.map(x => x.serviceYear)
        } else {
            return investments.map(x => x.serviceYear)
        }
    }

    const dataLine = {
        labels: getYears(),
        datasets: [
            {
                label: 'Faturamentos: R$',
                data: invoicings.map(x => x.value),
                borderColor: '#0000a0',
                backgroundColor: '#0000a0',
            },
            {
                label: 'Obrigações: R$',
                data: obligations.map(x => x.value),
                borderColor: '#0079f6',
                backgroundColor: '#0079f6',
            },
            {
                label: 'Investimentos em P&D: R$',
                data: investments.map(x => x.value),
                borderColor: '#00c6de',
                backgroundColor: '#00c6de',
            },
        ],
    };

    const handleSelect = (e: ISelectProps[]) => {
        var values = e.map((info: ISelectProps) => info.value);
        setTypesSelect(e);
        setSelecteds(values);
    }

    const resetAll = () => {
        setInvestments([]);
        setInvoicings([]);
        setObligations([]);
    }

    async function getAllValuesToChart() {
        try {
            props.setLoading(true);

            if (selecteds.includes("invoicing")) {
                await api.get(`/Home/GetTotalInvoicingForServicesByCompanyId?companyId=${company.companyId}&year=${company.year}`).then(resp => {
                    if (resp.status == 200) {
                        setInvoicings(resp.data.data ?? []);
                    }
                });
            }
            if (selecteds.includes("obligation")) {
                await api.get(`/Home/GetTotalObligationForServicesByCompanyId?companyId=${company.companyId}&year=${company.year}`).then(resp => {
                    if (resp.status == 200) {
                        setObligations(resp.data.data ?? []);
                    }
                });
            }
            if (selecteds.includes("investment")) {
                await api.get(`/Home/GetTotalInvestmentsForServicesByCompanyId?companyId=${company.companyId}&year=${company.year}`).then(resp => {
                    if (resp.status == 200) {
                        setInvestments(resp.data.data ?? []);
                    }
                });
            }
        } catch (error: any) {
            toast.error(error);
        } finally {
            props.setLoading(false);
        }
    }

    useEffect(() => {
        resetAll();
        if (selecteds.length > 0) {
            getAllValuesToChart();
        }
    }, [selecteds]);

    return (
        <div className='card-body card-shadow-background w-100 me-2 d-flex flex-column'>
            <div className='w-100 mt-2 font-size-title mb-4'>
                <div className="w-100 text-center mb-3">
                    <span className="text-bold">Personalizado por Tempo</span>
                </div>
                <div className="w-100 d-flex align-items-center justify-content-center">
                    <Select
                        isMulti
                        onChange={(e: any) => handleSelect(e)}
                        options={allTypesToSelectHome}
                        defaultValue={typesSelect}
                        className="basic-multi-select w-75"
                        classNamePrefix="select"
                    />
                </div>
            </div>
            <div className='d-flex align-items-center justify-content-center'>
                <Line className='chart-body' options={optionsLine} data={dataLine} />
            </div>
        </div>
    )
}