import { useCallback, useState } from "react";
import { Button, Card } from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import { FaFileImport, FaFileUpload, FaTimes } from "react-icons/fa";
import './styles.css';
import { toast } from "react-toastify";
import { useCompany } from "../../../hooks/useCompany";
import { loadAsync } from 'jszip';
import { apiXML } from "../../../services/axiosToXML";
import { useAuth } from "oidc-react";
import { v4 } from 'uuid';
import { getSession } from "../../../utils/storageSession";
import { TokenSessionName } from "../../../Static/TokenNames";

interface IUploadFileComponentProps {
    toggleLoading: (data: boolean) => void,
    userEmail: string,
    hide: () => void,
}

const UploadInvoicingFileComponent = (props: IUploadFileComponentProps) => {
    const user = useAuth();
    const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
    const { company } = useCompany();

    const onDrop = useCallback((acceptedFiles: File[]) => {
        setSelectedFiles([...selectedFiles, ...acceptedFiles])
    }, [selectedFiles]);

    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({ onDrop });

    const getAllFilesToSend = async () => {
        var files = acceptedFiles;
        var listFiles: Blob[] = [];
        var listMIME = ["application/x-rar-compressed", "application/vnd.rar"]
        var length = files.length;


        for (let i = 0; i < length; i++) {
            switch (files[i].type) {
                case "application/x-zip-compressed":
                case "application/x-7z-compressed":
                case "application/zip":
                    await loadAsync(files[i]).then(async (resp) => {
                        for (let zobj of Object.values(resp.files)) {
                            if (zobj.dir) continue;
                            const zblob = await zobj.async("blob");

                            listFiles.push(new File([zblob], zobj.name));
                        }
                    });
                    break;
                case "text/xml":
                case "application/xml":
                    listFiles.push(files[i]);
                    break;
                default:
                    if (listMIME.includes(files[i].type)) {
                        toast.error('Por favor, troque seu arquivo .rar por um .zip e tente novamente!');
                        return;
                    } else {
                        toast.error('Por favor, insira um arquivo válido!');
                        return;
                    }
            }
        };

        handleUploadFile(listFiles);
    }

    const MassiveUploadFile = async (files: Blob[], total: number, uuid: string) => {
        try {
            var UploadFileCommand = new FormData()

            files.map((file: Blob) => {
                UploadFileCommand.append('files', file);
            });

            const URL = `/upload/Invoicing/uploadXML/${company.year}/${company.taxNumber}/${company.serviceId}/${company.companyId}/${uuid}/${total}/${props.userEmail}`;

            await apiXML.post(URL, UploadFileCommand, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${getSession(TokenSessionName) ?? user?.userData?.access_token}`,
                }
            });
        } catch (error: any) {
            toast.error(error);
        }
    }

    const handleUploadFile = async (files: Blob[]) => {
        props.toggleLoading(true);
        try {
            var qtd = 1000;
            var total = files.length;
            var uuid = v4();
            const uploadPromises = [];

            for (let i = 0; i < total; i += qtd) {
                const batch = files.slice(i, i + qtd);
                const uploadPromise = MassiveUploadFile(batch, total, uuid);
                uploadPromises.push(uploadPromise);
            }

            await Promise.all(uploadPromises)
                .then(() => {
                    removeAllFiles();
                    toast.warn("O upload está sendo feito! assim que concluir, enviaremos um email a você!");
                    props.hide();
                })
                .catch(error => {
                    toast.error('Erro durante o upload', error);
                });
        } catch (error: any) {
            toast.error(error);
        } finally {
            props.toggleLoading(false);
        }
    }

    const handleRemoveFile = (file: any) => {
        const newFiles = [...selectedFiles];
        newFiles.splice(newFiles.indexOf(file), 1);
        setSelectedFiles(newFiles);
    }

    const removeAllFiles = () => {
        setSelectedFiles([]);
    }

    return (
        <div className="dropzoneSection">
            <section className="dropzoneContainer">
                <div {...getRootProps({ className: 'dropzone w-100 h-100 d-flex flex-column align-items-center justify-content-center p-2' })}>
                    <input {...getInputProps()} />
                    {selectedFiles.length == 0 ?
                        <span className="text-center">Arraste e solte os arquivos aqui ou clique para selecionar os arquivos</span>
                        :
                        <div className="fileContainer">
                            <div className="fileContent">
                                {selectedFiles.map((file: any, index: any) => {
                                    return (
                                        <Card className="cardFile" title={file.path} key={file.path} >
                                            <Card.Body className="fileComponent d-flex align-items-center justify-content-center flex-column">
                                                <FaFileImport size={60} />
                                                <div className="fileOverflow mt-2">
                                                    <strong className="ms-2">
                                                        {file.path}
                                                    </strong>
                                                </div>
                                                <FaTimes onClick={(e) => { handleRemoveFile(index); e.stopPropagation() }} className="cardRemoveFileIcon" title="Remover arquivo" />
                                            </Card.Body>
                                        </Card>
                                    )
                                })}
                            </div>
                        </div>
                    }
                </div>
            </section>
            <Button disabled={selectedFiles.length <= 0 ? true : false} onClick={getAllFilesToSend} variant="primary" className="mt-2 w-100">
                <FaFileUpload className="svgWhite" />
                <strong className="ms-2 textWhite">
                    Upload
                </strong>
            </Button>
        </div>
    )
}

export default UploadInvoicingFileComponent;