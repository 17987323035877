import { TextField } from "@mui/material";
import { ProductProps } from "../../../../interfaces/Product/IProduct";
import { Col, Row } from "react-bootstrap";
import { formatDate, formatISODate } from "../../../../utils/formatDate";
import { ProductModelProps } from "../../../../interfaces/ProductModel/IProductModel";

interface ProductDataModelCharacterizationProps{
    product?: ProductProps | undefined;
    model: ProductModelProps;
}

const manufacturingTypeDescription: any = {
    0: 'Própria',
    1: 'Terceirizada com Controle de Assunção',
    2: 'Terceirizada sem Controle de Assunção'
}

export function ProductDataModelCharacterization({ product, model }: ProductDataModelCharacterizationProps) {
    return (
        <div className='mb-4'>
            <div className="row mb-3">
                <div className='  col-8'>
                    <label className='form-label '></label>
                    <TextField
                        label="Nome do Modelo" variant="outlined"
                        disabled
                        className='form-control '
                        value={model.name}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </div>
                <div className='  col '>
                    <label className='form-label '></label>
                    <TextField
                        label="NCM" variant="outlined"
                        disabled
                        className='form-control '
                        placeholder="Número de NCM..."
                        value={product?.ncm?.toString()}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </div>
            </div>
            <Row className='mb-3'>
                <Col className=''>
                    <label className='form-label'></label>
                    <TextField
                        label="Tipo de Fabricação"
                        disabled
                        className='form-control'
                        value={product?.manufacturingType != null ? manufacturingTypeDescription[product?.manufacturingType] : ''}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Col>
                <Col className=''>
                    <label className='form-label'></label>
                    <TextField
                        label="Início da Fabricação"
                        disabled
                        className='form-control'
                        value={formatISODate(model.createdDate)}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Col>
                <Col className=''>
                    <label className='form-label'></label>
                    <TextField
                        label="Portaria de PPB"
                        disabled
                        className='form-control'
                        value={product?.ppbDescription?.toString()}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Col>
                <Col className=''>
                    <label className='form-label'></label>
                    <TextField
                        label="Empresa Cumprirá o PPB?"
                        disabled
                        className='form-control'
                        value={product?.isMicroComputer != null ? product?.isMicroComputer == true ? 'Sim' : 'Não' : ''}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Col>
                <Col className=''>
                    <label className='form-label'></label>
                    <TextField
                        label="Origem da Tecnologia"
                        disabled
                        className='form-control'
                        value={product?.technologyOrigin != null ? product?.technologyOrigin == 0 ? 'Nacional' : 'Estrangeira' : ''}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Col>
            </Row>
            <Row>
                <Col className='' xs={6}>
                    <label className='form-label'></label>
                    <TextField
                        label="Nome do detentor da tecnologia que autorizou sua produção no Brasil"
                        disabled
                        className='form-control'
                        value={product?.technologyOwner?.toString()}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Col>
                <Col className=''>
                    <label className='form-label'></label>
                    <TextField
                        label="O Produto é Patenteado?"
                        disabled
                        className='form-control'
                        value={product?.hasPatents != null ? product?.hasPatents == true ? 'Sim' : 'Não' : ''}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Col>
                <Col className=''>
                    <label className='form-label'></label>
                    <TextField
                        label="O Produto é Microcomputador?"
                        disabled
                        className='form-control'
                        value={product?.isMicroComputer != null ? product?.isMicroComputer == true ? 'Sim' : 'Não' : ''}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Col>
                <Col className=''>
                    <label className='form-label'></label>
                    <TextField
                        label="Contrato de Assunção?"
                        disabled
                        className='form-control'
                        value={product?.hasContract != null ? product?.hasContract == true ? 'Sim' : 'Não' : ''}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Col>
            </Row>
        </div>
    )
}