import { Button } from "@mui/material";
import { TfiDownload } from "react-icons/tfi";
import { IXMLLogsProps } from "../../../interfaces/IXMLLogsProps";
import { FillTable } from "../../Shared/FillTable";
import { TablePaginationFooter } from "../../Shared/Pagination";
import { ProgressEnum } from "../../../utils/AllSelectsToLogs";
import { CustomProvider, DateRangePicker } from 'rsuite';
import ptBR from 'rsuite/locales/pt_BR';
import './styles.css';

interface IXMLLogsComponent {
    getAllXMLLogs: (page: number) => Promise<void>;
    setUserEmail: React.Dispatch<React.SetStateAction<string>>;
    setBatchName: React.Dispatch<React.SetStateAction<string>>;
    setStatus: React.Dispatch<React.SetStateAction<string>>;
    setActionXMLStart: React.Dispatch<React.SetStateAction<Date | undefined>>;
    setActionXMLEnd: React.Dispatch<React.SetStateAction<Date | undefined>>;
    allUserEmails: string[];
    allBatchNames: string[];
    xmlLogs: IXMLLogsProps[] | undefined;
    total: number | undefined;
    GetExcelXMLLog: () => Promise<void>;
}

export default function XMLLogsComponent(props: IXMLLogsComponent) {
    const { 
        getAllXMLLogs, 
        setUserEmail, 
        setBatchName, 
        setStatus, 
        allUserEmails, 
        allBatchNames, 
        xmlLogs, 
        total, 
        setActionXMLStart, 
        setActionXMLEnd, 
        GetExcelXMLLog 
    } = props;

    return (
        <div className="action-log-container">
            <div className="d-flex align-items-center justify-content-start mb-4">
                <select className="form-select" onChange={e => setUserEmail(e.target.value)}>
                    <option value="" selected>Selecione o Usuário</option>
                    {allUserEmails.map((userEmail, index) => {
                        return (
                            <option key={index} value={userEmail}>{userEmail}</option>
                        )
                    })}
                </select>
                <select className="form-select mx-2" onChange={e => setBatchName(e.target.value)}>
                    <option value="" selected>Selecione um Lote</option>
                    {allBatchNames.map((batchName, index) => {
                        return (
                            <option key={index} value={batchName}>{batchName}</option>
                        )
                    })}
                </select>
                <select className="form-select" onChange={e => setStatus(e.target.value)}>
                    <option value="" selected>Selecione um Status</option>
                    {ProgressEnum.map((progress, index) => {
                        return(
                            <option key={index} value={progress.value}>{progress.label}</option>
                        )
                    })}
                </select>
                <CustomProvider locale={ptBR}>
                    <DateRangePicker
                        className="date-container"
                        format="dd/MM/yyyy"
                        onChange={e => {
                            if (e != null) {
                                setActionXMLStart(e[0]);
                                setActionXMLEnd(e[1]);
                            } else {
                                setActionXMLStart(undefined);
                                setActionXMLEnd(undefined);
                            }
                        }}
                    />
                </CustomProvider>
                <Button
                    variant="contained"
                    className="d-flex align-items-center justify-content-center w-100"
                    endIcon={<TfiDownload className="icon-white" size={16} />}
                    onClick={GetExcelXMLLog}
                >
                    Extrair Resultados
                </Button>
            </div>
            <table className='table table-bordered table-striped table-hover mt-2'>
                <thead>
                    <tr>
                        <th scope="col">Usuário</th>
                        <th scope="col">Email</th>
                        <th scope="col">Data</th>
                        <th scope="col">Lote</th>
                        <th scope="col">Arquivo</th>
                        <th scope="col">Concluído?</th>
                        <th scope="col">Mensagem de Erro</th>
                    </tr>
                </thead>
                <tbody>
                    {xmlLogs?.map((xml, index) => {
                        return (
                            <tr key={index}>
                                <td>{xml.userName}</td>
                                <td>{xml.email}</td>
                                <td>{new Date(xml.created).toLocaleString('pt-BR', { timeZone: "America/Sao_Paulo", hour12: false })}</td>
                                <td>{xml.batchName}</td>
                                <td className="error-message-style" title={xml.fileName}>{xml.fileName}</td>
                                <td>{ProgressEnum[xml.status].label}</td>
                                <td className="error-message-style" title={xml.errorMessage}>{xml.errorMessage}</td>
                            </tr>
                        )
                    })}
                    {xmlLogs && <FillTable columns={7} rows={xmlLogs.length} />}
                </tbody>
            </table>
            <TablePaginationFooter
                totalItems={total ?? 0}
                change={getAllXMLLogs}
            />
        </div>
    )
}