import * as yup from "yup";

export const schemaInternalProjects = yup.object().shape({
  projectName: yup.string()
    .required("O Campo Nome do Projecto é obrigatório")
    .max(250, "Por favor, insira um valor entre 5 e 250 caracteres")
    .min(5, "Por favor, insira um valor entre 5 e 250 caracteres"),

  startDate: yup.date().nullable(true),

  endDate: yup.date().nullable(true),

  identification: yup.string()
    .trim()
    .nullable(true)
    .when('licenseDate', {
      is: (licenseDate: string | null) => licenseDate != null && licenseDate != "",
      then: yup.string()
        .required('O Campo é obrigatório')
        .max(50000, 'Por favor, insira um valor válido')
        .min(1, 'Por favor, insira um valor válido')
        .nullable()
    }).transform((_, val) => val !== '' ? val : null),

  acronym: yup.string()
    .trim()
    .nullable(true)
    .when('licenseDate', {
      is: (licenseDate: string | null) => licenseDate != null && licenseDate != "",
      then: yup.string()
        .required('O Campo é obrigatório')
        .nullable()
    }).transform((_, val) => val !== '' ? val : null),

  typology: yup.string().nullable(true),

  other: yup.string()
    .trim()
    .nullable(true)
    .when('licenseDate', {
      is: (licenseDate: string | null) => licenseDate != null && licenseDate != "",
      then: yup.string()
        .required('O Campo é obrigatório')
        .max(50000, 'Por favor, insira um valor válido')
        .min(1, 'Por favor, insira um valor válido')
        .nullable()
    }).transform((_, val) => val !== '' ? val : null),

  projectToExchangePPBforRD: yup.string().nullable(true),

  ranges: yup.string().nullable(true),

  coverage: yup.string().nullable(true),

  development: yup.string().nullable(true),

  economicActivity: yup.string().trim().nullable(true).max(100, 'Por favor, insira um valor maxímo de 100 caracteres.'),

  hasPublications: yup.bool()
    .nullable(true)
    .when('licenseDate', {
      is: (licenseDate: string | null) => licenseDate != null && licenseDate != "",
      then: yup.bool()
        .required('O Campo é obrigatório')
        .nullable(true)
    })
    .transform((_, val) => typeof val == "string" && val != "" ? val === "true" ? true : false : null),

  hasIntellectualProperty: yup.bool()
    .nullable(true)
    .when('licenseDate', {
      is: (licenseDate: string | null) => licenseDate != null && licenseDate != "",
      then: yup.bool()
        .required('O Campo é obrigatório')
        .nullable(true)
    })
    .transform((_, val) => typeof val == "string" && val != "" ? val === "true" ? true : false : null),

  nature: yup.string().nullable(true),

  name: yup.string()
    .nullable(true)
    .when('licenseDate', {
      is: (licenseDate: string | null) => licenseDate != null && licenseDate != "",
      then: yup.string()
        .required('O Campo name é obrigatório')
        .max(250, 'Por favor, insira um valor entre 5 e 250 caracteres')
        .min(5, 'Por favor, insira um valor entre 5 e 250 caracteres')
        .nullable()
    }).transform((_, val) => val !== '' ? val : null),

  cpf: yup.string()
    .nullable(true)
    .when('licenseDate', {
      is: (licenseDate: string | null) => licenseDate != null && licenseDate != "",
      then: yup.string()
        .required('O Campo é obrigatório')
        .max(14, 'Por favor, insira um valor válido')
        .min(14, 'Por favor, insira um valor válido')
        .nullable()
    }).transform((_, val) => val !== '' ? val : null),

  hasCpf: yup.bool().nullable(true),

  telephone: yup.string()
    .nullable(true)
    .when('licenseDate', {
      is: (licenseDate: string | null) => licenseDate != null && licenseDate != "",
      then: yup.string()
        .required('O Campo é obrigatório')
        .max(15, 'Por favor, insira um valor válido')
        .min(14, 'Por favor, insira um valor válido')
        .nullable()
    }).transform((_, val) => val !== '' ? val : null),

  phoneType: yup.string().nullable(true),

  projectScope: yup.string().trim().nullable(true).max(32000, 'Por favor, insira um valor até 32 mil caracteres.'),

  developmentBaseYear: yup.string().trim().nullable(true).max(32000, 'Por favor, insira um valor até 32 mil caracteres.'),

  stepsForeseenProject: yup.string().trim().nullable(true).max(32000, 'Por favor, insira um valor até 32 mil caracteres.'),

  scientificTechnicalProblem: yup.string().trim().nullable(true).max(32000, 'Por favor, insira um valor até 32 mil caracteres.'),

  objetivo: yup.string().trim().nullable(true).max(50000, 'Por favor, insira um valor até 50 mil caracteres.'),

  descriptionActivities: yup.string().trim().nullable(true).max(50000, 'Por favor, insira um valor até 50 mil caracteres.'),

  resultsObtained: yup.string().trim().nullable(true).max(50000, 'Por favor, insira um valor até 50 mil caracteres.')

});

