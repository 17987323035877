export function formatCurrency(value?: string) {
    var options = { currency: 'BRL', minimumFractionDigits: 2, maximumFractionDigits: 3 };
    return new Intl.NumberFormat('pt-BR', options).format(
        parseFloat(value ?? "0")
    )
}

export function formatCurrencyWithSign(value?: string) {
    var options = { style: "currency", currency: 'BRL', minimumFractionDigits: 2, maximumFractionDigits: 3 };
    return new Intl.NumberFormat('pt-BR', options).format(
        parseFloat(value ?? "0")
    )
}

function verifyTwoDecimalPlaces(number : string | number){
    const parsedNumber = parseFloat(number.toString());

    const fixedNumber = parsedNumber.toFixed(2);

    return parsedNumber.toString() === fixedNumber;

}

export function formatUSDCurrencyToFloat(value?: string | number) {
    if (value) {       
        if (verifyTwoDecimalPlaces(value))
            value = value.toString()?.replace(',', '.');
        else
            value = value.toString()?.replace(/\./g, '').replace(',', '.');

        var floatValue = parseFloat(value);

        return floatValue;
    }

    return 0;
}

export function formatBRLCurrencyToFloat(value?: string | number) {
    if (value) {
        if (verifyTwoDecimalPlaces(value))
            value = value.toString()?.replace(',', '.');
        else
            value = value.toString()?.replace(/\./g, '').replace(',', '.');
        
        var floatValue = parseFloat(value);
        return floatValue;
    }

    return 0;
}

export function convertCurrencyToFloat(value: string) {
    if(value != "") {
        var splited = value.split("R$");
        return parseFloat(splited[splited.length > 1 ? 1 : 0].trim().replace(".", "").replace(",", "."));
    } else {
        return 0;
    }
}