import { api } from "../../../services/axios";
import { useEffect, useState } from "react";
import Loader from "../../../components/Shared/Loader";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import AcquisitionDetailsForm, { IAcquisitionDetailsProps } from "../../../components/Acquisitions/AcquisitionDetailsForm";

export default function AcquisitionsDetails() {
    const { id } = useParams();
    const [loading, setLoading] = useState(false);
    const [acquisitionModel, setAcquisitionModel] = useState({} as IAcquisitionDetailsProps);

    async function getInvoicingModel() {
        setLoading(true);
        try {
            const result = await api.get(`acquisitions/getById/${id}`);
            const response = await result.data;

            setAcquisitionModel(response.data);
        }
        catch (error: any) {
            toast.error(error);
        }
        finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        getInvoicingModel();
    }, []);

    return (
        <>
            {loading && <Loader />}
            <AcquisitionDetailsForm acquisitionModel={acquisitionModel} />
        </>
    );
}