import { FormEvent, useContext, useState } from 'react';
import { Modal as ModalContainer } from 'react-bootstrap';
import Loader from '../../Shared/Loader';

interface ModalProps {
    isShowing: boolean;
    hide: () => void;
    id: string;
    oldPathName: string;
    context: React.Context<any>;
    internalProjectsId?: string;
}

export function EditPathModal({ isShowing, hide, id, oldPathName, context, internalProjectsId }: ModalProps) {
    const { updatePath } = useContext(context);
    const [pathName, setPathName] = useState(oldPathName);
    const [loading, setLoading] = useState(false);

    async function handleCreatePath(e: FormEvent) {
        e.preventDefault();
        setLoading(true);
        try {
            updatePath({
                pathName: pathName,
                id: id,
                internalProjectsId: internalProjectsId,
            }).then(() => {
                setLoading(false);
            });
        }
        catch (err: any) {
        }
        finally {
        }
    }

    return (
        isShowing
            ?
            (
                <>
                    {loading && <Loader />}
                    <ModalContainer show={isShowing} onHide={hide}>
                        <ModalContainer.Header closeButton>
                            <ModalContainer.Title>Editar Sub-Pasta</ModalContainer.Title>
                        </ModalContainer.Header>
                        <ModalContainer.Body>
                            <form onSubmit={handleCreatePath}>
                                <div className='mb-3'>
                                    <label htmlFor="" className='form-label'>Nome</label>
                                    <input type="text" className='form-control' value={pathName} onChange={(e) => setPathName(e.target.value)} />
                                </div>
                                <button className='btn btn-success w-100'>Salvar</button>
                            </form>
                        </ModalContainer.Body>
                    </ModalContainer >
                </>
            )
            :
            null
    )
}