import * as yup from 'yup';

const message: string = "Por favor, insira um valor ate 250 caracteres";

export const schemaProjectDescription = yup.object().shape({

  fieldObjectiveOne: yup.string().trim()
    .nullable(true)
    .when('licenseDate', {
      is: (licenseDate: string | null) => licenseDate != null && licenseDate != "",
      then: yup.string()
        .required('O Campo é obrigatório')
        .max(250, message)
        .min(1, message)
        .nullable()
    }).transform((_, val) => val !== '' ? val : null),

  fieldObjectiveTwo: yup.string().trim()
    .nullable(true)
    .when('licenseDate', {
      is: (licenseDate: string | null) => licenseDate != null && licenseDate != "",
      then: yup.string()
        .required('O Campo é obrigatório')
        .max(250, message)
        .min(1, message)
        .nullable()
    }).transform((_, val) => val !== '' ? val : null),

  fieldObjectiveThree: yup.string().trim()
    .nullable(true)
    .when('licenseDate', {
      is: (licenseDate: string | null) => licenseDate != null && licenseDate != "",
      then: yup.string()
        .required('O Campo é obrigatório')
        .max(250, message)
        .min(1, message)
        .nullable()
    }).transform((_, val) => val !== '' ? val : null),

  fieldDescriptionOfActivitiesOne: yup.string().trim()
    .nullable(true)
    .when('licenseDate', {
      is: (licenseDate: string | null) => licenseDate != null && licenseDate != "",
      then: yup.string()
        .required('O Campo é obrigatório')
        .max(250, message)
        .min(1, message)
        .nullable()
    }).transform((_, val) => val !== '' ? val : null),

  fieldDescriptionOfActivitiesTwo: yup.string().trim()
    .nullable(true)
    .when('licenseDate', {
      is: (licenseDate: string | null) => licenseDate != null && licenseDate != "",
      then: yup.string()
        .required('O Campo é obrigatório')
        .max(250, message)
        .min(1, message)
        .nullable()
    }).transform((_, val) => val !== '' ? val : null),

  fieldDescriptionOfActivitiesThree: yup.string().trim()
    .nullable(true)
    .when('licenseDate', {
      is: (licenseDate: string | null) => licenseDate != null && licenseDate != "",
      then: yup.string()
        .required('O Campo é obrigatório')
        .max(250, message)
        .min(1, message)
        .nullable()
    }).transform((_, val) => val !== '' ? val : null),

  fieldResultsObtainedOne: yup.string().trim()
    .nullable(true)
    .when('licenseDate', {
      is: (licenseDate: string | null) => licenseDate != null && licenseDate != "",
      then: yup.string()
        .required('O Campo é obrigatório')
        .max(250, message)
        .min(1, message)
        .nullable()
    }).transform((_, val) => val !== '' ? val : null),

  fieldResultsObtidosTwo: yup.string().trim()
    .nullable(true)
    .when('licenseDate', {
      is: (licenseDate: string | null) => licenseDate != null && licenseDate != "",
      then: yup.string()
        .required('O Campo é obrigatório')
        .max(50000, message)
        .min(1, message)
        .nullable()
    }).transform((_, val) => val !== '' ? val : null),

});