import { Collapse, Modal as ModalContainer } from 'react-bootstrap';
import { BsFillCloudArrowUpFill } from 'react-icons/bs';
import { IoCodeSlash } from 'react-icons/io5';
import { IconType } from 'react-icons/lib';

interface UploadModalProps{
    icon: IconType;
    setFile: React.Dispatch<React.SetStateAction<Blob | undefined>>;
    isShowing: boolean;
    toggle: () => void;
    action: (() => void) | (() => Promise<void>)
}

export function UploadModal({ icon : Icon, setFile, isShowing, toggle, action } : UploadModalProps) {
    return (
        <ModalContainer show={isShowing} onHide={toggle} className='modal-lg'>
            <ModalContainer.Header>
                <div className='modal-custom-header'>
                    <Icon size={100} />
                    <h3>
                        <strong>
                            Upload de Excel
                        </strong>
                    </h3>
                    <p>Selecione um arquivo .xlsx para realizar o upload dos dados</p>
                </div>
            </ModalContainer.Header>
            <ModalContainer.Body>
                <input type="file" onChange={(e) => e.target.files && setFile(e.target.files[0])} className='form-control' />
                <button className='btn btn-success mt-3' type='button' onClick={action}>
                    <BsFillCloudArrowUpFill className='me-2' size={20} />
                    Carregar documento
                </button>
            </ModalContainer.Body>
        </ModalContainer >
    )
}