export function getSession(sessionKey : string){
    const response = sessionStorage.getItem(sessionKey);
    return response;
}

export function setSession(sessionKey: string, sessionValue: string){
    sessionStorage.setItem(sessionKey, sessionValue);
}

export function clearSession(){
    sessionStorage.clear();
}
