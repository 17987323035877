import { AiFillInfoCircle } from "react-icons/ai";
import { SummaryFieldsProps } from "../../../pages/Credit/CreditTabs";
import { formatCurrency } from "../../../utils/formatCurrency";
import { useEffect, useState } from "react";
import { api } from "../../../services/axios";
import { useParams } from "react-router-dom";

interface IInvestValuesFormProps {
    isSudamSudene: boolean;
    validated: boolean;
    investValueSudamSudeneCO: number;
    investValueSudamSudeneCOTecNac: number;
    investValueOthersRegions: number;
    investValueOthersRegionsTecNac: number;
    sudamSudeneCOConsolidation: SummaryFieldsProps;
    sudamSudeneCOTecNacConsolidation: SummaryFieldsProps;
    othersRegionsConsolidation: SummaryFieldsProps;
    othersRegionsTecNacConsolidation: SummaryFieldsProps;
    setInvestValueOthersRegions: React.Dispatch<React.SetStateAction<number>>;
    setInvestValueOthersRegionsTecNac: React.Dispatch<React.SetStateAction<number>>;
    setInvestValueSudamSudeneCO: React.Dispatch<React.SetStateAction<number>>;
    setInvestValueSudamSudeneCOTecNac: React.Dispatch<React.SetStateAction<number>>;
    reCalcAllSummaryValues(): Promise<void>;
}

export function InvestValuesForm(props: IInvestValuesFormProps) {
    const { serviceId, creditId } = useParams();
    const validNumbers = new RegExp(/^\d+(\.\d{1,})?$/i);
    const [obligationTecnacTotalValue, setObligationTecNacTotalValue] = useState(0);
    const [obligationTotalValue, setObligationTotalValue] = useState(0);

    function validate(value: string) {
        var resp = validNumbers.test(value);

        return resp
    }

    return (
        <div className='card bg-light'>
            <div className='table-responsive'>
                <form>
                    <table className='summary-table'>
                        <thead>
                            <tr>
                                <th></th>
                                <th>
                                    Investimentos em PD&I
                                </th>
                                <th>
                                    Faturamento Total
                                </th>
                                <th>
                                    Valor de Crédito
                                </th>
                                <th>
                                    Limite de Crédito
                                </th>
                                <th>
                                    Crédito - Excedente
                                </th>
                                <th>
                                    Crédito - Remanescente
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th>
                                    Sudam/ Sudene/ Centro- Oeste
                                </th>
                                <td>
                                    <input
                                        type="text"
                                        className='form-control'
                                        defaultValue={props.isSudamSudene || props.validated ? formatCurrency(props.investValueSudamSudeneCO.toFixed(2)) : props.investValueSudamSudeneCO.toFixed(2).replace(".", ",")}
                                        onChange={e => props.setInvestValueSudamSudeneCO(validate(e.target.value.replace(",", ".")) ? parseFloat(e.target.value.replace(",", ".")) : 0)}
                                        placeholder='0,00'
                                        disabled={!props.isSudamSudene || props.validated}
                                        onBlur={props.reCalcAllSummaryValues}
                                    />
                                    <AiFillInfoCircle size={15} />
                                </td>
                                <td>
                                    <input
                                        type="text"
                                        className='form-control'
                                        value={formatCurrency(props.sudamSudeneCOConsolidation.invoicingConsolidation.toFixed(2))}
                                        readOnly={true}
                                        disabled
                                    />
                                    <AiFillInfoCircle size={15} />
                                </td>
                                <td>
                                    <input
                                        type="text"
                                        className='form-control'
                                        value={formatCurrency(props.sudamSudeneCOConsolidation.creditValue.toFixed(2))}
                                        readOnly={true}
                                        disabled
                                    />
                                    <AiFillInfoCircle size={15} />
                                </td>
                                <td>
                                    <input
                                        type="text"
                                        className='form-control'
                                        value={formatCurrency(props.sudamSudeneCOConsolidation.creditLimit.toFixed(2))}
                                        readOnly={true}
                                        disabled
                                    />
                                    <AiFillInfoCircle size={15} />
                                </td>
                                <td>
                                    <input
                                        type="text"
                                        className='form-control'
                                        value={formatCurrency(props.sudamSudeneCOConsolidation.surplusCreditValue.toFixed(2))}
                                        readOnly={true}
                                        disabled
                                    />
                                    <AiFillInfoCircle size={15} />
                                </td>
                                <td>
                                    <input type="text"
                                        className='form-control'
                                        value={formatCurrency(props.sudamSudeneCOConsolidation.remainingCreditValue.toFixed(2))}
                                        readOnly={true}
                                        disabled
                                    />
                                    <AiFillInfoCircle size={15} />
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    Sudam/ Sudene/ Centro-Oeste/ Tecnologia Nacional
                                </th>
                                <td>
                                    <input
                                        type="text"
                                        className='form-control'
                                        placeholder='0,00'
                                        defaultValue={props.isSudamSudene || props.validated ? formatCurrency(props.investValueSudamSudeneCOTecNac.toFixed(2)) : props.investValueSudamSudeneCOTecNac.toFixed(2).replace(".", ",")}
                                        onChange={e => props.setInvestValueSudamSudeneCOTecNac(validate(e.target.value.replace(",", ".")) ? parseFloat(e.target.value.replace(",", ".")) : 0)}
                                        disabled={!props.isSudamSudene || props.validated}
                                        onBlur={props.reCalcAllSummaryValues}
                                    />
                                    <AiFillInfoCircle size={15} />
                                </td>
                                <td>
                                    <input
                                        type="text"
                                        className='form-control'
                                        value={formatCurrency(props.sudamSudeneCOTecNacConsolidation.invoicingConsolidation.toFixed(2))}
                                        readOnly={true}
                                        disabled
                                    />
                                    <AiFillInfoCircle size={15} />
                                </td>
                                <td>
                                    <input
                                        type="text"
                                        className='form-control'
                                        value={formatCurrency(props.sudamSudeneCOTecNacConsolidation.creditValue.toFixed(2))}
                                        readOnly={true}
                                        disabled
                                    />
                                    <AiFillInfoCircle size={15} />
                                </td>
                                <td>
                                    <input
                                        type="text"
                                        className='form-control'
                                        value={formatCurrency(props.sudamSudeneCOTecNacConsolidation.creditLimit.toFixed(2))}
                                        readOnly={true}
                                        disabled
                                    />
                                    <AiFillInfoCircle size={15} />
                                </td>
                                <td>
                                    <input
                                        type="text"
                                        className='form-control'
                                        value={formatCurrency(props.sudamSudeneCOTecNacConsolidation.surplusCreditValue.toFixed(2))}
                                        readOnly={true}
                                        disabled
                                    />
                                    <AiFillInfoCircle size={15} />
                                </td>
                                <td>
                                    <input type="text"
                                        className='form-control'
                                        value={formatCurrency(props.sudamSudeneCOTecNacConsolidation.remainingCreditValue.toFixed(2))}
                                        readOnly={true}
                                        disabled
                                    />
                                    <AiFillInfoCircle size={15} />
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    Outras Regiões
                                </th>
                                <td>
                                    <input
                                        type="text"
                                        className='form-control'
                                        placeholder='0,00'
                                        defaultValue={!props.isSudamSudene || props.validated ? formatCurrency(props.investValueOthersRegions.toFixed(2)) : props.investValueOthersRegions.toFixed(2).replace(".", ",")}
                                        onChange={e => props.setInvestValueOthersRegions(validate(e.target.value.replace(",", ".")) ? parseFloat(e.target.value.replace(",", ".")) : 0)}
                                        disabled={props.isSudamSudene || props.validated}
                                        onBlur={props.reCalcAllSummaryValues}
                                    />
                                    <AiFillInfoCircle size={15} />
                                </td>
                                <td>
                                    <input
                                        type="text"
                                        className='form-control'
                                        value={formatCurrency(props.othersRegionsConsolidation.invoicingConsolidation.toFixed(2))}
                                        readOnly={true}
                                        disabled
                                    />
                                    <AiFillInfoCircle size={15} />
                                </td>
                                <td>
                                    <input
                                        type="text"
                                        className='form-control'
                                        value={formatCurrency(props.othersRegionsConsolidation.creditValue.toFixed(2))}
                                        readOnly={true}
                                        disabled
                                    />
                                    <AiFillInfoCircle size={15} />
                                </td>
                                <td>
                                    <input
                                        type="text"
                                        className='form-control'
                                        value={formatCurrency(props.othersRegionsConsolidation.creditLimit.toFixed(2))}
                                        readOnly={true}
                                        disabled
                                    />
                                    <AiFillInfoCircle size={15} />
                                </td>
                                <td>
                                    <input
                                        type="text"
                                        className='form-control'
                                        value={formatCurrency(props.othersRegionsConsolidation.surplusCreditValue.toFixed(2))}
                                        readOnly={true}
                                        disabled
                                    />
                                    <AiFillInfoCircle size={15} />
                                </td>
                                <td>
                                    <input type="text"
                                        className='form-control'
                                        value={formatCurrency(props.othersRegionsConsolidation.remainingCreditValue.toFixed(2))}
                                        readOnly={true}
                                        disabled
                                    />
                                    <AiFillInfoCircle size={15} />
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    Outras Regiões Tecnologia Nacional
                                </th>
                                <td>
                                    <input
                                        type="text"
                                        className='form-control'
                                        placeholder='0,00'
                                        defaultValue={!props.isSudamSudene || props.validated ? formatCurrency(props.investValueOthersRegionsTecNac.toFixed(2)) : props.investValueOthersRegionsTecNac.toFixed(2).replace(".", ",")}
                                        onChange={e => props.setInvestValueOthersRegionsTecNac(validate(e.target.value.replace(",", ".")) ? parseFloat(e.target.value.replace(",", ".")) : 0)}
                                        disabled={props.isSudamSudene || props.validated}
                                        onBlur={props.reCalcAllSummaryValues}
                                    />
                                    <AiFillInfoCircle size={15} />
                                </td>
                                <td>
                                    <input
                                        type="text"
                                        className='form-control'
                                        value={formatCurrency(props.othersRegionsTecNacConsolidation.invoicingConsolidation.toFixed(2))}
                                        readOnly={true}
                                        disabled
                                    />
                                    <AiFillInfoCircle size={15} />
                                </td>
                                <td>
                                    <input
                                        type="text"
                                        className='form-control'
                                        value={formatCurrency(props.othersRegionsTecNacConsolidation.creditValue.toFixed(2))}
                                        readOnly={true}
                                        disabled
                                    />
                                    <AiFillInfoCircle size={15} />
                                </td>
                                <td>
                                    <input
                                        type="text"
                                        className='form-control'
                                        value={formatCurrency(props.othersRegionsTecNacConsolidation.creditLimit.toFixed(2))}
                                        readOnly={true}
                                        disabled
                                    />
                                    <AiFillInfoCircle size={15} />
                                </td>
                                <td>
                                    <input
                                        type="text"
                                        className='form-control'
                                        value={formatCurrency(props.othersRegionsTecNacConsolidation.surplusCreditValue.toFixed(2))}
                                        readOnly={true}
                                        disabled
                                    />
                                    <AiFillInfoCircle size={15} />
                                </td>
                                <td>
                                    <input
                                        type="text"
                                        className='form-control'
                                        value={formatCurrency(props.othersRegionsTecNacConsolidation.remainingCreditValue.toFixed(2))}
                                        readOnly={true}
                                        disabled
                                    />
                                    <AiFillInfoCircle size={15} />
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    Total
                                </th>
                                <td>
                                    {/* esse é V2 */}
                                    <input
                                        type="text"
                                        className='form-control'
                                        value={formatCurrency((props.investValueOthersRegions +
                                                props.investValueOthersRegionsTecNac +
                                                props.investValueSudamSudeneCO +
                                                props.investValueSudamSudeneCOTecNac).toFixed(2))
                                        }
                                        readOnly={true}
                                        disabled
                                    />
                                </td>
                                <td>
                                    {/* esse é V1 */}
                                    <input
                                        type="text"
                                        className='form-control'
                                        value={formatCurrency((props.othersRegionsConsolidation.invoicingConsolidation + 
                                            props.sudamSudeneCOConsolidation.invoicingConsolidation + 
                                            props.othersRegionsTecNacConsolidation.invoicingConsolidation + 
                                            props.sudamSudeneCOTecNacConsolidation.invoicingConsolidation).toFixed(2))
                                        }
                                        readOnly={true}
                                        disabled
                                    />
                                </td>
                                <td>
                                    <input
                                        type="text"
                                        className='form-control'
                                        value={formatCurrency((props.othersRegionsConsolidation.creditValue +
                                                props.othersRegionsTecNacConsolidation.creditValue +
                                                props.sudamSudeneCOConsolidation.creditValue +
                                                props.sudamSudeneCOTecNacConsolidation.creditValue).toFixed(2))
                                        }
                                        readOnly={true}
                                        disabled
                                    />
                                </td>
                                <td>
                                    <input
                                        type="text"
                                        className='form-control'
                                        value={formatCurrency((props.othersRegionsConsolidation.creditLimit +
                                                props.othersRegionsTecNacConsolidation.creditLimit +
                                                props.sudamSudeneCOConsolidation.creditLimit +
                                                props.sudamSudeneCOTecNacConsolidation.creditLimit).toFixed(2))
                                        }
                                        readOnly={true}
                                        disabled
                                    />
                                </td>
                                <td>
                                    <input
                                        type="text"
                                        className='form-control'
                                        value={formatCurrency((props.othersRegionsConsolidation.surplusCreditValue +
                                                props.othersRegionsTecNacConsolidation.surplusCreditValue +
                                                props.sudamSudeneCOConsolidation.surplusCreditValue +
                                                props.sudamSudeneCOTecNacConsolidation.surplusCreditValue).toFixed(2))
                                        }
                                        readOnly={true}
                                        disabled
                                    />
                                </td>
                                <td>
                                    <input type="text"
                                        className='form-control'
                                        value={formatCurrency((props.othersRegionsConsolidation.remainingCreditValue +
                                                props.othersRegionsTecNacConsolidation.remainingCreditValue +
                                                props.sudamSudeneCOConsolidation.remainingCreditValue +
                                                props.sudamSudeneCOTecNacConsolidation.remainingCreditValue).toFixed(2))
                                        }
                                        readOnly={true}
                                        disabled
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </form>
            </div>
        </div>
    )
}