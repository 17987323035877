import { useState, useEffect } from "react";
import { AiOutlineArrowDown } from "react-icons/ai";
import { BiArrowBack, BiArrowFromBottom, BiArrowToBottom, BiCog } from "react-icons/bi";
import { BsFileArrowDown } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { IdentityCompanyCard } from "../../components/Services/IdentityCompanyCard";
import Loader from "../../components/Shared/Loader";
import { TablePaginationFooter } from "../../components/Shared/Pagination";
import { useCompany } from "../../hooks/useCompany";
import { useOAuthUser } from "../../hooks/useOAuthUser";
import { api } from "../../services/axios";

interface CompanyTenantProps {
    areaId: string;
    areaName: string | null;
    companyId: number;
    crmAccountId: string | null;
    id: string;
    name: string
    number: string
}

interface TenantsPaginationProps {
    items: CompanyTenantProps[];
    currentPage: number;
    itemsPerPage: number;
    skip: number;
    take: number;
    total: number;
    word: string | null;

}

export function TenantCompanies() {
    const [companies, setCompanies] = useState<TenantsPaginationProps>();
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const [searchText, setSearchText] = useState("");

    let navigate = useNavigate();

    useEffect(() => {
        async function getAllCompanies() {
            setLoading(true);
            try {
                const result = await api.get(`/TenantsCompanies/GetPagination/page=1&take=7&word=all`);

                const response = await result.data;

                const data = response.data;

                setCompanies(data);
            }
            catch (error: any) {
                console.log('getAllCompanies error', error);
            }
            finally {
                setLoading(false);
            }
        }

        getAllCompanies();
    }, []);

    async function search() {
        setLoading(true);
        setCompanies(undefined);
        try {
            let text = searchText == null || searchText == "" ? 'all' : searchText;

            const result = await api.get(`/TenantsCompanies/GetPagination/page=1&take=7&word=${encodeURIComponent(text)}`);

            const response = await result.data;

            const data = response.data;

            setCompanies(data);

        }
        catch (error: any) {
            console.log('ERROR', error);
        }
        finally {
            setLoading(false)
        }
    }

    async function updateUserRelation(){
        setLoading(true);

        try{
        const result = await api.get(`/TenantsCompanies/updateRelResComp`);
        }
        catch(error: any) {

        }
        finally {
            setLoading(false);
        }

    }


    async function changePage(page: number) {
        setLoading(true);
        try {
            let text = searchText == null || searchText == "" ? 'all' : searchText;

            const result = await api.get(`/TenantsCompanies/GetPagination/page=${page}&take=7&word=${encodeURIComponent(text)}`);

            const response = await result.data;

            const data = response.data;

            setCurrentPage(page);

            setCompanies(data);

        }
        catch (error: any) {
            console.log('CHANGE PAGE', error);
        }
        finally {
            setLoading(false)
        }
    }


    return (
        <>
            {loading && <Loader />}
            <div className='card'>
                <div className='card-header d-flex justify-content-between'>
                    <h2></h2>
                    <div className='d-flex'>
                        <button
                            className='btn btn-primary text-white d-flex align-items-center'
                            onClick={() => navigate('/')}
                        >
                            <BiArrowBack className='me-2' />
                            Voltar
                        </button>
                    </div>
                </div>
                <div className='card-body p-4'>
                    <div className='mb-4 d-flex'>
                        <input placeholder='Pesquisar por empresa' className='form-control me-4' type='text' onChange={(e) => setSearchText(e.target.value)} />
                        <button className='btn btn-success' onClick={() => search()}>Pesquisar</button>
                        <button className='btn btn-success ms-1' onClick={() => updateUserRelation()}>Atualizar</button>
                    </div>
                    <table className='table table-bordered table-striped table-hover mb-4'>
                        <thead>
                            <tr>
                                <th scope="col">Empresa</th>
                                <th scope="col">Nº</th>
                                <th scope="col">Anos</th>
                            </tr>
                        </thead>
                        {companies?.items?.map(company => {
                            return (
                                <IdentityCompanyCard
                                    key={company.id}
                                    id={company.id}
                                    name={company.name}
                                    number={company.number}
                                />
                            )
                        })}
                    </table>

                    {companies?.items &&
                        <TablePaginationFooter
                            totalItems={companies.total}
                            change={changePage}
                        />}
                </div>
            </div>
        </>
    );
}