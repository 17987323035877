import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { api } from "../../../services/axios";
import { AiOutlinePlus, AiOutlineMinus, AiFillHome } from 'react-icons/ai';

import { Profiler, useState } from "react";
import { v4 as uuidv4 } from 'uuid';
import Loader from "../../../components/Shared/Loader";
import { toast } from "react-toastify";
import { useNavigate, Link, useParams } from "react-router-dom";
import { useCompany } from "../../../hooks/useCompany";
import { Button, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { createProductModelSchema } from "../../../schemas/createProductModelSchema";

interface CreateProductModelForm {
    name: string;
    status: boolean | null;
    description: string | null;
    createdDate: Date | null;
    approvalDate: Date | null;
    isRequistred: boolean | null;
    companyId: string;
    productId: string | null;
    serviceId: string;
    isTecnac: boolean;
    codes: CodeProps[];
}

interface CodeProps {
    id: string;
    code: string;
    description: string;
}

export default function CreateProductModel() {
    const [modelName, setModelName] = useState("");
    const [modelCod, setModelCod] = useState("");
    const [loading, setLoading] = useState(false);
    const [codes, setCodes,] = useState<CodeProps[]>([]);
    const navigate = useNavigate();
    const { productId, tecnacId } = useParams();
    const { company, updateHeaderValues } = useCompany();
    const [approvalDate, setapprovalDate] = useState<Date>();
    const [createdDate, setcreatedDate] = useState<Date>();


    async function handleCreateProductModel(data: CreateProductModelForm) {
        setLoading(true);

        data.codes = codes;
        let productModel = data;

        productModel.createdDate = createdDate != null ? new Date(createdDate) : null;
        productModel.approvalDate = approvalDate != null ? new Date(approvalDate) : null;
        productModel.productId = productId ? productId : '';
        productModel.codes = data.codes;
        productModel.isTecnac = tecnacId != undefined ? true : false;
        data.status = typeof data.status == "string" && data.status != "" ? data.status === "true" ? true : false : null;
        data.serviceId = company.serviceId;

        try {
            const result = await api.post('productmodel/post', productModel);
            const response = await result.data;
            if (response.statusCode === 200) {
                toast.success('Modelo de Produto Cadastrado com Sucesso!');
                navigate(`/${company.serviceId}/products/${productId}/models`);
                await updateHeaderValues();
            }

        }
        catch (error: any) {
            console.log('ERROR', error);
        }
        finally {
            setLoading(false);
        }
    }

    function handleAddCodes() {
        if (modelName && modelCod) {
            var model: CodeProps = {
                description: modelName,
                code: modelCod,
                id: uuidv4()
            }

            setCodes(rest => [...rest, model]);
            setModelName("");
            setModelCod("");
        }
    }

    function handleRemoveCodes(id: string) {
        let filteredCodes = codes.filter(code => code.id !== id);
        setCodes(filteredCodes);
    }

    const { register, handleSubmit, control, formState: { errors } } = useForm<CreateProductModelForm>({
        resolver: yupResolver(createProductModelSchema)
    });

    return (
        <>
            {loading && <Loader />}
            <div className='card'>
                <div className='card-header'>
                    <div className='breadCrumb'>
                        <Link to={`/${company.serviceId}/home`} className='breadCrumb-home d-flex align-center'>
                            <AiFillHome className='breadCrumb-home' />
                        </Link>
                        <span className='breadCrumb-separator'>{' > '}</span>
                        <span className='breadCrumb-item-actual'>
                            <Link to={`/${company.serviceId}/${company.companyId}/products`}>
                                I.1 Produtos e Modelos
                            </Link>
                        </span>
                        <span className='breadCrumb-separator'>{' > '}</span>
                        <Link className='breadCrumb-item-actual' to={`/${company.serviceId}/products/${productId}/models`}>
                            Modelos de Produto teste
                        </Link>
                        <span className='breadCrumb-separator'>{' > '}</span>
                        <span className='breadCrumb-item'>Criar Modelo de Produto</span>
                    </div>
                </div>
                <form className='card-body' onSubmit={handleSubmit(handleCreateProductModel)}>
                    <div className='mb-3 w-100'>

                        <TextField
                            label="Nome do Modelo" variant="outlined"
                            className='form-control forms-control'
                            placeholder="N° da portaria e data de publicação "
                            {...register('name')}
                            error={!!errors.name}
                            helperText={errors.name?.message}
                        />

                    </div>
                    <div className='mb-3 w-100'>
                        <FormControl fullWidth>
                            <InputLabel>Status do Modelo</InputLabel>
                            <Select label="Status do Modelo" className="forms-control"  {...register('status')} fullWidth>
                                <MenuItem value='true'>
                                    Habilitado
                                </MenuItem>
                                <MenuItem value='false'>
                                    Não Habilitado
                                </MenuItem>

                            </Select>
                            <span className='error-field'>{errors.status?.message}</span>
                        </FormControl>
                    </div>
                    <div className='mb-3 w-100'>
                        <TextField
                            label="Descrição do Modelo e características básicas"
                            className="form-control form-control w-100"
                            {...register('description')}
                            error={!!errors.description}
                            helperText={errors.description?.message} />

                    </div>
                    <div className='mb-3 w-100'>
                        <div className='d-flex align-center flex-column'>

                            <div className='d-flex row'>
                                <TextField label="Códigos" className="form-label col-5 shadow-sm mx-3" value={modelCod} onChange={(e) => setModelCod(e.target.value)} />
                                <TextField label="Descrições" className="form-label col-6" value={modelName} onChange={(e) => setModelName(e.target.value)} />
                                <Button
                                    className='btn btn-success mx-3 white col'
                                    type='button'
                                    onClick={handleAddCodes}
                                >
                                    <AiOutlinePlus size={20} className='white' />
                                </Button>
                            </div>
                            {codes.map(code => {
                                return (
                                    <div className='d-flex mt-3 row' key={code.id}>
                                        <TextField className='form-label col-5 shadow-sm mx-3' value={code.code} disabled />
                                        <TextField className='form-label col-6' value={code.description} disabled />
                                        <Button
                                            className='btn btn-success mx-3 white col'
                                            type='button'
                                            onClick={() => handleRemoveCodes(code.id)}
                                        >
                                            <AiOutlineMinus size={20} className='white' />
                                        </Button>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    <div className='d-flex  mb-3 row'>
                        <div className=' w-100 col'>

                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <Controller
                                    name="createdDate"
                                    control={control}
                                    render={({ field: { ref, value } }) => (
                                        <DatePicker
                                            className="forms-control w-100"
                                            label="Data de Criação de Modelo"
                                            format="dd/MM/yyyy"
                                            inputRef={ref}
                                            onChange={(e: any) => setcreatedDate(e)}
                                            value={value != null ? new Date(value) : null}
                                        />
                                    )}
                                />
                            </LocalizationProvider>

                        </div>
                        <div className='col d-flex align-items-center justify-content-center'>
                            <FormControl fullWidth>
                                <InputLabel>Modelo Cadastrado?</InputLabel>
                                <Select label="Modelo Cadastrado?" className="forms-control" {...register('isRequistred')} fullWidth>
                                    <MenuItem value='true'>
                                        Sim
                                    </MenuItem>
                                    <MenuItem value='false'>
                                        Não
                                    </MenuItem>
                                </Select>
                                <span className='error-field'>{errors.isRequistred?.message}</span>
                            </FormControl>

                        </div>
                        <div className=' w-100 col '>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <Controller
                                    name="approvalDate"
                                    control={control}
                                    render={({ field: { ref, value } }) => (
                                        <DatePicker
                                            className="forms-control w-100"
                                            label="Data de Aprovação"
                                            format="dd/MM/yyyy"
                                            inputRef={ref}
                                            onChange={(e: any) => setapprovalDate(e)}
                                            value={value != null ? new Date(value) : null}
                                        />
                                    )}
                                />
                                {errors.approvalDate && (
                                    <span className='error-field'>{errors.approvalDate.message}</span>
                                )}
                            </LocalizationProvider>

                        </div>
                    </div>
                    <button className='btn btn-success w-100' type='submit'>Salvar Modelo</button>
                </form>
            </div>
        </>
    );
}