import { createTheme, styled } from "@mui/material";
import { SnackbarProvider } from 'notistack';
import { TaskAltOutlined, InfoOutlined, ErrorOutline, WarningAmberOutlined } from '@mui/icons-material';

//Para ativar o UIKit novamente basta tirar os comentarios!
export const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

export const GlobalStyles = createTheme({
    typography: {
        fontFamily: 'CenturyGothic',
    },
    palette: {
        primary: {
            main: '#0000A4',
            light: '#0011FF',
            dark: '#0E0066',
        },
        secondary: {
            main: '#00AEFF',
            light: '#47B8FF',
            dark: '#0070A8',
        },
        error: {
            main: '#DB192D',
            light: '#EF768A',
            dark: '#AD1414',
        },
        warning: {
            main: '#EFAC14',
            light: '#F5B166',
            dark: '#C4860D',
        },
        info: {
            main: '#AFAFC3',
            light: '#C1BFCF',
            dark: '#7C7D9D',
        },
        success: {
            main: '#8CAD14',
            light: '#AFE624',
            dark: '#6D850F',
        },
    },
    components: {
        // Name of the component
        MuiButton: {
            styleOverrides: {
                root: ({ theme, ownerState }) => ({
                    ...(ownerState.color == "primary" ? {
                        "svg": {
                            fill: theme.palette.primary.main
                        }
                    }
                        :
                        ownerState.color == "secondary" ? {
                            "svg": {
                                fill: theme.palette.secondary.main
                            }
                        }
                            :
                            ownerState.color == "warning" ? {
                                "svg": {
                                    fill: theme.palette.warning.main
                                }
                            }
                                :
                                ownerState.color == "error" ? {
                                    "svg": {
                                        fill: theme.palette.error.main
                                    }
                                }
                                    :
                                    ownerState.color == "info" ? {
                                        "svg": {
                                            fill: theme.palette.info.main
                                        }
                                    }
                                        :
                                        ownerState.color == "success" && {
                                            "svg": {
                                                fill: theme.palette.success.main
                                            }
                                        }),
                    textTransform: 'capitalize',
                    fontSize: '14px',
                    fontWeight: '700',
                    height: '36px',
                }),
                sizeSmall: {
                    padding: '4px 10px',
                    fontSize: '13px',
                },
                sizeMedium: {
                    padding: '6px 16px',
                    fontSize: '14px',
                },
                sizeLarge: {
                    padding: '8px 22px',
                    fontSize: '15px',
                },
                text: {
                    ":disabled": {
                        color: "#2B2B2B38",
                        backgroundColor: "#ddd9d9",
                        "svg": {
                            fill: "#2B2B2B38 !important"
                        }
                    },
                },
                contained: {
                    color: '#FFF',
                    ":disabled": {
                        color: "#2B2B2B38",
                        backgroundColor: "#2B2B2B12",
                        "svg": {
                            fill: "#2B2B2B38 !important"
                        }
                    },
                    "svg": {
                        fill: '#FFF'
                    }
                },
                outlined: {
                    ":disabled": {
                        color: "#2B2B2B38",
                        backgroundColor: "#ddd9d9",
                        "svg": {
                            fill: "#2B2B2B38 !important"
                        }
                    },
                }
            }
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    height: '56px',
                    borderRadius: '4px',
                    backgroundColor: '#FFF',
                },
            },
        },
        MuiSelect: {
            styleOverrides: {
                root: {
                    height: '56px',
                    minWidth: '150px',
                    borderRadius: '4px',
                }
            }
        },
        MuiAlert: {
            styleOverrides: {
                message: ({ ownerState }) => ({
                    color: "#FFF",
                    ...(ownerState.severity == "info" && {
                        color: "#000"
                    }),
                }),
                root: ({ ownerState }) => ({
                    ...(ownerState.variant == "filled" &&
                    {
                        "svg": {
                            fill: '#FFF'
                        },
                        ...(ownerState.severity == "info" && {
                            "svg": {
                                fill: '#000'
                            }
                        }),
                    })
                }),
            }
        },
        MuiLinearProgress: {
            styleOverrides: {
                root: {
                    height: '16px',
                    borderRadius: '8px',
                },
                bar: {
                    height: '16px',
                    borderRadius: '8px',
                },
                buffer: ({ theme }) => ({
                    backgroundColor: theme.palette.info.main,
                }),
                dashed: {
                    display: 'none',
                },
                bar1Buffer: ({ theme }) => ({
                    backgroundColor: theme.palette.primary.main,
                }),
                bar2Buffer: ({ theme }) => ({
                    backgroundColor: theme.palette.secondary.main,
                }),
            }
        },
        MuiSwitch: {
            styleOverrides: {
                root: {},
                thumb: {
                    color: "#FFF"  // Configura a cor branca para o thumb por padrão
                },
                track: {},
                switchBase: {
                    "&.Mui-checked": {
                        "& + .MuiSwitch-track": {},
                        "& .MuiSwitch-thumb": {
                            color: "inherit"  // Quando estiver checado, o thumb irá herdar a cor do seu parent
                        }
                    }
                }
            }
        },
        MuiPagination: {
            defaultProps: {
                showFirstButton: true,
                showLastButton: true,
                shape: 'rounded',
            },
        },
        MuiPaginationItem: {
            styleOverrides: {
                root: {
                    fontWeight: '600',
                },
                outlinedSecondary: ({ theme, ownerState }) => ({
                    ...(ownerState.selected == true && {
                        color: theme.palette.primary.main + " !important",
                    }),
                }),
                textSecondary: ({ ownerState }) => ({
                    ...(ownerState.selected == true && {
                        color: '#FFF !important',
                    }),
                }),
            },
        },
        MuiCheckbox: {
            styleOverrides: {
                root: ({ theme, ownerState }) => ({
                    ...(ownerState.color == "primary" ? {
                        '.MuiSvgIcon-root': {
                            fill: theme.palette.primary.main + " !important",
                        }
                    } : ownerState.color == "secondary" ? {
                        '.MuiSvgIcon-root': {
                            fill: theme.palette.secondary.main + " !important",
                        }
                    } : ownerState.color == "error" ? {
                        '.MuiSvgIcon-root': {
                            fill: theme.palette.error.main + " !important",
                        }
                    } : ownerState.color == "success" ? {
                        '.MuiSvgIcon-root': {
                            fill: theme.palette.success.main + " !important",
                        }
                    } : ownerState.color == "warning" ? {
                        '.MuiSvgIcon-root': {
                            fill: theme.palette.warning.main + " !important",
                        }
                    } : ownerState.color == "info" && {
                        '.MuiSvgIcon-root': {
                            fill: theme.palette.info.main + " !important",
                        }
                    })
                }),
            }
        },
        MuiIconButton: {
            styleOverrides: {
                root: ({ theme, ownerState }) => ({
                    ...(ownerState.color == "primary" ? {
                        '.MuiSvgIcon-root': {
                            fill: theme.palette.primary.main + " !important",
                        }
                    } : ownerState.color == "secondary" ? {
                        '.MuiSvgIcon-root': {
                            fill: theme.palette.secondary.main + " !important",
                        }
                    } : ownerState.color == "error" ? {
                        '.MuiSvgIcon-root': {
                            fill: theme.palette.error.main + " !important",
                        }
                    } : ownerState.color == "success" ? {
                        '.MuiSvgIcon-root': {
                            fill: theme.palette.success.main + " !important",
                        }
                    } : ownerState.color == "warning" ? {
                        '.MuiSvgIcon-root': {
                            fill: theme.palette.warning.main + " !important",
                        }
                    } : ownerState.color == "info" && {
                        '.MuiSvgIcon-root': {
                            fill: theme.palette.info.main + " !important",
                        }
                    })
                }),
            },
        },
    },
});

export const Notistack = styled(SnackbarProvider)(({ theme }) => ({
    // '#notistack-snackbar': {
    //     color: '#FFF',
    //     inlineSize: '250px',
    //     wordBreak: 'break-all',
    // },
    // '#notistack-snackbar svg': {
    //     marginRight: '10px',
    //     alignSelf: 'start',
    // },
    // '#notistack-snackbar svg path': {
    //     fill: '#FFF',
    // },
    // '&.notistack-MuiContent': {
    //     alignItems: 'start',
    // },
    // '&.notistack-MuiContent button svg path': {
    //     color: '#FFF',
    // },
    // '&.notistack-MuiContent-info': {
    //     backgroundColor: theme.palette.info.main,
    // },
    // '&.notistack-MuiContent-success': {
    //     backgroundColor: theme.palette.success.main,
    // },
    // '&.notistack-MuiContent-warning': {
    //     backgroundColor: theme.palette.warning.main,
    // },
    // '&.notistack-MuiContent-error': {
    //     backgroundColor: theme.palette.error.main,
    // },
    // '&.notistack-MuiContent-info #notistack-snackbar': {
    //     color: '#000 !important',
    //     "svg path": {
    //         fill: '#000'
    //     }
    // }
}));

export const ToastIcons = {
    TaskAltOutlined,
    InfoOutlined,
    ErrorOutline,
    WarningAmberOutlined
};