export function toQueryParams(data: any, chave: any = null) {
    return Object.entries(data).map(([key, val]) =>
        val !== null && val !== 'null' ?
            (val instanceof Array ?
                toQueryParamsSimple(val, key) :
                `${chave ? chave : key}=${(val instanceof Date ? formatAsDate(val) : val)}`) : '').filter(p => p !== '').join('&');
}

export function toQueryParamsSimple(data: any, chave: any = null) {
    return Object.entries(data).map(([key, value]) => value !== null && value !== 'null' ?
        `${key}=${value instanceof Date ? formatAsDate(value) : value}` : '').filter(p => p !== '').join('&')
}

export function formatAsDate(val: any) {
    const d = new Date(val);
    const month = '' + (d.getMonth() + 1);
    const day = '' + d.getDate();
    const year = d.getFullYear();

    let formatedDate = [year, padLeft(month), padLeft(day)].join('-');

    const hour = '' + d.getHours();
    const minute = '' + d.getMinutes();

    if (hour)
        formatedDate += `T${padLeft(hour)}:${minute ? padLeft(minute) : '00'}:00`

    return formatedDate;
}

export function padLeft(value: string) {
    if (value.length < 2)
        value = '0' + value;
    
    return value;
}