import { useEffect, useState } from "react";
import Loader from "../../../components/Shared/Loader";
import { api } from "../../../services/axios";
import { AiFillHome } from "react-icons/ai";
import { toast } from "react-toastify";
import { Link, useParams } from 'react-router-dom';
import { InvoicingProductExit } from "../InvoicingProductExit"
import { InvoicingProductEntry } from "../InvoicingProductEntry"
import PaginationTabHeader from "../../../components/Shared/PaginationTabHeader";
import { UpdateProductFormProps } from "../../../components/Products/UpdateProductForm";
import { useCompany } from "../../../hooks/useCompany";

const tabs: any = {
    0: <InvoicingProductExit/>,
    1: <InvoicingProductEntry/>
}

export default function CreditTabs() {
    const [pag, setPag] = useState(0);
    const [key, setKey] = useState(0);
    const { productId } = useParams();
    const { company } = useCompany();
    const [loading, setLoading] = useState(false);
    const [product, setProduct] = useState({} as UpdateProductFormProps);

    async function screenAccess() {
        try {
            setLoading(true);

            await api.get(`invoicing/ScreenAccess/serviceId=${company.serviceId}`);
        } catch(error: any) {
            toast.error(error);
        } finally {
            setLoading(false);
        }
    }

    async function getProduct() {
        setLoading(true);
        try {
            await api.get(`product/getById/${productId}`).then((resp) => {
                const response = resp.data;

                const productResult = response.data as UpdateProductFormProps;
                productResult.startManufacturingDate = productResult.startManufacturingDate != undefined ? new Date(new Date(productResult.startManufacturingDate?.toString().split("T")[0]).setHours(24)) : null;
                productResult.pleitoDate = productResult.pleitoDate != undefined ? productResult.pleitoDate?.toString().split("T")[0] : productResult.pleitoDate;
                productResult.publishDate = productResult.publishDate != undefined ? productResult.publishDate?.toString().split("T")[0] : productResult.publishDate;
                productResult.licenseDate = productResult.licenseDate != undefined ? productResult.licenseDate?.toString().split("T")[0] : productResult.licenseDate;

                setProduct(response.data);
            });
        }
        catch (error: any) {
            toast.error(error);
        }
        finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        screenAccess();
        getProduct();
    }, []);

    return (
        <>
            {loading && <Loader />}
            <div className='card '>
                <div className='card-header'>
                    <div className='breadCrumb'>
                        <Link to={`/${company.serviceId}/home`} className='breadCrumb-home d-flex align-center'>
                            <AiFillHome className='breadCrumb-home' />
                        </Link>
                        <span className='breadCrumb-separator'>{' > '}</span>
                        <Link to={`/${company.serviceId}/${company.companyId}/invoicing`}><span className=''>II.Faturamentos</span></Link>
                        <span className='breadCrumb-separator'>{' > '}</span>
                        <span className='breadCrumb-item-actual'>Produto {product.name}</span>
                    </div>
                </div>

                <div className='card-body'>
                    <PaginationTabHeader setPag={setPag} pag={pag} setKey={setKey} keyDefault={key} tabs={["Vendas", "Devoluções"]} />
                    {tabs[pag]}
                </div>
            </div>
        </>
    );
}