import "./styles.css";
import { InputLabel, MenuItem, Select, TextField, FormControl, Accordion, AccordionSummary, Typography, AccordionDetails } from "@mui/material";
import { UseFormReturn } from "react-hook-form";
import { ExpandMore } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { DynamicSelectComponet } from "../FiPositioningDynamicSelects/dynamicSelects";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { FiPositioningFormProps } from "../../../pages/Projects/InternalProjectsTabs/Interfaces/InternalProjectsFormProps";
import { PutFiPositioningAPI } from "../../../API/InternalProjectsAPI";
import { IResponseProps } from "../../../interfaces/IResponseProps";
import { handleKeyDown } from "../../../utils/projectInternal";
import { dynamicCriterionC1toC4, dynamicCriterionC5toC7 } from "../../../Static/FiPositioningStatic";
import { ButtonSubmitDefault } from "../../Shared/ButtonSubmitDefault/ButtonSubmitDefault";

export interface IInternalProjectsTabProps {
    fiPoistioningTabForm: UseFormReturn<FiPositioningFormProps, any>
}
export interface IDynamicCritetion {
    selectLabel: string,
    sigla: any,
    field: string,
    criterion: number,
}

export function FiPositioningTab({ fiPoistioningTabForm }: IInternalProjectsTabProps) {
    const { register, handleSubmit, control, formState: { errors }, getValues, setValue, watch } = fiPoistioningTabForm;

    const { id, typology } = useParams();
    const [loading, setLoading] = useState(false);
    const [framework, setFramework] = useState("");
    const [criterion, setCriterion] = useState<IDynamicCritetion[]>([]);
    const [projectIsTic, setProjectIsTic] = useState<boolean>(getValues('projectIsTic'));

    useEffect(() => {
        if (typology !== "5") {
            dynamicCriterionC1toC4.map(p => p.criterion = getValues(p.field as any) ?? parseInt(getValues(p.field as any)));
            setCriterion(dynamicCriterionC1toC4)
        }
        else {
            dynamicCriterionC5toC7.map(p => p.criterion = getValues(p.field as any) ?? parseInt(getValues(p.field as any)));
            setCriterion(dynamicCriterionC5toC7);
        }
    }, [])

    async function setCritSelect(id: number, crit: number) {
        var critValue = criterion.map((p, i) => {
            if (i === id)
                p.criterion = crit;

            return p;
        });
        setCriterion(critValue);
    }

    async function setFrameworkFunciton() {
        if (typology !== "5") {
            // Criterios 1 e 2
            (projectIsTic && dynamicCriterionC1toC4[0].criterion >= 1 && dynamicCriterionC1toC4[1].criterion >= 2)
                ? setFramework("Enquadrado")
                : setFramework("Não Enquadrado");
        } else {
            // Criterios 5, 6 e 7
            (projectIsTic && dynamicCriterionC5toC7[0].criterion >= 2 && dynamicCriterionC5toC7[1].criterion >= 2 && dynamicCriterionC5toC7[2].criterion >= 2)
                ? setFramework("Enquadrado")
                : setFramework("Não Enquadrado");
        }
    }

    async function handleUpdateProject(data: FiPositioningFormProps) {

        setLoading(true);

        data.id = id ?? "";
        data.criterionCFrameworkAnalysis = 1;

        if (framework === "Enquadrado")
            data.criterionCFrameworkAnalysis = 0;

        data.projectIsTic = projectIsTic;
        
        try {
            await PutFiPositioningAPI(data).then((resp: IResponseProps) => {
                resp.statusCode === 200
                    ? toast.success('Projeto editado com sucesso')
                    : toast.error('Erro ao editar projeto.');
            });
        } catch (error: any) {
            toast.error(error);
        }
        finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        setFrameworkFunciton();
    }, [projectIsTic, criterion])

    return (
        <>
            <div className="card">
                <form className="card-body" onSubmit={handleSubmit(handleUpdateProject)} onKeyDown={handleKeyDown}>
                    <div className=''>
                        <div className='mb-2'>
                            <div className="row mb-3">
                                <div className='col-6'>
                                    <FormControl fullWidth>
                                        <InputLabel>O projeto é de TIC?</InputLabel>
                                        <Select
                                            label="O projeto é de TIC?"
                                            className="forms-control"
                                            {...register("projectIsTic", { onChange: (e: any) => { setProjectIsTic(JSON.parse(e.target.value)) } })}
                                            fullWidth
                                            defaultValue={getValues('projectIsTic')?.toString()}
                                        >
                                            <MenuItem value="false">Não</MenuItem>
                                            <MenuItem value="true">Sim</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                        </div>
                        <div className='mb-2'>
                            <div className="text-title">
                                Resultados Obitidos
                            </div>
                            <div className="card pe-2">
                                {criterion.map((p, i) => {
                                    return (
                                        <div className="mb-2" key={i}>
                                            <DynamicSelectComponet
                                                selectLabel={p.selectLabel}
                                                cValue={p.sigla}
                                                register={register}
                                                selectRegister={p.field}
                                                setCritC={setCritSelect}
                                                setFrameworkFunciton={setFrameworkFunciton}
                                                id={i}
                                                defaultValue={p.criterion} />
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className='col'>
                                <TextField
                                    label="Análise Enquadramento Critério C"
                                    variant="outlined"
                                    className='form-control'
                                    value={framework}
                                    disabled
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="mb-4">
                                <Accordion>
                                    <div className="text-title">
                                        <AccordionSummary
                                            aria-controls="panel5d-content"
                                            id="panel5d-header"
                                            expandIcon={<ExpandMore />}>
                                            <Typography>Observações
                                            </Typography>
                                        </AccordionSummary>
                                    </div>
                                    <AccordionDetails>
                                        <Typography>
                                            <div className="row mb-1 mt-2">
                                                <div className='col-12'>
                                                    <TextField
                                                        variant="outlined"
                                                        className='form-control h-100'
                                                        multiline
                                                        rows={4}
                                                        {...register('observation')}
                                                        defaultValue={getValues('observation')?.toString() ?? null}
                                                        error={!!errors.observation}
                                                        helperText={errors.observation?.message}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                            </div>
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                        </div>
                        {id != null && <ButtonSubmitDefault type="submit" />}
                    </div>
                </form >
            </div >
        </>
    )
}