import "./styles.css";
import { UseFormReturn } from "react-hook-form";
import { handleKeyDown } from "../../../utils/projectInternal";
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import { ProjectDescriptionFormProps } from "../../../pages/Projects/InternalProjectsTabs/Interfaces/InternalProjectsFormProps";
import { useParams } from "react-router-dom";
import { PutProjectDescriptionAPI } from "../../../API/InternalProjectsAPI";
import { toast } from "react-toastify";
import { IResponseProps } from "../../../interfaces/IResponseProps";
import { useEffect, useState } from "react";
import { ButtonSubmitDefault } from "../../Shared/ButtonSubmitDefault/ButtonSubmitDefault";
import { TypographyCustom } from "../../Shared/InternalProjects/TypographyCustom/TypographyCustom";
import { fieldsAllTypes, fieldsProfessionalTrainingAndQualification } from "../../../Static/ProjectDescriptionStatic";

export interface IProjectDescriptionFormProps {
    projectDescriptionTabForm: UseFormReturn<ProjectDescriptionFormProps, any>
}
export interface IFieldProps {
    id: number,
    field: string,
    label: string,
}
export interface IFieldComponentProps {
    id: number,
    title: string,
    subTitle: string,
    list: IFieldProps[],
}

export function ProjectDescriptionTab({ projectDescriptionTabForm }: IProjectDescriptionFormProps) {
    const { register, handleSubmit, control, formState: { errors }, getValues, setValue, watch } = projectDescriptionTabForm;
    const { id, typology } = useParams();
    const [loading, setLoading] = useState(false);
    const [fields, setFields] = useState<IFieldComponentProps[]>(fieldsAllTypes);

    console.log('erros: ', errors);

    useEffect(() => {
        (typology !== "5")
            ? setFields(fieldsAllTypes)
            : setFields(fieldsProfessionalTrainingAndQualification)
    }, [fields])

    async function handleUpdateProject(data: ProjectDescriptionFormProps) {
        setLoading(true);
        try {
            data.id = id ?? "00000000-0000-0000-0000-000000000000";

            await PutProjectDescriptionAPI(data).then((resp: IResponseProps) => {

                resp.statusCode === 200
                    ? toast.success('Projeto editado com sucesso')
                    : toast.error('Erro ao editar projeto.');
            });
        } catch (error: any) {
            toast.error(error);
        }
        finally {
            setLoading(false);
        }
    }
    return (
        <>
            <div className="card">
                <form className="card-body" onSubmit={handleSubmit(handleUpdateProject)} onKeyDown={handleKeyDown}>
                    <div className="mb-4">
                        {fields.map((data, index) => {
                            return (
                                <Accordion key={index} className="mt-4">
                                    <div className="text-title">
                                        <AccordionSummary aria-controls="panel8d-content" id="panel8d-header" expandIcon={<ExpandMore />}>
                                            <Typography>
                                                <h6>- {data.title}</h6>
                                            </Typography>
                                        </AccordionSummary>
                                    </div>
                                    <AccordionDetails>
                                        <Typography className="font-weight-bold">
                                            {data.subTitle}
                                        </Typography>

                                        {data.list.map((p, i) => {
                                            return (
                                                <TypographyCustom
                                                    key={i}
                                                    field={p.field}
                                                    label={p.label}
                                                    register={register}
                                                    getValues={getValues}
                                                />
                                            )
                                        })}
                                    </AccordionDetails>
                                </Accordion>
                            )
                        })}
                    </div>

                    {id != null && <ButtonSubmitDefault type="submit" />}
                </form >
            </div >
        </>
    )

}