import { useContext } from "react";
import { CompanyContext, CompanyContextProps } from "../contexts/companyContext";

export function useCompany() : CompanyContextProps{
    const context = useContext(CompanyContext);

    if(!context){
        throw new Error('Erro ao utilizar o contexto de empresas');
    }

    return context;
}