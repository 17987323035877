import { Col } from "react-bootstrap";
import { FaFolderOpen } from "react-icons/fa";
import { Link } from "react-router-dom";

import { useCompany } from "../../../hooks/useCompany";

interface Paramenters {
    path: string,
    title: string
}

export function CustomCard({path, title} : Paramenters) {
    const { company } = useCompany();

    return (
        <Col xs={3}>
            <Link to={`/${company.serviceId}/${path}`}>
                <div className="card">
                    <div className="card-header product-box">
                        <div className="d-flex align-items-center justify-content-center pb-4 pt-4">
                            <FaFolderOpen size={100} className='gray' />
                        </div>
                    </div>
                    <div className="card-body product-box">
                        <label className="text-center w-100">{title}</label>
                    </div>
                </div>
            </Link>
        </Col>
    );
}