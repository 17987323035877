import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { CompanyProvider } from '../../../contexts/companyContext';
import { useAuth } from '../../../hooks/useAuth';
import Footer from '../../Shared/Footer';


export function OpenRoute() {
    return (
        <CompanyProvider>
            <div className=''>
                <Outlet />
            </div>
            <Footer />
        </CompanyProvider>

    )
        
}