import { AiFillHome } from "react-icons/ai";
import Loader from "../../components/Shared/Loader";
import { Link } from "react-router-dom";
import { useCompany } from "../../hooks/useCompany";
import { useEffect, useState } from "react";
import { Button, FormControl, IconButton, InputAdornment, MenuItem, Select, TextField } from "@mui/material";
import { Search } from "@mui/icons-material";
import TableDefault from "../../components/Shared/TableDefault";
import { ContractColumns } from "../../Static/ContractColumns";
import { FilterPaginatorRequest, IButtonsFilters } from "../../components/Requests/FilterPaginatorRequest";
import { ContractsModel } from "../../models/ContractsModel";
import { IResponseProps } from "../../interfaces/IResponseProps";
import { GetAllPaginatingByServiceIdAPI, GetCountAllButtonsByServiceIdAPI, RemoveContractAPI } from "../../API/ContractsAPI";
import { CreateContractsModal } from "../../components/ContractModule/CreateContractModal";
import useModal from "../../hooks/useModal";
import { toast } from "react-toastify";

interface IContractsPagination {
    items: ContractsModel[];
    total: number;
    currentPage: number;
    word: string;
}

interface IfiltersProps {
    id: string,
    quantity: number,
}

export default function ContractModule() {
    const { company } = useCompany();
    const { isShowing, toggle } = useModal();
    const [loading, setLoading] = useState<boolean>(false);
    const [downloadType, setDownloadType] = useState<number>(0);
    const [take, setTake] = useState<number>(7);
    const [searchText, setSearchText] = useState("");
    const [contractsPagination, setContractsPagination] = useState<IContractsPagination>();
    const [isFirstRender, setIsFirstRender] = useState(true);
    const [buttons, setButtons] = useState<IButtonsFilters[]>([
        { id: 0, label: 'Contrato de Assunção', isSelected: false, quantity: 0 },
        { id: 1, label: 'Contrato Tripartite - Convênio', isSelected: false, quantity: 0 },
        { id: 2, label: 'Contrato Tripartite - Projetos Próprios', isSelected: false, quantity: 0 },
    ]);

    const handlerClick = async (id: number) => {
        let btns = buttons.map(p => {
            if (p.id == id)
                p.isSelected = !p.isSelected;

            return p;
        });

        setButtons(btns);
    }

    const getAllFilterNumbers = async () => {
        try {
            setLoading(true);

            await GetCountAllButtonsByServiceIdAPI(company.serviceId).then((resp: IResponseProps) => {
                if(resp.statusCode == 200) {
                    var response = resp.data as IfiltersProps[];
                    let btns = buttons;
                    
                    btns.map((p, i) => {
                        p.quantity = response.find(d => d.id == i.toString())?.quantity ?? 0;
                    });

                    setButtons(btns);
                }
            })
        } catch(error: any) {
            toast.error(error);
        } finally {
            setLoading(false);
        }
    }

    const getAll = async (page: number = 1) => {
        try {
            setLoading(true);

            let text = searchText == null || searchText == "" ? "\"\"" : searchText;
            let filters: any[] = [];

            buttons.map(p => {
                if (p.isSelected)
                    filters.push({ id: p.id, isSelected: p.isSelected });
            });

            let request: FilterPaginatorRequest = {
                page: page,
                take: take,
                word: encodeURIComponent(text),
                buttonsFilters: ''
            }

            if (filters.length > 0) {
                let filter: string = '';

                filters.forEach(p => {
                    filter = filter.concat(encodeURIComponent(JSON.stringify({ id: p.id, isSelected: p.isSelected })) + ',');
                });

                filter = filter.slice(0, -1);
                request.buttonsFilters = filter;
            }

            await GetAllPaginatingByServiceIdAPI(company.serviceId, request).then(async (resp: IResponseProps) => {
                if (resp.statusCode == 200) {
                    var data = resp.data as IContractsPagination;

                    if (data != null) {
                        data.items.forEach((item) => {
                            item.contractTypeText = buttons.find(x => x.id == item.contractType)?.label ?? "";
                            item.hasContractText = item.hasContract ? "Sim" : "Não";
                        });

                        setContractsPagination(data);
                    }
                }
            });
        } catch (error: any) {
            toast.error(error);
        } finally {
            setLoading(false);
        }
    }

    async function deleteContract(id: string) {
        try {
            setLoading(true);

            await RemoveContractAPI(id).then((resp: IResponseProps) => {
                if(resp.statusCode == 200) {
                    toast.success("Contrato deletado com sucesso!");
                    getAll();
                    getAllFilterNumbers();
                }
            });
        } catch(error: any) {
            toast.error(error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (isFirstRender) {
            setIsFirstRender(false);
            return;
        }

        getAll();
    }, [buttons, take]);

    useEffect(() => {
        getAllFilterNumbers();
        getAll();
    }, []);

    return (
        <>
            {loading && <Loader />}
            <div className='card'>
                <div className='card-header'>
                    <div className='breadCrumb'>
                        <Link to={`/${company.serviceId}/home`} className='breadCrumb-home d-flex align-center'>
                            <AiFillHome className='breadCrumb-home' />
                        </Link>
                        <span className='breadCrumb-separator'>{' > '}</span>
                        <span>Módulo de Contratos</span>
                    </div>
                </div>
                <div className='card-body'>
                    <div className="w-100 d-flex align-items-start">
                        <div className="w-50 me-4 flex-wrap">
                            {buttons.length > 0 && buttons.map((btn, index) => {
                                return (
                                    <Button
                                        key={index}
                                        variant="contained"
                                        className='p-2 mb-4 text-center me-2 justify-content-around text-nowrap'
                                        color={btn.isSelected ? 'primary' : 'info'}
                                        onClick={() => handlerClick(btn.id)}
                                    >
                                        <span className='statusValues me-2'>{btn.quantity}</span>
                                        {btn.label}
                                    </Button>
                                )
                            })}
                        </div>
                        <div className="w-50 ms-4">
                            <div className="d-flex justify-content-end w-100 align-items-center mb-2">
                                <FormControl size="small">
                                    <Select
                                        value={downloadType}
                                        disabled={true}
                                        onChange={(e) => setDownloadType(parseInt(e.target.value.toString()))}
                                        className="forms-control h-100 me-2 w-25"
                                    >
                                        <MenuItem value={0}>Consolidado</MenuItem>
                                        <MenuItem value={1}>Em Branco</MenuItem>
                                        <MenuItem value={2}>Filtrada</MenuItem>
                                    </Select>
                                </FormControl>
                                <Button disabled={true} className="text-nowrap w-25" variant="contained" color="secondary">
                                    Download Projetos
                                </Button>
                                <Button disabled={true} className="text-nowrap w-25 ms-2" variant="contained" color="secondary">
                                    Upload Projetos
                                </Button>
                            </div>
                            <TextField
                                size="small"
                                value={searchText}
                                onBlur={() => getAll()}
                                onChange={(e) => setSearchText(e.target.value)}
                                className="w-100 mt-1 h-100"
                                label="Pesquisar"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton>
                                                <Search />
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </div>
                    </div>
                    <div className="my-4">
                        <TableDefault
                            columns={ContractColumns}
                            hasHeader={true}
                            hasPagination={true}
                            rows={contractsPagination?.items ?? []}
                            lastColumnIsAction={true}
                            changePage={getAll}
                            hasDelete={true}
                            functionToDelete={deleteContract}
                            isAllText={true}
                            total={contractsPagination?.total ?? 0}
                            take={take}
                            linkToEdit={`/${company.serviceId}/contractModule/edit/`}
                            hasEdit={true}
                        />
                    </div>
                    <div className="d-flex justify-content-between w-100">
                        <div>
                            <FormControl size="small">
                                <Select
                                    value={take}
                                    onChange={(e) => setTake(parseInt(e.target.value.toString()))}
                                    className="forms-control h-100 me-2 w-100"
                                >
                                    <MenuItem value={7}>Visualizando: 07</MenuItem>
                                    <MenuItem value={15}>Visualizando: 15</MenuItem>
                                    <MenuItem value={25}>Visualizando: 25</MenuItem>
                                    <MenuItem value={50}>Visualizando: 50</MenuItem>
                                    <MenuItem value={100}>Visualizando: 100</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        <Button onClick={toggle} className="text-nowrap" color="primary" variant="contained">Cadastrar Novo Contrato</Button>
                    </div>
                </div>
            </div>
            <CreateContractsModal isShowing={isShowing} toggle={toggle} />
        </>
    )
};