import { useState, useContext, useEffect, createRef } from "react";
import { Collapse } from "react-bootstrap";
import { GiSevenPointedStar } from "react-icons/gi";
import useModal from "../../../hooks/useModal";
import { ComponentsProps } from "../ComponentsProduct";
import { ComponentsTableActions } from "../../ProductComponents/ComponentsTableActions";
import DeleteModal from "../../Shared/DeleteModal";
import './styles.css';
import { ProductModelProps } from "../../../interfaces/ProductModel/IProductModel";

interface ComponentsTableProps {
    model?:ProductModelProps | null;
    newComponents: ComponentsProps[];
    savedComponents: ComponentsProps[];
    removeComponent: (id: string) => void;
    removeSavedComponent: (id: string) => Promise<void>
    removeAllNewComponents: () => void;
    removeAllSavedComponents: () => Promise<void>;
    hasTecnac: boolean;
}

export function ComponentsTable({ model, newComponents, savedComponents, removeComponent, removeAllNewComponents, removeSavedComponent, removeAllSavedComponents, hasTecnac }: ComponentsTableProps) {
    const ComponentsRef = createRef<HTMLDivElement>();
    const [colunmOrder, setColunmOrder] = useState<keyof ComponentsProps>('name');
    const [order, setOrder] = useState(1);



    useEffect(() => {
        if (ComponentsRef && ComponentsRef.current) {
            const { scrollHeight, clientHeight } = ComponentsRef.current;

            ComponentsRef.current.scrollTo({
                left: 0, top: scrollHeight - clientHeight,
                behavior: 'smooth'
            });
        }

    }, [newComponents]);


    const handleOrder = (fieldName: any) => {
        setOrder(-order);
        setColunmOrder(fieldName);
    }

    savedComponents = savedComponents.sort((a, b) => {
        return a[colunmOrder] < b[colunmOrder] ? -order : order
    });

    newComponents = newComponents.sort((a, b) => {
        return a[colunmOrder] < b[colunmOrder] ? -order : order
    });

    return (
        <>
            <div className='table-responsive table-max-height' ref={ComponentsRef}>
                <table className='table table-bordered table-white table-hover'>
                    <thead>
                        <tr>
                            <th onClick={() => handleOrder('name')}>Componente</th>
                            <th onClick={() => handleOrder('origin')}>Origem</th>
                            <th onClick={() => handleOrder('percentage')}>Porcentagem</th>
                            {!hasTecnac &&
                                <th></th>
                            }
                        </tr>
                    </thead>
                    <tbody>
                        <>
                            {newComponents.length == 0 && savedComponents.length == 0 ?
                                <>
                                    <tr>
                                        <td colSpan={12} className='text-center'>
                                            Não há componentes cadastrados no momento
                                        </td>
                                    </tr>
                                </>
                                :
                                null
                            }
                            {savedComponents.map((Component, index) => (
                                <>
                                    <tr key={index}>
                                        <td width='100px' title={Component.name}>{Component.name}</td>
                                        <td>{Component.origin}</td>
                                        <td>{Component.percentage}%</td>
                                        {!hasTecnac &&
                                            <ComponentsTableActions model={model} action={removeSavedComponent} id={Component.id} />
                                        }
                                    </tr>
                                </>

                            ))}

                            {newComponents.map((Component, index) => (
                                <>
                                    <tr key={index}>
                                        <td width='100px'>
                                            <div className='new-Component' title={Component.name}>
                                                {Component.name}
                                                <GiSevenPointedStar size={10} />
                                            </div>
                                        </td>
                                        <td>{Component.origin}</td>
                                        <td>{Component.percentage} %</td>
                                        {!hasTecnac &&
                                            <ComponentsTableActions action={removeComponent} id={Component.id} />
                                        }
                                    </tr>
                                </>

                            ))}
                        </>

                    </tbody>
                </table>
            </div>
        </>
    )
}