import { Accordion, AccordionSummary, Button, Collapse, Switch } from "@mui/material";
import { useEffect, useState } from "react";
import AddendumInfos from "./AddendumInfos";
import AdditiveInfos from "./AdditiveInfos";
import { Add } from "@mui/icons-material";
import { ContractsFilesModel } from "../../../models/ContractsFilesModel";

interface IContractFilesModuleProps {
    additives: ContractsFilesModel[],
    Addendum: ContractsFilesModel[],
    addMoreAdditive(): void,
    addMoreAddendum(): void,
    updateAdditives(position: number, key: string, value: any): void,
    updateAddendum(position: number, key: string, value: any): void
}

export default function ContractFilesModule(props: IContractFilesModuleProps) {
    const [openAddendum, setOpenAddendum] = useState<boolean>(false);
    const [openAdditive, setOpenAdditive] = useState<boolean>(false);

    useEffect(() => {
        if(props.additives.length > 1 && openAdditive == false) {
            setOpenAdditive(true);
        }
    }, [props.additives]);

    useEffect(() => {
        if(props.Addendum.length > 1 && openAddendum == false) {
            setOpenAddendum(true);
        }
    }, [props.Addendum]);

    return (
        <div className="w-100">
            <div className="mb-3">
                <Accordion className='card-header d-flex align-items-center justify-content-between cursor-pointer w-100 mb-0 pb-0'>
                    <AccordionSummary className="w-100">
                        <div className='d-flex align-items-center justify-content-between w-100'>
                            <div>
                                <span>Aditivo</span>
                            </div>
                            <Switch color="secondary" checked={openAdditive} onChange={(e) => setOpenAdditive(e.target.checked)} />
                        </div>
                    </AccordionSummary>
                </Accordion>
                <Collapse in={openAdditive}>
                    {props.additives.map((item, index) => {
                        return (
                            <AdditiveInfos position={index} updateAdditives={props.updateAdditives} item={item} key={index} />
                        )
                    })}
                </Collapse>
            </div>
            {openAdditive &&
                <div className="d-flex w-100 align-items-center justify-content-end">
                    <Button onClick={props.addMoreAdditive} variant="text" color="primary" endIcon={<Add />}>Adicionar Termo Aditivo</Button>
                </div>
            }
            <div className="mt-3 mb-3">
                <Accordion className='card-header d-flex align-items-center justify-content-between cursor-pointer w-100 mb-0 pb-0'>
                    <AccordionSummary className="w-100">
                        <div className='d-flex align-items-center justify-content-between w-100'>
                            <div>
                                <span>Adendo</span>
                            </div>
                            <Switch color="secondary" checked={openAddendum} onChange={(e) => setOpenAddendum(e.target.checked)} />
                        </div>
                    </AccordionSummary>
                </Accordion>
                <Collapse in={openAddendum}>
                    {props.Addendum.map((item, index) => {
                        return (
                            <AddendumInfos position={index} updateAddendum={props.updateAddendum} item={item} key={index} />
                        )
                    })}

                </Collapse>
            </div>
            {openAddendum &&
                <div className="d-flex w-100 align-items-center justify-content-end">
                    <Button onClick={props.addMoreAddendum} variant="text" color="primary" endIcon={<Add />}>Adicionar adendo</Button>
                </div>
            }
        </div>
    )
}