import { useContext, useEffect, useState, FormEvent } from 'react';
import { Link, useParams } from 'react-router-dom';
import { AiFillHome } from 'react-icons/ai';
import PaginationTabHeader from '../../../components/Shared/PaginationTabHeader';
import CreditProductList from '../ProductList';
import { useCompany } from '../../../hooks/useCompany';
import { CreditContext } from '../../../contexts/creditContext';
import { CreditForm } from '../../../components/Credit/CreditForm';
import { toast } from 'react-toastify';
import Loader from '../../../components/Shared/Loader';
import { api } from '../../../services/axios';
import "./styles.css";
import { InvestValuesForm } from '../../../components/Credit/InvestValuesForm';

export interface FormProps {
    normalNotTecnacSurplusValue: number;
    tecnacSurplusValue: number;
    normalNotTecnacRemainingValue: number;
    tecnacRemainingValue: number;
    creditApplicationDate: string;
    creditReceiptFilePath: any;
    creditCertificateFilePath: any;
}

export interface SummaryFieldsProps {
    investValue: number;
    invoicingConsolidation: number;
    creditValue: number;
    creditLimit: number;
    remainingCreditValue: number;
    surplusCreditValue: number;
}

interface SummaryProps {
    sudamSudeneCOConsolidation: SummaryFieldsProps;
    sudamSudeneCOTecNacConsolidation: SummaryFieldsProps;
    othersRegionsConsolidation: SummaryFieldsProps;
    othersRegionsTecNacConsolidation: SummaryFieldsProps;
    region: string;
}

export default function CreditTabs() {
    let { countings, creditId } = useParams();
    const { credit, GetCredit } = useContext(CreditContext);
    const { company, updateHeaderValues } = useCompany();
    //initial states
    const [pag, setPag] = useState(0);
    const [key, setKey] = useState(0);
    const [loading, setLoading] = useState(false);
    //credit values
    const [sumQuarterSurplus, setSumQuarterSurplus] = useState<number>(0);
    const [sumQuarterRemaining, setSumQuarterRemaining] = useState<number>(0);
    const [creditRemainingValue, setCreditRemainingValue] = useState<number>(0);
    const [creditSurplusValue, setCreditSurplusValue] = useState<number>(0);
    const [creditValue, setCreditValue] = useState<number>(0);
    //file
    const [creditReceiptFile, setCreditReceiptFile] = useState<Blob>();
    const [clearReceiptInput, setClearReceiptInput] = useState(false);
    const [creditCertificateFile, setCreditCertificateFile] = useState<Blob>();
    const [clearCertificateInput, setClearCertificateInput] = useState(false);
    const [creditReceiptFilePathState, setCreditReceiptFilePathState] = useState<string | null>();
    const [creditCreditCertificatePathState, setCreditCertificatePathState] = useState<string | null>();
    //field to edit
    const [validateCheck, setValidateCheck] = useState(false);
    const [normalNotTecnacSurplusValue, setNormalNotTecnacSurplusValue] = useState<number>(0);
    const [tecnacSurplusValue, setTecnacSurplusValue] = useState<number>(0);
    const [normalNotTecnacRemainingValue, setNormalNotTecnacRemainingValue] = useState<number>(0);
    const [tecnacRemainingValue, setTecnacRemainingValue] = useState<number>(0);
    const [creditApplicationDate, setCreditApplicationDate] = useState<string>();
    //calculated values
    const [accumulatedCreditSurplusValue, setAccumulatedCreditSurplusValue] = useState<number>(0);
    const [accumulatedRemainingCreditValue, setAccumulatedRemainingCreditValue] = useState<number>(0);
    //invest values
    const [summaryValues, setSummaryValues] = useState<SummaryProps>();
    const [region, setRegion] = useState<string>("");
    const [isSudamSudene, setIsSudamSudene] = useState(false);
    const [investValueId, setInvestValueId] = useState<string>();
    const [investValueOthersRegions, setInvestValueOthersRegions] = useState<number>(0);
    const [investValueOthersRegionsTecNac, setInvestValueOthersRegionsTecNac] = useState<number>(0);
    const [investValueSudamSudeneCO, setInvestValueSudamSudeneCO] = useState<number>(0);
    const [investValueSudamSudeneCOTecNac, setInvestValueSudamSudeneCOTecNac] = useState<number>(0);
    //history
    const [lastNormal, setLastNormal] = useState<number>(0);
    const [lastTecnac, setLastTecnac] = useState<number>(0);
    const [lastNormalRemaining, setLastNormalRemaining] = useState<number>(0);
    const [lastTecnacRemaining, setLastTecnacRemaining] = useState<number>(0);
    const [getAnError, setGetAnError] = useState<boolean>(false);
    //const to use
    const countingText = ['Anual', '1º', '2º', '3º', '4º'];
    const countingsArray = countings?.split(',');
    const initialSummaryValues = {
        creditLimit: 0,
        creditValue: 0,
        investValue: 0,
        invoicingConsolidation: 0,
        remainingCreditValue: 0,
        surplusCreditValue: 0
    } as SummaryFieldsProps;

    //async functions
    async function getInvestValue() {
        try {
            setLoading(true);

            await api.get(`/investValue/GetByCredit/${creditId}`).then(resp => {
                if (resp.data.statusCode === 200) {
                    if (resp.data.data) {
                        setInvestValueId(resp.data.data.id);
                        setInvestValueOthersRegions(resp.data.data.investValueOthersRegions);
                        setInvestValueOthersRegionsTecNac(resp.data.data.investValueOthersRegionsTecNac);
                        setInvestValueSudamSudeneCO(resp.data.data.investValueSudamSudeneCO);
                        setInvestValueSudamSudeneCOTecNac(resp.data.data.investValueSudamSudeneCOTecNac);

                    }
                }
            });
        } catch (error: any) {
            toast.error(error);
        } finally {
            setLoading(false);
        }
    }

    async function getSummaryValues() {
        try {
            setLoading(true);

            await api.get(`/investValue/getConsolidation/${company.serviceId}/${creditId}`).then(resp => {
                if (resp.data.statusCode === 200) {
                    setSummaryValues(resp.data.data);

                    const region = resp.data.data.region;

                    const regionsIsSudamSudene = region != "Outras Regiões";

                    setRegion(region);
                    setIsSudamSudene(regionsIsSudamSudene);
                }
            });
        } catch (error: any) {
            toast.error(error);
        } finally {
            setLoading(false);
        }
    }

    async function getQuartersSum() {
        try {
            setLoading(true);

            var id = credit?.branchCompanyId ? credit?.branchCompanyId : "\"\"";

            await api.get(`/Credit/GetQuartersSum?creditId=${creditId}&serviceId=${company.serviceId}&branchCompanyId=${id}`).then(resp => {
                if (resp.status == 200) {
                    var data = resp.data.data;

                    setSumQuarterSurplus(data.sumQuarterSurplus);
                    setSumQuarterRemaining(data.sumQuarterRemaining);
                }
            })
        } catch (error: any) {
            toast.error(error);
        } finally {
            setLoading(false);
        }
    }

    async function uploadFile(creditId: string) {
        setLoading(true);
        try {
            let formData = new FormData();

            formData.append('id', creditId);
            formData.append('serviceId', company.serviceId);

            if (creditReceiptFile != undefined) {
                formData.append('creditReceiptFile', creditReceiptFile);
            }

            if (creditCertificateFile != undefined) {
                formData.append('creditCertificateFile', creditCertificateFile);
            }

            const result = await api.post('credit/uploadfile', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            const response = await result.data;

            const data = response.data;

            if (response.statusCode === 200) {
                toast.success('Upload dos arquivos feitos com sucesso!');

                setCreditCertificatePathState(data.creditCertificateFilePath);
                setCreditReceiptFilePathState(data.creditReceiptFilePath);
                setClearCertificateInput(true);
                setClearReceiptInput(true);
                setCreditCertificateFile(undefined);
                setCreditReceiptFile(undefined);
            }
        }
        catch (err: any) {
            toast.error(err);
        }
        finally {
            setLoading(false);
        }
    }

    async function submitFormCredit(e: FormEvent) {
        try {
            e.preventDefault();

            setLoading(true);

            let date = creditApplicationDate != null ? new Date(creditApplicationDate) : null;

            const object = {
                id: credit?.id,
                investValueId: investValueId,
                serviceId: company.serviceId,
                normalNotTecnacSurplusValue: normalNotTecnacSurplusValue,
                tecnacSurplusValue: tecnacSurplusValue,
                normalNotTecnacRemainingValue: normalNotTecnacRemainingValue,
                tecnacRemainingValue: tecnacRemainingValue,
                creditValue: creditValue,
                creditSurplusValue: creditSurplusValue,
                creditRemainingValue: creditRemainingValue,
                creditApplicationDate: date,
                validated: validateCheck,
                investValueOthersRegions: investValueOthersRegions,
                investValueOthersRegionsTecNac: investValueOthersRegionsTecNac,
                investValueSudamSudeneCO: investValueSudamSudeneCO,
                investValueSudamSudeneCOTecNac: investValueSudamSudeneCOTecNac,
            }

            const result = await api.put(`/Credit/Put`, object);

            const response = await result.data;

            if (validateCheck == true) {
                toast.info('A versão está sendo salva como validada e não poderá mais ser editada. Esse checkbox só poderá ser desmarcado por usuários com o perfil de administrator.');
            }

            if (response.statusCode === 200) {
                toast.success('Valor de Crédito salvo com sucesso!');

                if (creditApplicationDate) {
                    setCreditApplicationDate(creditApplicationDate?.toString().split("T")[0]);
                }

                await GetCredit(credit?.id ?? "");
                await uploadFile(credit?.id ?? "");

                await updateHeaderValues();
            }
        }
        catch (err: any) {
            toast.error(err);
        }
        finally {
            setLoading(false);
        }
    }

    async function handleDeleteFile(isCreditReceiptFile: boolean) {
        try {
            setLoading(true);
            const result = await api.post('credit/deletefile', {
                id: credit?.id,
                serviceId: company.serviceId,
                removeCreditReceiptFilePath: isCreditReceiptFile
            });

            const response = await result.data;

            if (response.statusCode === 200) {
                toast.success('Arquivo deletado com sucesso!');

                if (isCreditReceiptFile) {
                    setCreditReceiptFilePathState(null);
                    setClearReceiptInput(true);
                }
                else {
                    setCreditCertificatePathState(null);
                    setClearCertificateInput(true);
                }
            }
        }
        catch (err: any) {
            toast.error('Falha ao tentar deletar arquivo');
        }
        finally {
            setLoading(false);
        }
    }

    async function reCalcAllSummaryValues() {
        try {
            setLoading(true);

            var investNormalValue = isSudamSudene ? investValueSudamSudeneCO : investValueOthersRegions;
            var investValueTecNac = isSudamSudene ? investValueSudamSudeneCOTecNac : investValueOthersRegionsTecNac;

            await api.get(`/InvestValue/GetReCalcInvestValues/${company.serviceId}/${credit?.id}/${company.cnpj}/${region}/${isSudamSudene}/${investNormalValue}/${investValueTecNac}/${normalNotTecnacSurplusValue}/${tecnacSurplusValue}`).then(resp => {
                if (resp.data.statusCode == 200) {

                    setSummaryValues(resp.data.data);
                    setLastNormal(normalNotTecnacSurplusValue);
                    setLastTecnac(tecnacSurplusValue);
                } else {
                    if (resp.data.errors[0].includes("Normal")) {
                        setNormalNotTecnacSurplusValue(lastNormal);
                    } else if (resp.data.errors[0].includes("Tecnac")) {
                        setTecnacSurplusValue(lastTecnac);
                    }

                    setGetAnError(true);
                }
            });
        } catch (error: any) {
            toast.error(error);
        } finally {
            setLoading(false);
        }
    }

    async function screenAccess() {
        try {
            setLoading(true);

            await api.get(`credit/ScreenAccess/serviceId=${company.serviceId}`);
        } catch (error: any) {
            toast.error(error);
        } finally {
            setLoading(false);
        }
    }

    //normal functions
    function calcAccumulatedSurplus(isNormal?: boolean) {
        var result = ((sumQuarterSurplus - normalNotTecnacSurplusValue) - tecnacSurplusValue);

        if (getAnError) {
            setGetAnError(false);
        }

        if (result < 0) {
            toast.error("Valor de crédito acumulado insuficiente");
            if (isNormal == true) {
                setNormalNotTecnacSurplusValue(lastNormal);
            } else if (isNormal == false) {
                setTecnacSurplusValue(lastTecnac);
            } else {
                setNormalNotTecnacSurplusValue(lastNormal);
                setTecnacSurplusValue(lastTecnac);
            }
            return false;
        } else {
            setAccumulatedCreditSurplusValue(result);
            setNormalNotTecnacSurplusValue(normalNotTecnacSurplusValue);
            setTecnacSurplusValue(tecnacSurplusValue);
            return true;
        }
    }

    function calcAccumulatedRemaining(isNormal?: boolean) {
        var result = ((sumQuarterRemaining - normalNotTecnacRemainingValue) - tecnacRemainingValue);

        if (result < 0) {
            toast.error("Valor de crédito acumulado insuficiente");
            if (isNormal == true) {
                setNormalNotTecnacRemainingValue(lastNormalRemaining);
            } else if (isNormal == false) {
                setTecnacRemainingValue(lastTecnacRemaining);
            } else {
                setNormalNotTecnacRemainingValue(lastNormalRemaining);
                setTecnacRemainingValue(lastTecnacRemaining);
            }
        } else {
            setAccumulatedRemainingCreditValue(result);
            setNormalNotTecnacRemainingValue(normalNotTecnacRemainingValue);
            setTecnacRemainingValue(tecnacRemainingValue);
        }
    }

    function changeValidateCheck() {
        setValidateCheck(!validateCheck);
    }

    function updateCreditValues() {
        var credit = summaryValues ? summaryValues.othersRegionsConsolidation.creditValue + summaryValues.othersRegionsTecNacConsolidation.creditValue + summaryValues.sudamSudeneCOConsolidation.creditValue + summaryValues.sudamSudeneCOTecNacConsolidation.creditValue : 0;
        var creditSurplus = summaryValues ? summaryValues.othersRegionsConsolidation.surplusCreditValue + summaryValues.othersRegionsTecNacConsolidation.surplusCreditValue + summaryValues.sudamSudeneCOConsolidation.surplusCreditValue + summaryValues.sudamSudeneCOTecNacConsolidation.surplusCreditValue : 0;
        var creditRemaining = summaryValues ? summaryValues.othersRegionsConsolidation.remainingCreditValue + summaryValues.othersRegionsTecNacConsolidation.remainingCreditValue + summaryValues.sudamSudeneCOConsolidation.remainingCreditValue + summaryValues.sudamSudeneCOTecNacConsolidation.remainingCreditValue : 0;

        setCreditRemainingValue(creditRemaining);
        setCreditSurplusValue(creditSurplus);
        setCreditValue(credit);
    }

    function initAllStates() {
        setNormalNotTecnacSurplusValue(credit?.normalNotTecnacSurplusValue ?? 0);
        setNormalNotTecnacRemainingValue(credit?.normalNotTecnacRemainingValue ?? 0);
        setTecnacSurplusValue(credit?.tecnacSurplusValue ?? 0);
        setTecnacRemainingValue(credit?.tecnacRemainingValue ?? 0);
        setLastNormal(credit?.normalNotTecnacSurplusValue ?? 0);
        setLastTecnac(credit?.tecnacSurplusValue ?? 0);
        setLastNormalRemaining(credit?.normalNotTecnacRemainingValue ?? 0);
        setLastTecnacRemaining(credit?.tecnacRemainingValue ?? 0);
        setValidateCheck(credit?.validated ?? false);
        setCreditApplicationDate(credit?.creditApplicationDate);
        setCreditReceiptFilePathState(credit?.creditReceiptFilePath);
        setCreditCertificatePathState(credit?.creditCertificateFilePath);
    }

    //use effects
    useEffect(() => {
        screenAccess();
    }, []);

    useEffect(() => {
        if (creditId) {
            GetCredit(creditId);
        }
    }, [creditId]);

    useEffect(() => {
        if (credit && credit.id != "" && credit.id != undefined && credit.id != null) {
            getQuartersSum();
            getSummaryValues();
            getInvestValue();
            initAllStates();
        }
    }, [credit]);

    useEffect(() => {
        calcAccumulatedSurplus();
    }, [sumQuarterSurplus]);

    useEffect(() => {
        calcAccumulatedRemaining();
    }, [sumQuarterRemaining]);

    useEffect(() => {
        updateCreditValues();
    }, [summaryValues]);

    useEffect(() => {
        if (getAnError) {
            calcAccumulatedSurplus();
        }
    }, [getAnError]);

    return (
        <>
            {loading && < Loader />}
            <div className='card'>
                <div className='card-header'>
                    <div className='breadCrumb'>
                        <Link to={`/${company.serviceId}/home`} className='breadCrumb-home d-flex align-center'>
                            <AiFillHome className='breadCrumb-home' />
                        </Link>
                        <span className='breadCrumb-separator'>{' > '}</span>
                        <span className='breadCrumb-item-actual'>
                            <Link to={`/${company.serviceId}/credit`}>
                                V. Crédito Financeiro
                            </Link>
                        </span>
                        <span className='breadCrumb-separator'>{' > '}</span>
                        <span className='breadCrumb-item'>
                            {
                                countingsArray?.map((x, index) => (
                                    <span key={index}> {countingText[parseInt(x)]}</span>
                                ))
                            }
                            {
                                countingsArray != undefined ?
                                    countingsArray?.length >= 1 && countingsArray[0] != '0'
                                        ?
                                        ' Trimestre'
                                        :
                                        ''
                                    :
                                    ''
                            }
                        </span>
                    </div>
                </div>
                <div className='card-body'>
                    <PaginationTabHeader setPag={setPag} pag={pag} setKey={setKey} keyDefault={key} tabs={["Produto", "Resumo de Valores", "Valores de Créditos"]} />
                    {pag == 0 ?
                        <CreditProductList key={0} />
                        :
                        pag == 1 ?
                            <InvestValuesForm
                                key={1}
                                investValueOthersRegions={investValueOthersRegions}
                                investValueOthersRegionsTecNac={investValueOthersRegionsTecNac}
                                investValueSudamSudeneCO={investValueSudamSudeneCO}
                                investValueSudamSudeneCOTecNac={investValueSudamSudeneCOTecNac}
                                isSudamSudene={isSudamSudene}
                                othersRegionsConsolidation={summaryValues?.othersRegionsConsolidation ?? initialSummaryValues}
                                othersRegionsTecNacConsolidation={summaryValues?.othersRegionsTecNacConsolidation ?? initialSummaryValues}
                                sudamSudeneCOConsolidation={summaryValues?.sudamSudeneCOConsolidation ?? initialSummaryValues}
                                sudamSudeneCOTecNacConsolidation={summaryValues?.sudamSudeneCOTecNacConsolidation ?? initialSummaryValues}
                                validated={credit?.validated ?? false}
                                setInvestValueOthersRegions={setInvestValueOthersRegions}
                                setInvestValueOthersRegionsTecNac={setInvestValueOthersRegionsTecNac}
                                setInvestValueSudamSudeneCO={setInvestValueSudamSudeneCO}
                                setInvestValueSudamSudeneCOTecNac={setInvestValueSudamSudeneCOTecNac}
                                reCalcAllSummaryValues={reCalcAllSummaryValues}
                            />
                            :
                            <CreditForm
                                submitFormCredit={submitFormCredit}
                                accumulatedCreditSurplusValue={accumulatedCreditSurplusValue}
                                validated={credit?.validated ?? false}
                                validatedCheck={validateCheck}
                                reCalcAllSummaryValues={reCalcAllSummaryValues}
                                calcAccumulatedSurplus={calcAccumulatedSurplus}
                                creditAvailableValue={creditRemainingValue}
                                accumulatedRemainingCreditValue={accumulatedRemainingCreditValue}
                                calcAccumulatedRemaining={calcAccumulatedRemaining}
                                normalNotTecnacSurplusValue={normalNotTecnacSurplusValue}
                                tecnacSurplusValue={tecnacSurplusValue}
                                normalNotTecnacRemainingValue={normalNotTecnacRemainingValue}
                                tecnacRemainingValue={tecnacRemainingValue}
                                creditSurplusValue={creditSurplusValue}
                                creditValue={creditValue}
                                changeValidateCheck={changeValidateCheck}
                                creditApplicationDate={creditApplicationDate}
                                setCreditReceiptFile={setCreditReceiptFile}
                                clearReceiptInput={clearReceiptInput}
                                setClearReceiptInput={setClearReceiptInput}
                                handleDeleteFile={handleDeleteFile}
                                creditReceiptFilePathState={creditReceiptFilePathState}
                                setCreditCertificateFile={setCreditCertificateFile}
                                clearCertificateInput={clearCertificateInput}
                                setClearCertificateInput={setClearCertificateInput}
                                creditCreditCertificatePathState={creditCreditCertificatePathState}
                                setNormalNotTecnacRemainingValue={setNormalNotTecnacRemainingValue}
                                setTecnacSurplusValue={setTecnacSurplusValue}
                                setTecnacRemainingValue={setTecnacRemainingValue}
                                setNormalNotTecnacSurplusValue={setNormalNotTecnacSurplusValue}
                                setCreditApplicationDate={setCreditApplicationDate}
                                key={2}
                            />
                    }
                </div>
            </div>
        </>
    );
}