import { AiFillHome } from 'react-icons/ai';
import { useState } from "react";
import Loader from "../../../components/Shared/Loader";
import { Link, useParams } from "react-router-dom";
import { formatDate } from "../../../utils/formatDate";
import { useCompany } from "../../../hooks/useCompany";
import { Button, TextField } from "@mui/material";
import "./style.css";

export interface InvoicingDetailsProps {
    id: string;
    invoiceNum: string | null;
    itemCode: string | null;
    invoiceDate: Date | null;
    invoiceSeries: string | null;
    invoiceAcessKey: string | null;
    destName: string | null;
    destDocument: string | null;
    destUF: string | null;
    emitMun: string | null;
    emitDocument: string | null;
    emitUF: string | null;
    itemCST: string | null;
    itemCFOP: string | null;
    itemNCM: string | null;
    itemName: string | null;
    itemQuantity: string | null;
    itemUniValue: string | null;
    itemTotalValueWithoutTax: string | null;
    itemIPI: string | null;
    itemICMS: string | null;
    itemICMSSTDIFAL: string | null;
    itemPIS: string | null;
    itemCOFINS: string | null;
    itemTotalValueWithTax: string | null;
    destCity: string | null;

}

interface PreLoadInvoicingDetails {
    invoicingModel: InvoicingDetailsProps;
}

export default function InvoicingDetailsForm({ invoicingModel }: PreLoadInvoicingDetails) {
    const [loading, setLoading] = useState(false);
    const { id, isLinked, productId, isTecnac } = useParams();
    const { company } = useCompany();

    function calcularTotalSemImpostos(invoicingModel: InvoicingDetailsProps): string {
        const quantidade = parseFloat(invoicingModel.itemQuantity || '0');
        const valorUnitario = parseFloat(invoicingModel.itemUniValue || '0');
        const total = (quantidade * valorUnitario).toFixed(2);
        return total;
    }
    function formatarMoeda(valor: string | number): string {
        const valorNumerico = typeof valor === "string" ? parseFloat(valor.replace(',', '.')) : valor;
        const valorFormatado = valorNumerico.toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
            minimumFractionDigits: 2,
        });

        return valorFormatado;
    }

    var tax =
        (parseFloat(calcularTotalSemImpostos(invoicingModel)) +
            parseFloat(invoicingModel.itemIPI ? invoicingModel.itemIPI : "0") +
            parseFloat(invoicingModel.itemICMSSTDIFAL ? invoicingModel.itemICMSSTDIFAL : "0")).toFixed(2);

    function formatCNPJ(cnpj: string | null): string {
        if (!cnpj) return "";

        const stripped = cnpj.replace(/[^0-9]/g, "");
        const masked = stripped.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");

        return masked;
    }
    function formatNCM(ncm: string | null) {
        if (!ncm) return "";

        const stripped = ncm.replace(/[^0-9]/g, "");
        const masked = stripped.replace(/(\d{4})(\d{2})(\d{2})/, "$1.$2.$3");

        return masked;
    }

    return (
        <>
            {loading && <Loader />}
            <div className='card'>
                <div className='card-header'>
                    <div className='breadCrumb'>
                        <Link to={`/${company.serviceId}/home`} className='breadCrumb-home d-flex align-center'>
                            <AiFillHome className='breadCrumb-home' />
                        </Link>
                        <span className='breadCrumb-separator'>{' > '}</span>
                        <Link className='breadCrumb-item' to={`/${company.serviceId}/${company.companyId}/invoicing`}>
                            II.Faturamento
                        </Link>
                        <span className='breadCrumb-separator'>{' > '}</span>
                        <span className='breadCrumb-item-actual'>{invoicingModel.itemName}</span>
                    </div>
                </div>
                <form className='card-body'>
                    <div className=''>
                        <div className="d-flex align-items-center ">
                            <div className='mb-4 w-100 fieldset'>
                                <label className='form-label required label-customer'></label>
                                <TextField label="Chave de Acesso" className='form-control' value={invoicingModel.invoiceAcessKey ? invoicingModel.invoiceAcessKey : ""} />
                            </div>
                            <div className='mb-3 w-50 ms-4 fieldset'>
                                <label className='form-label required label-customer'></label>
                                <TextField label="N° Nota Fiscal" className='form-control form-control' value={invoicingModel.invoiceNum ? invoicingModel.invoiceNum : ""} />
                            </div>
                            <div className='mb-3 w-20 ms-4 fieldset'>
                                <label className='form-label required label-customer'></label>
                                <TextField label="Série" className='form-control form-control' value={invoicingModel.invoiceSeries ? invoicingModel.invoiceSeries : ""}>
                                </TextField>
                            </div>
                            <div className='mb-3 w-25 ms-4 fieldset'>
                                <label className='form-label required label-customer'></label>
                                <TextField label="Data" className='form-control form-control' value={invoicingModel.invoiceDate != null ? formatDate(new Date(invoicingModel.invoiceDate)) : "dd/mm/aaaa"} />
                            </div>
                        </div>
                        <div className="d-flex align-items-center fieldset">
                            <div className='mb-3 w-100'>
                                <label className='form-label required label-customer'></label>
                                <TextField label="Destinatário" className='form-control form-control' value={invoicingModel.destName ? invoicingModel.destName : ""} />
                            </div>
                            <div className='mb-3 w-50 ms-4 fieldset'>
                                <label className='form-label required label-customer'></label>
                                <TextField label="CNPJ Destinatário" className='form-control form-control' value={formatCNPJ(invoicingModel.destDocument ? invoicingModel.destDocument : "")} />
                            </div>
                            <div className='mb-3 w-25 ms-4 fieldset'>
                                <label className='form-label required label-customer'></label>
                                <TextField label="Município" className='form-control form-control' value={invoicingModel.destCity ? invoicingModel.destCity : ""} />
                            </div>
                            <div className='mb-3 w-20 ms-4 fieldset'>
                                <label className='form-label required label-customer'></label>
                                <TextField label="UF" className='form-control form-control' value={invoicingModel.destUF ? invoicingModel.destUF : ""} />
                            </div>
                        </div>
                        <div className="d-flex align-items-center fieldset">
                            <div className='mb-3 w-100'>
                                <label className='form-label required label-customer'></label>
                                <TextField label="CNPJ Emitente" className='form-control form-control' value={formatCNPJ(invoicingModel.emitDocument ? invoicingModel.emitDocument : "")} />
                            </div>
                            <div className='mb-3 w-100 ms-4 fieldset'>
                                <label className='form-label required label-customer'></label>
                                <TextField label="Município Emitente" className='form-control form-control' value={invoicingModel.emitMun ? invoicingModel.emitMun : ""} />
                            </div>
                            <div className='mb-3 w-100 ms-4 fieldset'>
                                <label className='form-label required label-customer'></label>
                                <TextField label="UF Emitente" className='form-control form-control' value={invoicingModel.emitUF ? invoicingModel.emitUF : ""} />
                            </div>
                        </div>
                        <div className="d-flex align-items-center fieldset">
                            <div className='mb-3 w-100'>
                                <label className='form-label required label-customer'></label>
                                <TextField label="Código do Item" className='form-control form-control' value={invoicingModel.itemCode ? invoicingModel.itemCode : ""} />
                            </div>
                        </div>
                        <div className="d-flex align-items-center fieldset">
                            <div className='mb-3 w-100'>
                                <label className='form-label required label-customer'></label>
                                <TextField label="Descrição do Item" className='form-control form-control' value={invoicingModel.itemName ? invoicingModel.itemName : ""} />
                            </div>
                        </div>
                        <div className="d-flex align-items-center ">
                            <div className='mb-3 w-25 fieldset'>
                                <label className='form-label required label-customer'></label>
                                <TextField label="NCM" className='form-control form-control' value={formatNCM(invoicingModel.itemNCM ? invoicingModel.itemNCM : "")} />
                            </div>
                            <div className='mb-3 w-20 ms-4 fieldset'>
                                <label className='form-label required label-customer'></label>
                                <TextField label="CST" className='form-control form-control' value={invoicingModel.itemCST ? invoicingModel.itemCST : "000"} />
                            </div>
                            <div className='mb-3 w-20 ms-4 fieldset'>
                                <label className='form-label required label-customer'></label>
                                <TextField label="CFOP" className='form-control form-control' value={invoicingModel.itemCFOP ? invoicingModel.itemCFOP : "0000"} />
                            </div>
                            <div className='mb-3 w-25 ms-4 fieldset'>
                                <label className='form-label required label-customer'></label>
                                <TextField label="Quantidadade" className='form-control form-control' value={parseInt(invoicingModel.itemQuantity ? invoicingModel.itemQuantity : "0")} />
                            </div>
                            <div className='mb-3 w-25 ms-4 fieldset'>
                                <label className='form-label required label-customer'></label>
                                <TextField label="Valor Unitário" className='form-control form-control' value={formatarMoeda(invoicingModel.itemUniValue ? invoicingModel.itemUniValue : "")} />
                            </div>
                        </div>
                        <div className="d-flex align-items-center fieldset">
                            <div className='mb-3 w-100'>
                                <label className='form-label required label-customer'></label>
                                <TextField
                                    label="Valor Total sem Impostos"
                                    className='form-control form-control'
                                    value={formatarMoeda(calcularTotalSemImpostos(invoicingModel))}
                                />
                            </div>
                        </div>
                        <div className="d-flex align-items-center fieldset">
                            <div className='mb-3 w-25'>
                                <label className='form-label required label-customer'></label>
                                <TextField label="ICMS" className='form-control form-control' value={formatarMoeda(invoicingModel.itemICMS ? invoicingModel.itemICMS : "0")} />
                            </div>
                            <div className='mb-3 w-20 ms-4 fieldset'>
                                <label className='form-label required label-customer'></label>
                                <TextField label="PIS" className='form-control form-control' value={formatarMoeda(invoicingModel.itemPIS ? invoicingModel.itemPIS : "0")} />
                            </div>
                            <div className='mb-3 w-20 ms-4 fieldset'>
                                <label className='form-label required label-customer'></label>
                                <TextField label="COFINS" className='form-control form-control' value={formatarMoeda(invoicingModel.itemCOFINS ? invoicingModel.itemCOFINS : "0")} />
                            </div>
                            <div className='mb-3 w-25 ms-4 fieldset'>
                                <label className='form-label required label-customer'></label>
                                <TextField label="ICMS ST ou DIFAL" className='form-control form-control' value={formatarMoeda(invoicingModel.itemICMSSTDIFAL ? invoicingModel.itemICMSSTDIFAL : "")} />
                            </div>
                            <div className='mb-3 w-25 ms-4 fieldset'>
                                <label className='form-label required label-customer'></label>
                                <TextField label="IPI" className='form-control form-control' value={formatarMoeda(invoicingModel.itemIPI ? invoicingModel.itemIPI : "")} />
                            </div>
                        </div>
                        <div className="d-flex align-items-center ">
                            <div className='mb-3 w-100 fieldset'>
                                <label className='form-label required label-customer'></label>
                                <TextField label="Valor Total com Impostos" className='form-control form-control' value={formatarMoeda(tax)} />
                            </div>
                        </div>
                    </div>
                    <div className="text-end">
                        <Link to={isLinked == undefined
                            ? productId != undefined
                                ? `/${company.serviceId}/${company.companyId}/invoicing/list/${productId}/${isTecnac ?? 0}`
                                : `/${company.serviceId}/${company.companyId}/invoicing`
                            : `/${company.serviceId}/${company.companyId}/invoicingNotLinked`
                        }>
                            <Button variant="outlined" className="btn " style={{ width: '120px', height: '40px' }}>
                                Voltar
                            </Button>
                        </Link>
                    </div>
                </form>
            </div>
        </>
    );
}