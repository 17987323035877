import { useContext, useState } from "react";
import { FaPen, FaTrash } from "react-icons/fa";
import { IoIosSave } from "react-icons/io";
import { toast } from "react-toastify";
import useModal from "../../../hooks/useModal";
import { api } from "../../../services/axios";
import DeleteModal from "../../Shared/DeleteModal";
import Loader from "../../Shared/Loader";
import { ServiceYearProps } from "../IdentityCompanyCard";
import { CompanyContext } from "../../../contexts/companyContext";

interface ServiceFormProps {
    uf: string;
    tenantCompanyId: string;
    year: number;
    countingType: number;
    id: string;
    setService: React.Dispatch<React.SetStateAction<ServiceYearProps[]>>;
}

function getYears() {
    const nowDate = Date.now();
    const nowYear = new Date(nowDate).getFullYear();

    const years: number[] = [];

    for (let index = -5; index < 5; index++) {
        const element = nowYear + index;
        years.push(element);
    }
    return years;
}

const brasilianUFs = [
    "AC", "AL", "AM", "AP", "BA", "CE", "DF", "ES", "GO", "MA", "MG", "MS", "MT", "PA", "PB", "PE", "PI", "PR", "RJ", "RN", "RO", "RR", "RS", "SC", "SE", "SP", "TO"
];

export function ServiceForm({ year, tenantCompanyId, countingType, id, setService, uf: ufProp }: ServiceFormProps) {
    const [isDisabled, setIsDisabled] = useState(true);
    const [save, setSave] = useState(false);
    const [fiscalYear, setFiscalYear] = useState(year);
    const [counting, setCounting] = useState(countingType);
    const [uf, setUf] = useState(ufProp);
    const [loading, setLoading] = useState(false);
    const { isShowing, toggle } = useModal();
    const context = useContext(CompanyContext);


    function handleToggleEnableFields() {
        setIsDisabled(!isDisabled);
        setSave(!save);
    }

    async function handleUpdateService() {
        setLoading(true);
        try {
            const fiscalYearObject = {
                id: id,
                counting: counting,
                year: fiscalYear,
                uf: uf,
                tenantCompanyId: context.company.companyId,
                title : context.company.title
            }

            const result = await api.put('/serviceYear/put', fiscalYearObject);

            const response = await result.data;

            if (response.statusCode === 200) {
                toast.success('Ano fiscal editado com sucesso!');
                handleToggleEnableFields();
            }
        }
        catch (error: any) {
            console.log('Error create service', error);
        }
        finally {
            setLoading(false);
        }
    }

    async function handleDeleteService() {
        setLoading(true);
        try {
            const fiscalYearObject = {
                id: id,
            }

            const result = await api.delete('/serviceYear/delete', {
                data: {
                    id: id
                }
            });

            const response = await result.data;

            if (response.statusCode === 200) {
                toast.success('Ano fiscal excluido com sucesso!');
                setService((current) =>
                    current.filter((service) => service.id !== id)
                );
            }
        }
        catch (error: any) {
            console.log('Error create service', error);
        }
        finally {
            setLoading(false);
        }
    }

    return (
        <>
            {loading && <Loader />}
            <tr>
                <td>
                    <select
                        className='form-select'
                        disabled={isDisabled}
                        onChange={(e) => setFiscalYear(parseInt(e.target.value))}
                    >
                        {getYears().map(y => (
                            <option key={y} value={y} selected={y == year}>{y}</option>
                        ))}
                    </select>
                </td>
                <td>
                    <select
                        className='form-select'
                        disabled={isDisabled}
                        onChange={(e) => setCounting(parseInt(e.target.value))}
                    >
                        <option value='0' selected={countingType == 0 ? true : false}>Anual</option>
                        <option value='1' selected={countingType == 1 ? true : false}>Trimestral</option>
                        <option value='2' selected={countingType == 2 ? true : false}>Mensal</option>
                    </select>
                </td>
                <td>
                    <select
                        className='form-select'
                        disabled={isDisabled}
                        onChange={(e) => setUf(e.target.value)}
                    >
                        <option value="">Selecione</option>
                        {brasilianUFs.map((item) => (
                            <option
                                value={item}
                                selected={item == uf}

                            >
                                {item}
                            </option>
                        ))}
                    </select>
                </td>
                {save
                    ?
                    <td className='d-flex align-items-center justify-content-center tr-200px' onClick={handleUpdateService}>
                        <button className='btn btn-success' type='button' title='Salvar ano fiscal'>
                            <IoIosSave />
                        </button>
                    </td>
                    :
                    <td className='d-flex align-items-center justify-content-center tr-200px'>
                        <button className='btn btn-success' onClick={handleToggleEnableFields} title='Editar ano fiscal'>
                            <FaPen size={15} />
                        </button>
                       {/*<button className='btn btn-success' onClick={toggle} title='Deletar ano fiscal'>
                            <FaTrash size={15} />
                        </button>*/}
                    </td>
                }
            </tr>
            {isShowing && <DeleteModal
                hide={toggle}
                isShowing={isShowing}
                title={'Deletar Ano Fiscal'}
                text={'Tem certeza que deseja deletar este ano fiscal?'}
                action={handleDeleteService}
            />}
        </>
    )
}