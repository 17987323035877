import { useEffect, useState } from 'react';
import Loader from '../../components/Shared/Loader';
import './styles.css';
import { Link } from 'react-router-dom';
import { AiFillHome } from 'react-icons/ai';
import { useCompany } from '../../hooks/useCompany';
import PaginationTabHeader from '../../components/Shared/PaginationTabHeader';
import { Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { api } from '../../services/axios';
import CreateCFOP from '../../components/CFOPCompanies/CreateCFOP';
import { v4 } from 'uuid';
import { useCan } from '../../hooks/useCan';
import { Button, Table, TableCell, TableHead, TableRow } from '@mui/material';
import { Pagination } from '@mui/material';

export interface ICFOPCompaniesProps {
    code: string;
    id: string;
    description: string;
    isChecked: boolean;
    isDefault?: boolean;
}

export default function CFOPCompanies() {
    const canUpdate = useCan({ rolesCan: ["Administrator", "Consultant"] });
    const { company } = useCompany();
    const [loading, setLoading] = useState<boolean>(false);
    const [page, setPage] = useState<number>(0);
    const [key, setKey] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [totalPages, setTotalPages] = useState<number>(0);
    const [CFOPs, setCFOPs] = useState<ICFOPCompaniesProps[]>([]);
    const [CFOPsPaginated, setCFOPsPaginated] = useState<ICFOPCompaniesProps[]>([]);
    const [hasInvoice, setHasInvoice] = useState<boolean>(false);
    const [createCFOP, setCreateCFOP] = useState<boolean>(false);
    const CFOPExitDefault = ["5101", "5111", "5113", "5116", "5118", "5122", "5401", "5402", "6101", "6103", "6105", "6107", "6111", "6113", "6116", "6118", "6122", "6401", "6402", "6404"];
    const CFOPEntryDefault = ["1201", "1208", "1410", "2201", "2401", "3201"];
    const permissionFinancial = useCan({ rolesCan: ["Financial"] });
    const permissionTechnician = useCan({ rolesCan: ["Technician"] });
    const permissionMaster = useCan({ rolesCan: ["Master"] });
    let hasPermition = false;

    if (permissionFinancial || permissionTechnician || permissionMaster) {
        hasPermition = true;
    }

    async function GetCFOPsByCompanyAndInvoiceType() {
        try {
            setLoading(true);

            await api.get(`/CFOPCompanies/GetAllByCompanyId?companyId=${company.companyId}&invoiceType=${page}`).then(resp => {
                if (resp.data.statusCode === 200) {
                    let allItems = resp.data.data as ICFOPCompaniesProps[];

                    if (page === 0) {
                        CFOPExitDefault.forEach(x => {
                            allItems.push({
                                code: x,
                                description: "Padrão",
                                id: v4(),
                                isChecked: true,
                                isDefault: true
                            } as ICFOPCompaniesProps)
                        });
                    } else {
                        CFOPEntryDefault.forEach(x => {
                            allItems.push({
                                code: x,
                                description: "Padrão",
                                id: v4(),
                                isChecked: true,
                                isDefault: true
                            } as ICFOPCompaniesProps)
                        });
                    }

                    let total = allItems.length;
                    const pages = total / 10;
                    const ceilPages = Math.ceil(pages);

                    setCFOPs(allItems);
                    setTotalPages(ceilPages);
                }
            });

            await api.get(`/CFOPCompanies/HasInvoiceInCompany?companyId=${company.companyId}&invoiceType=${page}`).then(resp => {
                if (resp.data.statusCode === 200) {
                    setHasInvoice(resp.data.data);
                }
            });
        } catch (error: any) {
            toast.error(error);
        } finally {
            setLoading(false);
        }
    }

    async function PutCFOPChecked(id: string, checked: boolean) {
        try {
            setLoading(true);

            await api.put('/CFOPCompanies/PutCFOPChecked', { Id: id, IsChecked: checked, serviceId: company.serviceId }).then(resp => {
                if (resp.data.statusCode === 200) {
                    toast.success("CFOP atualizado!");
                    GetCFOPsByCompanyAndInvoiceType();
                }
            })
        } catch (error: any) {
            toast.error(error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        GetCFOPsByCompanyAndInvoiceType();
    }, [page]);

    useEffect(() => {
        paginationInfos();
    }, [currentPage, CFOPs]);

    function paginationInfos() {
        let itemsInThisPage = CFOPs.slice((currentPage - 1) * 10, currentPage * 10);
        setCFOPsPaginated(itemsInThisPage);
    }

    const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setCurrentPage(value);
    };
   
    return (
        <>
            {loading && <Loader />}
            <div className='card'>
                <div className='card-header'>
                    <div className='breadCrumb'>
                        <Link to={`/${company.serviceId}/home`} className='breadCrumb-home d-flex align-center'>
                            <AiFillHome className='breadCrumb-home' />
                        </Link>
                        <span className='breadCrumb-separator'>{' > '}</span>
                        <Link to={`/${company.serviceId}/company/edit`}><span className='breadCrumb-item-actual'>Editar informações da empresa</span></Link>
                        <span className='breadCrumb-separator'>{' > '}</span>
                        <span className=''>Listagem CFOP Elegíveis</span>
                    </div>
                </div>

                <div className='card-body'>
                    <PaginationTabHeader setPag={setPage} pag={page} setKey={setKey} keyDefault={key} tabs={["Saídas", "Entradas"]} />
                    <div className='card-body bg-light w-100 h-100 p-4 border mb-0'>
                        <Table className='table table-bordered table-striped table-hover mb-4'>
                            <TableHead>
                                <TableRow>
                                    <TableCell scope="col">CFOP</TableCell>
                                    <TableCell scope="col">Justificativa</TableCell>
                                    <TableCell scope="col"></TableCell>
                                </TableRow>
                            </TableHead>
                            <tbody>
                                {CFOPsPaginated.map((cfop, i) => {
                                    if(hasPermition){
                                        
                                        return (
                                            <TableRow key={i}>
                                                <TableCell className='col-md-1'>{cfop.code}</TableCell>
                                                <TableCell>{cfop.description}</TableCell>
                                                <TableCell className='col-md-1'>
                                                    <Form.Check
                                                        className='ms-2 cursor-pointer'
                                                        checked={cfop.isChecked}
                                                        readOnly={(cfop.isChecked && hasInvoice) || cfop.isDefault || !canUpdate}
                                                        onChange={() => PutCFOPChecked(cfop.id, !cfop.isChecked)}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        )
                                    }else{
                                        return (
                                        
                                            <TableRow key={i}>
                                                <TableCell className='col-md-1'>{cfop.code}</TableCell>
                                                <TableCell>{cfop.description}</TableCell>
                                                <TableCell className='col-md-1'>
                                                    <Form.Check
                                                        className='ms-2 cursor-pointer'
                                                        checked={cfop.isChecked}
                                                        disabled={ (cfop.isChecked && hasInvoice) || cfop.isDefault || !canUpdate}
                                                        readOnly={(cfop.isChecked && hasInvoice) || cfop.isDefault || !canUpdate}
                                                        onChange={() => PutCFOPChecked(cfop.id, !cfop.isChecked)}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        )
                                    }
                                })}
                            </tbody>
                        </Table>
                        <div className='d-flex align-items-center justify-content-end'>
                            {CFOPs.length > 0 &&
                                <Pagination
                                    count={totalPages}
                                    page={currentPage}
                                    onChange={handlePageChange}
                                    color="primary"
                                />
                            }
                        </div>
                    </div>
                    {!createCFOP ?
                        <div onClick={() => setCreateCFOP(true)} className='mt-4 d-flex align-items-center justify-content-end'>
                            <Button variant="contained" className='btn'>Adicionar CFOP +</Button>
                        </div>
                        :
                        <CreateCFOP
                            GetCFOPsByCompanyAndInvoiceType={GetCFOPsByCompanyAndInvoiceType}
                            companyId={company.companyId}
                            serviceId={company.serviceId}
                            setCreateCFOP={setCreateCFOP}
                            setLoading={setLoading}
                            type={page}
                        />
                    }
                </div>
            </div>
        </>
    )
}
