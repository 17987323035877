import "./styles.css";
import { UseFormReturn } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useCompany } from "../../../hooks/useCompany";
import { toast } from "react-toastify";
import { InternalProjectsFormProps } from "../../../pages/Projects/InternalProjectsTabs/Interfaces/InternalProjectsFormProps";
import { IResponseProps } from "../../../interfaces/IResponseProps";
import { PostInternalProjectsAPI, PutInternalProjectsAPI } from "../../../API/InternalProjectsAPI";
import { ProjectTable } from "../ProjectTable";

interface IProjectInformationFormProps {
    internalProjectsTabForm: UseFormReturn<InternalProjectsFormProps, any>,
}

export function ProjectInformationTab({ internalProjectsTabForm }: IProjectInformationFormProps) {
    const { lastYearProject, lastYearProjectImport, id, lastYearId, typology } = useParams();

    const navigate = useNavigate();
    const { company } = useCompany();

    const params = lastYearProject === "1"
        ? `/${company.serviceId}/internalProjects/tabs/${lastYearProject}/${lastYearProjectImport}/${lastYearId ?? "0"}`
        : `/${company.serviceId}/internalProjects/tabs/${lastYearProject}/${lastYearProjectImport}`;


    async function Put(data: InternalProjectsFormProps) {
        await PutInternalProjectsAPI(data).then((resp: IResponseProps) => {
            const response = resp;

            if (response.statusCode === 200) {
                toast.success('Projeto atualizado com sucesso');
                navigate(`${params}/${response.data.id}/${response.data.typology ?? null}`);
            }
            else
                toast.error('Erro ao criar projeto.');
        });
    }

    async function Post(data: InternalProjectsFormProps) {
        await PostInternalProjectsAPI(data).then((resp: IResponseProps) => {
            const response = resp;

            if (response.statusCode === 200) {
                toast.success('Projeto criado com sucesso');
                navigate(`${params}/${response.data.id}/${response.data.typology ?? null}`);
            } else {
                toast.error('Erro ao criar projeto.');
            }
        });
    }

    return (
        <ProjectTable InternalProjects={internalProjectsTabForm} Post={Post} Put={Put} isButtonSave={true} isEdition={id != undefined} isProjectLastYear={false} />
    )
}

