import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { api } from "../../../services/axios";
import { CodesTable } from "../../../components/ProductsModel/CodesTable";
import { v4 as uuidV4 } from 'uuid';
import useModal from "../../../hooks/useModal";
import Loader from "../../../components/Shared/Loader";
import { toast } from "react-toastify";
import { showErrors } from "../../../utils/showErrors";
import { Modal as ModalContainer } from 'react-bootstrap';
import { UploadModal } from "../../../components/Shared/UploadModal";
import { IoCodeSlash } from 'react-icons/io5';
import { useCompany } from "../../../hooks/useCompany";
import { Button, TextField } from "@mui/material";
import { useCan } from "../../../hooks/useCan";
import DownloadIcon from '@mui/icons-material/Download';
import UploadIcon from '@mui/icons-material/Upload';
import { ProductDataModelCharacterization } from "./ProductData";
import { ProductProps } from "../../../interfaces/Product/IProduct";
import { ProductModelProps } from "../../../interfaces/ProductModel/IProductModel";
import { SetFileName } from "../../../utils/SetFileName";

export interface CodeProps {
    id: string;
    code: string;
    description: string;
}

interface ModelCharacterizationProps {
    model: ProductModelProps;
    product?: ProductProps | undefined;
}

export function ModelCharacterization({ model, product }: ModelCharacterizationProps) {
    const [file, setFile] = useState<Blob>();
    const [loading, setLoading] = useState(false);
    const [newCodes, setNewCodes] = useState<CodeProps[]>([]);
    const [savedCodes, setSavedCodes] = useState<CodeProps[]>([]);
    const [description, setDescription] = useState('');
    const { isShowing, toggle } = useModal();
    const [codeNumber, setCodeNumber] = useState('');
    const [codeDescription, setCodeDescription] = useState('');
    const { modelId } = useParams();
    const { company } = useCompany();
    const { isShowing: isShowingDeleteModal, toggle: toggleDeleteModal } = useModal();
    const { updateHeaderValues } = useCompany();
    const permissionFinancial = useCan({ rolesCan: ["Financial"] });

    function isValidCode(code: CodeProps): boolean {

        if (!code.code) {
            return false;
        }

        if (!code.description) {
            return false;
        }

        if (newCodes.some(x => x.code == code.code)) {
            toast.error(`Não é possível inserir o código ${code.code} pois ele já existe!`);
            return false;
        }

        if (savedCodes.some(x => x.code == code.code)) {
            toast.error(`Não é possível inserir o código ${code.code} pois ele já existe!`);
            return false;
        }

        return true;
    }

    async function handleDownloadCodeModel() {
        setLoading(true);
        try {
            await api.get(`codes/download/${company.serviceId}`, {
                responseType: 'blob'
            })
                .then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', SetFileName(company.title,company.year.toString(), "modelosDeProdutos", "xlsx"));
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                });
        }
        catch (err: any) {
            console.log('Download Error', err);
        }
        finally {
            setLoading(false);
        }
    }

    async function handleUploadCodes() {
        toggle();
        setLoading(true);
        try {
            const formData = new FormData();

            if (file != undefined) {
                formData.append('file', file);
            }

            const result = await api.post('codes/ReadCodeExcel', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            const response = await result.data;

            if (response.statusCode === 200) {
                addNewCodesRange(response.data.result);
                showErrors(response.data.errors);
            }
        }
        catch (err: any) {

        }
        finally {
            setLoading(false);
        }

    }

    async function handleSaveData() {
        try {
            setLoading(true);

            const object = {
                id: modelId,
                codes: newCodes,
                description: description,
                companyId: company.companyId,
                serviceId: company.serviceId
            }

            const result = await api.post('productModel/PostDescriptionWithNewCodes', object);

            const response = await result.data;

            if (response.statusCode === 200) {
                toast.success('Os dados foram salvos com sucesso!');
                addSavedCodesRange(newCodes);
                setNewCodes([]);
                await updateHeaderValues();
            }
        }
        catch (err: any) {

        }
        finally {
            setLoading(false);
        }
    }

    function addCode(code: CodeProps) {
        if (isValidCode(code)) {
            setNewCodes(codes => [...codes, code]);
        }
    }

    function addNewCodesRange(codes: CodeProps[]) {
        codes.forEach(element => {
            if (isValidCode(element)) {
                setNewCodes(codes => [...codes, element]);
            }
        });
    }

    function addSavedCodesRange(codes: CodeProps[]) {
        codes.forEach(element => {
            setSavedCodes(codes => [...codes, element]);
        });
    }

    function removeCode(id: string) {
        const codesFiltered = newCodes.filter(code => {
            return code.id != id;
        });

        toast.success('Código removido com sucesso!');
        setNewCodes(codesFiltered);
    }

    function removeSavedCodeFromList(id: string) {
        const codesFiltered = savedCodes.filter(code => {
            return code.id != id;
        });

        setSavedCodes(codesFiltered);
    }

    async function removeSavedCode(id: string) {
        setLoading(true);
        try {
            const result = await api.delete(`codes/Delete/${id}/${company.serviceId}`);
            const response = await result.data

            if (response.statusCode === 200) {
                toast.success('Código deletado com sucesso!');
                removeSavedCodeFromList(id);
                await updateHeaderValues();
            }
        }
        catch (err: any) {

        }
        finally {
            setLoading(false);
        }

    }

    function removeAllNewCodes() {
        setNewCodes([]);
        toast.success('Códigos deletados com sucesso!');
        toggleDeleteModal();
    }

    async function removeAllSavedCodes() {
        setLoading(true);
        try {
            const result = await api.delete(`codes/DeleteByModel/${modelId}/${company.serviceId}`);
            const response = await result.data

            if (response.statusCode === 200) {
                toast.success('Códigos deletados com sucesso!');
                setSavedCodes([]);
                toggleDeleteModal();
                await updateHeaderValues();
            }
        }
        catch (err: any) {

        }
        finally {
            setLoading(false);
        }
    }

    function handleAddLine() {

        if (codeNumber && codeDescription) {
            const object = {
                id: uuidV4(),
                code: codeNumber,
                description: codeDescription
            }
            addCode(object);
            setCodeNumber('');
            setCodeDescription('');
        }
    }

    useEffect(() => {
        async function getCodes() {
            setLoading(true);
            try {
                const result = await api.get(`codes/GetAllByModel/${modelId}`);
                const response = await result.data;

                if (response.statusCode === 200) {
                    setSavedCodes(response.data);
                }
            }
            catch (err: any) {

            }
            finally {
                setLoading(false);
            }
        }

        getCodes();
    }, []);

    return (
        <>
            {loading && <Loader />}

            <div className='bg-light w-100 h-100 p-4 border'>

                <ProductDataModelCharacterization model={model} product={product} />

                <CodesTable
                    newCodes={newCodes}
                    savedCodes={savedCodes}
                    removeSavedCode={removeSavedCode}
                    removeCode={removeCode}
                />
                {!permissionFinancial &&
                    <div className='mt-4  d-flex justify-content-end'>
                        <Button variant='contained' color='secondary' className='me-2 fw-bold' onClick={handleDownloadCodeModel}>
                            Modelo de Excel
                            <DownloadIcon className='ms-2' />
                        </Button>
                        <Button variant='contained' color='secondary' className='fw-bold' onClick={toggle}>
                            Upload Modelo
                            <UploadIcon className='ms-2' />
                        </Button>
                    </div>
                }

                <div className='mt-4'>
                    {!permissionFinancial &&
                        <><div className='d-flex'>
                            <TextField
                                type="text"
                                className='form-control me-4'
                                label='Código do Produto'
                                onChange={(e) => setCodeNumber(e.target.value)}
                                value={codeNumber} />
                            <TextField
                                type="text"
                                className='form-control'
                                label='Descrição da NF'
                                onChange={(e) => setCodeDescription(e.target.value)}
                                value={codeDescription} />
                        </div><Button
                            className='cursor-pointer btn btn-success mt-3 text-white'
                            onClick={handleAddLine}
                        >
                                Adicionar Linha
                            </Button></>
                    }
                </div>

                <div className='mt-3'>
                    {!permissionFinancial &&
                        <>
                            <label className='label'></label>
                            <TextField
                                label="Descrição e Características Básicas"
                                className={`form-control`}
                                multiline
                                rows={6}
                                disabled={model.isCreatedByProduct}
                                defaultValue={model.description}
                                placeholder='Descrição'
                                onChange={(e) => setDescription(e.target.value)}
                                style={{ height: 'auto' }}
                            />
                        </>
                    }
                </div>
                <div className='row mt-4'>
                    <div className='col d-flex justify-content-center'>
                        {!permissionFinancial &&
                            <Button variant='contained' className='btn btn-success' type='button' onClick={handleSaveData}>
                                Salvar Modelo
                            </Button>}
                    </div>

                    {/* <div className='d-flex justify-content-end col-2 text-nowrap'>
                        <button className='btn btn-remove w-100' type='button' onClick={toggleDeleteModal}>
                            <FaTrash />
                        </button>
                    </div> */}
                </div>
            </div >
            <UploadModal
                action={handleUploadCodes}
                icon={IoCodeSlash}
                isShowing={isShowing}
                toggle={toggle}
                setFile={setFile}
            />
            <ModalContainer show={isShowingDeleteModal} onHide={toggleDeleteModal}>
                <ModalContainer.Header closeButton>
                    <ModalContainer.Title>Exclusão massiva</ModalContainer.Title>
                </ModalContainer.Header>
                <ModalContainer.Body>
                    <div className=' d-flex align-items-center justify-content-center'>
                        <button
                            className='btn btn-success me-2'
                            onClick={removeAllNewCodes}
                        >
                            Códigos novos
                        </button>
                        <button
                            className='btn btn-success'
                            onClick={removeAllSavedCodes}
                        >
                            Códigos salvos
                        </button>
                    </div>
                </ModalContainer.Body>
            </ModalContainer >
        </>
    )
}