import { Button, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { ProductModelProps } from "../../../interfaces/ProductModel/IProductModel";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { api } from "../../../services/axios";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useState } from "react";
import Loader from "../../../components/Shared/Loader";


interface HabilitationControlProps {
    productModel?: ProductModelProps | undefined;
    getModel(): Promise<void>;
}

interface HabilitationControlForm {
    id: string;
    publishDate: Date;
    habilitationStatus: any;
    habilitationNumber: string;
    serviceId: string;
}

export function HabilitationControl({ productModel, getModel }: HabilitationControlProps) {
    const { modelId, serviceId } = useParams();
    const [loading, setLoading] = useState(false);


    const { register, handleSubmit, control, formState: { errors } } = useForm<HabilitationControlForm>({
        defaultValues: {
            id: modelId ?? "",
            habilitationNumber: productModel?.habilitationNumber,
            habilitationStatus: productModel?.habilitationStatus,
            publishDate: productModel?.publishDate,
            serviceId: serviceId
        }
    });

    async function handleSubmitHabilitationControl(data: HabilitationControlForm) {
        setLoading(true);

        try{
            data.habilitationStatus = parseInt(data.habilitationStatus);

            const result = await api.put('/productModel/PutHabilitationControl', data);
            const response = await result.data;
    
            if(response.statusCode == 200)
            {
                toast.success("Informações alteradas com sucesso!");
                getModel();
            }
        }
        catch(exception: any)
        {   
            toast.error('Erro ao salvar informações');
        }
        finally{
            setLoading(false);
        }
    }

    return (
        <>
        {loading && <Loader />}
        <form className='bg-light w-100 h-100 p-4 border' onSubmit={handleSubmit(handleSubmitHabilitationControl)}>
            <div className='d-flex row'>
                <FormControl className="bg-white col me-4">
                    <InputLabel>Status de Habilitação</InputLabel>
                    <Select
                        label="Origem da Tecnologia"
                        {...register('habilitationStatus')}
                        defaultValue={productModel?.habilitationStatus}
                    >
                        <MenuItem value="0">Elaboração</MenuItem>
                        <MenuItem value="1">Submetido</MenuItem>
                        <MenuItem value="2">Habilitação</MenuItem>
                    </Select>
                </FormControl>
                <TextField
                    className='bg-white col me-4'
                    label='Portaria Habilitação'
                    {...register('habilitationNumber')}
                />
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Controller
                        name='publishDate'
                        control={control}
                        render={({ field: { ref, value, onChange } }) => (
                            <DatePicker
                                onChange={onChange}
                                label='Data Publicação'
                                className='bg-white col'
                                format='dd/MM/yyyy'
                                inputRef={ref}
                                value={value != null ? new Date(value) : null}
                            />
                        )}
                    />
                </LocalizationProvider>
            </div>

            <div className='row mt-4'>
                <div className='col d-flex justify-content-center'>
                    <Button
                        variant='contained'
                        className='btn btn-success'
                        type='submit'
                    >
                        Salvar Modelo
                    </Button>
                </div>
            </div>
        </form>
        </>

    )
}