import { ContractsFilesModel } from "../models/ContractsFilesModel";
import { api } from "../services/axios";

const PostContractFilesAPI = async (contractsFiles: ContractsFilesModel, contractsId: string, serviceId: string) => {
    try {
        let formData = new FormData();

        formData.append("file", contractsFiles.file!);
        formData.append("contractsId", contractsId);
        formData.append("serviceId", serviceId);
        formData.append("additiveIdentification", contractsFiles.additiveIdentification?.toString() ?? "");
        formData.append("additiveNumber", contractsFiles.additiveNumber?.toString() ?? "");
        formData.append("dateSubscription", contractsFiles.dateSubscription?.toISOString() ?? "");
        formData.append("dateValidity", contractsFiles.dateValidity?.toISOString() ?? "");
        formData.append("docType", contractsFiles.docType?.toString() ?? "");

        var result = await api.post('/ContractsFiles/Post/', formData);
        var response = result.data;
        return response;
    } catch (error: any) {
        throw new Error(error.response.data);
    }
}

const PutContractFilesAPI = async (contractsFiles: ContractsFilesModel) => {
    try {
        var result = await api.put('/ContractsFiles/Put/', contractsFiles);
        var response = result.data;
        return response;
    } catch (error: any) {
        throw new Error(error.response.data);
    }
}

const GetContractsFilesByIdAPI = async (contractId: string) => {
    try {
        var result = await api.get(`/ContractsFiles/GetAllByContractsId/${contractId}`);
        var response = result.data;
        return response;
    } catch (error: any) {
        throw new Error(error.response.data);
    }
}

export {
    PostContractFilesAPI,
    PutContractFilesAPI,
    GetContractsFilesByIdAPI
}