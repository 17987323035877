import { useEffect, useState } from "react";
import { AiFillFileExcel, AiFillHome } from "react-icons/ai";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import AcquistionAction from "../../../components/Acquisitions/AcquisitionAction";
import { UpdateProductFormProps } from "../../../components/Products/UpdateProductForm";
import Loader from "../../../components/Shared/Loader";
import { TablePaginationFooter } from "../../../components/Shared/Pagination";
import { useCompany } from "../../../hooks/useCompany";
import useModal from "../../../hooks/useModal";
import { IInconsistencyDetailsProps } from "../../../interfaces/IInconsistencyDetailsProps";
import { AcquisitionWithCancellingCorrectionModel, AcquisitionsModel } from "../../../models/acquisitionsModel";
import { api } from "../../../services/axios";
import { formatDate, formatDateMonth } from "../../../utils/formatDate";
import './styles.css';
import Form from 'react-bootstrap/Form';
import { AcquisitionsInconsistenciesModal } from "../../../components/Acquisitions/AcquisitionsInconsistenciesModal";
import { UploadModal } from "../../../components/Shared/UploadModal";
import { BsDownload, BsFillCloudArrowDownFill, BsFillCloudArrowUpFill } from "react-icons/bs";
import { FillTable } from "../../../components/Shared/FillTable";
import { HiOutlineUpload } from "react-icons/hi";
import { Button } from "@mui/material";

interface IAcquisitionsPaginationProps {
    items: AcquisitionsModel[];
    total: number;
    currentPage: number;
    word: string;
    take: number;
}

export interface IJustificationAcquisitionProps {
    acquisitionId: string,
    justification: string,
    considerBilling: boolean,
    inconsistencyId: number
}

export default function AcquisitionsNotLinked() {
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const [searchText, setSearchText] = useState("");
    const [acquisitionsPagination, setAcquisitionsPagination] = useState<IAcquisitionsPaginationProps>();
    const [inconsistencyDetails, setInconsistencyDetails] = useState<IInconsistencyDetailsProps[]>([]);
    const { isShowing: isShowingInconsistency, toggle: toggleInconsistency } = useModal();
    const { isShowing: isShowingNotLinked, toggle: toggleNotLinked } = useModal();
    const [isVisible, setIsVisible] = useState(false);
    const [selectedValue, setSelectedValue] = useState(0);
    const [checkedInconsistencies, setCheckedInconsistencies] = useState<string[]>([]);
    const [checkAllInconsistencies, setCheckAllInconsistencies] = useState(false);
    const [justification, setJustification] = useState<IJustificationAcquisitionProps>();
    const [product, setProduct] = useState({} as UpdateProductFormProps);
    const { isShowing, toggle } = useModal();
    const [file, setFile] = useState<Blob>();
    const { productId, isNotLinked } = useParams();
    const { company, getObligationTotalValue } = useCompany();

    async function handleClick() {
        setIsVisible(true);
        await getAllWithInconsistency();
    };

    function onClose() {
        toggleInconsistency();
        getAllByInconsistency();
        setJustification(undefined);
        setCheckAllInconsistencies(false);
        setCheckedInconsistencies([]);
    }

    async function getProduct() {
        setLoading(true);
        try {
            const result = await api.get(`product/getById/${productId}`);
            const response = await result.data;

            setProduct(response.data);

        }
        catch (error: any) {
            toast.error(error);
        }
        finally {
            setLoading(false);
        }
    }

    async function getAll(page?: number) {
        setLoading(true);
        try {
            let text = searchText == null || searchText == "" ? "\"\"" : searchText;

            const result = await api.get(`acquisitions/getAll/productId=${productId}&serviceId=${company.serviceId}&page=${page ?? 1}&take=7&word=${encodeURIComponent(text)}`);
            const response = await result.data;

            setAcquisitionsPagination(response.data);

            if (page) {
                setCurrentPage(page);
            }
        }
        catch (error: any) {
            toast.error(error);
        }
        finally {
            setLoading(false);
        }
    }

    async function getAllWithInconsistency(page?: number) {
        setLoading(true);
        try {
            let text = searchText == null || searchText == "" ? "\"\"" : searchText;

            const result = await api.get(`acquisitions/GetAllWithInconsistencyType/productId=${productId}&serviceId=${company.serviceId}&page=${page ?? 1}&take=7&word=${encodeURIComponent(text)}`);
            const response = await result.data;

            setAcquisitionsPagination(response.data);

            if (page) {
                setCurrentPage(page);
            }
        }
        catch (error: any) {
            toast.error(error);
        }
        finally {
            setLoading(false);
        }
    }

    async function getAllByInconsistency(page?: number) {
        setLoading(true);
        try {
            let text = searchText == null || searchText == "" ? "\"\"" : searchText;

            const result = await api.get(`acquisitions/GetAllByInconsistencyType/productId=${productId}&serviceId=${company.serviceId}&inconsistencyType=${selectedValue}&page=${page ?? 1}&take=7&word=${encodeURIComponent(text)}`);
            const response = await result.data;

            setAcquisitionsPagination(response.data);

            if (page) {
                setCurrentPage(page);
            }
        }
        catch (error: any) {
            toast.error(error);
        }
        finally {
            setLoading(false);
        }
    }

    async function handleDeleteInvoicing(id: string) {
        setLoading(true);
        try {
            const result = await api.delete('acquisitions/delete', {
                data: {
                    id: id
                }
            });

            const response = await result.data;

            if (response.statusCode === 200) {
                toast.success('Faturamento deletado com sucesso!');

                getAll(currentPage);
            }

        }
        catch (error: any) {
            toast.error(error);
        }
        finally {
            setLoading(false);
        }
    }

    async function getIncosistency() {
        setLoading(true);
        try {
            const result = await api.get(`inconsistency/GetToAcquisition`);
            const response = await result.data;

            const data: IInconsistencyDetailsProps[] = response.data;

            setInconsistencyDetails(data);
        } catch (error: any) {
            toast.error(error);
        } finally {
            setLoading(false);
        }
    }

    async function getAllNotLinked(page?: number) {
        setLoading(true);
        try {
            let text = searchText == null || searchText == "" ? "\"\"" : searchText;
            const result = await api.get(`acquisitions/GetAllNotLinked/serviceId=${company.serviceId}&page=${page ?? 1}&take=7&word=${encodeURIComponent(text)}`);
            const response = await result.data;
            setAcquisitionsPagination(response.data);
            if (page) {
                setCurrentPage(page);
            }
        }
        catch (error: any) {
            toast.error(error);
        }
        finally {
            setLoading(false)
        }
    }

    function handleSelection(inconsistencyId: number) {
        setSelectedValue(inconsistencyId);
    }

    async function getJustificationByAcquisitionId(acquisitionId: string) {
        try {
            setLoading(true);

            await api.get(`acquisitions/GetJustificationByAcquisitionId/acquisitionId=${acquisitionId}&inconsistencyType=${selectedValue}`).then((resp) => {
                if (resp.status == 200) {
                    setJustification(resp.data.data);
                }
            });
        } catch (error: any) {
            toast.error(error);
        } finally {
            setLoading(false);
        }
    }

    function handleToggleCheckAll() {
        var isTrue = checkAllInconsistencies;

        var items = acquisitionsPagination ? acquisitionsPagination.items.filter(x => x.alreadyJustificated == false).map(x => x.id) : [];

        setCheckAllInconsistencies(!checkAllInconsistencies);
        setCheckedInconsistencies(isTrue ? [] : items);
    }

    function handleCheckInconsistency(checkedInconsistency: string) {
        if (checkedInconsistencies.some(e => e === checkedInconsistency)) {
            const filteredList = checkedInconsistencies.filter(item => {
                return item != checkedInconsistency;
            });

            setCheckedInconsistencies(filteredList);
        }
        else {
            setCheckedInconsistencies(ant => [...ant, checkedInconsistency]);
        }
    }

    async function uploadMassiveAcquisitions() {
        setLoading(true);
        try {
            let formData = new FormData();

            if (file != undefined) {
                formData.append('file', file);
            }

            const result = await api.post(`JustificationAcquisition/ReadExcel/2/${company.serviceId}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            const response = await result.data;

            const data = response.data;

            if (response.statusCode === 200) {
                toast.success(`As inconsistências foram tratadas com sucesso. ${data.notSaved} linhas foram desconsideradas por serem inconsistências não justificáveis.`);

            }
        }
        catch (err: any) {
            console.log('UPLOAD FILE ERROR', err);
        }
        finally {
            setLoading(false);
        }
    }

    async function uploadAcquisitionsNotLinked() {
        try {
            setLoading(true);

            let formData = new FormData();

            if (file != undefined) {
                formData.append('file', file);
            }

            await api.put(`/Acquisitions/UploadExcelNotLinked/serviceId=${company.serviceId}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(result => {
                if (result.data.statusCode === 200) {
                    getObligationTotalValue();
                    toast.success('Upload Concluído!');
                }
            });
        }
        catch (err: any) {
            toast.error(err);
        }
        finally {
            setLoading(false);
        }
    }

    async function handleDownloadExcelMassiveJustificationAcquisition() {
        setLoading(true)
        try {
            api.get(`justification/DownloadExcel/2/${company.serviceId}`, {
                responseType: 'blob'
            })
                .then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'Invoicing.xlsx');
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                });
        }
        catch (error: any) {
            toast.error(error);
        }
        finally {
            setLoading(false);
        }
    }

    async function downloadNotLinked() {
        try {
            setLoading(true);

            await api.get(`/Acquisitions/DownloadNotLinked/serviceId=${company.serviceId}`, {
                responseType: 'blob'
            }).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'Acquisitions.xlsx');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            });
        } catch (error: any) {
            toast.error(error);
        } finally {
            setLoading(false);
        }
    }

    async function screenAccess() {
        try {
            setLoading(true);

            await api.get(`Acquisitions/ScreenAccess/serviceId=${company.serviceId}`);
        } catch(error: any) {
            toast.error(error);
        } finally {
            setLoading(false);
        }
    }

    async function screenAccessNotLinked() {
        try {
            setLoading(true);

            await api.get(`Acquisitions/ScreenAccessNotLinked/serviceId=${company.serviceId}`);
        } catch(error: any) {
            toast.error(error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (!isNotLinked) {
            screenAccess();
            getProduct();
            getAll();
            getIncosistency();
        } else if (isNotLinked == "true") {
            screenAccessNotLinked();
            getAllNotLinked();
        }
    }, []);

    useEffect(() => {
        if (selectedValue > 0) {
            getAllByInconsistency();
        }
    }, [selectedValue]);

    useEffect(() => {
        var length = acquisitionsPagination?.items.filter(x => x.alreadyJustificated == false).length ?? 0;

        if (checkedInconsistencies.length > 0 && checkedInconsistencies.length == length) {
            setCheckAllInconsistencies(true);
        } else {
            setCheckAllInconsistencies(false);
        }
    }, [checkedInconsistencies]);

    useEffect(() => {
        if (justification != undefined) {
            toggleInconsistency();
        }
    }, [justification]);

    return (
        <>
            {loading && <Loader />}
            <div className='card'>
                <div className='card-header'>
                    <div className='breadCrumb'>
                        <Link to={`/${company.serviceId}/home`} className='breadCrumb-home d-flex align-center'>
                            <AiFillHome className='breadCrumb-home' />
                        </Link>
                        <span className='breadCrumb-separator'>{' > '}</span>
                        <Link to={`/${company.serviceId}/${company.companyId}/acquisitions`}><span className=''>II.2. Aquisições</span></Link>
                        <span className='breadCrumb-separator'>{' > '}</span>
                        <span className='breadCrumb-item-actual'>{isNotLinked && isNotLinked == "true" ? 'Itens não Faturados' : `Produto ${product.name}`}</span>
                    </div>
                </div>
                <div className='card-body'>
                    <div className='card-body bg-light w-100 h-100 p-4 border'>
                        {!isNotLinked ?
                            <>
                                <div className="d-flex justify-content-end aling-items-center">
                                    <select
                                        className={`${isVisible ? 'set-block' : 'set-hidden'} form-select w-100 mb-4 mr-2 `}
                                        defaultValue={selectedValue}
                                        onChange={(e) => {
                                            handleSelection(parseInt(e.target.value));
                                            parseInt(e.target.value) == 0 && getAll();
                                            parseInt(e.target.value) == 0 && setCheckedInconsistencies([]);
                                            parseInt(e.target.value) == 0 && setCheckAllInconsistencies(false);
                                        }}
                                    >
                                        <option value="0">Filtro de Inconsistências</option>
                                        {inconsistencyDetails?.map(model => {
                                            return (
                                                <option value={model.id} key={model.id}>{model.inconsistencyText}</option>
                                            );
                                        })}
                                    </select>
                                    {(acquisitionsPagination?.items && acquisitionsPagination?.items.length > 0 && inconsistencyDetails?.length > 0) &&
                                        <button
                                            className={`${isVisible ? 'set-hidden' : 'set-block'} btn btn-success p-2 mr-2 mb-4 text-center `}
                                            onClick={handleClick}
                                        >
                                            Inconsistências
                                        </button>
                                    }
                                </div>
                                <div className={`${isVisible ? 'd-block' : 'd-none'} mb-4 d-flex justify-content-end`} >
                                    <div className=''>
                                        <Button variant="contained" className='btn  me-2 fw-bold' onClick={handleDownloadExcelMassiveJustificationAcquisition}>
                                            <BsFillCloudArrowDownFill className='me-2' size={20} />
                                            Modelo de Excel
                                        </Button>
                                        <Button variant="contained" className='btn  fw-bold' onClick={toggle}>
                                            <BsFillCloudArrowUpFill className='me-2' size={20} />
                                            Upload Modelo
                                        </Button>
                                    </div>
                                </div>
                            </>
                            :
                            <div className="d-flex justify-content-end aling-items-center">
                                <Button variant="contained"  className='btn  p-2 mr-2 mb-4 text-center me-2  justify-content-around' onClick={downloadNotLinked}>
                                    Download Modelo <BsDownload className='cursor-pointer white mb-1' />
                                </Button >
                                <Button variant="contained"  className='btn  p-2 mr-2 mb-4 text-center me-2  justify-content-around' onClick={toggleNotLinked}>
                                    Upload Modelo <HiOutlineUpload className="mb-1" size={20} />
                                </Button>
                            </div>
                        }
                        <table className='table table-bordered table-striped table-hover mb-4'>
                            <thead>
                                <tr>
                                    <th scope="col">Nº da Nota</th>
                                    <th scope="col">NCM</th>
                                    <th scope="col">Descrição do Item da Nota Fiscal</th>
                                    <th scope="col">Mês de Emissão</th>
                                    <th scope="col">Data de Emissão</th>
                                    <th scope="col">Valor Total + Impostos</th>
                                    <th scope="col">Valor de base de calculo</th>
                                    <th scope="col">{(isVisible && selectedValue != 0) && <Form.Check readOnly={true} className='cursor-pointer d-flex align-items-center justify-content-center mb-1' checked={checkAllInconsistencies} onClick={handleToggleCheckAll} />}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {acquisitionsPagination?.items.map(model => {
                                    return (
                                        <tr key={model.id}>
                                            <th scope="row">{model.acquisitionNum}</th>
                                            <td>{model.itemNCM}</td>
                                            <td>{model.itemName}</td>
                                            <td>{model.acquisitionDate != null ? formatDateMonth(new Date(model.acquisitionDate)) : ""}</td>
                                            <td>{model.acquisitionDate != null ? formatDate(new Date(model.acquisitionDate)) : ""}</td>
                                            <td>R${((Number(model.itemUniValue?.replace(",", ".") ?? "0") * Number(model.itemQuantity)) + Number(model.itemIPI?.replace(",", ".") ?? "0") + Number(model.itemICMSSTDIFAL?.replace(",", ".") ?? "0")).toFixed(2).toString().replace(".", ",")}</td>
                                            <td>R${(Number(model.itemUniValue?.replace(",", ".") ?? "0") * Number(model.itemQuantity)).toFixed(2).toString().replace(".", ",")}</td>
                                            <AcquistionAction
                                                selectedValue={selectedValue}
                                                isVisible={isVisible}
                                                id={model.id}
                                                isLinked={!!isNotLinked}
                                                action={() => handleDeleteInvoicing(model.id)}
                                                checkAllInconsistencies={checkAllInconsistencies}
                                                checkedInconsistencies={checkedInconsistencies}
                                                handleCheckInconsistency={handleCheckInconsistency}
                                                alreadyJustificated={model.alreadyJustificated ?? false}
                                                getJustificationByAcquisitionId={getJustificationByAcquisitionId}
                                            />
                                        </tr>
                                    );
                                })}
                                {acquisitionsPagination?.items && <FillTable columns={8} rows={acquisitionsPagination?.items.length} />}
                            </tbody>
                        </table>
                        {checkedInconsistencies.length > 0 &&
                        <div className="d-flex justify-content-center align-items-center">
                            <Button variant="contained" className={`${(isVisible && selectedValue != 0) ? 'set-block' : 'set-hidden'} btn  p-2 mr-2 mb-4 text-center`} onClick={toggleInconsistency}>Justificar</Button>
                        </div>
                            
                        }
                        {acquisitionsPagination?.items &&
                            <TablePaginationFooter
                                totalItems={acquisitionsPagination?.total}
                                change={isNotLinked ? getAllNotLinked : getAllWithInconsistency}
                            />
                        }
                        {isShowingInconsistency &&
                            <AcquisitionsInconsistenciesModal
                                toggle={onClose}
                                justificationProp={justification}
                                isShowing={isShowingInconsistency}
                                inconsistencyId={selectedValue}
                                checkedInconsistencies={checkedInconsistencies}
                            />
                        }
                        {(isShowing || isShowingNotLinked) && <UploadModal
                            isShowing={isShowing ? isShowing : isShowingNotLinked}
                            toggle={isShowingNotLinked ? toggleNotLinked : toggle}
                            icon={AiFillFileExcel}
                            setFile={setFile}
                            action={isShowing ? uploadMassiveAcquisitions : uploadAcquisitionsNotLinked}
                        />}
                    </div>
                </div>
            </div>
        </>
    );
}