import { api } from "../../../services/axios";
import { useEffect, useState } from "react";
import Loader from "../../../components/Shared/Loader";
import { useLocation, useParams } from "react-router-dom";
import EconomicDataForms, { EconomicDataFormsProps } from "../../../components/Products/EconomicDataProduct";
import ComponentProduct from "../../../components/Products/ComponentsProduct";
import PaginationTabHeader from "../../../components/Shared/PaginationTabHeader";
import '../../../components/Shared/PaginationTabHeader/styles.css';
import { Link } from "react-router-dom";
import { AiFillHome } from "react-icons/ai";
import { useCompany } from "../../../hooks/useCompany";


export default function EconomicDataProduct() {
    const [loading, setLoading] = useState(false);
    const { id } = useParams();
    const [pag, setPag] = useState(0);
    const [key, setKey] = useState(0);
    const [economicDataProduct, setEconomicDataProduct] = useState({} as EconomicDataFormsProps);
    const { company } = useCompany();
    const [tabSelected, setTabSelected] = useState(0);

    const location = useLocation()
    const { hasTecnac } = location.state

    useEffect(() => {
        async function getEconomicData() {
            setLoading(true);
            try {
                const result = await api.get(`economicdata/getByProduct/${id}`);
                const response = await result.data;
                const data = response.data;

                setEconomicDataProduct(data);
            }
            catch (error: any) {
                console.log('GET ECONOMIC DATA', error);
            }
            finally {
                setLoading(false);
            }
        }
        getEconomicData();
    }, []);

    return (
        <div className='card'>
            <div className='card-header'>
                <div className='breadCrumb'>
                    <Link to={`/${company.serviceId}/home`} className='breadCrumb-home d-flex align-center'>
                        <AiFillHome className='breadCrumb-home' />
                    </Link>
                    <span className='breadCrumb-separator'>{' > '}</span>
                    <Link to={`/${company.serviceId}/${company.companyId}/products`}>
                        I.Produtos
                    </Link>
                    <span className='breadCrumb-separator'>{' > '}</span>
                    <span className='breadCrumb-item-actual'>I.1 Produtos e Modelo - Dados Economicos </span>
                </div>
            </div>
            <div className='card-body'>
                <div className='pagination-tabs-header'>
                    <button
                        className={`button-tab-pag ${tabSelected == 0 ? 'tab-selected' : ''}`}
                        onClick={() => setTabSelected(0)}
                    >
                        Dados Econômicos
                    </button>
                    <button
                        className={`button-tab-pag ${tabSelected == 1 ? 'tab-selected' : ''}`}
                        onClick={() => setTabSelected(1)}
                    >
                        Composição Média do Produto
                    </button>
                </div>
                {economicDataProduct.productId ? <EconomicDataForms hasTecnac={hasTecnac} economicData={economicDataProduct} show={tabSelected == 0} /> : <Loader />}
                {<ComponentProduct hasTecnac={hasTecnac} productId={id} show={tabSelected == 1} />}
            </div>
        </div>
    );

}