import { AiFillHome } from 'react-icons/ai';
import { useState } from "react";
import Loader from "../../../components/Shared/Loader";
import { Link, useParams } from "react-router-dom";
import { formatDate } from "../../../utils/formatDate";
import { useCompany } from "../../../hooks/useCompany";
import "./style.css";
import { Button, TextField } from '@mui/material';
import { Label } from 'reactstrap';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { Controller } from 'react-hook-form';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

export interface IAcquisitionDetailsProps {
    id: string;
    acquisitionNum: string | null;
    itemCode: string | null;
    acquisitionDate: Date | null;
    acquisitionSeries: string | null;
    acquisitionAcessKey: string | null;
    destName: string | null;
    destCNPJ: string | null;
    destUF: string | null;
    itemCTS: string | null;
    itemCFOP: string | null;
    itemNCM: string | null;
    itemName: string | null;
    itemQuantity: string | null;
    itemUniValue: string | null;
    itemTotalValueWithoutTax: string | null;
    itemIPI: string | null;
    itemICMS: string | null;
    itemICMSDIFAL: string | null;
    itemPIS: string | null;
    itemCOFIS: string | null;
    itemTotalValueWithTax: string | null;
    destCity: string | null;
}

interface PreLoadAcquisitionDetails {
    acquisitionModel: IAcquisitionDetailsProps;
}

export default function AcquisitionDetailsForm({ acquisitionModel }: PreLoadAcquisitionDetails) {
    const { id, isLinked, productId } = useParams();
    const { company } = useCompany();

    var tax =
        parseInt(acquisitionModel.itemTotalValueWithoutTax ? acquisitionModel.itemTotalValueWithoutTax : "0") +
        parseInt(acquisitionModel.itemIPI ? acquisitionModel.itemIPI : "0") +
        parseInt(acquisitionModel.itemICMSDIFAL ? acquisitionModel.itemICMSDIFAL : "0");

    return (
        <div className='card'>
            <div className='card-header'>
                <div className='breadCrumb'>
                    <Link to={`/${company.serviceId}/home`} className='breadCrumb-home d-flex align-center'>
                        <AiFillHome className='breadCrumb-home' />
                    </Link>
                    <span className='breadCrumb-separator'>{' > '}</span>
                    <Link className='breadCrumb-item' to={`/${company.serviceId}/acquisitions/list/${productId}`}>
                        II.Aquisições
                    </Link>
                    <span className='breadCrumb-separator'>{' > '}</span>
                    <span className='breadCrumb-item-actual'>{acquisitionModel.itemName}</span>
                </div>
            </div>
            <form className='card-body'>
                <div className=''>
                    <div className="d-flex align-items-center mt-3">
                        <div className='mb-4 w-100 fieldset '>
                            <label className='form-label required label-customer'></label>
                            <TextField label="Chave de Acesso" disabled className='form-control form-control' value={acquisitionModel.acquisitionAcessKey ? acquisitionModel.acquisitionAcessKey : ""} />
                        </div>
                        <div className='mb-3 w-50 ms-4 fieldset'>
                            <label className='form-label required label-customer'> </label>
                            <TextField label="N° Nota Fiscal" disabled className='form-control form-control' value={acquisitionModel.acquisitionNum ? acquisitionModel.acquisitionNum : ""} />
                        </div>
                        <div className='mb-3 w-20 ms-4 fieldset '>
                            <label className='form-label required label-customer'></label>
                            <TextField label="Série" disabled className='form-control form-control' value={acquisitionModel.acquisitionSeries ? acquisitionModel.acquisitionSeries : ""} />
                        </div>
                        <div className='mb-3 w-25 ms-4 fieldset'>
                            <label className='form-label required label-customer'></label>
                           
                            <TextField label="Data" disabled className='form-control form-control'  value={acquisitionModel.acquisitionDate != null ? formatDate(new Date(acquisitionModel.acquisitionDate)) : ""} />
                        </div>
                    </div>
                    <div className="d-flex align-items-center  mt-2">
                        <div className='mb-3 w-100 fieldset '>
                            <label className='form-label required label-customer'></label>
                            <TextField label="Destinatário" disabled className='form-control form-control' value={acquisitionModel.destName ? acquisitionModel.destName : ""} />
                        </div>
                        <div className='mb-3 w-50 ms-4 fieldset'>

                            <TextField label="CNPJ Destinatário" disabled className='form-control form-control' value={acquisitionModel.destCNPJ ? acquisitionModel.destCNPJ : ""} />
                        </div>
                        <div className='mb-3 w-25 ms-4 fieldset'>
                            <label className='form-label required label-customer'></label>
                            <TextField label="Município" disabled className='form-control form-control' value={acquisitionModel.destCity ? acquisitionModel.destCity : ""} />
                        </div>
                        <div className='mb-3 w-20 ms-4 fieldset'>
                            <label className='form-label required label-customer'></label>
                            <TextField label="UF" disabled className='form-control form-control' value={acquisitionModel.destUF ? acquisitionModel.destUF : ""} />
                        </div>
                    </div>
                    <div className="d-flex align-items-center mt-2 ">
                        <div className='mb-3 w-100 fieldset mt-2'>
                            <label className='form-label required label-customer'></label>
                            <TextField label="Código do Item" disabled className='form-control form-control' value={acquisitionModel.itemCode ? acquisitionModel.itemCode : ""} />
                        </div>
                    </div>
                    <div className="d-flex align-items-center mt-2">
                        <div className='mb-3 w-100 fieldset'>
                            <label className='form-label required label-customer'></label>
                            <TextField label="Descrição do Item" maxRows={4} disabled className='form-control form-control' value={acquisitionModel.itemName ? acquisitionModel.itemName : ""} />
                        </div>
                    </div>
                    <div className="d-flex align-items-center mt-2">
                        <div className='mb-3 w-25 fieldset'>
                            <label className='form-label required label-customer'></label>
                            <TextField
                                label="NCM"
                                disabled
                                className='form-control form-control'
                                value={acquisitionModel.itemNCM ? acquisitionModel.itemNCM : ""}
                            />
                        </div>
                        <div className='mb-3 w-20 ms-4 fieldset'>
                            <label className='form-label required label-customer'></label>
                            <TextField
                                label="CST"
                                disabled
                                className='form-control form-control'
                                value={acquisitionModel.itemCTS ? acquisitionModel.itemCTS : ""}
                            />
                        </div>
                        <div className='mb-3 w-20 ms-4 fieldset'>
                            <label className='form-label required label-customer'></label>
                            <TextField
                                label="CFOP"
                                disabled
                                className='form-control form-control'
                                value={acquisitionModel.itemCFOP ? acquisitionModel.itemCFOP : ""}
                            />
                        </div>
                        <div className='mb-3 w-25 ms-4 fieldset'>
                            <label className='form-label required label-customer'></label>
                            <TextField
                                label="Quantidade"
                                disabled
                                className='form-control form-control'
                                value={acquisitionModel.itemQuantity ? acquisitionModel.itemQuantity : ""}
                            />
                        </div>
                        <div className='mb-3 w-25 ms-4 fieldset'>
                            <label className='form-label required label-customer'></label>
                            <TextField
                                label="Valor Unitário"
                                disabled
                                className='form-control form-control'
                                value={acquisitionModel.itemUniValue ? acquisitionModel.itemUniValue : ""}
                            />
                        </div>
                    </div>
                    <div className="d-flex align-items-center">
                        <div className='mb-3 w-100 fieldset mt-2'>
                            <label className='form-label required label-customer'></label>
                            <TextField
                                label="Valor Total sem Impostos"
                                disabled
                                className='form-control form-control'
                                value={acquisitionModel.itemTotalValueWithoutTax ? acquisitionModel.itemTotalValueWithoutTax : ""}
                            />
                        </div>
                    </div>
                    <div className="d-flex align-items-center mt-2">
                        <div className='mb-3 w-25 fieldset'>
                            <label className='form-label required label-customer'></label>
                            <TextField
                                label="ICMS"
                                disabled
                                className='form-control form-control'
                                value={acquisitionModel.itemICMS ? acquisitionModel.itemICMS : ""}
                            />
                        </div>
                        <div className='mb-3 w-20 ms-4 fieldset'>
                            <label className='form-label required label-customer'></label>
                            <TextField
                                label="PIS"
                                disabled
                                className='form-control form-control'
                                value={acquisitionModel.itemPIS ? acquisitionModel.itemPIS : ""}
                            />
                        </div>
                        <div className='mb-3 w-20 ms-4 fieldset'>
                            <label className='form-label required label-customer'></label>
                            <TextField
                                label="COFINS"
                                disabled
                                className='form-control form-control'
                                value={acquisitionModel.itemCOFIS ? acquisitionModel.itemCOFIS : ""}
                            />
                        </div>
                        <div className='mb-3 w-25 ms-4 fieldset'>
                            <label className='form-label required label-customer'></label>
                            <TextField
                                label="ICMS ST ou DIFAL"
                                disabled
                                className='form-control form-control'
                                value={acquisitionModel.itemICMSDIFAL ? acquisitionModel.itemICMSDIFAL : ""}
                            />
                        </div>
                        <div className='mb-3 w-25 ms-4 fieldset'>
                            <label className='form-label required label-customer'></label>
                            <TextField
                                label="IPI"
                                disabled
                                className='form-control form-control'
                                value={acquisitionModel.itemIPI ? acquisitionModel.itemIPI : ""}
                            />
                        </div>
                    </div>
                    <div className="d-flex align-items-center">
                        <div className='mb-3 w-100 fieldset mt-2'>
                            <label className='form-label required label-customer'></label>
                            <TextField
                                label="Valor Total com Impostos"
                                disabled
                                className='form-control form-control'
                                value={tax}
                            />
                        </div>
                    </div>
                </div>
                <div className='text-end'>
                    <Link to={isLinked == undefined ? `/${company.serviceId}/acquisitions/list/${productId}` : `/${company.serviceId}/acquisitions/list/isNotLinked=true`}>
                        <Button variant="outlined" className='btn '>Voltar</Button>
                    </Link>
                </div>

            </form>
        </div>
    );
}