import { Outlet } from 'react-router-dom';
import Footer from '../../components/Shared/Footer';
import Header from '../../components/Shared/Header';
import NavBar from '../../components/Shared/Navbar';
import { CompanyProvider } from '../../contexts/companyContext';
import './styles.css';

export default function MainLayout() {
    return (
        <CompanyProvider>
            <div className="background-banner container-main-router">
                <NavBar />
                <div className='margins-side'>
                    <Header />
                    <main className='container-fluid'>
                        <Outlet />
                    </main>
                </div>
                <Footer />
            </div>
        </CompanyProvider>
    );
}

