import { api } from "../services/axios";
import { SetFileName } from "../utils/SetFileName";

const GetAllByServiceIdAPI = async (serviceId: string, page: number, take: number, word: string) => {
    try {
        var result = await api.get(`/UploadInvoiceMassiveRegister/GetAllByServiceId/serviceId=${serviceId}&page=${page}&take=${take}&word=${encodeURIComponent(word)}`);
        var response = result.data;
        return response;
    } catch (error: any) {
        throw new Error(error.response.data);
    }
};

const DownloadAPI = async (serviceId: string, registerId: string, title: string, year: string, batchId: string) => {
    try {
        await api.get(`/UploadInvoiceMassiveRegister/Download/registerId=${registerId}&serviceId=${serviceId}`, {
            responseType: 'blob'
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', SetFileName(title, year, `historico_Faturamento_${batchId}`, "xlsx"));
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        });
    } catch (error: any) {
        throw new Error(error.response.data);
    }
}

const DeleteMassiveAPI = async (registerId: string, serviceId: string) => {
    try {
        var result = await api.delete(`/UploadInvoiceMassiveRegister/DeleteMassive/registerId=${registerId}&serviceId=${serviceId}`);
        var response = result.data;
        return response;
    } catch (error: any) {
        throw new Error(error.response.data);
    }
}

export {
    GetAllByServiceIdAPI,
    DeleteMassiveAPI,
    DownloadAPI
}