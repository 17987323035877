import { useContext } from "react";
import { AuthContext } from "../contexts/authContext";

interface UseCanParams {
    rolesCan?: string[];
}

export function useCan({ rolesCan = [] }: UseCanParams) {
    const { roles } = useContext(AuthContext);
 
   

    if (roles.length == 0) {
        return false;
    }

    // if (roles && roles.includes("Administrator"))
    //     return true;

    if (rolesCan?.length > 0) {
        for (let i = 0; i < rolesCan.length; i++) {
            if (roles.includes(rolesCan[i])) {
              return true;
            }
          }
          return false;
    }

    return true;
}