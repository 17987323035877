export const projectToExchangePPBforRDList = [
    { id: "0", val: "Sim" },
    { id: "1", val: "Não" }
];

export const rangesList = [
    { id: "0", val: "Na Instituição" },
    { id: "1", val: "Na Empresa" },
    { id: "2", val: "No Mercado Interno" },
    { id: "3", val: "Exportação" }
];

export const typologyList = [
    { id: "0", val: "Hardware" },
    { id: "1", val: "Hardware com software embarcado" },
    { id: "2", val: "Software" },
    { id: "3", val: "Software aplicado" },
    { id: "4", val: "Melhoria de Processo Produtivo Básico" },
    { id: "5", val: "Formação e Capacitação Profissional" },
    { id: "6", val: "Outro" }
];

export const coverageList = [
    { id: "0", val: "Novo para a empresa, mas existente no mercado nacional" },
    { id: "1", val: "Novo no mercado nacional, mas já existente no mercado mundial" },
    { id: "2", val: "Novo no mercado mundial" }
];

export const developmentlist = [
    { id: "0", val: "Aprimoramentos a partir de algo existente" },
    { id: "1", val: "Desenvolvimento de algo novo" }
];

export const naturelist = [
    { id: "0", val: "Inciso I" },
    { id: "1", val: "Inciso II" },
    { id: "2", val: "Inciso III" },
    { id: "3", val: "Inciso IV-a" },
    { id: "4", val: "Inciso IV-b" },
    { id: "5", val: "Inciso IV-c" },
    { id: "6", val: "1º" }
];

export const phoneTypeList = [
    { id: "0", val: "Fixo" },
    { id: "1", val: "Celular" }
];

export const radioGrouplist = [
    { val: "true", label: "Sim" },
    { val: "false", label: "Não" }
]

export const informationTabAccordionList = [
    { title: "Escopo do Projeto", label: "", field: "projectScope", fieldErrors: "errors.projectScope" },
    { title: "O projeto teve desenvolvimento no Ano-Base?", label: "", field: "developmentBaseYear", fieldErrors: "errors.developmentBaseYear" },
    { title: "Quais etapas metodológicas estão previstas no projeto no ano?", label: "", field: "stepsForeseenProject", fieldErrors: "errors.stepsForeseenProject" },
    { title: "Qual problema técnico científico o projeto visa solucionar em seu desenvolvimento no ano?", label: "", field: "scientificTechnicalProblem", fieldErrors: "errors.scientificTechnicalProblem" },
]


export const lastYearTabAccordionList = [
    {},
    {},
    {},
    {},
]