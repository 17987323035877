import * as yup from 'yup';


export const schemaFiPositioning = yup.object().shape({
  projectIsTic: yup.bool()
    .nullable(true)
    .when('licenseDate', {
      is: (licenseDate: string | null) => licenseDate != null && licenseDate != "",
      then: yup.bool().required('O Campo é obrigatório')
    })
    .transform((_, val) => typeof val == "string" && val != "" ? val === "true" ? true : false : null),

  technicalScientificProblem: yup.number().nullable(true),
  contextualizationOfTheSteps: yup.number().nullable(true),
  investigativeActivity: yup.number().nullable(true),
  noveltyElement: yup.number().nullable(true),
  content: yup.number().nullable(true),
  level: yup.number().nullable(true),
  trainedOrQualifiedPersonnel: yup.number().nullable(true),
  criterionCFrameworkAnalysis: yup.number().nullable(true),
  observation: yup.string().required("O Campo Observação é obrigatório").max(32000, "Por favor, insira um valor até 32 mil caracteres."),
});