import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { api } from "../../../services/axios";
import { AiFillHome } from 'react-icons/ai';
import { Profiler, useEffect, useState } from "react";
import Loader from "../../../components/Shared/Loader";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import './style.css';
import { useCompany } from "../../../hooks/useCompany";
import { schema } from "../../../schemas/updateProductSchema";
import { ProductModelAddList } from "../ProductModelAddList";
import { ProductModel } from "../../../models/productModelViewModel";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { InputLabel } from "@mui/material";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useCan } from "../../../hooks/useCan";

export interface AssumptionContractProductsProps {
    productId: string;
    assumptionContractId: string;
}

export interface BranchCompaniesProps {
    productId: string;
    branchCompanyId: string;
}

export interface UpdateProductFormProps {
    id: string;
    serviceId: string;
    name: string;
    cnpj: string | null;
    hasPatents: boolean | null;
    hasAccessory: boolean | null;
    companyWillComplyPPB: boolean | null;
    assumptionContractsProducts: AssumptionContractProductsProps[];
    assumptionContractsProductsString: string[];
    hasContract: boolean | null;
    isMicroComputer: boolean | null;
    ncm: string | null;
    origin: string | null;
    technologyOwner: string | null;
    technicianDescription: string | null;
    ppbDescription: string | null;
    manufacturingDescription: string | null;
    observation: string | null;
    ordinanceNumber: string | null;
    accessoryDescription: string | null;
    manufacturingType: number | null;
    technologyOrigin: number | null;
    ordinanceType: number | null;
    status: number | null;
    pleitoDate: string | null;
    licenseDate: string | null;
    startManufacturingDate: Date | null;
    publishDate: string | null;
    tecnacProductId: string | null;
    ppbOrdinance: string | null;
    models: ProductModelProps[];
    companiesProducts: BranchCompaniesProps;
    companiesProductsId: string | null;
    matrixEnabled: boolean;
}

export interface ProductModelProps {
    name: string;
    id: string;
}

interface PreloadProductForm {
    product: UpdateProductFormProps;
}

interface AssumptionContractsMultiSelectProps {
    value: string;
    label: string;
}

interface BranchCompaniesSelectProps {
    value: string;
    label: string;
}


export default function UpdateProductForm({ product }: PreloadProductForm) {
    const { register, handleSubmit, formState: { errors }, control, getValues, setValue, watch } = useForm<UpdateProductFormProps>({
        resolver: yupResolver(schema),
        defaultValues: product,
        shouldUnregister: false
    });

    const navigate = useNavigate();
    const { company } = useCompany();
    const [loading, setLoading] = useState(false);
    const [models, setModels] = useState<ProductModelProps[]>([]);
    const [hasAssumptionContract, setHasAssumptionContract] = useState(product.hasContract);
    const [assumptionContracts, setAssumptionContracts] = useState<AssumptionContractsMultiSelectProps[]>([]);
    const [selectedAssumptionContracts, setSelectedAssumptionContracts] = useState<string[]>([]);
    const [branchCompanies, setBranchCompanies] = useState<BranchCompaniesSelectProps[]>([]);
    const [selectedBranchCompanies, setSelectedBranchCompanies] = useState<string>(product.companiesProducts?.branchCompanyId ?? (product.matrixEnabled == true ? company.companyId : ""));
    const hasAccessory = watch('hasAccessory');
    const permissionfinancial = useCan({ rolesCan: ["Financial"] });

    async function getAssumptionContracts() {
        try {
            setLoading(true);
            await api.get(`/AssumptionContract/GetByCompany/${company.companyId}`).then((resp) => {
                const response = resp.data;

                if (response.statusCode === 200) {
                    const contractsToSelect = response.data.map((x: any) => {
                        return {
                            value: x.id,
                            label: x.name
                        }
                    });

                    const contractsSelected = contractsToSelect.filter((x: any) => {
                        return product.assumptionContractsProducts.some(y => y.assumptionContractId == x.value)
                    });

                    setSelectedAssumptionContracts(contractsSelected.map((x: any) => x.value));
                    setAssumptionContracts(contractsToSelect);
                }
            });
        }
        catch (err: any) {
            toast.error(err);
        }
        finally {
            setLoading(false);
        }
    }

    async function getBranchCompanies() {
        try {
            setLoading(true);
            await api.get(`branchCompany/GetAllByCompany/${company.companyId}`).then((resp) => {
                const response = resp.data;

                if (response.statusCode === 200) {
                    let companiesMatrixToSelect = [];
                    companiesMatrixToSelect.push({
                        value: company.companyId,
                        label: company.taxNumber + " - " + company.title
                    })

                    let branchCompaniesToSelect = response.data.map((x: any) => {
                        return {
                            value: x.id,
                            label: x.cnpj + " - " + x.name
                        }
                    })
                    let brachAndMatrixCompanies = [...companiesMatrixToSelect, ...branchCompaniesToSelect];

                    setBranchCompanies(brachAndMatrixCompanies);
                    setSelectedBranchCompanies(product.companiesProducts?.branchCompanyId ?? (product.matrixEnabled == true ? company.companyId : ""));
                }
            });
        }
        catch (err: any) {
            toast.error(err);
        }
        finally {
            setLoading(false);
        }
    }

    function parseModels(models: ProductModel[]) {
        const modelsParsed = models.map(model => {
            let modelParsed: ProductModelProps = {
                id: model.id,
                name: model.name
            }

            return modelParsed;
        });

        return modelsParsed;
    }

    async function GetModelsByProduct() {
        setLoading(true);
        try {
            await api.get(`productmodel/getbyproduct/${product.id}`).then(resp => {
                const response = resp.data;
                if (response.statusCode === 200) {
                    const models: ProductModel[] = response.data
                    const modelsParsed = parseModels(models);
                    setModels(modelsParsed);
                    setSelectedBranchCompanies(product.companiesProducts?.branchCompanyId ?? (product.matrixEnabled == true ? company.companyId : ""));
                }
            });
        }
        catch (error: any) {
            toast.error(error);
        }
        finally {
            setLoading(false);
        }
    }

    async function handleUpdateProject(data: UpdateProductFormProps) {
        setLoading(true);
        const sumPercentage = await api.get(`component/getSumPercentageByProduct/${product.id}`);
        const verifyEconomic = await api.get(`economicdata/VerifyIsNull/${product.id}`);

        //force boolean values in select option html
        data.isMicroComputer = typeof data.isMicroComputer == "string" && data.isMicroComputer != "" ? data.isMicroComputer === "true" ? true : false : null;
        data.hasAccessory = typeof data.hasAccessory == "string" && data.hasAccessory != "" ? data.hasAccessory === "true" ? true : false : null;
        data.hasContract = typeof data.hasContract == "string" && data.hasContract != "" ? data.hasContract === "true" ? true : false : null;
        data.hasPatents = typeof data.hasPatents == "string" && data.hasPatents != "" ? data.hasPatents === "true" ? true : false : null;
        data.companyWillComplyPPB = typeof data.companyWillComplyPPB == "string" && data.companyWillComplyPPB != "" ? data.companyWillComplyPPB === "true" ? true : false : null;
        //end

        data.assumptionContractsProductsString = selectedAssumptionContracts;
        data.companiesProductsId = selectedBranchCompanies != null && selectedBranchCompanies != "" ? selectedBranchCompanies : null;
        data.models = models;
        data.serviceId = company.serviceId;

        //make validation to edit
        var percentage = sumPercentage.data.data;
        var economicData = verifyEconomic.data.data;
        var status = data.status;
        var validStatusEnabled = true;
        var validStatusSubmitted = true;

        if (data.ncm == null || data.companyWillComplyPPB == null || data.manufacturingType == null || data.startManufacturingDate == null || data.ppbOrdinance == null || data.isMicroComputer == null || data.technologyOrigin == null
            || data.technologyOwner == null || data.hasPatents == null || data.technicianDescription == null || data.ppbDescription == null || data.manufacturingDescription == null ||
            data.hasAccessory == null || data.accessoryDescription == null || data.observation == null) {
            validStatusSubmitted = false;
        }

        if (data.ncm == null || data.companyWillComplyPPB == null || data.manufacturingType == null || data.startManufacturingDate == null || data.ppbOrdinance == null || data.isMicroComputer == null || data.technologyOrigin == null
            || data.technologyOwner == null || data.hasPatents == null || data.technicianDescription == null || data.ppbDescription == null || data.manufacturingDescription == null ||
            data.hasAccessory == null || data.accessoryDescription == null || data.observation == null || data.ordinanceNumber == null || data.publishDate == null) {
            validStatusEnabled = false;
        }

        if (status == 1) {
            if (percentage != 100 || economicData == false || validStatusSubmitted == false) {
                toast.error('Para alterar o Status para submetido todos os campos de produto, dados econômicos e composição média de produto (em 100%) devem estar preenchidos');
                setLoading(false);
                return;
            }
        } else if (status == 2) {
            if (percentage != 100 || economicData == false || validStatusEnabled == false) {
                toast.error('Para alterar o Status para habilitado todos os campos de produto, dados econômicos e composição média de produto (em 100%) devem estar preenchidos');
                setLoading(false);
                return;
            }
        }

        console.log("PRODUCT PUT", data);

        try {
            await api.put('product/put', data).then(resp => {
                const response = resp.data;

                if (response.statusCode === 200) {
                    toast.success('Produto Editado com Sucesso!');
                    navigate(`/${company.serviceId}/${company.companyId}/products`);
                }
            });
        }
        catch (error: any) {
            toast.error(error);
        }
        finally {
            setLoading(false);
        }
    }

    function handleChangeAssumptionContract(value: string) {
        if (value == "true") {
            setHasAssumptionContract(true);
        }
        else
            setHasAssumptionContract(false);
    }

    async function handleEnableTecnac() {
        setLoading(true);
        try {
            const object = {
                productId: product.id,
                status: 0,
                serviceId: company.serviceId
            }

            await api.post('productTecnac/Post', object).then(resp => {
                const response = resp.data;

                if (response.statusCode === 200) {
                    toast.success('Produto Tecnac Habilitado com sucesso!');
                    navigate(`/${company.serviceId}/products/tecnac/${product.id}`);
                }
            });
        }
        catch (err: any) {
            toast.error(err);
        }
        finally {
            setLoading(false);
        }
    }

    function setValueToAcessoriesDescription() {
        var value = getValues().hasAccessory;
        var accesories = getValues().accessoryDescription;

        if (value != undefined) {
            document.getElementById("acess-desc-label")?.classList.remove("css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root");
            document.getElementById("acess-desc-label")?.classList.add("css-1jy569b-MuiFormLabel-root-MuiInputLabel-root");
            document.getElementById("acess-desc-label")?.classList.add("back-white");
        }

        if (value != undefined && value.toString() == "true" && accesories != null) {
            setValue("accessoryDescription", accesories);
            document.getElementById("acess-desc")?.removeAttribute("disabled");
            document.getElementById("acess-desc")?.removeAttribute('placeholder');
        } else if (value != undefined && value.toString() == "false") {
            setValue("accessoryDescription", "Não há.");
            document.getElementById("acess-desc")?.setAttribute("disabled", "true");
            document.getElementById("acess-desc")?.removeAttribute('placeholder');
        } else {
            setValue("accessoryDescription", "");
            document.getElementById("acess-desc")?.removeAttribute("disabled");
            document.getElementById("acess-desc")?.setAttribute('placeholder', "Lista acessorios");
            document.getElementById("acess-desc-label")?.classList.remove("css-1jy569b-MuiFormLabel-root-MuiInputLabel-root");
            document.getElementById("acess-desc-label")?.classList.add("css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root");
            document.getElementById("acess-desc-label")?.classList.remove("back-white");
        }
    }

    useEffect(() => {
        if (product.id) {
            getAssumptionContracts();
            getBranchCompanies();
            setValue('isMicroComputer', product.isMicroComputer);
            setValue('hasAccessory', product.hasAccessory);
            setValue('hasContract', product.hasContract);
            setValue('hasPatents', product.hasPatents);
            setValue('companyWillComplyPPB', product.companyWillComplyPPB);
            GetModelsByProduct();
        }
    }, [product]);

    // useEffect(() => {
    //     setValueToAcessoriesDescription();
    // }, [hasAccessory]);

    function handleAddProductModels(model: ProductModelProps) {
        setModels(rest => [...rest, model]);
    }

    function handleRemoveProductModels(id: string) {
        let filteredModels = models.filter(model => model.id !== id);
        setModels(filteredModels);
    }

    const addThreeHours = (date: string) => {
        const newDate = new Date(date);
        newDate.setHours(newDate.getHours() + 3);
        return newDate;
    }

    return (
        <>
            {loading && <Loader />}
            <div className='card'>
                <div className='card-header'>
                    <div className='breadCrumb'>
                        <Link to={`/${company.serviceId}/home`} className='breadCrumb-home d-flex align-center'>
                            <AiFillHome className='breadCrumb-home' />
                        </Link>
                        <span className='breadCrumb-separator'>{' > '}</span>
                        <span className='breadCrumb-item-actual'>
                            <Link to={`/${company.serviceId}/${company.companyId}/products`}>
                                I.Produtos
                            </Link>
                        </span>
                        <span className='breadCrumb-separator'>{' > '}</span>
                        <Link to={`/${company.serviceId}/${company.companyId}/products`}>
                            I.1 Produtos e Modelos
                        </Link>
                        <span className='breadCrumb-separator'>{' > '}</span>
                        <span className='breadCrumb-item'>Editar Produto {product.name}</span>
                    </div>
                </div>

                <form className='card-body' onSubmit={handleSubmit(handleUpdateProject)} >
                    <div className='font-size'>
                        <div className="row mb-3">
                            <div className='  col-8'>
                                <label className='form-label '></label>
                                <TextField
                                    label="Nome do Produto" variant="outlined"
                                    className='form-control '
                                    disabled={product.status == 2 || permissionfinancial}
                                    {...register('name')}
                                    error={!!errors.name}
                                    helperText={errors.name?.message}

                                />
                            </div>
                            <div className='  col '>
                                <label className='form-label '></label>
                                <TextField
                                    label="NCM" variant="outlined"
                                    inputProps={{ maxLength: 8 }}
                                    disabled={product.status == 2 || permissionfinancial}
                                    className='form-control '
                                    placeholder="Número de NCM..."
                                    {...register('ncm')}
                                    error={!!errors.ncm}
                                    helperText={errors.ncm?.message}
                                />
                            </div>
                        </div>
                        <div className='d-flex  row mb-3'>
                            <div className=' w-100 col'>
                                <FormControl fullWidth>
                                    <InputLabel>Tipo de Fabricação</InputLabel>
                                    <Select disabled={product.status == 2 || permissionfinancial} label="Tipo de Fabricação" className="forms-control" {...register('manufacturingType')}
                                        defaultValue={product.manufacturingType} fullWidth>
                                        <MenuItem value='0'>
                                            Própria
                                        </MenuItem>
                                        <MenuItem value='1'>
                                            Terceirizada com Contrato de Assunção
                                        </MenuItem>
                                        <MenuItem value='2'>
                                            Terceirizada sem Contrato de Assunção
                                        </MenuItem>
                                    </Select>
                                    <span className='error-field'>{errors.manufacturingType?.message}</span>
                                </FormControl>
                            </div>
                            <div className=' w-100 col'>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <Controller
                                        name="startManufacturingDate"
                                        control={control}
                                        render={({ field: { ref, value, onChange } }) => (
                                            <DatePicker
                                                onChange={onChange}
                                                label="Inicio da Fabricação"
                                                className="forms-control w-100"
                                                format="dd/MM/yyyy"
                                                disabled={product.status == 2 || permissionfinancial}
                                                inputRef={ref}
                                                value={value != null ? new Date(value) : null}
                                            />
                                        )}
                                    />
                                    <span className="error-field">{errors.startManufacturingDate?.message}</span>
                                </LocalizationProvider>

                            </div>
                            <div className=' col'>
                                <label className='form-label required'> </label>
                                <TextField
                                    label="Portaria de PPB" variant="outlined"
                                    className='form-control forms-control'
                                    disabled={product.status == 2 || permissionfinancial}
                                    placeholder="N° da portaria e data de publicação "
                                    {...register("ppbOrdinance")}
                                    error={!!errors.ppbOrdinance}
                                    helperText={errors.ppbOrdinance?.message}
                                />

                            </div>
                            <div className=' w-100  col'>
                                <FormControl fullWidth >
                                    <InputLabel>Empresa Cumprirá o PPB?</InputLabel>
                                    <Select disabled={product.status == 2 || permissionfinancial} label="Empresa Cumprirá o PPB?" className="forms-control" {...register('companyWillComplyPPB')}
                                        defaultValue={product.companyWillComplyPPB} fullWidth>

                                        <MenuItem value="true">Sim</MenuItem>
                                        <MenuItem value="false">Não</MenuItem>
                                    </Select>
                                    <span className='error-field'>{errors.companyWillComplyPPB?.message}</span>
                                </FormControl>
                            </div>

                            <div className=' w-100  col'>
                                <FormControl fullWidth>
                                    <InputLabel>Origem da Tecnologia</InputLabel>
                                    <Select disabled={product.status == 2 || permissionfinancial} label="Origem da Tecnologia" className="forms-control" {...register('technologyOrigin')}
                                        defaultValue={product.technologyOrigin} fullWidth>

                                        <MenuItem value="0">Nacional</MenuItem>
                                        <MenuItem value="1">Estrangeira</MenuItem>
                                    </Select>
                                    <span className='error-field'>{errors.technologyOrigin?.message}</span>
                                </FormControl>
                            </div>
                        </div>
                        <div className="d-flex mb-3  row">
                            <div className=' col-12 w-100'>
                                <label className='  form-label '></label>
                                <TextField
                                    label="Nome do detentor de tecnologia que autorizou sua produção no Brasil" variant="outlined"
                                    className='form-control forms-control'
                                    placeholder="Nome..."
                                    disabled={product.status == 2 || permissionfinancial}
                                    {...register('technologyOwner')}
                                    error={!!errors.technologyOwner}
                                    helperText={errors.technologyOwner?.message}
                                />
                            </div >
                        </div >
                        <div className=' d-flex row'>
                            <div className=' w-100  col'>
                                <FormControl fullWidth>
                                    <InputLabel>O produto é patenteado?</InputLabel>
                                    <Select disabled={product.status == 2 || permissionfinancial} label="O produto é patenteado?" className="forms-control" {...register("hasPatents")}
                                        defaultValue={product.hasPatents} fullWidth>

                                        <MenuItem value="true">Sim</MenuItem>
                                        <MenuItem value="false">Não</MenuItem>
                                    </Select>
                                    <span className='error-field'>{errors.hasPatents?.message}</span>
                                </FormControl>
                            </div>
                            <div className='mb-3 w-100 ms-2 col'>
                                <FormControl fullWidth >
                                    <InputLabel>O produto é microcomputador?</InputLabel>
                                    <Select disabled={product.status == 2 || permissionfinancial} label="O produto é microcomputador?" className="forms-control"  {...register('isMicroComputer')}
                                        defaultValue={product.isMicroComputer} fullWidth>
                                        <MenuItem value="true">Sim</MenuItem>
                                        <MenuItem value="false">Não</MenuItem>
                                    </Select>
                                    <span className='error-field'>{errors.isMicroComputer?.message}</span>
                                </FormControl>
                            </div>
                            <div className=' w-100 col'>
                                <FormControl fullWidth>
                                    <InputLabel>Contrato de Assunção</InputLabel>
                                    <Select disabled={product.status == 2 || permissionfinancial} label="Contrato de Assunção" className="forms-control" {...register('hasContract', {
                                        onChange: e => handleChangeAssumptionContract(e.target.value)
                                    })} defaultValue={product.hasContract}
                                        fullWidth>
                                        <MenuItem value="true">Sim</MenuItem>
                                        <MenuItem value="false">Não</MenuItem>
                                    </Select>
                                    <span className='error-field'>{errors.hasContract?.message}</span>
                                </FormControl>
                            </div>
                        </div>
                        {
                            hasAssumptionContract &&
                            <div className='col-10 w-100 mb-3'>
                                <FormControl fullWidth>
                                    <InputLabel className='form-label'>Empresas que assumirão os compromissos de investimento em P&D</InputLabel>
                                    <Select
                                        label="Empresas que assumirão os compromissos de investimento em P&D"
                                        multiple
                                        disabled={product.status == 2 || permissionfinancial}
                                        value={selectedAssumptionContracts}
                                        onChange={(e: any) => setSelectedAssumptionContracts(e.target.value)}
                                        placeholder="Selecione..."
                                        className="forms-control"
                                        fullWidth
                                    >
                                        {assumptionContracts.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                {/* <label className='form-label'>Empresas que assumirão os compromissos de investimento em P&D</label>
                            <Select
                                isMulti
                                value={selectedAssumptionContracts}
                                options={assumptionContracts}
                                className="basic-multi-select"
                                placeholder='Selecione...'
                                onChange={(e: any) => setSelectedAssumptionContracts(e)}
                            /> */}
                            </div>
                        }
                        <div className='d-flex  row mb-3'>
                            <div className='col-10 w-100'>
                                <FormControl fullWidth>
                                    <InputLabel className='form-label required '>Empresas habilitadas</InputLabel>
                                    <Select
                                        label="Empresas habilitadas"
                                        disabled={product.status == 2 || permissionfinancial}
                                        className="forms-control"
                                        value={selectedBranchCompanies}
                                        onChange={(e) => setSelectedBranchCompanies(e.target.value)}
                                        displayEmpty
                                        fullWidth
                                    >

                                        {branchCompanies.map((company, index) => (
                                            <MenuItem key={index} value={company.value}>
                                                {company.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                        <div className='mb-4 d-flex  row'>
                            {
                                product.status == 0
                                    ?
                                    <>
                                        <ProductModelAddList
                                            models={models}
                                            addModel={handleAddProductModels}
                                            removeModel={handleRemoveProductModels}
                                        />
                                    </>
                                    :
                                    <div>
                                        <label className='form-label'>Modelos de Produtos</label>
                                        <div className='d-flex align-items-center bg-light border border-2 rounded mt-1 p-2 w-100'>
                                            {models.map(model => {
                                                return (
                                                    <span className="spans-m2" key={model.id}>
                                                        {model.name};{" "}
                                                    </span>
                                                );
                                            })}
                                        </div>
                                    </div>
                            }
                        </div>
                        <div className='mb-3 h-100 '>
                            <TextField
                                className='mb-3 h-100 w-100 forms-control'
                                label='Descrição das Características Técnicas do produto'
                                placeholder='Descrição...'
                                multiline
                                disabled={product.status == 2 || permissionfinancial}
                                rows={3}
                                {...register('technicianDescription')}
                                error={!!errors.technicianDescription}
                                helperText={errors.technicianDescription?.message}
                                inputProps={{ style: { resize: "both" } }} // habilitar resizabled textarea
                            />
                        </div>
                        <div className='mb-3 h-100'>
                            <TextField
                                className='mb-3 h-100 w-100 forms-control'
                                label='Descrição de PPB aplicável ao produto'
                                placeholder='Descrição...'
                                multiline
                                rows={3}
                                disabled={product.status == 2 || permissionfinancial}
                                {...register('ppbDescription')}
                                error={!!errors.ppbDescription}
                                helperText={errors.ppbDescription?.message}
                                inputProps={{ style: { resize: "both" } }} // habilitar resizabled textarea
                            />
                        </div>
                        <div className='mb-3 h-100'>
                            <TextField
                                className='mb-3 h-100 w-100 forms-control'
                                label='Descrição das instalações fabris e principais equipamentos de produção'
                                placeholder='Descrição...'
                                multiline
                                disabled={product.status == 2 || permissionfinancial}
                                rows={3}
                                {...register('manufacturingDescription')}
                                error={!!errors.manufacturingDescription}
                                helperText={errors.manufacturingDescription?.message}
                                inputProps={{ style: { resize: "both" } }} // habilitar resizabled textarea
                            />

                        </div>
                        <div className='d-flex mb-3  row'>
                            <div className=' w-100 col'>
                                <FormControl fullWidth>
                                    <InputLabel>Possui Acessórios?</InputLabel>
                                    <Select disabled={product.status == 2 || permissionfinancial} label="Possui Acessórios?" className="forms-control" {...register('hasAccessory')} defaultValue={product.hasAccessory} fullWidth>

                                        <MenuItem value="true">Sim</MenuItem>
                                        <MenuItem value="false">Não</MenuItem>
                                    </Select>
                                    <span className='error-field'>{errors.hasAccessory?.message}</span>
                                </FormControl>
                            </div>
                            <div className=' col-8'>

                                <TextField
                                    label="Quais acessórios?" variant="outlined"
                                    className='form-control forms-control'
                                    placeholder="Lista acessorios"
                                    id="acess-desc"
                                    disabled={product.status == 2 || permissionfinancial}
                                    {...register('accessoryDescription')}
                                    error={!!errors.accessoryDescription}
                                    helperText={errors.accessoryDescription?.message}
                                />

                            </div>
                        </div>
                        <div className='mb-3 h-100 w-100'>
                            <TextField
                                className='mb-3 h-100 w-100 forms-control'
                                label='Outras Observações'
                                placeholder='Descrição...'
                                multiline
                                disabled={product.status == 2 || permissionfinancial}
                                rows={3}
                                {...register('observation')}
                                error={!!errors.observation}
                                helperText={errors.observation?.message}
                                inputProps={{ style: { resize: "both" } }} // habilitar resizabled textarea
                            />
                        </div>
                        <div className='d-flex align-items-center mb-3 row'>
                            <div className='mb-3 col'>
                                <FormControl fullWidth>
                                    <InputLabel>Status da Habilitação</InputLabel>
                                    <Select label="Status da Habilitação" disabled={permissionfinancial} className="forms-control" {...register('status')} defaultValue={product.status} fullWidth>
                                        <MenuItem value="0">Elaboração</MenuItem>
                                        <MenuItem value="1">Submetido</MenuItem>
                                        <MenuItem value="2">Habilitado</MenuItem>
                                    </Select>
                                    <span className='error-field'>{errors.status?.message}</span>
                                </FormControl>
                            </div>
                            <div className='mb-3 w-100 col'>
                                <TextField
                                    type="number"
                                    disabled={product.status == 2 || permissionfinancial}
                                    fullWidth
                                    className="forms-control"
                                    label='Portaria Habilitação'
                                    {...register('ordinanceNumber')}
                                />
                                <span className='error-field'>{errors.ordinanceNumber?.message}</span>
                            </div>
                            <div className='mb-3 w-100 col'>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <Controller
                                        name="publishDate"
                                        control={control}
                                        render={({ field: { ref, value, onChange } }) => (
                                            <DatePicker
                                                onChange={onChange}
                                                disabled={product.status == 2 || permissionfinancial}
                                                className="forms-control w-100"
                                                label="Data de Habilitação"
                                                format="dd/MM/yyyy"
                                                inputRef={ref}
                                                defaultValue={value != null ? addThreeHours(value) : null}
                                            />
                                        )}
                                    />
                                </LocalizationProvider>
                                <span className='error-field'>{errors.publishDate?.message}</span>
                            </div>
                        </div>
                    </div>
                    {!permissionfinancial &&
                        <div className="d-flex row mb-3 ">
                            <div className="col-6 d-flex">
                                <Button
                                    component={Link}
                                    to={`/${company.serviceId}/products/economic/${product.id}`}
                                    state={{ hasTecnac: product.status == 2 }}
                                    variant="outlined"
                                    className="flex-fill"
                                >
                                    Dados Econômicos
                                </Button>
                            </div>
                            <div className="col-6 d-flex">
                                <Button variant="contained" className="flex-fill btn-success" type='submit'>Salvar</Button>
                            </div>
                        </div>
                    }
                    {!permissionfinancial &&
                        <div className="row d-flex ">

                            {product.tecnacProductId != null ? (
                                <div className="col-12 ">
                                    <Button
                                        variant="contained"
                                        href={`/products/tecnac/${product.id}`}
                                        className="w-100"
                                    >
                                        TECNAC
                                    </Button>
                                </div>
                            ) : (
                                <div className="col-12">
                                    <Button
                                        className="w-100"
                                        variant="contained"
                                        onClick={handleEnableTecnac}
                                        disableElevation={product.status !== 2}
                                    >
                                        Habilitar TECNAC
                                    </Button>
                                </div>
                            )}
                        </div>
                    }
                </form>
            </div >
        </>
    );
}