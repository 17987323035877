import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import { GlobalStyles } from './assets/GlobalStyles';
import { ThemeProvider } from '@mui/material';


ReactDOM.createRoot(document.getElementById('root')!).render(
    <ThemeProvider theme={GlobalStyles}>
        <App />
    </ThemeProvider>
)