import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { api } from "../../../services/axios";
import { AiOutlineMinus, AiFillHome } from 'react-icons/ai';
import { useEffect, useState } from "react";
import { v4 as uuidv4 } from 'uuid';
import Loader from "../../../components/Shared/Loader";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { useCompany } from "../../../hooks/useCompany";
//import Select from 'react-select'
import { schema } from "../../../schemas/createProductSchema";
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { Button, Grid, InputLabel } from "@mui/material";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import "./style.css"

interface CreateProductForm {
    name: string;
    cnpj: string | null;
    hasPatents: boolean | null;
    hasAccessory: boolean | null;
    hasContract: boolean | null;
    isMicroComputer: boolean | null;
    companyWillComplyPPB: boolean | null;
    ncm: string | null;
    origin: string | null;
    technologyOwner: string | null;
    technicianDescription: string | null;
    ppbDescription: string | null;
    manufacturingDescription: string | null;
    observation: string | null;
    ordinanceNumber: string | null;
    accessoryDescription: string | null;
    manufacturingType: number | null;
    habilitation: number | null;
    technologyOrigin: number | null;
    ordinanceType: number | null;
    status: number | null;
    pleitoDate: Date | null;
    licenseDate: Date | null;
    startManufacturingDate: Date | null;
    publishDate: Date | null;
    ppbOrdinance: string | null;
    models: ProductModelProps[];
    assumptionContractsProducts: string[];
    companiesProductsId: string | null;
    companyId: string;
    serviceId: string;
}

interface ProductModelProps {
    value: string;
    key: string;
}

interface AssumptionContractsMultiSelectProps {
    value: string;
    label: string;
}

interface BranchCompaniesSelectProps {
    value: string;
    label: string;
}

export default function CreateProduct() {
    const { register, handleSubmit, control, formState: { errors }, getValues, setValue, watch } = useForm<CreateProductForm>({
        resolver: yupResolver(schema)
    });
    const [modelName, setModelName] = useState("");
    const [loading, setLoading] = useState(false);
    const [hasAssumptionContract, setHasAssumptionContract] = useState(false);
    const [models, setModels] = useState<ProductModelProps[]>([]);
    const [assumptionContracts, setAssumptionContracts] = useState<AssumptionContractsMultiSelectProps[]>([]);
    const [selectedAssumptionContracts, setSelectedAssumptionContracts] = useState<string[]>([]);
    const [branchCompanies, setBranchCompanies] = useState<BranchCompaniesSelectProps[]>([]);
    const [selectedBranchCompanies, setSelectedBranchCompanies] = useState<string>("");
    const [startManufacturingDate, setStartManufacturingDate] = useState<Date>();
    const navigate = useNavigate();
    const { company } = useCompany();
    const hasAccessory = watch('hasAccessory');

    async function handleCreateProject(data: CreateProductForm) {
        setLoading(true);

        data.models = models;
        let product = data;

        product.startManufacturingDate = startManufacturingDate != null ? new Date(startManufacturingDate) : null;
        product.publishDate = data.publishDate != null ? new Date(data.publishDate) : null;
        product.licenseDate = data.licenseDate != null ? new Date(data.licenseDate) : null;
        product.pleitoDate = data.pleitoDate != null ? new Date(data.pleitoDate) : null;
        product.origin = "ORIGEM";
        product.companyId = company.companyId;
        product.status = 0;
        product.assumptionContractsProducts = selectedAssumptionContracts;
        product.companiesProductsId = selectedBranchCompanies != null && selectedBranchCompanies != "" ? selectedBranchCompanies : null;
        product.serviceId = company.serviceId;

        console.log("PRODUCT", product);

        try {
            await api.post('product/post', product).then((resp) => {
                const response = resp.data;

                if (response.statusCode === 200) {
                    toast.success('Produto Cadastrado com Sucesso!');
                    navigate(`/${company.serviceId}/${company.companyId}/products`);
                }
            });
        }
        catch (error: any) {
            toast.error(error);
        }
        finally {
            setLoading(false);
        }
    }

    async function getAssumptionContracts() {
        try {
            setLoading(true);
            await api.get(`/AssumptionContract/GetByCompany/${company.companyId}`).then(resp => {
                const response = resp.data;

                if (response.statusCode === 200) {
                    const assumptionsToSelect = response.data.map((x: any) => {
                        return {
                            value: x.id,
                            label: x.name
                        }
                    });

                    setAssumptionContracts(assumptionsToSelect);
                }
            });
        }
        catch (err: any) {
            toast.error(err);
        }
        finally {
            setLoading(false);
        }
    }

    async function getBranchCompanies() {
        try {
            setLoading(true);
            await api.get(`branchCompany/GetAllByCompany/${company.companyId}`).then((resp) => {
                const response = resp.data;

                if (response.statusCode === 200) {
                    let companiesMatrixToSelect = [];
                    companiesMatrixToSelect.push({
                        value: company.companyId,
                        label: company.taxNumber + " - " + company.title
                    })

                    let branchCompaniesToSelect = response.data.map((x: any) => {
                        return {
                            value: x.id,
                            label: x.cnpj + " - " + x.name
                        }
                    })
                    let brachAndMatrixCompanies = [...companiesMatrixToSelect, ...branchCompaniesToSelect];

                    setBranchCompanies(brachAndMatrixCompanies);
                }
            });
        } catch (err: any) {
            toast.error(err);
        }
        finally {
            setLoading(false);
        }
    }

    function handleChangeAssumptionContract(value: string) {
        if (value == "true") {
            setHasAssumptionContract(true);
        }
        else
            setHasAssumptionContract(false);
    }

    function handleAddProductModels() {
        if (modelName) {
            var model: ProductModelProps = {
                value: modelName,
                key: uuidv4()
            }

            setModels(rest => [...rest, model]);
            setModelName("");
        }
    }

    function handleRemoveProductModels(id: string) {
        let filteredModels = models.filter(model => model.key !== id);
        setModels(filteredModels);
    }

    function handleKeyDown(event: any) {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
    }

    function setValueToAcessoriesDescription() {
        var value = getValues().hasAccessory;

        if (value != undefined && value.toString() == "false") {
            setValue("accessoryDescription", "Não há.");
            document.getElementById("acess-desc")?.setAttribute("disabled", "true");
            document.getElementById("acess-desc")?.removeAttribute('placeholder');
            document.getElementById("acess-desc-label")?.classList.remove("css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root");
            document.getElementById("acess-desc-label")?.classList.add("css-1jy569b-MuiFormLabel-root-MuiInputLabel-root");
            document.getElementById("acess-desc-label")?.classList.add("back-white");
        } else {
            setValue("accessoryDescription", "");
            document.getElementById("acess-desc")?.removeAttribute("disabled");
            document.getElementById("acess-desc")?.setAttribute('placeholder', "Lista acessorios");
            document.getElementById("acess-desc-label")?.classList.remove("css-1jy569b-MuiFormLabel-root-MuiInputLabel-root");
            document.getElementById("acess-desc-label")?.classList.add("css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root");
            document.getElementById("acess-desc-label")?.classList.remove("back-white");
        }
    }

    useEffect(() => {
        getAssumptionContracts();
        getBranchCompanies();
    }, []);

    // useEffect(() => {
    //     setValueToAcessoriesDescription();
    // }, [hasAccessory]);

    return (
        <>
            {loading && <Loader />}
            <div className='card'>
                <div className='card-header'>
                    <div className='breadCrumb'>
                        <Link to={`/${company.serviceId}/home`} className='breadCrumb-home d-flex align-center'>
                            <AiFillHome className='breadCrumb-home' />
                        </Link>
                        <span className='breadCrumb-separator'>{' > '}</span>
                        <span className='breadCrumb-item-actual'>
                            <Link to={`/${company.serviceId}/${company.companyId}/products`}>
                                I.Produtos
                            </Link>
                        </span>
                        <span className='breadCrumb-separator'>{' > '}</span>
                        <span className='breadCrumb-item-actual'>
                            <Link to={`/${company.serviceId}/${company.companyId}/products`}>
                                I.1 Produtos e Modelos
                            </Link>
                        </span>
                        <span className='breadCrumb-separator'>{' > '}</span>
                        <span className='breadCrumb-item'>Cadastrar Produto</span>
                    </div>
                </div>
                <form className='card-body' onSubmit={handleSubmit(handleCreateProject)} onKeyDown={handleKeyDown} >
                    <div className=''>
                        <div className="row mb-3">
                            <div className='  col-8'>
                                <label className='form-label '></label>
                                <TextField
                                    label="Nome do Produto" variant="outlined"
                                    className='form-control '
                                    {...register('name')}
                                    error={!!errors.name}
                                    helperText={errors.name?.message}
                                />
                            </div>
                            <div className='  col '>
                                <label className='form-label '></label>
                                <TextField
                                    label="NCM" variant="outlined"
                                    inputProps={{ maxLength: 8 }}
                                    className='form-control '
                                    placeholder="Número de NCM..."
                                    {...register('ncm')}
                                    error={!!errors.ncm}
                                    helperText={errors.ncm?.message}
                                />
                            </div>
                        </div>
                        <div className='d-flex  row mb-3'>
                            <div className=' w-100 col'>
                                <FormControl fullWidth>
                                    <InputLabel>Tipo de Fabricação</InputLabel>
                                    <Select label="Tipo de Fabricação" className="forms-control" {...register('manufacturingType')} fullWidth>
                                        <MenuItem value='0'>
                                            Própria
                                        </MenuItem>
                                        <MenuItem value='1'>
                                            Terceirizada com Contrato de Assunção
                                        </MenuItem>
                                        <MenuItem value='2'>
                                            Terceirizada sem Contrato de Assunção
                                        </MenuItem>
                                    </Select>
                                    <span className='error-field'>{errors.manufacturingType?.message}</span>
                                </FormControl>
                            </div>
                            <div className=' w-100 col'>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <Controller
                                        name="startManufacturingDate"
                                        control={control}
                                        render={({ field: { ref, value } }) => (
                                            <DatePicker
                                                label="Inicio da Fabricação"
                                                className="forms-control w-100"
                                                format="dd/MM/yyyy"
                                                inputRef={ref}
                                                onChange={(e: any) => setStartManufacturingDate(e)}
                                                value={value != null ? new Date(value) : null}
                                            />
                                        )}
                                    />
                                    <span className="error-field">{errors.startManufacturingDate?.message}</span>
                                </LocalizationProvider>

                            </div>
                            <div className=' col'>
                                <label className='form-label required'> </label>
                                <TextField
                                    label="Portaria de PPB" variant="outlined"
                                    className='form-control forms-control'
                                    placeholder="N° da portaria e data de publicação "
                                    {...register("ppbOrdinance")}
                                    error={!!errors.ppbOrdinance}
                                    helperText={errors.ppbOrdinance?.message}
                                />

                            </div>
                            <div className=' w-100  col'>
                                <FormControl fullWidth >
                                    <InputLabel>Empresa Cumprirá o PPB?</InputLabel>
                                    <Select label="Empresa Cumprirá o PPB?" className="forms-control" {...register('companyWillComplyPPB')} fullWidth>

                                        <MenuItem value="true">Sim</MenuItem>
                                        <MenuItem value="false">Não</MenuItem>
                                    </Select>
                                    <span className='error-field'>{errors.companyWillComplyPPB?.message}</span>
                                </FormControl>
                            </div>

                            <div className=' w-100  col'>
                                <FormControl fullWidth>
                                    <InputLabel>Origem da Tecnologia</InputLabel>
                                    <Select label="Origem da Tecnologia" className="forms-control" {...register('technologyOrigin')} fullWidth>

                                        <MenuItem value="0">Nacional</MenuItem>
                                        <MenuItem value="1">Estrangeira</MenuItem>
                                    </Select>
                                    <span className='error-field'>{errors.technologyOrigin?.message}</span>
                                </FormControl>
                            </div>
                        </div>

                        <div className="d-flex  row mb-3">
                            <div className=" w-100 col-10 ">
                                <label className='  form-label '></label>
                                <TextField
                                    label="Nome do detentor de tecnologia que autorizou sua produção no Brasil" variant="outlined"
                                    className='form-control  forms-control '
                                    placeholder="Nome..."
                                    {...register('technologyOwner')}
                                    error={!!errors.technologyOwner}
                                    helperText={errors.technologyOwner?.message}
                                />
                            </div>
                        </div>
                        <div className=" d-flex row ">
                            <div className=' w-100  col'>
                                <FormControl fullWidth>
                                    <InputLabel>O produto é patenteado?</InputLabel>
                                    <Select label="O produto é patenteado?" className="forms-control" {...register("hasPatents")} fullWidth>

                                        <MenuItem value="true">Sim</MenuItem>
                                        <MenuItem value="false">Não</MenuItem>
                                    </Select>
                                    <span className='error-field'>{errors.hasPatents?.message}</span>
                                </FormControl>
                            </div>
                            <div className='mb-3 w-100 ms-2 col'>
                                <FormControl fullWidth >
                                    <InputLabel>O produto é microcomputador?</InputLabel>
                                    <Select label="O produto é microcomputador?" className="forms-control"  {...register('isMicroComputer')} fullWidth>

                                        <MenuItem value="true">Sim</MenuItem>
                                        <MenuItem value="false">Não</MenuItem>
                                    </Select>
                                    <span className='error-field'>{errors.isMicroComputer?.message}</span>
                                </FormControl>
                            </div>
                            <div className=' w-100  col'>
                                <FormControl fullWidth>
                                    <InputLabel>Contrato de Assunção</InputLabel>
                                    <Select label="Contrato de Assunção" className="forms-control" {...register('hasContract', {
                                        onChange: e => handleChangeAssumptionContract(e.target.value)
                                    })} fullWidth>

                                        <MenuItem value="true">Sim</MenuItem>
                                        <MenuItem value="false">Não</MenuItem>
                                    </Select>
                                    <span className='error-field'>{errors.hasContract?.message}</span>
                                </FormControl>
                            </div>
                        </div>
                        {
                            hasAssumptionContract &&
                            <div className='mb-3 w-100'>

                                <FormControl fullWidth>
                                    <InputLabel className='form-label'>Empresas que assumirão os compromissos de investimento em P&D</InputLabel>
                                    <Select
                                        label="Empresas que assumirão os compromissos de investimento em P&D"
                                        multiple
                                        value={selectedAssumptionContracts}
                                        onChange={(e: any) => setSelectedAssumptionContracts(e.target.value)}
                                        placeholder="Selecione..."
                                        className="forms-control"
                                        fullWidth
                                    >
                                        {assumptionContracts.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                {/* <label className='form-label'>Empresas que assumirão os compromissos de investimento em P&D</label>
                                <Select
                                    isMulti
                                    name="text"
                                    options={assumptionContracts}
                                    className="basic-multi-select"
                                    placeholder='Selecione...'
                                    onChange={(e: any) => setSelectedAssumptionContracts(e)}
                                /> */}
                            </div>
                        }
                        <div className='d-flex  row mb-3'>
                            <div className=" w-100 col-10" >
                                <FormControl fullWidth>
                                    <InputLabel className='form-label required '>Empresas habilitadas</InputLabel>
                                    <Select
                                        label="Empresas habilitadas"
                                        className="forms-control"
                                        value={selectedBranchCompanies}
                                        onChange={(e) => setSelectedBranchCompanies(e.target.value)}

                                        fullWidth
                                    >

                                        {branchCompanies.map((company, index) => (
                                            <MenuItem key={index} value={company.value}>
                                                {company.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                        <div className='mb-4 d-flex  row'>
                            <label className=' form-label'></label>
                            <div className=' d-flex'>
                                <TextField
                                    label="Modelos de Produtos"
                                    className='form-control'
                                    value={modelName}
                                    onChange={(e) => setModelName(e.target.value)}
                                />
                                <Button
                                    variant="contained"
                                    className='btn btn-success ms-4 white'
                                    onClick={handleAddProductModels}
                                >
                                    <h2 className="text-white text-center mb-0">+</h2>
                                </Button>
                            </div>
                        </div>
                        {models.map(model => {
                            return (
                                <div className='mb-4 d-flex row'>
                                    <div className='d-flex' key={model.key}>
                                        <TextField
                                            className='form-control readonly'
                                            value={model.value}
                                            disabled
                                        />
                                        <Button
                                            className='btn btn-success ms-4 white'
                                            type='button'
                                            onClick={() => handleRemoveProductModels(model.key)}
                                        >
                                            <AiOutlineMinus size={25} className='text-white text-center mb-0 font-weight-bold' />
                                        </Button>
                                    </div></div>
                            );
                        })}

                        <div className='mb-3 h-100'>
                            <TextField
                                fullWidth
                                className='mb-3 h-100 w-100 forms-control'
                                label='Descrição das Características Técnicas do produto'
                                placeholder='Descrição...'
                                multiline
                                rows={3}
                                {...register('technicianDescription')}
                                error={!!errors.technicianDescription}
                                helperText={errors.technicianDescription?.message}
                                inputProps={{ style: { resize: "both" } }} // habilitar resizabled textarea
                            />
                            <span className='error-field'>{errors.technicianDescription?.message}</span>
                        </div>

                        <div className='mb-3 h-100'>
                            <TextField
                                fullWidth
                                className='mb-3 h-100 w-100 forms-control'
                                label='Descrição de PPB aplicável ao produto'
                                placeholder='Descrição...'
                                multiline
                                rows={3}
                                {...register('ppbDescription')}
                                error={!!errors.ppbDescription}
                                helperText={errors.ppbDescription?.message}
                                inputProps={{ style: { resize: "both" } }} // habilitar resizabled textarea
                            />
                            <span className='error-field'>{errors.ppbDescription?.message}</span>
                        </div>

                        <div className='mb-3 h-100'>
                            <TextField
                                fullWidth
                                className='mb-3 h-100 w-100 forms-control'
                                label='Descrição das instalações fabris e principais equipamentos de produção'
                                placeholder='Descrição...'
                                multiline
                                rows={3}
                                {...register('manufacturingDescription')}
                                error={!!errors.manufacturingDescription}
                                helperText={errors.manufacturingDescription?.message}
                                inputProps={{ style: { resize: "both" } }} // habilitar resizabled textarea
                            />
                            <span className='error-field'>{errors.manufacturingDescription?.message}</span>
                        </div>

                        <div className='d-flex mb-3  row'>
                            <div className=' w-100 col'>
                                <FormControl fullWidth>
                                    <InputLabel>Possui Acessórios?</InputLabel>
                                    <Select label="Possui Acessórios?" className="forms-control" {...register('hasAccessory')} fullWidth>

                                        <MenuItem value="true">Sim</MenuItem>
                                        <MenuItem value="false">Não</MenuItem>
                                    </Select>
                                    <span className='error-field'>{errors.hasAccessory?.message}</span>
                                </FormControl>
                            </div>
                            <div className='col-8'>
                                <TextField
                                    label="Quais acessórios?" variant="outlined"
                                    className='form-control forms-control'
                                    id="acess-desc"
                                    placeholder="Lista acessorios"
                                    {...register('accessoryDescription')}
                                    error={!!errors.accessoryDescription}
                                    helperText={errors.accessoryDescription?.message}
                                />
                            </div>
                        </div>
                        <div className='mb-3 h-100 w-100'>
                            <TextField
                                className='mb-3 h-100 w-100 forms-control'
                                label='Outras Observações'
                                placeholder='Descrição...'
                                multiline
                                rows={3}
                                {...register('observation')}
                                error={!!errors.observation}
                                helperText={errors.observation?.message}
                                inputProps={{ style: { resize: "both" } }} // habilitar resizabled textarea
                            />
                        </div>
                        <div className='d-flex align-items-center mb-3 row'>
                            <div className='mb-3 col'>
                                <FormControl fullWidth>
                                    <InputLabel>Status da Habilitação</InputLabel>
                                    <Select label="Status da Habilitação" disabled className="forms-control" {...register('status')} fullWidth>
                                        <MenuItem value="0">Elaboração</MenuItem>
                                        <MenuItem value="1">Submetido</MenuItem>
                                        <MenuItem value="2">Habilitado</MenuItem>
                                    </Select>
                                    <span className='error-field'>{errors.status?.message}</span>
                                </FormControl>
                            </div>
                            <div className='mb-3 w-100 col'>
                                <TextField
                                    fullWidth
                                    type="number"
                                    className="forms-control"
                                    label='Portaria Habilitação'
                                    disabled
                                    {...register('ordinanceNumber')}
                                />
                                <span className='error-field'>{errors.ordinanceNumber?.message}</span>
                            </div>
                            <div className='mb-3 w-100 col'>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <Controller
                                        name="publishDate"
                                        control={control}
                                        render={({ field: { ref, value } }) => (
                                            <DatePicker
                                                className="forms-control w-100"
                                                label="Data de Habilitação"
                                                format="dd/MM/yyyy"
                                                disabled
                                                inputRef={ref}
                                                value={value != null ? new Date(value) : null}
                                            />
                                        )}
                                    />
                                </LocalizationProvider>

                                <span className='error-field'>{errors.publishDate?.message}</span>
                            </div>
                        </div>
                    </div>
                    <button className='btn btn-success height w-100' type='submit'>Salvar Produto</button>
                </form >
            </div >
        </>
    );
}