import { Col } from "react-bootstrap";
import { FaFolderOpen } from "react-icons/fa";
import { PathActions } from "../../../Path/PathActions";
import { FillTable } from "../../FillTable";
import { TablePaginationFooter } from "../../Pagination";
import { PathsPagination, PathProps } from "../../../../interfaces/Files/IFiles";

interface IFolderList {
    setPathSearch: (value: React.SetStateAction<string>) => void
    searchPaths: (text: string) => Promise<void>;
    pathSearch: string,
    paths: PathsPagination,
    handleSelectSubPath: (path: PathProps) => void,
    changePathPage: (page: number) => Promise<void>,
    context: React.Context<any>,
    internalProjectsId?: string,
}

export function FolderList(props: IFolderList) {
    const { setPathSearch, searchPaths, pathSearch, paths, handleSelectSubPath, changePathPage, context, internalProjectsId } = props;

    return (
        <Col xs={12} sm={12} md={12} lg={6} className='p-0'>
            <div className='d-flex justify-content-end'>
                <div className="">
                    <div className="input-group mb-3">
                        <input type="text" className="form-control w-25" onChange={(e) => setPathSearch(e.target.value)} aria-describedby="basic-addon2" />
                        <div className="input-group-append" onClick={() => searchPaths(pathSearch)}>
                            <span className="search-button">Pesquisar</span>
                        </div>
                    </div>
                </div>
            </div>
            <table className='table table-bordered table-striped text-nowrap'>
                <thead>
                    <tr>
                        <th>Nome da Pasta</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {paths?.items && paths.items?.map(item => (
                        <tr key={item.id}>
                            <td title={item.pathName} onClick={() => handleSelectSubPath(item)}>
                                <FaFolderOpen className='me-2' />
                                {item.pathName}
                            </td>
                            <td>
                                <PathActions id={item.id} pathName={item.pathName} context={context} internalProjectsId={internalProjectsId} />
                            </td>
                        </tr>
                    ))}
                    {paths.items && <FillTable columns={2} rows={paths?.items.length} />}
                </tbody>
            </table>
            {paths?.items &&
                <TablePaginationFooter
                    totalItems={paths?.total}
                    change={changePathPage}
                />}
        </Col>
    )
}