import { Button } from "@mui/material";

type types = "button" | "submit" | "reset" | undefined;
export interface IButtonSubmitDefaultFormProps {
    type: types;
    onclick?: () => void;
}

export function ButtonSubmitDefault({ type, onclick }: IButtonSubmitDefaultFormProps) {

    return (
        <>
            <div className='d-flex justify-content-end align-items-center mb-3'>
                <Button variant="contained" size="large" className='p-2 mb-4 text-center justify-content-around' type={type} onClick={onclick}>
                    Salvar alterações
                </Button>
            </div>
        </>
    )
}