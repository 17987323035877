import { Button } from "@mui/material";
import { InvoicingProductInput } from "../../../components/Credit/InvoicingProductInput";
import { UploadInput } from "../../../components/Credit/UploadInput";
import { formatCurrency } from "../../../utils/formatCurrency";
import { File } from "../File";
import { FormEvent } from "react";

interface ICreditFormProps {
    submitFormCredit(e: FormEvent): Promise<void>;
    reCalcAllSummaryValues(): Promise<void>;
    changeValidateCheck(): void;
    calcAccumulatedRemaining(isNormal?: boolean): void
    calcAccumulatedSurplus(isNormal?: boolean): boolean;
    handleDeleteFile(isCreditReceiptFile: boolean): Promise<void>;
    setCreditReceiptFile: React.Dispatch<React.SetStateAction<Blob | undefined>>;
    setClearReceiptInput: React.Dispatch<React.SetStateAction<boolean>>;
    setCreditCertificateFile: React.Dispatch<React.SetStateAction<Blob | undefined>>;
    setClearCertificateInput: React.Dispatch<React.SetStateAction<boolean>>;
    setNormalNotTecnacRemainingValue: React.Dispatch<React.SetStateAction<number>>;
    setNormalNotTecnacSurplusValue: React.Dispatch<React.SetStateAction<number>>;
    setTecnacSurplusValue: React.Dispatch<React.SetStateAction<number>>;
    setTecnacRemainingValue: React.Dispatch<React.SetStateAction<number>>;
    setCreditApplicationDate: React.Dispatch<React.SetStateAction<string | undefined>>;
    clearCertificateInput: boolean;
    clearReceiptInput: boolean;
    validated: boolean;
    validatedCheck: boolean;
    creditAvailableValue: number;
    accumulatedCreditSurplusValue: number;
    accumulatedRemainingCreditValue: number;
    normalNotTecnacSurplusValue: number;
    tecnacSurplusValue: number;
    normalNotTecnacRemainingValue: number;
    tecnacRemainingValue: number;
    creditValue: number;
    creditSurplusValue: number;
    creditApplicationDate: string | undefined;
    creditReceiptFilePathState: string | null | undefined;
    creditCreditCertificatePathState: string | null | undefined;
}

export function CreditForm(props: ICreditFormProps) {
    return (
        <form onSubmit={e => props.submitFormCredit(e)}>
            <div className='container-fluid mt-4'>
                <h4 className='mb-4'><strong>Valores Acumulados nos Exercícios Anteriores</strong></h4>
                <div className='d-flex'>
                    <div className='me-2'>
                        <strong className='d-block mb-4'>Excedente</strong>
                        <div className='w-100'>
                            <div className='w-100'>
                                <InvoicingProductInput
                                    label='Valor Acumulado'
                                    value={formatCurrency(props.accumulatedCreditSurplusValue.toFixed(2))}
                                    isDisabled={true}
                                />
                            </div>
                            <div className="d-flex align-items-center">
                                <div className='me-2'>
                                    <InvoicingProductInput
                                        label='Normal não Tenac'
                                        value={props.validated ? formatCurrency(props.normalNotTecnacSurplusValue.toFixed(2)) : props.normalNotTecnacSurplusValue.toString().replace(".", ",")}
                                        isDisabled={props.validated}
                                        onBlur={() => {
                                            if (props.calcAccumulatedSurplus(true)) {
                                                props.reCalcAllSummaryValues();
                                            }
                                        }}
                                        setValue={props.setNormalNotTecnacSurplusValue}
                                    />
                                </div>
                                <div className='ms-2'>
                                    <InvoicingProductInput
                                        label='Tenac'
                                        value={props.validated ? formatCurrency(props.tecnacSurplusValue.toFixed(2)) : props.tecnacSurplusValue.toString().replace(".", ",")}
                                        isDisabled={props.validated}
                                        onBlur={() => {
                                            if (props.calcAccumulatedSurplus(false)) {
                                                props.reCalcAllSummaryValues();
                                            }
                                        }}
                                        setValue={props.setTecnacSurplusValue}
                                    />
                                </div>
                            </div>
                            <div className='w-100'>
                                <InvoicingProductInput
                                    label='Excedente Disponível'
                                    value={formatCurrency(props.creditAvailableValue.toFixed(2))}
                                    isDisabled={true}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='ms-2'>
                        <strong className='d-block mb-4'>Remanescente</strong>
                        <div className='w-100'>
                            <div className='w-100'>
                                <InvoicingProductInput
                                    label='Valor Acumulado'
                                    value={formatCurrency(props.accumulatedRemainingCreditValue.toFixed(2))}
                                    isDisabled={true}
                                />
                            </div>
                            <div className="d-flex align-items-center">
                                <div className='me-2'>
                                    <InvoicingProductInput
                                        label='Normal não Tenac'
                                        value={props.validated ? formatCurrency(props.normalNotTecnacRemainingValue.toFixed(2)) : props.normalNotTecnacRemainingValue.toString().replace(".", ",")}
                                        isDisabled={props.validated}
                                        onBlur={() => props.calcAccumulatedRemaining(true)}
                                        setValue={props.setNormalNotTecnacRemainingValue}
                                    />
                                </div>
                                <div className='ms-2'>
                                    <InvoicingProductInput
                                        label='Tenac'
                                        value={props.validated ? formatCurrency(props.tecnacRemainingValue.toFixed(2)) : props.tecnacRemainingValue.toString().replace(".", ",")}
                                        isDisabled={props.validated}
                                        onBlur={() => props.calcAccumulatedRemaining(false)}
                                        setValue={props.setTecnacRemainingValue}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <h4 className='mb-2'><strong>Crédito Disponível</strong></h4>
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-4 border-bottom"></div>
                <div className='mb-3'>
                    <div className='d-flex align-items-center'>
                        <div className='w-100 me-2'>
                            <InvoicingProductInput
                                label='Valor de Credito'
                                value={formatCurrency(props.creditValue.toFixed(2))}
                                isDisabled={true}
                            />
                        </div>
                        <div className='w-100 me-2 ms-2'>
                            <InvoicingProductInput
                                label='Valor de Crédito - Excedente'
                                value={formatCurrency(props.creditSurplusValue.toFixed(2))}
                                isDisabled={true}
                            />
                        </div>
                        <div className='w-100 ms-2'>
                            <InvoicingProductInput
                                label='Valor de Crédito - Remanescente'
                                value={formatCurrency(props.creditAvailableValue.toFixed(2))}
                                isDisabled={true}
                            />
                        </div>
                    </div>
                </div>
                <div className='form-check mb-3'>
                    <input className='form-check-input' type='checkbox' onChange={e => props.changeValidateCheck()} checked={props.validatedCheck} id='flexCheckDefault' />
                    <label className='form-check-label' >
                        Validar
                    </label>
                </div>
                {props.validated &&
                    <div className='d-flex'>
                        <div className='me-4 w-50'>
                            <label className='form-label'>Data de solicitação de crédito</label>
                            <div className='input-container w-100 d-flex align-items-center'
                            >
                                <input
                                    type="date"
                                    className='text-center'
                                    defaultValue={props.creditApplicationDate ? props.creditApplicationDate : ''}
                                    onChange={e => props.setCreditApplicationDate(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='ms-4 d-flex flex-column w-100 mt-4'>
                            <UploadInput
                                label='Recibo de Crédito'
                                name='creditReceiptFilePath'
                                setFile={props.setCreditReceiptFile}
                                clearValue={props.clearReceiptInput}
                                setClearValue={props.setClearReceiptInput}
                                isDisabled={false}
                            />
                            {props.creditReceiptFilePathState && <File deleteFunction={() => props.handleDeleteFile(true)} url={props.creditReceiptFilePathState} name={decodeURI(props.creditReceiptFilePathState.substring(props.creditReceiptFilePathState.lastIndexOf('/')).replace('/', ''))} />}
                            <UploadInput
                                label='Certificado de Crédito'
                                name='creditCertificateFilePath'
                                setFile={props.setCreditCertificateFile}
                                clearValue={props.clearCertificateInput}
                                setClearValue={props.setClearCertificateInput}
                                isDisabled={false}
                            />
                            {props.creditCreditCertificatePathState && <File deleteFunction={() => props.handleDeleteFile(false)} url={props.creditCreditCertificatePathState} name={decodeURI(props.creditCreditCertificatePathState.substring(props.creditCreditCertificatePathState.lastIndexOf('/')).replace('/', ''))} />}
                        </div>
                    </div>
                }
                <div className="d-flex justify-content-center">
                    <Button variant="contained" className="btn btn-success mt-4" type="submit">Salvar</Button>
                </div>
            </div>
        </form>
    );
}