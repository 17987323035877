import { FormEvent, useContext, useState } from 'react';
import { Modal as ModalContainer } from 'react-bootstrap';
import { useCompany } from '../../../hooks/useCompany';
import Loader from '../../Shared/Loader';

interface ModalProps {
    isShowing: boolean;
    hide: () => void;
    context: React.Context<any>;
    path: number;
    internalProjectsId?: string;
}

export function PathModal({ isShowing, hide, context, path, internalProjectsId }: ModalProps) {
    const { createPath, currentPath } = useContext(context);
    const { company } = useCompany();
    const [pathName, setPathName] = useState('');
    const [loading, setLoading] = useState(false);

    async function handleCreatePath(e: FormEvent) {
        e.preventDefault();
        setLoading(true);
        let pathMain = path != undefined ? path : 0;
        try {
            createPath({
                folderMain: pathMain,
                pathName: pathName,
                serviceId: company.serviceId,
                parentFolderId: currentPath.id,
                internalProjectsId: internalProjectsId
            }).then(() => {
                setLoading(false);
            });
        }
        catch (err: any) {

        }
        finally {

        }
    }

    return (
        isShowing
            ?
            (
                <>
                    {loading && <Loader />}
                    <ModalContainer show={isShowing} onHide={hide}>
                        <ModalContainer.Header closeButton>
                            <ModalContainer.Title>Criar nova Sub-Pasta</ModalContainer.Title>
                        </ModalContainer.Header>
                        <ModalContainer.Body>
                            <form onSubmit={handleCreatePath}>
                                <div className='mb-3'>
                                    <label htmlFor="" className='form-label'>Nome</label>
                                    <input type="text" className='form-control' onChange={(e) => setPathName(e.target.value)} />
                                </div>
                                <button className='btn btn-success w-100'>Criar</button>
                            </form>
                        </ModalContainer.Body>
                    </ModalContainer >
                </>
            )
            :
            null
    )
}