export const FilesColumns = [
    {
        name: "Nome",
        id: "fileName"
    },
    {
        name: "Tamanho",
        id: "size"
    },
    {
        name: "Data do Upload",
        id: "createdString"
    }
];

export const FilesSearchColumns = [
    {
        name: "Nome",
        id: "fileName"
    },
    {
        name: "Tamanho",
        id: "size"
    }
]