import { FormControl, InputLabel, MenuItem, Select } from "@mui/material"
import { Control, Controller, FieldError, UseFormRegister } from "react-hook-form"



interface ISelectTabProps {
    label: string,
    field: string,
    control: Control<any, any>,
    fieldErrors?: FieldError | undefined,
    register: UseFormRegister<any>,
    isDisabled?: boolean,
    list: any[],
    defaultValue: any,
    haschange: () => void,
    hasFormControl?: boolean
}


export const SelectCustom: React.FC<ISelectTabProps> = ({ label, field, control, fieldErrors, register, isDisabled = false, list, defaultValue, haschange, hasFormControl = true }) => {
    return (
        <FormControl fullWidth>
            <InputLabel id="label-select" shrink={defaultValue != ""}>{label}</InputLabel>
            <Select
                label={label}
                labelId="label-select"
                className={`${hasFormControl ? "forms-control" : ""} h-100`}
                {...register(field, { onChange: () => haschange() })}
                value={defaultValue}
                disabled={isDisabled}
                fullWidth
                notched={defaultValue != ""}
            >
                {list.map((p, i) => (
                    <MenuItem key={i} value={p.id}>{p.val}</MenuItem>
                ))}
            </Select>
            {fieldErrors &&
                <span className='error-field'>{fieldErrors?.message}</span>
            }
        </FormControl>
    )
}