import { Controller } from 'react-hook-form';
import './styles.css';


interface InvoicingProductInputProps {
    label: string;
    value?: string | undefined;
    isDisabled?: boolean;
    name?: string;
    control?: any;
    onBlur?: () => void;
    setValue?: React.Dispatch<React.SetStateAction<any>>;
}

export function InvoicingProductInput({ onBlur, control, label, value = "", name, isDisabled = false, setValue }: InvoicingProductInputProps) {
    let isBlocked = isDisabled ? 'blocked' : ''

    return (
        <div className={`input-container mb-4 ${isBlocked}`}>
            <label>{label}</label>

            {name
                ?
                <Controller
                    name={name}
                    control={control}
                    render={({ field }) => {
                        return <input
                            {...field}
                            onBlur={onBlur}
                            disabled={isDisabled}
                            {...(value != "" && { defaultValue: value })}
                            className={isBlocked}
                        />;
                    }}
                />
                :
                <input
                    disabled={isDisabled}
                    {...(setValue && { onChange: (e) => setValue(e.target.value.replace(",", ".")) })}
                    {...(value != "" && { value: value })}
                    onBlur={onBlur}
                    className={isBlocked}
                />
            }
        </div>
    )
}