import { FaTrash, FaEye } from "react-icons/fa";
import { Link, useParams } from "react-router-dom";
import useModal from "../../../hooks/useModal";
import DeleteModal from "../../Shared/DeleteModal";
import Form from 'react-bootstrap/Form';
import { useCompany } from "../../../hooks/useCompany";
import { AiFillMessage } from "react-icons/ai";

interface InvoicingActionsProps {
    id: string;
    action: any;
    isLinked?: boolean;
    isVisible?: boolean;
    checkedInconsistencies?: string[];
    checkAllInconsistencies?: boolean;
    handleCheckInconsistency?(checkedInconsistency: string): void;
    selectedValue?: number;
    alreadyJustificated: boolean;
    getJustificationByInvoicingId(invoicingId: string): Promise<void>;
}


export default function InvoicingAction({ id, action, isLinked = true, isVisible, checkedInconsistencies, checkAllInconsistencies, handleCheckInconsistency, selectedValue, alreadyJustificated, getJustificationByInvoicingId }: InvoicingActionsProps) {
    const { isShowing, toggle } = useModal();
    const { productId } = useParams();
    const { company } = useCompany();
    
    return (
        <>
            <td>
                <div className='d-flex justify-content-center aling-items-center h-100'>
                    <div className="d-flex justify-content-center aling-items-center ms-2 eye">
                        <Link to={isLinked 
                        ? `/${company.serviceId}/${company.companyId}/invoicing/${productId}/details/${id}` 
                        : `/${company.serviceId}/${company.companyId}/invoicing/details/${id}/false`}>
                            <FaEye className='cursor-pointer' />
                        </Link>
                    </div>
                    <div className="d-flex justify-content-center aling-items-center ms-2">
                        <div className="d-flex justify-content-center aling-items-center" onClick={toggle}>
                            <FaTrash className='cursor-pointer' />
                        </div>
                    </div>
                    {!alreadyJustificated ?
                        <div className="d-flex justify-content-center aling-items-center eye">
                            {(isVisible && selectedValue != 0) &&
                                <Form.Check
                                    className='ms-2 cursor-pointer'
                                    checked={((checkedInconsistencies?.some(x => x == id) && !checkAllInconsistencies) || (checkedInconsistencies?.some(x => x == id) && checkAllInconsistencies))}
                                    onClick={() => handleCheckInconsistency && handleCheckInconsistency(id)}
                                    readOnly={true}
                                />
                            }
                        </div>
                        :
                        <div className="d-flex justify-content-center aling-items-center ms-2" onClick={() => getJustificationByInvoicingId(id)}>
                            <AiFillMessage className='cursor-pointer' />
                        </div>
                    }
                </div>
            </td>
            <DeleteModal title='Excluir Produto' text='Tem certeza que deseja excluir este Faturamento?' isShowing={isShowing} hide={toggle} action={action} />
        </>
    )
}