import { useEffect, useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { BsFillCloudArrowDownFill, BsFillCloudArrowUpFill } from "react-icons/bs";
import { useParams } from "react-router-dom";
import { api } from "../../../services/axios";
import { CodesTable } from "../../../components/ProductsModel/CodesTable";
import { v4 as uuidV4 } from 'uuid';
import useModal from "../../../hooks/useModal";
import Loader from "../../../components/Shared/Loader";
import { toast } from "react-toastify";
import { showErrors } from "../../../utils/showErrors";
import { FaTrash } from "react-icons/fa";
import { Collapse, Modal as ModalContainer } from 'react-bootstrap';
import { UploadModal } from "../../../components/Shared/UploadModal";
import { IoCodeSlash } from 'react-icons/io5';
import { ModelComponentsTable } from "../../../components/ProductsModel/ModelComponentsTable";
import { Button, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { useCompany } from "../../../hooks/useCompany";
import { useCan } from "../../../hooks/useCan";
import { ProductModelProps } from "../../../interfaces/ProductModel/IProductModel";

export interface ComponentProps {
    id: string;
    name: string;
    origin: number;
    percentage: number;
}

interface ModelComponentsParams {
    model: ProductModelProps;
}

export function ModelComponents({ model }: ModelComponentsParams) {
    const [loading, setLoading] = useState(false);
    const [newComponents, setNewComponents] = useState<ComponentProps[]>([]);
    const [savedComponents, setSavedComponents] = useState<ComponentProps[]>([]);
    const [name, setName] = useState('');
    const [origin, setOrigin] = useState(0);
    const [percentage, setPercentage] = useState(0);
    const [percentageTotal, setPercentageTotal] = useState(0);
    const { modelId, serviceId } = useParams();
    const { company,  } = useCompany();
    const { isShowing: isShowingDeleteModal, toggle: toggleDeleteModal } = useModal();
    const permissionFinancial = useCan({ rolesCan: ["Financial"] });

    function isValidComponent(component: ComponentProps): boolean {

        if (!component.name) {
            return false;
        }

        if (Number.isNaN(component.percentage)) {
            toast.error(`Não é possível inserir o componente ${component.name} pois o valor da porcentagem não é válido`);
            return false
        }

        if (component.percentage > 100) {
            toast.error(`Não é possível inserir o componente ${component.name} pois o valor da porcentagem é maior que 100 %`);
            return false;
        }

        if ((percentageTotal + component.percentage) > 100) {
            toast.error(`Não é possível inserir o componente ${component.name} pois a soma das porcentagens ultrapassa 100 %`);
            return false;
        }

        if (newComponents.some(x => x.name == component.name && x.origin == component.origin)) {
            toast.error(`Não é possível inserir o componente ${component.name} pois ele já existe!`);
            return false;
        }

        if (savedComponents.some(x => x.name == component.name && x.origin == component.origin)) {
            toast.error(`Não é possível inserir o componente ${component.name} pois ele já existe!`);
            return false;
        }

        return true;
    }

    async function handleSaveData() {
        try {
            setLoading(true);

            const object = {
                modelId: modelId,
                components: newComponents,
                serviceId: company.serviceId,
            }

            const result = await api.post('ProductModelComponents/Post', object);

            const response = await result.data;

            if (response.statusCode === 200) {
                toast.success('Os dados foram salvos com sucesso!');
                addSavedComponentsRange(newComponents);
                setNewComponents([]);
            }
        }
        catch (err: any) {

        }
        finally {
            setLoading(false);
        }
    }

    function addComponent(component: ComponentProps) {
        if (isValidComponent(component)) {
            setNewComponents(components => [...components, component]);
            setPercentageTotal(total => total + component.percentage);
        }
    }

    function addNewComponentsRange(components: ComponentProps[]) {
        components.forEach(element => {
            if (isValidComponent(element)) {
                setNewComponents(components => [...components, element]);
            }
        });
    }

    function addSavedComponentsRange(components: ComponentProps[]) {
        components.forEach(element => {
            setSavedComponents(components => [...components, element]);
        });
    }

    function removeComponent(id: string) {
        const componentsFiltered = newComponents.filter(component => {
            return component.id != id;
        });

        if (percentageTotal > 0) {
            const component = newComponents.find(component => {
                return component.id == id;
            });

            if (component) {
                setPercentageTotal(total => total - component?.percentage);
            }
        }

        toast.success('Componente removido com sucesso!');
        setNewComponents(componentsFiltered);
    }

    function removeSavedComponentFromList(id: string) {
        const componentsFiltered = savedComponents.filter(component => {
            return component.id != id;
        });

        if (percentageTotal > 0) {
            const component = savedComponents.find(component => {
                return component.id == id;
            });

            if (component) {
                setPercentageTotal(total => total - component?.percentage);
            }

        }

        setSavedComponents(componentsFiltered);
    }

    async function removeSavedComponents(id: string) {
        setLoading(true);
        try {
            const result = await api.delete(`ProductModelComponents/Delete/${id}/${serviceId}`);
            const response = await result.data

            if (response.statusCode === 200) {
                toast.success('Componente deletado com sucesso!');
                removeSavedComponentFromList(id);
            }
        }
        catch (err: any) {

        }
        finally {
            setLoading(false);
        }

    }

    function removeAllNewComponents() {
        setNewComponents([]);
        toast.success('Componentes deletados com sucesso!');
        toggleDeleteModal();
    }

    async function removeAllSavedComponents() {
        setLoading(true);
        try {
            const result = await api.delete(`ProductModelComponents/DeleteByModel/${modelId}/${company.serviceId}`);
            const response = await result.data

            if (response.statusCode === 200) {
                toast.success('Componentes deletados com sucesso!');
                setSavedComponents([]);
                toggleDeleteModal();
            }
        }
        catch (err: any) {

        }
        finally {
            setLoading(false);
        }
    }

    function handleAddLine() {
        if (name) {
            const object: ComponentProps = {
                id: uuidV4(),
                name: name,
                origin: origin,
                percentage: percentage
            }

            addComponent(object);
            setName('');
            setOrigin(0);
            setPercentage(0);
        }
    }

    useEffect(() => {
        async function getComponents() {
            setLoading(true);
            try {
                const result = await api.get(`ProductModelComponents/GetAllByModel/${modelId}`);
                const response = await result.data;

                if (response.statusCode === 200) {
                    const data: ComponentProps[] = response.data;

                    let percentageSum = data.reduce((accumulator, object) => {
                        return accumulator + object.percentage;
                    }, 0);

                    setPercentageTotal(percentageSum);
                    setSavedComponents(response.data);
                }
            }
            catch (err: any) {

            }
            finally {
                setLoading(false);
            }
        }

        getComponents();
    }, []);

    return (
        <>
            {loading && <Loader />}
            <div className='bg-light w-100 h-100 p-4 border'>
                {!permissionFinancial ?
                    <>
                        <ModelComponentsTable
                            model={model}
                            newComponents={newComponents}
                            savedComponents={savedComponents}
                            removeSavedComponent={removeSavedComponents}
                            removeComponent={removeComponent} /><div className='mt-4'>
                            <div className='d-flex row'>
                                <TextField

                                    className='bg-white col me-4'
                                    label='Componente'
                                    onChange={(e) => setName(e.target.value)}
                                    value={name} />
                                <FormControl className="col">
                                    <InputLabel>Origem</InputLabel>
                                    <Select
                                        label="Origem"
                                        className='form bg-white  me-4'
                                        value={origin}
                                        onChange={(e) => setOrigin(Number(e.target.value))}
                                    >
                                        <MenuItem value={0}>Nacional</MenuItem>
                                        <MenuItem value={1}>Estrangeira</MenuItem>
                                    </Select>
                                </FormControl>
                                <TextField
                                    type="number"
                                    className='bg-white col'
                                    label='Porcentagem'
                                    onChange={(e) => setPercentage(parseInt(e.target.value))}
                                    value={percentage} />
                            </div>

                            <Button
                                variant='contained'
                                className='cursor-pointer btn btn-success mt-4'
                                type='button'
                                onClick={handleAddLine}
                            >
                                Adicionar Linha
                                <AiOutlinePlus className='ms-2' />
                            </Button>
                        </div>
                        <div className='row mt-4'>
                            <div className='col d-flex justify-content-center'>
                                <Button variant='contained' className='btn btn-success' type='button' onClick={handleSaveData}>Salvar Modelo</Button>
                            </div>
                        </div>
                    </>
                    :
                    <><ModelComponentsTable
                    model={model}
                        newComponents={newComponents}
                        savedComponents={savedComponents}
                        removeSavedComponent={removeSavedComponents}
                        removeComponent={removeComponent} /><div className='mt-4'>
                            <div className='d-flex row'>
                                <TextField
                                    disabled
                                    className='bg-white col me-4'
                                    label='Componente'
                                    onChange={(e) => setName(e.target.value)}
                                    value={name} />
                                <FormControl className="col">
                                    <InputLabel>Origem</InputLabel>
                                    <Select
                                        label="Origem"
                                        className='form bg-white  me-4'
                                        value={origin}
                                        onChange={(e) => setOrigin(Number(e.target.value))}
                                        disabled
                                    >
                                        <MenuItem value={0}>Nacional</MenuItem>
                                        <MenuItem value={1}>Estrangeira</MenuItem>
                                    </Select>
                                </FormControl>
                                <TextField
                                    type="number"
                                    className='bg-white col'
                                    label='Porcentagem'
                                    onChange={(e) => setPercentage(parseInt(e.target.value))}
                                    value={percentage}
                                    disabled />
                            </div>


                        </div><div className="alert alert-dark d-flex align-items-center justify-content-evenly w-25 container mt-5">
                            <h2 className="text-bold mt-2">Total:</h2>
                            <h2 className="mt-2">{percentageTotal}%</h2>
                        </div><div className='row mt-4'>
                            <div className='col d-flex justify-content-center'>

                            </div>
                            {/* <div className='d-flex justify-content-end col-2 text-nowrap'>
<button className='btn btn-remove w-100' type='button' onClick={toggleDeleteModal}>
<FaTrash />
</button>
</div> */}
                        </div></>
                }
            </div>
            <ModalContainer show={isShowingDeleteModal} onHide={toggleDeleteModal}>
                <ModalContainer.Header closeButton>
                    <ModalContainer.Title>Exclusão massiva</ModalContainer.Title>
                </ModalContainer.Header>
                <ModalContainer.Body>
                    <div className=' d-flex align-items-center justify-content-center'>
                        <button
                            className='btn btn-success me-2'
                            onClick={removeAllNewComponents}
                        >
                            Componentes novos
                        </button>
                        <button
                            className='btn btn-success'
                            onClick={removeAllSavedComponents}
                        >
                            Componentes salvos
                        </button>
                    </div>
                </ModalContainer.Body>
            </ModalContainer >

        </>
    )
}