import { SeverityEnum, ActionEnum, ScreenEnum, ProgressEnum } from "../../../utils/AllSelectsToLogs";
import { TfiDownload } from 'react-icons/tfi';
import { Button } from "@mui/material";
import { UsabilityLogsModel } from "../../../models/usabilityLogsModel";
import { FillTable } from "../../Shared/FillTable";
import { TablePaginationFooter } from "../../Shared/Pagination";
import './styles.css';
import { CustomProvider, DateRangePicker } from 'rsuite';
import ptBR from 'rsuite/locales/pt_BR';

interface IActionLogsComponent {
    getAllLogs: (page: number) => Promise<void>;
    setSeverity: React.Dispatch<React.SetStateAction<string>>;
    setAction: React.Dispatch<React.SetStateAction<string>>;
    setScreen: React.Dispatch<React.SetStateAction<string>>;
    setActionDateStart: React.Dispatch<React.SetStateAction<Date | undefined>>;
    setActionDateEnd: React.Dispatch<React.SetStateAction<Date | undefined>>;
    logs: UsabilityLogsModel[] | undefined;
    total: number | undefined;
    GetExcelUsabilityLog: () => Promise<void>;
}

export default function ActionLogsComponent(props: IActionLogsComponent) {
    const { getAllLogs, setSeverity, setAction, setScreen, logs, total, setActionDateStart, setActionDateEnd, GetExcelUsabilityLog } = props;

    return (
        <div className="action-log-container">
            <div className="d-flex align-items-center justify-content-start mb-4">
                <select onChange={e => setSeverity(e.target.value)} className="form-select">
                    <option value="" selected>Selecione o Grau de Impacto</option>
                    {SeverityEnum.map((severity, index) => {
                        return (
                            <option value={severity.value} key={index}>{severity.label}</option>
                        )
                    })}
                </select>
                <select onChange={e => setAction(e.target.value)} className="form-select mx-2">
                    <option value="" selected>Selecione o Tipo de Ação</option>
                    {ActionEnum.map((action, index) => {
                        return (
                            <option value={action.value} key={index}>{action.label}</option>
                        )
                    })}
                </select>
                <select onChange={e => setScreen(e.target.value)} className="form-select">
                    <option value="" selected>Selecione o Menu</option>
                    {ScreenEnum.map((screen, index) => {
                        return (
                            <option value={screen.value} key={index}>{screen.label}</option>
                        )
                    })}
                </select>
                <CustomProvider locale={ptBR}>
                    <DateRangePicker
                        className="date-container"
                        format="dd/MM/yyyy"
                        onChange={e => {
                            if (e != null) {
                                setActionDateStart(e[0]);
                                setActionDateEnd(e[1]);
                            } else {
                                setActionDateStart(undefined);
                                setActionDateEnd(undefined);
                            }
                        }}
                    />
                </CustomProvider>
                <Button
                    variant="contained"
                    className="d-flex align-items-center justify-content-center w-100"
                    endIcon={<TfiDownload className="icon-white" size={16} />}
                    onClick={GetExcelUsabilityLog}
                >
                    Extrair Resultados
                </Button>
            </div>
            <table className='table table-bordered table-striped table-hover mt-2'>
                <thead>
                    <tr>
                        <th scope="col">Severidade</th>
                        <th scope="col">Início</th>
                        <th scope="col">Término</th>
                        <th scope="col">Usuário</th>
                        <th scope="col">Email</th>
                        <th scope="col">Ação</th>
                        <th scope="col">Menu</th>
                        <th scope="col w-100">Descrição</th>
                        <th scope="col">Concluído?</th>
                    </tr>
                </thead>
                <tbody>
                    {logs?.map((log, index) => {
                        var name = log.userName.split(" ");
                        return (
                            <tr key={index}>
                                <td>{SeverityEnum[log.severity].label}</td>
                                <td>{new Date(log.startDate).toLocaleString('pt-BR', { timeZone: "America/Sao_Paulo", hour12: false })}</td>
                                <td>{new Date(log.endDate).toLocaleString('pt-BR', { timeZone: "America/Sao_Paulo", hour12: false })}</td>
                                <td>{name[0]} {name.length > 1 && name[name.length - 1]}</td>
                                <td>{log.email}</td>
                                <td>{ActionEnum[log.actionType].label}</td>
                                <td>{ScreenEnum[log.screen].label}</td>
                                <td className="description-limit" title={log.description}>{log.description}</td>
                                <td>{ProgressEnum[log.progress].label}</td>
                            </tr>
                        )
                    })}
                    {logs && <FillTable columns={9} rows={logs.length} />}
                </tbody>
            </table>
            <TablePaginationFooter
                totalItems={total ?? 0}
                change={getAllLogs}
            />
        </div>
    )
}