import React, { useEffect, createRef, Fragment, useState } from "react";
import { GiSevenPointedStar } from "react-icons/gi";
import { ComponentProps } from "../../../pages/ProductModels/ModelComponents";
import { CodeTableActions } from "../../Codes/CodeTableActions";
import './styles.css';
import { ComponentsTableActions } from "../../ProductComponents/ComponentsTableActions";
import { ProductModelProps } from "../../../interfaces/ProductModel/IProductModel";

interface ModelComponentsTableProps {
    model: ProductModelProps;
    newComponents: ComponentProps[];
    savedComponents: ComponentProps[];
    removeComponent: (id: string) => void;
    removeSavedComponent: (id: string) => Promise<void>
}


export function ModelComponentsTable({ model, newComponents, savedComponents, removeSavedComponent, removeComponent }: ModelComponentsTableProps) {

    const codesRef = createRef<HTMLDivElement>();
    const [colunmOrder, setColunmOrder] = useState<keyof ComponentProps>('name');
    const [order, setOrder ] = useState(1);

    useEffect(() => {
        if (codesRef && codesRef.current) {
            const { scrollHeight, clientHeight } = codesRef.current;

            codesRef.current.scrollTo({
                left: 0, top: scrollHeight - clientHeight,
                behavior: 'smooth'
            });
        }

    }, [newComponents]);

    const handleOrder = (fieldName:any) =>{
        setOrder(-order);
        setColunmOrder(fieldName);
    }

    savedComponents = savedComponents.sort (( a, b) => {
        return a[colunmOrder] < b[colunmOrder] ? -order : order
    });
    
    newComponents = newComponents.sort (( a, b) => {
        return a[colunmOrder] < b[colunmOrder] ? -order : order
    });

    return (
        <>
            <div className='table-responsive table-max-height' ref={codesRef}>
                <table className='table table-bordered table-white table-hover'>
                    <thead>
                        <tr>
                            <th onClick={() => handleOrder('name')}>Componente</th>
                            <th onClick={() => handleOrder('origin')}>Origem</th>
                            <th onClick={() => handleOrder('percentage')}>Porcentagem</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <>
                            {newComponents.length == 0 && savedComponents.length == 0 ?
                                <>
                                    <tr>
                                        <td colSpan={12} className='text-center'>
                                            Não há componentes cadastrados no momento
                                        </td>
                                    </tr>
                                </>
                                :
                                null
                            }
                            {savedComponents.map((component, index) => (
                                <Fragment key={component.id}>
                                    <tr >
                                        <td title={component.name}>{component.name}</td>
                                        <td>{component.origin == 0 ? 'Nacional' : 'Estrangeira'}</td>
                                        <td width='100px'>{component.percentage} %</td>
                                        <ComponentsTableActions model={model} action={removeSavedComponent} id={component.id} />
                                    </tr>
                                </Fragment>

                            ))}

                            {newComponents.map((component, index) => (
                                <Fragment key={component.id}>
                                    <tr>
                                        <td width='100px'>
                                            <div className='new-code' title={component.name}>
                                                {component.name}
                                                <GiSevenPointedStar size={10} />
                                            </div>
                                        </td>
                                        <td>{component.origin == 0 ? 'Nacional' : 'Estrangeira'}</td>
                                        <td>{component.percentage} %</td>
                                        <ComponentsTableActions action={removeComponent} id={component.id} />
                                    </tr>
                                </Fragment>
                            ))}
                        </>

                    </tbody>
                </table>
            </div>
        </>
    )
}