import { useState } from 'react';
import { Modal as ModalContainer } from 'react-bootstrap';
import { Button, FormControl, MenuItem, Select } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useCompany } from '../../../hooks/useCompany';

interface ICreateContractsModalProps {
    toggle: () => void;
    isShowing: boolean;
}

export function CreateContractsModal({ toggle, isShowing }: ICreateContractsModalProps) {
    const { company } = useCompany();
    const [contractType, setContractType] = useState<number>(3);
    const navigate = useNavigate();

    function goTo() {
        navigate(`/${company.serviceId}/contractModule/create/${contractType}`);
    }

    return (
        <ModalContainer onHide={toggle} className='modal-lg' show={isShowing}>
            <ModalContainer.Header closeButton>
                <ModalContainer.Title>Cadastrar Contrato</ModalContainer.Title>
            </ModalContainer.Header>
            <ModalContainer.Body className='p-4'>
                <div className='d-flex flex-column w-100'>
                    <div className='w-100 mb-4'>
                        <label className='mb-2'>Selecione o tipo de contrato</label>
                        <FormControl className='w-100' size="small">
                            <Select className="forms-control h-100" value={contractType} onChange={(e) => setContractType(parseInt(e.target.value.toString()))}>
                                <MenuItem disabled value={3}>tipo de contrato...</MenuItem>
                                <MenuItem value={0}>Contrato de Assunção</MenuItem>
                                <MenuItem value={1}>Contrato Tripartite - Convênio</MenuItem>
                                <MenuItem value={2}>Contrato Tripartite - Projetos Próprios</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    <Button className='w-100' type='submit' variant='contained' color="primary" onClick={goTo} disabled={contractType == undefined}>Avançar</Button>
                </div>
            </ModalContainer.Body>
        </ModalContainer>
    );
}