import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Loader from '../../../components/Shared/Loader';
import { Product } from '../../../models/productModel';
import { api } from '../../../services/axios';
import { AiOutlineArrowRight } from 'react-icons/ai';
import { TablePaginationFooter } from '../../../components/Shared/Pagination';
import { useForm } from 'react-hook-form';
import { Col, InputGroup, Row } from 'react-bootstrap';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useModal from '../../../hooks/useModal';
import { FillTable } from '../../../components/Shared/FillTable';
import { useCompany } from '../../../hooks/useCompany';
import { toast } from 'react-toastify';
import { BsArrowRightCircle } from 'react-icons/bs';

interface ProductsPagination {
    items: Product[];
    total: number;
    currentPage: number;
    word: string;
    take: number;
}

interface SearchProjectProps {
    search: string;
}

export default function CreditProductList() {
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const [searchText, setSearchText] = useState('');
    const [productsPagination, setProductsPagination] = useState<ProductsPagination>();
    const { countings, creditId } = useParams();
    const { company } = useCompany();
    const navigate = useNavigate();
    const { register, handleSubmit, formState: { errors } } = useForm<SearchProjectProps>();

    async function getProducts() {
        try {
            setLoading(true);
            const result = await api.get(`/product/GetAllByCredit/companyId=${company.companyId}&creditId=${creditId}&page=1&take=7&word=""`);
            const response = await result.data;

            const data: ProductsPagination = response.data;

            setProductsPagination(data);
        } catch (error: any) {
            toast.error(error);
        } finally {
            setLoading(false);
        }
    }

    async function search(data: SearchProjectProps) {
        setLoading(true);
        setProductsPagination(undefined);
        try {
            let text = searchText == null || searchText == "" ? "\"\"" : searchText;
            const result = await api.get(`product/GetAllByCredit/companyId=${company.companyId}&creditId=${creditId}&page=1&take=7&word=${encodeURIComponent(text)}`);
            const response = await result.data;
            setProductsPagination(response.data);

        }
        catch (error: any) {
            toast.error(error);
        }
        finally {
            setLoading(false)
        }
    }

    async function changePage(page: number) {
        setLoading(true);
        try {
            let text = searchText == null || searchText == "" ? "\"\"" : searchText;
            const result = await api.get(`product/GetAllByCredit/companyId=${company.companyId}&creditId=${creditId}&page=${page}&take=7&word=${encodeURIComponent(text)}`);
            const response = await result.data;
            setProductsPagination(response.data);
            setCurrentPage(page);

        }
        catch (error: any) {
            toast.error(error);
        }
        finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        getProducts();
    }, []);

    return (
        <>
            {loading && <Loader />}
            <div className=''>
                <Row className='w-100 p-0 m-0 mt-4'>
                    <Col xs={12} className='p-0'>
                        <form onSubmit={handleSubmit(search)}>
                            <InputGroup className='mb-2'>
                                <input className='form-control' {...register('search')} placeholder='Pesquisar...' aria-label='Pesquisar' aria-describedby='basic-addon2' onChange={(e: any) => setSearchText(e.target.value)} />
                                <InputGroup.Text id='basic-addon2'>
                                    <button type='submit' className='p-0 m-0 border-0 bg-transparent'>
                                        <FontAwesomeIcon icon={faMagnifyingGlass} className='cursor-pointer' />
                                    </button>
                                </InputGroup.Text>
                            </InputGroup>
                            <p className='error-message'>{errors.search?.message}</p>
                        </form>
                    </Col>
                </Row>

                <table className='table table-bordered table-striped table-hover mb-4'>
                    <thead>
                        <tr>
                            <th scope='col'>Nome do Produto</th>
                            <th scope='col'></th>
                        </tr>
                    </thead>
                    <tbody>
                        {productsPagination?.items.map(product => {
                            return (
                                <>
                                    <tr key={product.id}>
                                        <th scope='row'>{product.name}</th>
                                        <td className='text-end' onClick={() => navigate(`/${company.serviceId}/credit/products/financecredits/${creditId}/${countings}/${product.id}/0`, { state: { productName: product.name } })}>
                                            <BsArrowRightCircle title='gerar, editar ou consultar crédito financeiro' className='cursor-pointer text-dark' size={20} />
                                        </td>
                                    </tr>
                                    {product.tecnacProduct &&
                                        <tr key={product.tecnacProduct.id}>
                                            <th scope='row'>{product.name} - TECNAC</th>
                                            <td className='text-end' onClick={() => navigate(`/${company.serviceId}/credit/products/financecredits/${creditId}/${countings}/${product.id}/1`, { state: { productName: product.name } })}>
                                                <BsArrowRightCircle title='gerar, editar ou consultar crédito financeiro' className='cursor-pointer text-dark' size={20} />
                                            </td>
                                        </tr>
                                    }
                                </>
                            );
                        })}
                        {productsPagination?.items && <FillTable columns={2} rows={productsPagination?.items.length} />}
                    </tbody>
                </table>
                {productsPagination?.items &&
                    <TablePaginationFooter
                        totalItems={productsPagination?.total}
                        change={changePage}
                    />}
            </div>
        </>
    );
}