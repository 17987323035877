import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Loader from "../../../components/Shared/Loader";
import { ProductHabilitationStatus, ProductModel } from "../../../models/productModelViewModel";
import { api } from "../../../services/axios";
import { toast } from "react-toastify";
import { TablePaginationFooter } from "../../../components/Shared/Pagination";
import ProductModelAction from "../../../components/ProductsModel/ProductModelActions";
import { formatDate } from "../../../utils/formatDate";
import { FillTable } from "../../../components/Shared/FillTable";
import { useCompany } from "../../../hooks/useCompany";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button } from '@mui/material';
import { Home as AiFillHome } from '@mui/icons-material'
import { useCan } from "../../../hooks/useCan";

interface ProductsModelsPagination {
    items: ProductModel[];
    total: number;
    currentPage: number;
    word: string;
    take: number;
}

export default function ProductsModelsByProduct() {
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const [searchText, setSearchText] = useState("");
    const [productsModelPagination, setModelProductsPagination] = useState<ProductsModelsPagination>();
    const { id, tecnacId } = useParams();
    const { company, updateHeaderValues } = useCompany();
    const url = tecnacId == undefined ? `productmodel/GetAllByProduct/serviceId=${company.serviceId}&productId=${id}` : `productmodel/GetAllByTecnac/serviceId=${company.serviceId}&productId=${id}&tecnacId=${tecnacId}`;

    const permissionFinancial = useCan({ rolesCan: ["Financial"] });
    //const permissionTechnician = useCan({ rolesCan: ["Technician"] });
    const permissionMaster = useCan({ rolesCan: ["Master"] });

    var hasPermition = false

    if (permissionFinancial || permissionMaster) {
        hasPermition = true
    }

    async function getProductsModels() {
        try {
            setLoading(true);

            await api.get(`${url}&page=1&take=7&word=""`).then(resp => {
                const response = resp.data;

                const data: ProductsModelsPagination = response.data;

                setModelProductsPagination(data);
            });
        } catch (error: any) {
            toast.error(error);
        } finally {
            setLoading(false);
        }
    }

    async function changePage(page: number) {
        try {
            setLoading(true);

            let text = searchText == null || searchText == "" ? "\"\"" : searchText;

            await api.get(`${url}&page=${page}&take=7&word=${encodeURIComponent(text)}`).then(resp => {
                const response = resp.data;
                setModelProductsPagination(response.data);
                setCurrentPage(page);
            });
        }
        catch (error: any) {
            toast.error(error);
        }
        finally {
            setLoading(false)
        }
    }

    async function handleDeleteProductModel(id: string) {
        try {
            setLoading(true);

            await api.delete('productmodel/delete', {
                data: {
                    id: id,
                    serviceId: company.serviceId
                }
            }).then(resp => {
                const response = resp.data;

                if (response.statusCode === 200) {
                    toast.success('Modelo de Produto deletado com sucesso!');

                    let productsModelFilter = productsModelPagination?.items.filter(model => model.id != id);

                    const filterProductsModel = {
                        items: productsModelFilter,
                        currentPage: productsModelPagination?.currentPage,
                        take: productsModelPagination?.take,
                        total: productsModelPagination?.total,
                        word: productsModelPagination?.word
                    } as ProductsModelsPagination;

                    setModelProductsPagination(filterProductsModel);
                    updateHeaderValues();
                }
            });
        }
        catch (error: any) {
            toast.error(error);
        }
        finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        getProductsModels();
    }, []);

    return (
        <>
            {loading && <Loader />}
            <div className='card'>
                <div className='card-header'>
                    <div className='breadCrumb'>
                        <Link to={`/${company.serviceId}/home`} className='breadCrumb-home d-flex align-center'>
                            <AiFillHome className='breadCrumb-home' />
                        </Link>
                        <span className='breadCrumb-separator'>{' > '}</span>
                        <span className='breadCrumb-item-actual'>
                            <Link to={`/${company.serviceId}/${company.companyId}/products`}>
                                I.Produtos
                            </Link>
                        </span>
                        <span className='breadCrumb-separator'>{' > '}</span>
                        <span className='breadCrumb-item-actual'>
                            <Link to={`/${company.serviceId}/${company.companyId}/products`}>
                                I.1 Produtos e Modelos
                            </Link>
                        </span>
                        <span className='breadCrumb-separator'>{' > '}</span>
                        <span className='breadCrumb-item'>Modelos de Produtos</span>
                    </div>
                </div>


                <div className='card-body'>
                    <TableContainer>
                        <Table className='table table-bordered table-striped table-hover mb-4'>
                            <TableHead>
                                <TableRow>
                                    <TableCell scope="col">Nome do Modelo</TableCell>
                                    <TableCell scope="col">Data de Criação</TableCell>
                                    <TableCell scope="col">Data de Publicação</TableCell>
                                    <TableCell scope="col">Status</TableCell>
                                    <TableCell scope="col">Ações</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {productsModelPagination?.items.map(model => {
                                    return (
                                        <TableRow key={model.id}>
                                            {!permissionFinancial ?
                                                <TableCell component="th" scope="row">{model.name}{model.isTecnac ? ' - TECNAC' : ''}</TableCell>
                                                : <TableCell component="th" scope="row">
                                                    <Link className="text-decoration-none" to={`/${company.serviceId}/models/${model.id}/${tecnacId != undefined}/characterization`}>{model.name}{model.isTecnac ? ' - TECNAC' : ''}</Link>
                                                </TableCell>}
                                            <TableCell>{model.createdDate != null ? formatDate(new Date(model.createdDate), false) : ""}</TableCell>
                                            <TableCell>{model.publishDate != null ? formatDate(new Date(model.publishDate), false) : ""}</TableCell>
                                            <TableCell>{model.habilitationStatus != null ? ProductHabilitationStatus[model.habilitationStatus] : ''}</TableCell>
                                            <TableCell>
                                                <ProductModelAction
                                                    isTecnacPage={tecnacId != undefined}
                                                    id={model.id}
                                                    action={() => handleDeleteProductModel(model.id)}
                                                    isEnabled={ model.isCreatedByProduct && model.habilitationStatus != 2}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                                {productsModelPagination?.items && <FillTable columns={5} rows={productsModelPagination?.items.length} />}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {productsModelPagination?.items &&
                        <TablePaginationFooter
                            totalItems={productsModelPagination?.total}
                            change={changePage}
                        />}
                    {!permissionFinancial &&
                        <div className=' d-flex justify-content-center align-items-center mb-3'>
                            <Link className='  ' to={tecnacId == undefined ? `/${company.serviceId}/models/${id}/create` : `/${company.serviceId}/models/${id}/${tecnacId}/create`}><Button variant="contained">Inserir Modelo</Button></Link>
                        </div>}
                </div>

            </div>
        </>
    );
}