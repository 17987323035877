import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Loader from "../../../components/Shared/Loader";
import { InvoicingInfoAdditionalToAlert, InvoicingWithCancellingCorrectionModel } from "../../../models/invoicingModel";
import { api } from "../../../services/axios";
import { toast } from "react-toastify";
import { TablePaginationFooter } from "../../../components/Shared/Pagination";
import { formatDate, formatDateMonth } from "../../../utils/formatDate";
import useModal from "../../../hooks/useModal";
import { InconsistencyModal } from "../../../components/Invoicing/InconsistencyModal";
import InvoicingAction from "../../../components/Invoicing/InvoicingAction";
import './styles.css';
import { useCompany } from "../../../hooks/useCompany";
import { IInconsistencyDetailsProps } from "../../../interfaces/IInconsistencyDetailsProps";
import Form from 'react-bootstrap/Form';
import { IJustificationProps } from "../InvoicingProductExit";
import { BsExclamationLg, BsFillCloudArrowDownFill, BsFillCloudArrowUpFill } from "react-icons/bs";
import { UploadModal } from "../../../components/Shared/UploadModal";
import { AiFillFileExcel } from "react-icons/ai";
import { FillTable } from "../../../components/Shared/FillTable";
import Select from 'react-select';
import { ISelectProps } from "../../../interfaces/ISelectProps";
import { maskCNPJ } from "../../../utils/cnpjUtils";
import { IInvoicingFiltersVO } from "../../../interfaces/IInvoicingFiltersVO";
import { Button } from "@mui/material";
import { SetFileName } from "../../../utils/SetFileName";
import { UpdateProductFormProps } from "../../../components/Products/UpdateProductForm";

interface InvoicingPagination {
    items: InvoicingWithCancellingCorrectionModel[];
    total: number;
    currentPage: number;
    word: string;
    take: number;
}

// Entrada é 0
export function InvoicingProductEntry() {
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const [searchText, setSearchText] = useState("");
    const [invoicingPagination, setInvoicingPagination] = useState<InvoicingPagination>();
    const [allInvoicesByInconstancy, setAllInvoicesByInconstancy] = useState<InvoicingWithCancellingCorrectionModel[]>([]);
    const [inconsistencyDetails, setInconsistencyDetails] = useState<IInconsistencyDetailsProps[]>([]);
    const { isShowing: isShowingInconsistency, toggle: toggleInconsistency } = useModal();
    const [file, setFile] = useState<Blob>();
    const { isShowing, toggle } = useModal();
    const [isVisible, setIsVisible] = useState(false);
    const [selectedValue, setSelectedValue] = useState(0);
    const [checkedInconsistencies, setCheckedInconsistencies] = useState<string[]>([]);
    const [checkAllInconsistencies, setCheckAllInconsistencies] = useState(false);
    const [filterByPeriod, setFilterByPeriod] = useState<string>("0");
    const [justification, setJustification] = useState<IJustificationProps>();
    const { productId, isTecnac, serviceId } = useParams();
    const { company } = useCompany();
    const [ncmsSelect, setNcmsSelect] = useState<ISelectProps[]>([]);
    const [cnpjsSelect, setCnpjsSelect] = useState<ISelectProps[]>([]);
    const [periodsSelect, setPeriodsSelect] = useState<ISelectProps[]>([]);
    const [ncmsSelected, setNcmsSelected] = useState<string[]>([]);
    const [cnpjsSelected, setCnpjsSelected] = useState<string[]>([]);
    const [periodsSelected, setPeriodsSelected] = useState<string[]>([]);
    const [alreadyStarted, setAlreadyStarted] = useState<boolean>(false);
    const [invoiceNumFilter, setInvoiceNumFilter] = useState("");
    const [selectedOption, setSelectedOption] = useState(null);
    const [product, setProduct] = useState({} as UpdateProductFormProps);


    const options = invoicingPagination?.items.map((model, index) => ({
        value: model.invoicing.invoiceNum,
        label: model.invoicing.invoiceNum,
    }));

    const handleChange = (selected: any) => {
        setSelectedOption(selected);
        setInvoiceNumFilter(selected?.value || '');
    };



    async function handleClick() {
        setIsVisible(true);
        await getAllWithInconsistency();
    };

    function onClose() {
        toggleInconsistency();
        getAllByInconsistency();
        setJustification(undefined);
        setCheckAllInconsistencies(false);
        setCheckedInconsistencies([]);
    }
    
    async function getIncosistencyEntry() {
        setLoading(true);
        try {
            await api.get(`inconsistency/getByType/1`).then((resp) => {
                const response = resp.data;

                const data: IInconsistencyDetailsProps[] = response.data;

                setInconsistencyDetails(data);
            });

        }
        catch (error: any) {
            toast.error(error);
        }
        finally {
            setLoading(false);
        }
    } 
    async function getFilters() {
        try {
            setLoading(true);

            await api.get(`invoicing/GetAllFilters/productId=${productId}&serviceId=${company.serviceId}&type=1`).then((resp) => {
                if (resp.data.statusCode === 200) {
                    var data = resp.data.data as IInvoicingFiltersVO;

                    var ncmList = [] as ISelectProps[];
                    var cnpjList = [] as ISelectProps[];
                    var periodList = [] as ISelectProps[];

                    data.ncMs.forEach(x => {
                        ncmList.push({ label: x, value: x } as ISelectProps);
                    });

                    data.cnpJs.forEach(x => {
                        cnpjList.push({ label: maskCNPJ(x), value: x } as ISelectProps);
                    });

                    data.periods.forEach(x => {
                        periodList.push({ label: x, value: x } as ISelectProps);
                    });

                    setNcmsSelect(ncmList);
                    setCnpjsSelect(cnpjList);
                    setPeriodsSelect(periodList);
                }
            });
        } catch (error: any) {
            toast.error(error);
        } finally {
            setLoading(false);
        }
    }

    async function handleDeleteInvoicing(id: string) {
        setLoading(true);
        try {

            await api.delete('invoicing/delete', {
                data: {
                    id: id,
                    serviceId: company.serviceId
                }
            }).then((resp) => {
                const response = resp.data;

                if (response.statusCode === 200) {
                    toast.success('Faturamento deletado com sucesso!');

                    let invoicingFilter = invoicingPagination?.items.filter(model => model.invoicing.id != id);

                    const filterInvoicing = {
                        items: invoicingFilter,
                        currentPage: invoicingPagination?.currentPage,
                        take: invoicingPagination?.take,
                        total: invoicingPagination?.total,
                        word: invoicingPagination?.word
                    } as InvoicingPagination;

                    setInvoicingPagination(filterInvoicing);
                }
            });
        }
        catch (error: any) {
            toast.error(error);
        }
        finally {
            setLoading(false);
        }
    }

    async function handleSelection(inconsistencyId: number) {
        if (inconsistencyId == 0)
            await getAllWithInconsistency();
        else
            setSelectedValue(inconsistencyId);
    }

    async function getAllByInconsistency(page?: number) {
        setLoading(true);
        try {
            let text = searchText == null || searchText == "" ? "\"\"" : searchText;

            await api.get(`invoicing/GetAllByInconsistencyType/productId=${productId}&serviceId=${company.serviceId}&invoiceType=1&inconsistencyType=${selectedValue}&page=${page ?? 1}&take=7&word=${encodeURIComponent(text)}`).then((resp) => {
                const response = resp.data;

                setInvoicingPagination(response.data);

                if (page) {
                    setCurrentPage(page);
                }
            });

        }
        catch (error: any) {
            toast.error(error);
        }
        finally {
            setLoading(false);
        }
    }
    async function getAllByInconsistencyNotPaginated() {
        setLoading(true);
        try {
            let text = searchText == null || searchText == "" ? "\"\"" : searchText;

            await api.get(`invoicing/GetAllByInconsistencyTypeNotPaginated/productId=${productId}&serviceId=${company.serviceId}&invoiceType=1&inconsistencyType=${selectedValue}&word=${encodeURIComponent(text)}`).then((resp) => {
                const response = resp.data;
                setAllInvoicesByInconstancy(response.data)
            });

        }
        catch (error: any) {
            toast.error(error);
        }
        finally {
            setLoading(false);
        }
    }

    async function getAllWithInconsistency(page?: number) {
        setLoading(true);
        try {
            let text = searchText == null || searchText == "" ? "\"\"" : searchText;

            await api.get(`invoicing/GetAllWithInconsistency/productId=${productId}&serviceId=${company.serviceId}&invoiceType=1&page=${page ?? 1}&take=7&word=${encodeURIComponent(text)}`).then((resp) => {
                const response = resp.data;

                setInvoicingPagination(response.data);

                if (page) {
                    setCurrentPage(page);
                }
            });

        }
        catch (error: any) {
            toast.error(error);
        }
        finally {
            setLoading(false);
        }
    }

    async function changePageAllInvoicingsWithInconsistency(page: number) {
        setLoading(true);
        try {
            let text = searchText == null || searchText == "" ? "\"\"" : searchText;
            await api.get(`invoicing/GetAllWithInconsistency/productId=${productId}&serviceId=${company.serviceId}&invoiceType=1&page=${page}&take=7&word=${encodeURIComponent(text)}`).then((resp) => {
                const response = resp.data;
                setInvoicingPagination(response.data);
                setCurrentPage(page);
            });


        }
        catch (error: any) {
            toast.error(error);
        }
        finally {
            setLoading(false)
        }
    }
    async function changePageAllInvoicingsByInconsistency(page: number) {
        setLoading(true);
        try {
            let text = searchText == null || searchText == "" ? "\"\"" : searchText;
            await api.get(`invoicing/GetAllByInconsistencyType/productId=${productId}&serviceId=${company.serviceId}&invoiceType=1&inconsistencyType=${selectedValue}&page=${page}&take=7&word=${encodeURIComponent(text)}`).then((resp) => {
                const response = resp.data;
                setInvoicingPagination(response.data);
                setCurrentPage(page);
            });
        }
        catch (error: any) {
            toast.error(error);
        }
        finally {
            setLoading(false)
        }
    }

    async function getJustificationByInvoicingId(invoicingId: string) {
        try {
            setLoading(true);

            await api.get(`invoicing/GetJustificationByInvoicingId/invoicingId=${invoicingId}&inconsistencyType=${selectedValue}`).then((resp) => {
                if (resp.status == 200) {
                    setJustification(resp.data.data);
                }
            });
        } catch (error: any) {
            toast.error(error);
        } finally {
            setLoading(false);
        }
    }

    function handleToggleCheckAll() {
        var isTrue = checkAllInconsistencies;
        getAllByInconsistencyNotPaginated();
        var items = allInvoicesByInconstancy ? allInvoicesByInconstancy.filter(x => x.invoicing.alreadyJustificated == false).map(x => x.invoicing.id) : [];

        setCheckedInconsistencies(isTrue ? [] : items);
    }

    function handleCheckInconsistency(checkedInconsistency: string) {
        if (checkedInconsistencies.some(e => e === checkedInconsistency)) {
            const filteredList = checkedInconsistencies.filter(item => {
                return item != checkedInconsistency;
            });

            setCheckedInconsistencies(filteredList);
        }
        else {
            setCheckedInconsistencies(ant => [...ant, checkedInconsistency]);
        }
    }

    async function getProduct() {
        setLoading(true);
        try {
            await api.get(`product/getById/${productId}`).then((resp) => {
                const response = resp.data;

                const productResult = response.data as UpdateProductFormProps;
                productResult.startManufacturingDate = productResult.startManufacturingDate != undefined ? new Date(new Date(productResult.startManufacturingDate?.toString().split("T")[0]).setHours(24)) : null;
                productResult.pleitoDate = productResult.pleitoDate != undefined ? productResult.pleitoDate?.toString().split("T")[0] : productResult.pleitoDate;
                productResult.publishDate = productResult.publishDate != undefined ? productResult.publishDate?.toString().split("T")[0] : productResult.publishDate;
                productResult.licenseDate = productResult.licenseDate != undefined ? productResult.licenseDate?.toString().split("T")[0] : productResult.licenseDate;

                setProduct(response.data);
            });
        }
        catch (error: any) {
            toast.error(error);
        }
        finally {
            setLoading(false);
        }
    }

    async function handleDownloadExcelMassiveJustification() {
        setLoading(true)
        try {
            await api.get(`justification/DownloadExcelWithoutInconsistency/1/${company.serviceId}/${productId}`, {
                responseType: 'blob'
            }).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', SetFileName(company.title,company.year.toString(),product.name, "xlsx"));
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            });
        }
        catch (error: any) {
            toast.error(error);
        }
        finally {
            setLoading(false);
        }
    }

    async function handleDownloadExcelMassiveJustificationInconsistency() {
        setLoading(true)
        try {
            await api.get(`justification/DownloadExcel/1/${company.serviceId}/${productId}/${filterByPeriod}`, {
                responseType: 'blob'
            }).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', SetFileName(company.title,company.year.toString(),"inconsistencias", "xlsx"));
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            });
        }
        catch (error: any) {
            toast.error(error);
        }
        finally {
            setLoading(false);
        }
    }

    async function uploadMassiveJustification() {
        setLoading(true);
        try {
            let formData = new FormData();

            if (file != undefined) {
                formData.append('file', file);
            }

            await api.post(`Justification/ReadExcel/1/${company.serviceId}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then((resp) => {
                const response = resp.data;

                const data = response.data;

                if (response.statusCode === 200) {
                    toast.success(`As inconsistências foram tratadas com sucesso. ${data.notSaved} linhas foram desconsideradas por serem  não justificáveis.`);

                }
            });
        }
        catch (err: any) {
            toast.error(err);
        }
        finally {
            setLoading(false);
        }
    }

    async function filterAll(page?: number) {
        try {
            setLoading(true);
            let text = searchText == null || searchText == "" ? "\"\"" : searchText;

            const invoiceNumQuery = invoiceNumFilter !== "" ? `&invoiceNum=${invoiceNumFilter}` : "";

            await api.post('/Invoicing/FilterAll', {
                productId: productId,
                serviceId: company.serviceId,
                type: 1,
                ncmList: ncmsSelected,
                periodList: periodsSelected,
                cnpjList: cnpjsSelected,
                isTecnac: isTecnac == "1" ? true : false,
                page: page ?? 1,
                take: 7,
                word: text + invoiceNumQuery
            }).then((resp) => {
                const response = resp.data;
                setInvoicingPagination(response.data);
                setCurrentPage(page ?? 1);
                setAlreadyStarted(false);
            });
        } catch (error: any) {
            toast.error(error);
        } finally {
            setLoading(false);
        }
    }



    const handleSelectCNPJ = (e: ISelectProps[]) => {
        var values = e.map((info: ISelectProps) => info.value);
        setCnpjsSelected(values);
    }

    const handleSelectNCM = (e: ISelectProps[]) => {
        var values = e.map((info: ISelectProps) => info.value);
        setNcmsSelected(values);
    }

    const handleSelectPeriod = (e: ISelectProps[]) => {
        var values = e.map((info: ISelectProps) => info.value);
        setPeriodsSelected(values);
    }

    useEffect(() => {
        getIncosistencyEntry();
        getFilters(); 
        getProduct()
    }, []);

    useEffect(() => {
        if (ncmsSelected.length >= 0 || cnpjsSelected.length >= 0 || periodsSelected.length >= 0) {
            if (!alreadyStarted) {
                setAlreadyStarted(true);
            }
        }
    }, [ncmsSelected, cnpjsSelected, periodsSelected]);

    useEffect(() => {
        if (alreadyStarted) {
            filterAll();
        }
    }, [alreadyStarted]);

    useEffect(() => {
        if (selectedValue > 0) {
            getAllByInconsistency();
        }
    }, [selectedValue]);

    useEffect(() => {
        var length = invoicingPagination?.items.filter(x => x.invoicing.alreadyJustificated == false).length ?? 0;

        if (checkedInconsistencies.length > 0 && checkedInconsistencies.length == length) {
            setCheckAllInconsistencies(true);
        } else {
            setCheckAllInconsistencies(false);
        }
    }, [checkedInconsistencies]);

    useEffect(() => {
        if (justification != undefined) {
            toggleInconsistency();
        }
    }, [justification]);

    return (
        <>
            {loading && <Loader />}
            <div className='card-body bg-light w-100 h-100 p-4 border'>
                <div className="d-flex justify-content-end aling-items-center">
                    {isVisible ?
                        <select
                            className='form-select w-100 mb-4 mr-2'
                            defaultValue={selectedValue}
                            onChange={(e) => {
                                handleSelection(parseInt(e.target.value));
                                parseInt(e.target.value) == 0 && setCheckedInconsistencies([]);
                                parseInt(e.target.value) == 0 && setCheckAllInconsistencies(false);
                            }}
                        >
                            <option value="0">Filtro de Inconsistências</option>
                            {inconsistencyDetails?.map((model, index) => {
                                return (
                                    <option key={index} value={model.id}>{model.inconsistencyText}</option>
                                );
                            })}
                        </select>
                        :
                        <div className="w-100 d-flex flex-column">
                            <div className="mb-4 d-flex justify-content-end" >
                                <Button variant="contained" className="btn  me-2 fw-bold" onClick={handleDownloadExcelMassiveJustification}>Extração Excel</Button>
                                <Button variant="contained" className="btn  fw-bold" onClick={handleClick}>Inconsistências</Button>
                            </div>
                            <div className="w-100 d-flex align-items-center mb-4">
                                <div className="w-100 me-2">
                                    <label>Número de Nota Fiscal:</label>
                                    <Select
                                        value={selectedOption}
                                        onChange={handleChange}
                                        options={options}
                                        isClearable
                                        placeholder="Select..."
                                    />
                                </div>

                                <div className="w-100 me-2">
                                    <label>Buscar Por Período:</label>
                                    <Select
                                        isMulti
                                        onChange={(e: any) => handleSelectPeriod(e)}
                                        options={periodsSelect}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                    />
                                </div>
                                <div className="w-100">
                                    <label>CNPJ Emitente</label>
                                    <Select
                                        isMulti
                                        onChange={(e: any) => handleSelectCNPJ(e)}
                                        options={cnpjsSelect}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                    />
                                </div>
                            </div>
                        </div>
                    }
                </div>
                <div className={`${isVisible ? 'd-block' : 'd-none'} mb-4 d-flex justify-content-end`} >
                    <div className='d-flex align-items-center justify-content-end w-100'>
                        {company.counting === 1 &&
                            <select className="form-select w-25 me-2" value={filterByPeriod} onChange={e => setFilterByPeriod(e.target.value)}>
                                <option value="0">Consolidado</option>
                                <option value="1">1º trimestre</option>
                                <option value="2">2º trimestre</option>
                                <option value="3">3º trimestre</option>
                                <option value="4">4º trimestre</option>
                            </select>
                        }
                        <Button variant="contained" className='btn  me-2 fw-bold' onClick={handleDownloadExcelMassiveJustificationInconsistency}>
                            <BsFillCloudArrowDownFill className='me-2' size={20} />
                            Modelo de Excel
                        </Button>
                        <Button variant="contained" className='btn   fw-bold' onClick={toggle}>
                            <BsFillCloudArrowUpFill className='me-2' size={20} />
                            Upload Modelo
                        </Button>
                    </div>
                </div>
                <table className='table table-bordered table-striped table-hover mb-4'>
                    <thead>
                        <tr>
                            <th scope="col ">Nº da Nota </th>
                            <th scope="col">NCM</th>
                            <th scope="col">Descrição do Item da Nota Fiscal </th>
                            <th scope="col">Mês de Emissão </th>
                            <th scope="col">Data de Emissão</th>
                            <th scope="col">Valor Total + Impostos </th>
                            <th scope="col">Valor de base de calculo </th>
                            <th scope="col">{(isVisible && selectedValue != 0 && (invoicingPagination?.items.filter(x => x.invoicing.alreadyJustificated == null || x.invoicing.alreadyJustificated == false).length ?? 0) > 0) ? <Form.Check readOnly={true} className='cursor-pointer d-flex align-items-center justify-content-center mb-1' checked={checkAllInconsistencies} onClick={handleToggleCheckAll} /> : "Ações"}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {invoicingPagination?.items
                            .filter((model) =>
                                model.invoicing.invoiceNum.toLowerCase().includes(invoiceNumFilter.toLowerCase())
                            )
                            .map((model, index) => (

                                <tr key={index}>
                                    <th scope="row">
                                        {model.invoicing.invoiceNum}
                                        {model.hasCancelling && <BsExclamationLg className='me-2 ms-2' title='Essa nota foi cancelada e seu faturamento não será considerado' />}
                                        {model.hasCorrection && <BsExclamationLg title='Essa nota fiscal possui uma carta de correção vinculada' />}
                                    </th>
                                    <td>{model.invoicing.itemNCM}</td>
                                    <td>{model.invoicing.itemName}</td>
                                    <td>{model.invoicing.invoiceDate != null ? formatDateMonth(new Date(model.invoicing.invoiceDate)) : ""}</td>
                                    <td>{model.invoicing.invoiceDate != null ? formatDate(new Date(model.invoicing.invoiceDate)) : ""}</td>
                                    <td>R${((Number(model.invoicing.itemUniValue.replace(",", ".")) * Number(model.invoicing.itemQuantity)) + Number(model.invoicing.itemIPI.replace(",", ".")) + Number(model.invoicing.itemICMSSTDIFAL.replace(",", "."))).toFixed(2).toString().replace(".", ",")}</td>
                                    <td>R${(Number(model.invoicing.itemUniValue.replace(",", ".")) * Number(model.invoicing.itemQuantity)).toFixed(2).toString().replace(".", ",")}</td>
                                    <InvoicingAction
                                        selectedValue={selectedValue}
                                        isVisible={isVisible}
                                        id={model.invoicing.id}
                                        action={() => handleDeleteInvoicing(model.invoicing.id)}
                                        checkAllInconsistencies={checkAllInconsistencies}
                                        checkedInconsistencies={checkedInconsistencies}
                                        handleCheckInconsistency={handleCheckInconsistency}
                                        alreadyJustificated={model.invoicing.alreadyJustificated ?? false}
                                        getJustificationByInvoicingId={getJustificationByInvoicingId}
                                    />
                                </tr>
                            ))}
                        {invoicingPagination?.items && <FillTable columns={8} rows={invoicingPagination?.items.length} />}
                    </tbody>
                </table>
                {checkedInconsistencies.length > 0 &&
                    <div className="d-flex justify-content-center align-items-center">
                        <Button variant="contained"
                            className={`${isVisible && selectedValue !== 0 ? 'set-block' : 'set-hidden'
                                } btn  p-2 mr-2 mb-4 text-center`}
                            onClick={toggleInconsistency}
                        >
                            Justificar
                        </Button>
                    </div>

                }
                {invoicingPagination?.items &&
                    <TablePaginationFooter
                        totalItems={invoicingPagination?.total}
                        change={isVisible ? selectedValue != 0 ? changePageAllInvoicingsByInconsistency : changePageAllInvoicingsWithInconsistency : filterAll}
                    />
                }
                {isShowingInconsistency &&
                    <InconsistencyModal
                        toggle={onClose}
                        justificationProp={justification}
                        isShowing={isShowingInconsistency}
                        inconsistencyId={selectedValue}
                        checkedInconsistencies={checkedInconsistencies}
                    />
                }
                {isShowing && <UploadModal
                    isShowing={isShowing}
                    toggle={toggle}
                    icon={AiFillFileExcel}
                    setFile={setFile}
                    action={uploadMassiveJustification}
                />}
            </div>
        </>
    );
}