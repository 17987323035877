import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { MdEmojiObjects } from 'react-icons/md';
import { useEffect, useState } from "react";
import { api } from "../../../services/axios";
import { toast } from "react-toastify";
import { IJustificationProps } from "../../../pages/Invoicing/InvoicingProductExit";
import "./style.css"
import { Button, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { useCompany } from "../../../hooks/useCompany";

interface InconsistencyModalProps {
    isShowing: boolean;
    toggle: () => void;
    inconsistencyId?: number;
    checkedInconsistencies: string[];
    justificationProp: IJustificationProps | undefined;
}
export interface InconsistencyDetailsProps {
    id: number;
    inconsistencyType: number | null;
    inconsistencyText: string | null;
    isJustifiable: boolean | null;
}

export function InconsistencyModal({ isShowing, toggle, inconsistencyId, checkedInconsistencies, justificationProp }: InconsistencyModalProps) {
    const [loading, setLoading] = useState(false);
    const [inconsistency, setInconsistency] = useState<InconsistencyDetailsProps>();
    const [justification, setJustification] = useState<string>(justificationProp ? justificationProp.justification : "");
    const [considerBilling, setConsiderBilling] = useState<string>(justificationProp ? justificationProp.considerBilling ? "1" : "0" : inconsistency?.isJustifiable ? '1' : '0');
    const { company } = useCompany();

    async function getInconsistency() {
        setLoading(true);
        try {
            const result = await api.get(`inconsistency/getById/${inconsistencyId}`);
            const response = await result.data;

            const data: InconsistencyDetailsProps = response.data;
            setInconsistency(data);
        } catch (error: any) {
            toast.error(error);
        } finally {
            setLoading(false)
        }
    }

    async function sendJustification() {
        try {
            setLoading(true);

            await api.post('invoicing/UpdateJustification', {
                invoicingIds: checkedInconsistencies,
                justification: justification,
                justificationType: inconsistencyId,
                considerBilling: considerBilling == '0' ? false : true,
                serviceId: company.serviceId
            }).then(() => {
                toast.success("Justificativa Criada com sucesso!");
                toggle();
            });
        } catch (error: any) {
            toast.error(error);
        } finally {
            setLoading(false);
        }

    }

    async function updateJustification() {
        try {
            setLoading(true);

            await api.post('invoicing/UpdateJustificationAlreadyCreated', {
                invoicingId: justificationProp?.invoicingId,
                justification: justification,
                justificationType: inconsistencyId,
                considerBilling: considerBilling == '0' ? false : true,
                serviceId: company.serviceId
            }).then(() => {
                toast.success("Justificativa Modificada com sucesso!");
                toggle();
            });
        } catch (error: any) {
            toast.error(error);
        } finally {
            setLoading(false);
        }

    }

    useEffect(() => {
        getInconsistency();
    }, []);

    return (
        <Modal isOpen={isShowing} toggle={toggle} className='modal-lg width rounded'>
            {/* <ModalHeader className='d-flex align-center justify-content-center'>
                {/* <MdEmojiObjects size={80} /> 
            </ModalHeader> */}
            <ModalBody className='bg-light '>

                <div className='card '>
                    <div className="d-flex justify-content-end margin-12">
                        <div className="cursor-pointer" onClick={toggle}>x</div>
                    </div>

                    <div className='card-body'>
                        <div className="d-flex justify-content-end aling-items-center mb-3 mt-3">
                            <TextField className='form-control form-control border-primary-subtle' value={inconsistency?.inconsistencyText?.toString()} disabled />
                        </div>
                        <div className="justify-content aling-items-center  " >
                            <div className="bg-primary rounded-top p-2 text-white w-25 text-center justification">Justificativa:</div>

                            <TextField
                                className='form-control form-control border-primary-subtle'
                                onChange={e => setJustification(e.target.value)}
                                defaultValue={justificationProp ? justificationProp.justification : ""}
                                value={justification}
                                multiline
                                rows={6}
                                disabled={!inconsistency?.isJustifiable}
                            />
                        </div>
                        <div className="d-flex align-items-center mt-4 ">
                            <div className="d-flex align-items-center">
                                <span className="me-2">Considerar faturamento:</span>
                                <FormControl variant="outlined" sx={{ minWidth: 120 }}>

                                    <Select
                                        labelId="consider-billing-label"
                                        label="Considerar"
                                        className="border border-primary rounded cursor-pointer"
                                        defaultValue={
                                            justificationProp
                                                ? justificationProp.considerBilling
                                                    ? "1"
                                                    : "0"
                                                : inconsistency?.isJustifiable
                                                    ? "1"
                                                    : "0"
                                        }
                                        disabled={!inconsistency?.isJustifiable}
                                        onChange={(e) => setConsiderBilling(e.target.value)}
                                    >
                                        <MenuItem value="0">Não</MenuItem>
                                        <MenuItem value="1">Sim</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>

                        </div>
                        <div >
                            {inconsistency?.isJustifiable ?
                                <div className="d-flex justify-content-center align-items-center cursor-pointer w-100">
                                    <Button variant="contained" disabled={justification == "" || !considerBilling} className='btn  p-2 mr-2 mb-4 mt-5 text-center' onClick={justificationProp ? updateJustification : sendJustification}>Salvar</Button>
                                    {/* <button className='btn btn-secondary w-25 ms-2' onClick={toggle}>Fechar</button> */}
                                </div>
                                :
                                <Button variant="outlined" className='d-flex justify-content-center align-items-center cursor-pointer' onClick={toggle}>Fechar</Button>
                            }
                        </div>

                    </div>
                </div>

            </ModalBody>

        </Modal>
    )
}