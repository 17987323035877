export const dynamicCriterionC1toC4 = [
    { selectLabel: 'C1 Problema Técnico-Científico', sigla: 'C1', field: 'technicalScientificProblem', criterion: 0 },
    { selectLabel: 'C2 Contextualização das etapas', sigla: 'C2', field: 'contextualizationOfTheSteps', criterion: 0 },
    { selectLabel: 'C3 Atividade investigativa', sigla: 'C3', field: 'investigativeActivity', criterion: 0 },
    { selectLabel: 'C4 Elemento de novidade', sigla: 'C4', field: 'noveltyElement', criterion: 0 },
]

export const dynamicCriterionC5toC7 = [
    { selectLabel: 'C5 Conteúdo', sigla: 'C5', field: 'content', criterion: 0 },
    { selectLabel: 'C6 Nível', sigla: 'C6', field: 'level', criterion: 0 },
    { selectLabel: 'C7 Pessoal Formado ou Capacitado', sigla: 'C7', field: 'trainedOrQualifiedPersonnel', criterion: 0 },
]