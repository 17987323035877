import { useEffect, useState } from 'react';
import { Modal as ModalContainer } from 'react-bootstrap';
import Loader from '../../Shared/Loader';
import { FormControl, MenuItem, Select } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useCompany } from '../../../hooks/useCompany';
import { IResponseProps } from '../../../interfaces/IResponseProps';
import { GetAllByCompanyIdAPI } from '../../../API/InternalProjectsAPI';
import { InternalProjectsModel } from '../../../models/projectModel';
interface CreateProductsModalProps {
    toggle: () => void;
    isShowing: boolean;
}

export function CreateProductsModal({ toggle, isShowing }: CreateProductsModalProps) {
    const [loading] = useState(false);
    const { company } = useCompany();
    const [lastYearProject, setLastYearProject] = useState("0");
    const [lastYearProjectImport, setLastYearProjectImport] = useState("0");
    const [lastYearId, setlastYearId] = useState<string>("0");
    const [projectsList, setProjectsList] = useState<InternalProjectsModel[]>();
    const [hasProjectsList, setHasProjectsList] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        getAllProjectsLastYear();
    }, []);

    const getAllProjectsLastYear = async () => {
        await GetAllByCompanyIdAPI(company.companyId, company.year - 1).then((resp: IResponseProps) => {
            if (resp.statusCode === 200) {
                var data = resp.data as InternalProjectsModel[];

                if (data != null && data.length > 0) {
                    setProjectsList(data);
                    setHasProjectsList(true);
                }
            }
        });
    }

    function goTo() {
        navigate(`/${company.serviceId}/internalProjects/tabs/${lastYearProject}/${lastYearProjectImport}/${lastYearId ?? "0"}`);
    }

    return (
        <>
            {loading && <Loader />}
            <ModalContainer onHide={toggle} className='modal-lg' show={isShowing}>
                <ModalContainer.Header closeButton>
                    <ModalContainer.Title>Inserir Projeto</ModalContainer.Title>
                </ModalContainer.Header>
                <ModalContainer.Body className='p-4'>
                    <form className=''>
                        <div className=''>
                            <h6 className='form-label'>Projeto submetido no RDA do ano anterior?</h6>
                        </div>
                        <div className='row mb-4'>
                            <FormControl fullWidth size="small">
                                <Select className="forms-control h-100" fullWidth value={lastYearProject} onChange={(e) => setLastYearProject(e.target.value)}>
                                    <MenuItem value="0">Não</MenuItem>
                                    <MenuItem value="1">Sim</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        {lastYearProject === "1" && (
                            <div>
                                <div className=''>
                                    <h6 className='form-label'>Importar projetos do ano anterior?</h6>
                                </div>
                                <div className='row mb-4'>
                                    <FormControl fullWidth size="small">
                                        <Select className="forms-control h-100" fullWidth value={lastYearProjectImport} onChange={(e) => setLastYearProjectImport(e.target.value)} disabled={!hasProjectsList}>
                                            <MenuItem value="0">Não</MenuItem>
                                            <MenuItem value="1">Sim</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>

                                {hasProjectsList
                                    ? (lastYearProjectImport === "1" &&
                                        (<>
                                            <div className="form-label">
                                                <h6 className='form-label'>Selecione o projeto desejado do ano anterior:</h6>
                                            </div>
                                            <div className='row mb-4'>
                                                <FormControl fullWidth size="small">
                                                    <Select className="forms-control h-100" fullWidth value={lastYearId} onChange={(e) => setlastYearId(e.target.value)}>
                                                        {projectsList?.map((option, index) => (
                                                            <MenuItem key={index} value={option.id}>
                                                                Projeto: {option.projectName}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </div>
                                        </>
                                        )
                                    )
                                    : <>
                                        <div className='d-flex justify-content-center align-items-center text-center'>
                                            <h4>Empresa não possui projetos anteriores.</h4><br /><br />
                                        </div>
                                    </>
                                }

                                <div className='d-flex justify-content-center align-items-center text-center'>
                                    <label className='form-label'>a plataforma colará os campos com as informações
                                        <br />disponíveis do ano anterior e os preencherá
                                        <br />automaticamente.
                                    </label>
                                </div>
                            </div>
                        )}
                        <button className='btn btn-success w-100' type='submit' onClick={goTo} disabled={lastYearProject === "1" && hasProjectsList && lastYearProjectImport === "1" && lastYearId === "0"}>Avançar</button>
                    </form>
                </ModalContainer.Body>
            </ModalContainer>
        </>
    );
}