import { useCallback, useContext, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import { AiFillHome, AiOutlinePlus, AiOutlineSearch } from "react-icons/ai";
import { FaFileImport, FaTimes } from "react-icons/fa";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { FileManagerContent } from "../../../components/Files/FileManagerContent";
import { FillTable } from "../../../components/Shared/FillTable";
import Loader from "../../../components/Shared/Loader";
import { FileContext, FileProvider } from "../../../contexts/fileContext";
import { useCompany } from "../../../hooks/useCompany";
import { api } from "../../../services/axios";
import './styles.css';


export function Files() {
    const { company } = useCompany();
    const { path } = useParams();

    return (
        <>
            <div className='card'>
                <FileProvider pathMain={path != undefined ? parseInt(path) : 0}>
                    <FileManagerContent />
                </FileProvider>
            </div>
        </>
    )
}
