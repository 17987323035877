import React from 'react';
import { forwardRef } from 'react';
import { PatternFormat, NumericFormatProps } from 'react-number-format';

interface ICNPJInputProps {
    onChange: (event: { target: { name: string; value: string } }) => void;
    value: string,
    name: string;
}

export const CNPJInput = forwardRef<NumericFormatProps, ICNPJInputProps>(function CNPJInputCustom(props, ref) {
    const { onChange, value, ...other } = props;

    return (
        <PatternFormat
            format={`####-##`}
            {...other}
            getInputRef={ref}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            value={value}
        />
    );
},
);