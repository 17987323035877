import React, { createContext, ReactNode, useState } from "react";
import { api } from '../services/axios';
import { toast } from "react-toastify";
import Loader from "../components/Shared/Loader";

interface CreditProviderProps {
    children: ReactNode;
}

export interface CreditProps {
    id: string | null;
    normalNotTecnacSurplusValue: number;
    tecnacSurplusValue: number;
    normalNotTecnacRemainingValue: number;
    tecnacRemainingValue: number;
    creditRemainingValue: number;
    creditSurplusValue: number;
    creditValue: number;
    creditApplicationDate: string;
    creditReceiptFilePath: string;
    creditCertificateFilePath: string;
    branchCompanyId: string | null;
    countings: string;
    region: string;
    validated: boolean;
}

export interface CreditContextProps {
    credit: CreditProps | undefined;
    GetCredit: (id: string) => Promise<void>;
}

export const CreditContext = createContext({} as CreditContextProps);

export function CreditProvider({ children }: CreditProviderProps) {
    const [credit, setCredit] = useState<CreditProps>();
    const [loading, setLoading] = useState<boolean>(false);

    async function GetCredit(id: string) {
        try {
            setLoading(true);
            await api.get(`Credit/Get/${id}`).then(resp => {
                const response = resp.data;

                let data: CreditProps = response.data;

                if (response.statusCode === 200 && data != null) {
                    data.creditApplicationDate = data.creditApplicationDate != undefined ? data.creditApplicationDate?.toString().split("T")[0] : data.creditApplicationDate;

                    setCredit(data);
                }
                else {
                    setCredit({
                        creditValue: 0,
                        creditSurplusValue: 0,
                        creditRemainingValue: 0,
                        normalNotTecnacSurplusValue: 0,
                        normalNotTecnacRemainingValue: 0,
                        tecnacRemainingValue: 0,
                        tecnacSurplusValue: 0,
                        countings: '',
                        creditCertificateFilePath: '',
                        creditReceiptFilePath: '',
                        creditApplicationDate: '',
                        id: null,
                        validated: false,
                        branchCompanyId: null,
                        region: ''
                    })
                }
            });
        } catch (err: any) {
            toast.error(err);
        } finally {
            setLoading(false);
        }
    }

    return (
        <CreditContext.Provider value={{
            credit,
            GetCredit
        }}>
            {loading && <Loader />}
            {children}
        </CreditContext.Provider>
    );
}