import { api } from "../../../services/axios";
import { useEffect, useState } from "react";
import Loader from "../../../components/Shared/Loader";
import { useParams } from "react-router-dom";
import InvoicingDetailsForm, { InvoicingDetailsProps } from "../../../components/Invoicing/InvoicingDetailsForm";
import { toast } from "react-toastify";


export default function InvoicingDetails() {
    const [loading, setLoading] = useState(false);
    const { id, productId } = useParams();

    const [invoicingModel, setInvoicingModel] = useState({} as InvoicingDetailsProps);

    async function getInvoicingModel() {
        setLoading(true);
        try {
            await api.get(`invoicing/getById/${id}`).then((resp) => {
                const response = resp.data;

                setInvoicingModel(response.data);
            });

        }
        catch (error: any) {
            toast.error(error);
        }
        finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        getInvoicingModel();
    }, []);

    return (
        <>
            {loading && <Loader />}
            {invoicingModel.itemName && <InvoicingDetailsForm invoicingModel={invoicingModel} />}
        </>
    );
}