import ImageDefault from '../../../assets/Images/logo-default.png';
import { useCompany } from '../../../hooks/useCompany';
import { ServiceHeaderModal } from '../../Services/ServiceHeaderModal';
import useModal from '../../../hooks/useModal';
import { CompanyHeaderModal } from '../../Company/CompanyHeaderModal';
import { Link, useParams } from "react-router-dom";
import { maskCNPJ } from '../../../utils/cnpjUtils';
import './styles.css';
import { toast } from 'react-toastify';
import { api } from '../../../services/axios';
import { useEffect, useState } from 'react';
import { ServicesModel } from '../../../models/servicesModel';
import { formatCurrencyWithSign } from '../../../utils/formatCurrency';

const counting = [
    'Anual',
    'Trimestral',
    'Mensal'
]

export default function Header() {
    const { isShowing: isShowingCompanyModal, toggle: toggleCompanyModal } = useModal();
    const { isShowing, toggle } = useModal();
    const { serviceId } = useParams();
    const { company, handleUpdateServiceByHeader, obligationTotal } = useCompany();
    const [investMessage, setInvestMessage] = useState<string>("");
    const [incentivizedRealizedBilling, setIncentivizedRealizedBilling] = useState(0)
    const [services, setServices] = useState<ServicesModel[]>([]);

    console.log("incentivizedBillingForecastZFM", company.incentivizedBillingForecastZFM);
    console.log("incentivizedBillingForecastLINacional", company.incentivizedBillingForecastLINacional);

    function getFaturamentoIncentivado() {
        var sum = (company.incentivizedBillingForecastZFM ?? 0) + (company.incentivizedBillingForecastLINacional ?? 0);
        var text = "";

        if (sum > 0 && sum < 10000000)
            text = 'Abaixo de 10 milhões';
        else if (sum > 10000000 && sum < 30000000)
            text = 'Entre R$ 10 milhões e R$ 30 milhões';
        else if (sum > 30000000)
            text = 'Acima de R$ 30 milhões';
        else
            text = '--'

        setInvestMessage(text);
    }

    async function getGetIncentivizedRealizedBillingAsync() {
        try {
            await api.get(`/Obligation/GetIncentivizedRealizedBilling/${serviceId}`)
            .then((resp) => {
                if (resp.data.statusCode == 200) {
                    console.log("DATA", resp.data);
                    setIncentivizedRealizedBilling(resp.data.data.total);
                }
            });
        }
        catch (error: any) {
            toast.error(error);
        }
    }

    async function getAllYears() {
        try {
            await api.get(`/Home/GetAllYears?companyId=${company.companyId}`).then((resp) => {
                if (resp.data.statusCode == 200) {
                    setServices(resp.data.data);
                }
            });
        }
        catch (error: any) {
            toast.error(error);
        }
    }

    function changeService(year: number) {
        var service = services.filter(x => x.year == year)[0];
        handleUpdateServiceByHeader(service.counting, service.year, service.id, company.incentivizedBillingForecastZFM ?? 0, company.incentivizedBillingForecastLINacional ?? 0);
        window.location.href = `/${service.id}/home`;
    }

    useEffect(() => {
        getAllYears();
    }, []);

    useEffect(() => {
        getFaturamentoIncentivado();
        getGetIncentivizedRealizedBillingAsync();
    }, [company]);

    return (
        <>
            <div className="container-fluid d-flex align-items-center w-100 mb-3 mt-3">
                <div className='w-100 img-logo-company d-flex align-items-center justify-content-center'>
                    <Link to={`/${company.serviceId}/company/edit`}>
                        <img src={company.photoUrl ? company.photoUrl : ImageDefault} alt="logo" />
                    </Link>
                </div>
                <div className='w-100 margin-middle'>
                    <div className="d-flex flex-column justify-content-between h-100">
                        <Link to={`/${company.serviceId}/company/edit`}>
                            <div className='cursor-pointer w-100 d-flex align-items-center mb-1'>
                                <div className="header-info-width me-1 back-white header-info">
                                    <span>{company?.title}</span>
                                </div>
                                <div className="w-25 ms-1">
                                    <div className="back-white header-info">
                                        <span>{maskCNPJ(company.taxNumber)}</span>
                                    </div>
                                </div>
                            </div>
                        </Link>
                        <div className='d-flex align-items-center w-100 mt-1 mb-1'>
                            <div onClick={toggle} className="cursor-pointer back-white me-1 w-50 header-info">
                                <span>Previsão de Faturamento: {investMessage}</span>
                            </div>
                            <div className='w-50 d-flex align-items-center ms-1'>
                                <div onClick={toggle} className="cursor-pointer back-white me-1 w-50 header-info">
                                    <span>Apuração: {counting[company?.counting]}</span>
                                </div>
                                <div className="back-white ms-1 w-50 header-info">
                                    <span>Ano fiscal: </span>
                                    <select
                                        className='ms-2'
                                        onChange={(e) => changeService(parseInt(e.target.value))}
                                        value={company.year}
                                    >
                                        {services?.sort(x => x.year).map((service, index) => {
                                            return (
                                                <option key={index} value={service.year}>{service.year}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className='d-flex align-items-center w-100 mt-1'>
                            <div className="back-white me-1 w-50 header-info">
                                <span className='text-bold'>Faturamento Realizado Incentivado:</span><span className='ms-2'>{formatCurrencyWithSign(incentivizedRealizedBilling?.toFixed(2))}</span>
                            </div>
                            <Link to={`/${company.serviceId}/Obligations`} className='w-50 ms-1'>
                                <div className="cursor-pointer back-white w-100 header-info">
                                    <span className='text-bold'>Valor da Obrigação:</span><span className='ms-2'>{formatCurrencyWithSign(obligationTotal?.toFixed(2) ?? "0")}</span>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className='w-100 img-logo-company'>
                    <div className='custom-card-header text-bold'>
                        Valor de Investimento Realizado
                    </div>
                    <div className='custom-card-body d-flex align-items-center justify-content-center'>
                        <span>--</span>
                        <small>Total Dispêndio</small>
                    </div>
                </div>
            </div>
            {isShowing && <ServiceHeaderModal toggle={toggle} isShowing={isShowing} />}
            {isShowingCompanyModal && <CompanyHeaderModal toggle={toggleCompanyModal} isShowing={isShowingCompanyModal} />}
        </>
    );
}