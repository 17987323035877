import './styles.css';

const PaginationTabHeader = ({ tabs, setPag, pag, setKey, keyDefault, disableds = [] }: any) => {
    return (
        <div className='pagination-tabs-header'>
            {tabs.map((tab: any, index: any) => {
                return (
                    <button
                        key={index}
                        className={`button-tab-pag ${pag === index && "tab-selected"}`}
                        onClick={() => { setPag(index); setKey(keyDefault + 1) }}
                        disabled={ disableds.length > 0 ? disableds.includes(index) : false}
                    >
                        {tab}
                    </button>
                )
            })}
        </div>
    );
}

export default PaginationTabHeader;