import { forwardRef } from "react";
import { NumericFormatProps, PatternFormat } from "react-number-format";

interface ICustomCNPJInputProps {
    onChange: (event: { target: { name: string; value: string } }) => void;
    value: string,
    name: string;
}

export const CustomCNPJInput = forwardRef<NumericFormatProps, ICustomCNPJInputProps>(function CNPJInputCustom(props, ref) {
    const { onChange, value, ...other } = props;

    return (
        <PatternFormat
            format={`###.###.###/####-##`}
            {...other}
            getInputRef={ref}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            value={value}
        />
    );
},
);