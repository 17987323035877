import { FileModel } from "../../../models/FileModel";
import { Button, Modal } from "react-bootstrap"
import { FilesSearchColumns } from "../../../Static/FilesColumns";
import TableDefault from "../../Shared/TableDefault";

interface IModalSearchFileComponentProps {
    show: boolean,
    handleClose: () => void,
    serviceId: string,
    pathMain: string,
    searchFile: (page?: number) => Promise<void>,
    filesModal: FileModel[],
    totalModal: number,
    pathName: string,
    setPathName: React.Dispatch<React.SetStateAction<string>>
}

const ModalSearchFileComponent = ({ show, handleClose, searchFile, filesModal, totalModal,  pathName, setPathName}: IModalSearchFileComponentProps) => {

    return (
        <Modal show={show} size="lg" onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Buscar Arquivos</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <label className="required">Nome do arquivo:</label>
                <input required value={pathName} onChange={(e) => setPathName(e.target.value)} className="w-100 form-control mt-1" />
                <Button disabled={pathName == ""} className='btn btn-primary w-100 mt-2 mb-2' onClick={() => searchFile()}>
                    Buscar Arquivo
                </Button>
                <TableDefault
                    columns={FilesSearchColumns}
                    hasHeader={true}
                    hasPagination={true}
                    rows={filesModal}
                    lastColumnIsAction={false}
                    isFiles={true}
                    total={totalModal}
                    changePage={searchFile}
                />
            </Modal.Body>
        </Modal>
    )
}

export default ModalSearchFileComponent
