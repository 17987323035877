import { api } from "../../../services/axios";
import { useEffect, useState } from "react";
import Loader from "../../../components/Shared/Loader";
import { useParams } from "react-router-dom";
import ProductTecnacValues, { ProductTecnacProps } from "../../../components/Products/ProductTecnac";
import { toast } from "react-toastify";


export default function ProductTecnac() {
    const [loading, setLoading] = useState(false);
    const [productWithTecnac, setProductWithTecnac] = useState({} as ProductTecnacProps);
    const { id } = useParams();

    useEffect(() => {
        async function getProduct() {
            setLoading(true);
            try {
                const result = await api.get(`ProductTecnac/GetWithProduct/${id}`);
                const response = await result.data;

                const productResult = response.data as ProductTecnacProps;
                productResult.product.startManufacturingDate = productResult.product.startManufacturingDate != undefined ? new Date(new Date(productResult.product.startManufacturingDate?.toString().split("T")[0]).setHours(24)) : null;
                productResult.product.pleitoDate = productResult.product.pleitoDate != undefined ? productResult.product.pleitoDate?.toString().split("T")[0] : productResult.product.pleitoDate;
                productResult.product.publishDate = productResult.product.publishDate != undefined ? productResult.product.publishDate?.toString().split("T")[0] : productResult.product.publishDate;
                productResult.product.licenseDate = productResult.product.licenseDate != undefined ? productResult.product.licenseDate?.toString().split("T")[0] : productResult.product.licenseDate;

                if(productResult.tecnac != null){
                    productResult.tecnac.date = productResult.tecnac.date?.toString().split("T")[0];
                }

                setProductWithTecnac(response.data);

            }
            catch (error: any) {
                toast.error(error);
            }
            finally {
                setLoading(false);
            }
        }

        getProduct();
    }, []);


    return (
        productWithTecnac?.product?.name ? <ProductTecnacValues  productWithTecnac={productWithTecnac} /> : <Loader />
    );
}