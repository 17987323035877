import { TextField, Typography } from "@mui/material"

import { FieldError, UseFormGetValues, UseFormRegister } from "react-hook-form";

export interface ITypographyCustomProps {
    label: string,
    field: string,
    register: UseFormRegister<any>,
    getValues: UseFormGetValues<any>,
    fieldErrors?: FieldError | undefined,
}

export const TypographyCustom: React.FC<ITypographyCustomProps> = ({ label, field, register, getValues, fieldErrors }) => {

    return (
        <>
            <Typography>
                <div className="row mb-1 mt-2">
                    <div className='col-12'>
                        <TextField
                            label={label}
                            variant="outlined"
                            className='form-control h-100'
                            multiline
                            rows={5}
                            {...register(field)}
                            defaultValue={getValues(field)}
                            error={!!fieldErrors}
                            helperText={fieldErrors?.message}
                        />
                    </div>
                </div>
            </Typography>
        </>
    )
}