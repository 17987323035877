import { useState } from 'react';

function useLoading() {
    const [loading, setLoading] = useState(false);

    function toggleLoading(data: boolean) {
        setLoading(data);
    }

    return {
        loading,
        toggleLoading,
    }
};

export default useLoading;