import { FormEvent, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../components/Shared/Loader";
import { useCompany } from "../../hooks/useCompany";
import { api } from "../../services/axios";
import { useAuth } from "oidc-react";
import { FaSync } from "react-icons/fa";
import { toast } from "react-toastify";

interface CompanyIdentityProps {
    id: string;
    title: string;
    taxNumber: string;
    counting: number;
    serviceId: string;
    year: number;
}

const countingName = [
    'Anual',
    'Trimestral',
    'Mensal'
]

export default function ByPass() {
    const user = useAuth();
    const { alternancy } = useParams();
    const [companies, setCompanies] = useState<CompanyIdentityProps[]>([]);
    const { handleSelectCompany } = useCompany();
    const [loading, setLoading] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [year, setYear] = useState(getCurrentYear());
    const [currentYear, setCurrentYear] = useState(getCurrentYear());
    const [disabledButton, setDisabledButton] = useState<boolean>(false);

    let navigate = useNavigate();

    function getCurrentYear() {
        const nowDate = Date.now();
        return new Date(nowDate).getFullYear();
    }

    function getYears() {
        const nowDate = Date.now();
        const nowYear = new Date(nowDate).getFullYear();

        const years: number[] = [];

        for (let index = -5; index < 5; index++) {
            const element = nowYear + index;
            years.push(element);
        }
        return years;
    }

    async function handleClick(id: string) {
        setLoading(true);
        try {
            await handleSelectCompany(id, alternancy != null ? true : false).then((response) => {
                navigate(`/${response.serviceId}/home`);
            });
        }
        catch (err: any) {
            toast.error(err);
        }
        finally {
            setLoading(false);
        }

    }

    function submitForm(e: FormEvent) {
        e.preventDefault();
    }

    async function getAllCompanies() {
        try {
            if (user.userData) {
                setLoading(true);
                const result = await api.get(`/TenantsCompanies/GetCompaniesWithServices/year=${year}&email=${user.userData.profile.email}&name=${user.userData.profile.given_name}`, {
                    headers: {
                        Authorization: `Bearer ${user?.userData?.access_token}`,

                    }
                });

                const response = await result.data;

                if (response.statusCode === 200) {
                    const data = response.data;
                    setCompanies(data);
                    setCurrentYear(year);
                }
            }
        }
        catch (error: any) {
            console.log('getAllCompanies error', error);
        }
        finally {
            setTimeout(
                function () {
                    setLoading(false);
                }, 500);

        }
    }

    async function updateUserRelation() {
        setLoading(true);

        try {

            const response = await api.post(`/TenantsCompanies/SyncFiConnectCurrentUserCompanies`);

            const result = await response.data;

            if (result.statusCode === 200) {
                toast.success('Usuário sincronizado com sucesso!');
                await getAllCompanies();
            }
        }
        catch (error: any) {

        }
        finally {
            setLoading(false);
        }
    }

    const filteredCompanies = useMemo(() => {
        var searchCompanies = searchText.toUpperCase();
        return companies.filter((company) => company.title.toUpperCase().includes(searchCompanies));
    }, [searchText, companies]);

    useEffect(() => {
        getAllCompanies();
    }, [user, year]);

    useEffect(() => {
        if (disabledButton) {
            setTimeout(() => setDisabledButton(false), 100000);
        }
    }, [disabledButton]);

    return (
        <>
            {loading && <Loader />}
            <div className='w-100 h-100'>
                <div className='container'>
                    <div className='bg-light m-4 border p-4'>
                        <div className="d-flex justify-content-end">
                            <button disabled={disabledButton} className="btn btn-success" onClick={() => { setDisabledButton(true); updateUserRelation() }}>
                                Sincronizar empresas <FaSync className="cursor-pointer ms-1" />
                            </button>
                        </div>
                        <div className='d-flex align-items-center justify-content-center mt-4 mb-4'>
                            <h2>Selecione um serviço da lista</h2>
                        </div>
                        <div className=''>
                            <form onSubmit={submitForm} className='row mb-4'>
                                <div className='col-lg-8 col-sm-12 mt-sm-0'>
                                    <input
                                        placeholder='Procurar por empresa'
                                        className='form-control me-4'
                                        type='text'
                                        onChange={(e) => setSearchText(e.target.value)}
                                    />
                                </div>
                                <div className='col-lg-4 col-sm-12 mt-2 mt-sm-2 mt-lg-0 mt-md-2'>
                                    <div className='d-flex align-items-center w-100 justify-content-md-start justify-content-lg-end'>
                                        <strong className='me-4 text-nowrap'>Ano Fiscal</strong>
                                        <select className='form-select w-lg-50 w-md-100' onChange={(e) => setYear(parseInt(e.target.value))}>
                                            {getYears().map(y => (
                                                <option key={y} value={y} selected={y == year}>{y}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </form>
                            {companies.length > 0 &&
                                <div className='table-responsive'>
                                    <table className='table table-bordered table-hover mb-4 table-white'>
                                        <tbody>
                                            {filteredCompanies?.map(company => {
                                                return (
                                                    <tr key={company.id} className='cursor-pointer' onClick={() => handleClick(company.serviceId)}>
                                                        <th scope="row">
                                                            <span>
                                                                {company.title}
                                                            </span>
                                                        </th>
                                                        <td>
                                                            <span>
                                                                {currentYear}
                                                            </span>
                                                        </td>
                                                        <td>
                                                            <span>
                                                                {countingName[company.counting]}
                                                            </span>
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}