import React, { createContext, ReactNode, useContext, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import { api, CommonHeaderProperties } from '../services/axios';
import { getSession, setSession } from "../utils/storageSession";
import Loader from "../components/Shared/Loader";
import { toast } from "react-toastify";
import { CompanySessionName, TokenSessionName } from "../Static/TokenNames";

interface CompanyProviderProps {
    children: ReactNode;
}

export interface CompanyTenantsProps {
    areaName: string;
    crmAccountId: any;
    id: string;
    taxNumber: string;
    companyId: string;
    title: string;
    serviceId: string;
    counting: number;
    year: number;
    uf: string;
    photoUrl: string | null;
    region: string | null;
    obligation: number;
    invest: number;
    billing: number;
    branchRegionHasSuframa: boolean | null;
    cnpj: string | null;
    incentivizedBillingForecastZFM: number | null;
    incentivizedBillingForecastLINacional: number | null;
    cfopIsCustom: boolean;
}

export interface CompanyContextProps {
    handleSelectCompany: (id: string, alternancy: boolean) => Promise<CompanyTenantsProps>;
    handleUpdateServiceByHeader: (newCounting: number, newYear: number, serviceId: string, incentivizedBillingForecastLINacional: number, incentivizedBillingForecastZFM: number) => void;
    changeCompanyPhoto: (photoUrl: string) => void;
    updateHeaderValues: () => Promise<void>;
    getObligationTotalValue: () => Promise<void>;
    obligationTotal: number;
    company: CompanyTenantsProps;
}

export const CompanyContext = createContext({} as CompanyContextProps);

export function CompanyProvider({ children }: CompanyProviderProps) {
    const { getRolesByUser } = useAuth();
    const { serviceId } = useParams();
    const [loading, setLoading] = useState<boolean>(false);
    const [obligationTotal, setObligationTotal] = useState<number>(0);

    const [company, setCompany] = useState<CompanyTenantsProps>(() => {
        const result = getCompanyBySession();

        return result;
    });

    async function handleSelectCompany(id: string, alternancy: boolean) {

        const result = await api.get(`/TenantsCompanies/GetTenant/${id}/${alternancy}`);

        const response = await result.data;

        const data: CompanyTenantsProps = response.data;

        await getRolesByUser(data.id);

        saveCompany(data);

        return data;
    }

    async function updateHeaderValues() {
        const getBilling = await api.get(`/Company/GetGetIncentivizedBillingAccomplished/${company.serviceId}`);
        const getInvest = await api.get(`/investValue/GetConsolidationAll/${company.serviceId}`);

        const changedCompany = { ...company, billing: getBilling.data, invest: getInvest.data };
        saveCompany(changedCompany);
    }

    async function getObligationTotalValue() {
        try {
            await api.get(`/Obligation/GetObligationTotalValue?serviceId=${company.serviceId}`).then(resp => {
                if (resp.data.statusCode === 200) {
                    setObligationTotal(resp.data.data);
                }
            });
        } catch (error: any) {
            toast.error(error);
        }
    }

    useEffect(() => {
        if (company.id != "" && company.id != null && company.id != undefined) {
            getObligationTotalValue();
        }
    }, [company]);

    function getCompanyBySession(): CompanyTenantsProps {
        const companySession = getSession(CompanySessionName);

        if (companySession) {
            const companyParsed = JSON.parse(companySession);

            return companyParsed;
        }
        else {
            if (serviceId) {
                handleSelectCompany(serviceId, false);
            }
        }

        return {} as CompanyTenantsProps;
    }

    function saveCompany(company: CompanyTenantsProps) {
        setCompany(company);
        setSession(CompanySessionName, JSON.stringify(company));
    }

    function handleUpdateServiceByHeader(newCounting: number, newYear: number, serviceId: string, incentivizedBillingForecastLINacional: number, incentivizedBillingForecastZFM: number) {
        if (company.id) {
            const changedCompany = { ...company, counting: newCounting, year: newYear, serviceId: serviceId, 
                incentivizedBillingForecastLINacional: incentivizedBillingForecastLINacional, 
                incentivizedBillingForecastZFM: incentivizedBillingForecastZFM };
            saveCompany(changedCompany);
        }
    }

    function changeCompanyPhoto(photoUrl: string) {
        const changedCompany = { ...company, photoUrl: photoUrl };
        saveCompany(changedCompany);
    }

    const value = useMemo(
        () => ({
            handleSelectCompany,
            handleUpdateServiceByHeader,
            changeCompanyPhoto,
            updateHeaderValues,
            obligationTotal,
            company,
            getObligationTotalValue
        }),
        [company, obligationTotal]
    );

    return (
        <>
            {loading && <Loader />}
            <CompanyContext.Provider value={value}>
                {children}
            </CompanyContext.Provider>
        </>
    );
}