import { useCompany } from "../../../hooks/useCompany";
import { CreateOutlined, DeleteOutlineOutlined } from "@mui/icons-material";
import useModal from "../../../hooks/useModal";
import { Link } from "react-router-dom";
import DeleteModal from "../../Shared/DeleteModal";

interface ProjectActionProps {
    lastYearProject: number;
    lastYearProjectImport: number;
    lastYearId: string;
    id: string;
    typology: number;
    action: any;
    canDelete: boolean;
}

export default function ProjectAction({ lastYearProject, lastYearProjectImport, lastYearId, id, typology, action, canDelete }: ProjectActionProps) {
    const { isShowing, toggle } = useModal();
    const { company } = useCompany();

    const params = lastYearId != "0"
        ? `${lastYearProject}/${lastYearProjectImport}/${lastYearId}/${id}/${typology}`
        : `${lastYearProject}/${lastYearProjectImport}/${id}/${typology}`;

    // const params = `${lastYearProject}/${lastYearProjectImport}/${lastYearId}/${id}/${typology}`
    
    return (
        <>
            <td className="col">
                <div className="d-flex justify-content-between align-items-center">
                    <Link title="Consultar/Editar" to={`/${company.serviceId}/internalProjects/tabs/${params}`}>
                        <CreateOutlined width={20} color="info" />
                    </Link>
                    {canDelete ? <DeleteOutlineOutlined className='ms-2 cursor-pointer' width={20} onClick={toggle} color="error" /> : <div className="ms-2 div-trash-size"></div>}
                </div>
            </td>
            <DeleteModal title='Excluir Projeto' text='Tem certeza que deseja excluir este projeto?' isShowing={isShowing} hide={toggle} action={action} />

        </>
    )
}