import * as yup from 'yup';

export const schema = yup.object().shape({
    name: yup.string()
        .required("O Campo Nome do Produto é obrigatório").trim()
        .max(250, 'Por favor, insira um valor entre 5 e 250 caracteres')
        .min(5, 'Por favor, insira um valor entre 5 e 250 caracteres'),

    cnpj: yup.string().nullable()
        .when('licenseDate', {
            is: (licenseDate: string | null) => licenseDate != null && licenseDate != "",
            then: yup.string()
                .required('O Campo CNPJ da Unidade Fabricante é obrigatório')
                .max(20, 'Por favor, insira um cnpj válido')
                .min(14, 'Por favor, insira um cnpj válido')
                .nullable()
        }).transform((_, val) => val !== '' ? val : null),

    hasPatents: yup.string()
        .nullable(true)
        .when('licenseDate', {
            is: (licenseDate: string | null) => licenseDate != null && licenseDate != "",
            then: yup.string()
                .required('O Campo Patentes é obrigatório')
                .nullable(true)
        }),
    // .transform((_, val) => typeof val == "string" && val != "" ? val === "true" ? true : false : null),

 
    companyWillComplyPPB: yup.string().nullable(true)
    .when('licenseDate', {
        is: (licenseDate: string | null) => licenseDate != null && licenseDate != "",
        then: yup.string()
            .required('O Campo Empresa Cumprirá o PPB? é obrigatório')
            .nullable(true)
    }),

    hasAccessory: yup.string().nullable(true)
    .when('licenseDate', {
        is: (licenseDate: string | null) => licenseDate != null && licenseDate != "",
        then: yup.string()
            .required('O Campo Possui Acessórios? é obrigatório')
            .nullable(true)
    }),

    hasContract: yup.string().nullable(true)
        .when('licenseDate', {
            is: (licenseDate: string | null) => licenseDate != null && licenseDate != "",
            then: yup.string()
                .required('O Campo Contrato de Assunção é obrigatório')
                .nullable(true)
        }),

    isMicroComputer: yup.string()
        .nullable(true)
        .when('licenseDate', {
            is: (licenseDate: string | null) => licenseDate != null && licenseDate != "",
            then: yup.string()
                .required('O Campo Microcomputador é obrigatório')
                .nullable(true)
        }),

    ncm: yup.string().nullable(true)
        .test(
            "onlyNumbers", // type of the validator (should be unique)
            "Este campo aceita apenas numeros!", // error message
            (value) => /^\d+$/.test(value?.toString() ?? "")
        )
        .when('licenseDate', {
            is: (licenseDate: string | null) => licenseDate != null && licenseDate != "",
            then: yup.string()
                .required('O Campo NCM é obrigatório')
                .nullable(true)
                .test(
                    "onlyNumbers", // type of the validator (should be unique)
                    "Este campo aceita apenas numeros!", // error message
                    (value) => /^\d+$/.test(value?.toString() ?? "")
                )
        })
        .transform((_, val) => val !== '' ? val : null),

    technologyOwner: yup.string().nullable(true)
        .when('licenseDate', {
            is: (licenseDate: string | null) => licenseDate != null && licenseDate != "",
            then: yup.string()
                .required('O Campo Dono da Tecnologia é obrigatório')
                .nullable(true)
        }).transform((_, val) => val !== '' ? val : null),

    technicianDescription: yup.string().nullable(true)
        .when('licenseDate', {
            is: (licenseDate: string | null) => licenseDate != null && licenseDate != "",
            then: yup.string()
                .required('O Campo Descrição de Caracteristicas Técnicas é obrigatório')
                .nullable(true)
        }).transform((_, val) => val !== '' ? val : null),

    ppbDescription: yup.string().nullable(true)
        .when('licenseDate', {
            is: (licenseDate: string | null) => licenseDate != null && licenseDate != "",
            then: yup.string()
                .required('O Campo Descrição de PPB é obrigatório')
                .nullable(true)
        }).transform((_, val) => val !== '' ? val : null),

    manufacturingDescription: yup.string().nullable(true)
        .when('licenseDate', {
            is: (licenseDate: string | null) => licenseDate != null && licenseDate != "",
            then: yup.string()
                .required('O Campo Descrição de Fabricação é obrigatório')
                .nullable(true)
        }).transform((_, val) => val !== '' ? val : null),

    observation: yup.string().nullable(true)
        .when('licenseDate', {
            is: (licenseDate: string | null) => licenseDate != null && licenseDate != "",
            then: yup.string()
                .required('O Campo Observação é obrigatório')
                .nullable(true)
        }).transform((_, val) => val !== '' ? val : null),

    ordinanceNumber: yup.string().nullable(true)
        .when('licenseDate', {
            is: (licenseDate: string | null) => licenseDate != null && licenseDate != "",
            then: yup.string()
                .required('O Campo Número da Portaria de Habilitação é obrigatório')
                .nullable(true)
        }).transform((_, val) => val !== '' ? val : null),

    accessoryDescription: yup.string().nullable(true)
        .when('licenseDate', {
            is: (licenseDate: string | null) => licenseDate != null && licenseDate != "",
            then: yup.string()
                .required('O Campo Descrição de Acessórios é obrigatório')
                .nullable(true)
        }).transform((_, val) => val !== '' ? val : null),

    ppbOrdinance: yup.string().nullable(true)
        .when('licenseDate', {
            is: (licenseDate: string | null) => licenseDate != null && licenseDate != "",
            then: yup.string()
                .required('O Campo Descrição de Acessórios é obrigatório')
                .nullable(true)
        }).transform((_, val) => val !== '' ? val : null),


    manufacturingType: yup.number().nullable(true)
        .when('licenseDate', {
            is: (licenseDate: string | null) => licenseDate != null && licenseDate != "",
            then: yup.number()
                .required('O Campo Tipo de Fabricação é obrigatório')
                .nullable(true)
        }).transform((_, val) => val !== "" && !isNaN(val) && val !== null ? parseInt(val) : null),

    technologyOrigin: yup.number().nullable(true)
        .when('licenseDate', {
            is: (licenseDate: string | null) => licenseDate != null && licenseDate != "",
            then: yup.number()
                .required('O Campo Origem da Tecnologia é obrigatório')
                .nullable(true)
        }).transform((_, val) => val !== "" && !isNaN(val) && val !== null ? parseInt(val) : null),

    ordinanceType: yup.number().nullable(true)
        .when('licenseDate', {
            is: (licenseDate: string | null) => licenseDate != null && licenseDate != "",
            then: yup.number()
                .required('O Campo Tipo portaria TECNAC é obrigatório')
                .nullable(true)
        }).transform((_, val) => val !== "" && !isNaN(val) && val !== null ? parseInt(val) : null),

    status: yup.number().nullable(true)
        .when('licenseDate', {
            is: (licenseDate: string | null) => licenseDate != null && licenseDate != "",
            then: yup.number()
                .required('O Campo Status da Habilitação é obrigatório')
                .nullable(true)
        }).transform((_, val) => val !== "" && !isNaN(val) && val !== null ? parseInt(val) : null),

    pleitoDate: yup.date().nullable(true)
        .when('licenseDate', {
            is: (licenseDate: string | null) => licenseDate != null && licenseDate != "",
            then: yup.string()
                .required('O Campo Data do Pleito TECNAC é obrigatório')
                .nullable(true)
        }),

    licenseDate: yup.date()
        .nullable(true),

    startManufacturingDate: yup.date().nullable(true)
        .when('licenseDate', {
            is: (licenseDate: string | null) => licenseDate != null && licenseDate != "",
            then: yup.string()
                .required('O Campo Inicio da Fabricação é obrigatório')
                .nullable(true)
        }),

    publishDate: yup.date().nullable(true)
        .when('licenseDate', {
            is: (licenseDate: string | null) => licenseDate != null && licenseDate != "",
            then: yup.string()
                .required('O Campo Data da Publicação é obrigatório')
                .nullable(true)
        }),
});
