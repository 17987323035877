import { useContext } from "react";
import { AuthContext, AuthContextProps } from "../contexts/authContext";

export function useAuth() : AuthContextProps{
    const context = useContext(AuthContext);

    if(!context){
        throw new Error('Erro ao utilizar o contexto de autenticação');
    }

    return context;
}