import { useState, useContext, useEffect, createRef, Fragment } from "react";
import { Collapse } from "react-bootstrap";
import { AiOutlineClose } from "react-icons/ai";
import { GiSevenPointedStar } from "react-icons/gi";
import useModal from "../../../hooks/useModal";
import { CodeProps } from "../../../pages/ProductModels/ModelCharacterization";
import { CodeTableActions } from "../../Codes/CodeTableActions";
import DeleteModal from "../../Shared/DeleteModal";
import { TableContainer, Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material';

import './styles.css';

interface CodesTableProps {
    newCodes: CodeProps[];
    savedCodes: CodeProps[];
    removeCode: (id: string) => void;
    removeSavedCode?: (id: string) => Promise<void>
}


export function CodesTable({ newCodes, savedCodes, removeCode, removeSavedCode }: CodesTableProps) {

    const { isShowing: isShowingSingleCodeModal, toggle: toogleSingleCodeModal } = useModal();
    const codesRef = createRef<HTMLDivElement>();

    useEffect(() => {
        if (codesRef && codesRef.current) {
            const { scrollHeight, clientHeight } = codesRef.current;

            codesRef.current.scrollTo({
                left: 0, top: scrollHeight - clientHeight,
                behavior: 'smooth'
            });
        }

    }, [newCodes]);

    return (
        <>
            <TableContainer className='table-responsive table-max-height' ref={codesRef}>
                <Table className='table table-bordered table-white table-hover'>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center" className="fw-bold">Código</TableCell>
                            <TableCell align="center" className="fw-bold">Descrição Conforme NF</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {savedCodes.length === 0 && newCodes.length === 0 ? (
                            <TableRow style={{ width: '40%' }}>
                                <TableCell colSpan={12} className='text-center'>
                                    Não há códigos cadastrados no momento
                                </TableCell>
                            </TableRow>
                        ) : null}
                        {savedCodes.map((code, index) => (
                            <TableRow key={code.id}>
                                <TableCell width='40%' title={code.code}>{code.code}</TableCell>
                                <TableCell width='40%'>{code.description}</TableCell>
                                {removeSavedCode &&
                                    <CodeTableActions action={removeSavedCode} id={code.id} />
                                }
                            </TableRow>
                        ))}
                        {newCodes.map((code, index) => (
                            <TableRow key={code.id} >
                                <TableCell width='40%'>
                                    <div className='new-code' title={code.code}>
                                        {code.code}
                                        <GiSevenPointedStar size={10} />
                                    </div>
                                </TableCell>
                                <TableCell width='40%'>{code.description}</TableCell>
                                <CodeTableActions action={removeCode} id={code.id} />
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}