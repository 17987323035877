import { FormControl, MenuItem, Select } from "@mui/material";
import { AiFillInfoCircle } from "react-icons/ai";
import { inputValueLabel } from './inputValueLabel';
import { useState } from "react";
import { UseFormRegister } from "react-hook-form";
import { FiPositioningFormProps } from "../../../pages/Projects/InternalProjectsTabs/Interfaces/InternalProjectsFormProps";

interface IDynamicSelectComponetProps {
    selectLabel: string;
    cValue: "C1" | "C2" | "C3" | "C4" | "C5" | "C6" | "C7";
    register: UseFormRegister<FiPositioningFormProps>;
    selectRegister: any;
    setCritC: (id: number, valuePage: number) => void;
    setFrameworkFunciton: () => void;
    id: number;
    defaultValue: any;
}

export const DynamicSelectComponet = ({ selectLabel, cValue, register, selectRegister, setCritC, setFrameworkFunciton, id, defaultValue }: IDynamicSelectComponetProps) => {
    const [degree, setDegree] = useState<"0" | "1" | "2" | "3" | "">(defaultValue?.toString() ?? "0");

    return (
        <div className="row mb-1 mt-2 mb-2 ms-2">
            <div className='col-3 d-flex align-items-center'>
                <span className="me-2 p-1">{selectLabel}</span>
                <AiFillInfoCircle size={20} className="info-color" title={inputValueLabel[cValue]['Tooltip']} />
            </div>
            <div className='col-2'>
                <FormControl fullWidth size="small">
                    <Select value={degree} className="forms-control"
                        fullWidth
                        {...register(selectRegister)}
                        onChange={(e: any) => { setDegree(e.target.value); setCritC(id, parseInt(e.target.value)); setFrameworkFunciton() }}
                        defaultValue={defaultValue}
                    >
                        <MenuItem value="0">Grau 0</MenuItem>
                        <MenuItem value="1">Grau 1</MenuItem>
                        <MenuItem value="2">Grau 2</MenuItem>
                        <MenuItem value="3">Grau 3</MenuItem>
                    </Select>
                </FormControl>
            </div>
            <div className='col-7 ms-10'>
                <span className="selects-infos">
                    {inputValueLabel[cValue]["Selects"][degree]}
                </span>
            </div>
        </div>
    );
}