import { useState } from "react";
import { CommoditieModal } from "../../../components/Commodities/CreateCommoditielModal";
import { ProductionModal } from "../../../components/Productions/CreateProductionModal";
import { ProductModelModal } from "../../../components/Products/CreateProductModelModal";

export default function ProductionList() {
    const [modal, setModal] = useState(false);

    function toggle() {
        setModal(!modal);
    }


    return (
        <>
            <div className='card '>
                <div className='card-header'>
                    <span>I.1 Produtos {'>'} I.3 PPB</span>
                </div>
                <div className='card-body'>
                    <table className='table table-bordered table-striped table-hover mb-4'>
                        <thead>
                            <tr>
                                <th scope="col">Nome</th>
                                <th scope="col">Nacional/Importado</th>
                                <th scope="col">Tipo de Portaria</th>
                                <th scope="col">Quantidade</th>
                                <th scope="col"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th scope="row">1</th>
                                <td>Mark</td>
                                <td>Otto</td>
                                <td>@mdo</td>
                                <td>@mdo</td>
                            </tr>
                            <tr>
                                <th scope="row">2</th>
                                <td>Jacob</td>
                                <td>Thornton</td>
                                <td>@fat</td>
                                <td>@fat</td>
                            </tr>
                            <tr>
                                <th scope="row">3</th>
                                <td>@twitter</td>
                                <td>@twitter</td>
                                <td>@twitter</td>
                                <td>@twitter</td>
                            </tr>
                        </tbody>
                    </table>
                    <button className='btn btn-success w-100' onClick={toggle}>Inserir Produção</button>
                </div>
            </div>
            <ProductionModal toggle={toggle} isOpen={modal} />
        </>
    );
}