import './styles.css'
import { useState, useContext, useCallback } from "react";
import { Row } from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import { AiFillHome, AiOutlinePlus, AiOutlineSearch } from "react-icons/ai";
import { FaCloudDownloadAlt, FaTrash } from "react-icons/fa";
import { Link, useParams } from "react-router-dom";
import { FileContext } from "../../../contexts/fileContext";
import { useCompany } from "../../../hooks/useCompany";
import useModal from "../../../hooks/useModal";
import { PathModal } from "../../Path/PathModal";
import Loader from "../../Shared/Loader";
import DeleteModal from "../../Shared/DeleteModal";
import { Upload } from "../../Shared/UploadFiles/Upload";
import { FileList } from "../../Shared/UploadFiles/FileList";
import { FolderList } from "../../Shared/UploadFiles/FolderList";
import ModalSearchFileComponent from "../ModalSearchFileComponent";
import useLoading from "../../../hooks/useLoading";
import { toast } from 'react-toastify';
import { IResponseProps } from '../../../interfaces/IResponseProps';
import { SearchFileAPI } from '../../../API/FilesManagerAPI';
import { FileModel } from '../../../models/FileModel';

const folders: any = {
    0: 'Documentação Técnica',
    1: 'Documentação de Recursos Humanos',
    2: 'Documentação Financeira',
    3: 'Documentação Master'
}

export function FileManagerContent() {
    const { checkAllFiles, files, paths, currentPath, checkedFiles,
        handleToggleCheckAll, handleDeleteMassive, handleDownloadMassive,
        handleCheckFile, handleSelectSubPath, handleUploadFiles,
        changeFilePage, changePathPage, searchFiles, searchPaths } = useContext(FileContext);

    const { company } = useCompany();
    const [selectedFiles, setSelectedFiles] = useState<any[]>([]);
    const { loading, toggleLoading } = useLoading();
    const { path, folderId } = useParams();
    const { isShowing, toggle } = useModal();
    const [pathSearch, setPathSearch] = useState("");
    const [fileSearch, setFileSearch] = useState("");
    const { isShowing: isShowingDelete, toggle: toggleDelete } = useModal();
    const { isShowing: showSearchFileModal, toggle: toggleShowSearchFileModal } = useModal();
    const [pathName, setPathName] = useState<string>("");
    const [filesModal, setFilesModal] = useState<FileModel[]>([]);
    const [totalModal, setTotalModal] = useState<number>(0);

    const onDrop = useCallback((acceptedFiles: File[]) => {
        setSelectedFiles([...selectedFiles, ...acceptedFiles])
    }, [selectedFiles]);

    const onRemoveToTheList = (fileId: number) => {
        const filesFilter = [...selectedFiles];
        filesFilter.splice(fileId, 1);
        setSelectedFiles(filesFilter);
    }

    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({ onDrop });

    async function handleSendFiles() {
        toggleLoading(true);
        const pathNumber = path != undefined ? parseInt(path) : 0;
        try {
            handleUploadFiles(acceptedFiles, company.serviceId, pathNumber).then(() => {
                setSelectedFiles([])
                toggleLoading(false);
            });
        }
        catch (err: any) {
            console.log('ERROR', err);
        }
        finally {
        }
    }

    async function handleDownloadSelectedFiles() {
        toggleLoading(true);
        try {
            await handleDownloadMassive()
                .then(() => {
                    toggleLoading(false);
                });
        }
        catch (err: any) {
            console.log('ERROR', err);
        }
        finally {
        }
    }

    async function handleDeleteSelectedFiles() {
        toggleLoading(true);
        try {
            await handleDeleteMassive()
                .then(() => {
                    toggleLoading(false);
                });
        }
        catch (err: any) {
            console.log('ERROR', err);
        }
        finally {
        }
    }

    async function searchFileModal(page?: number) {
        toggleLoading(true);
        let text = pathName == null || pathName == "" ? "all" : pathName;
        try {
            await SearchFileAPI(company.serviceId, path ?? "", page ?? 1, 7, text, folderId)
                .then((resp: IResponseProps) => {
                    setFilesModal(resp.data.items);
                    setTotalModal(resp.data.total);
                })
                .finally(() => toggleLoading(false));

        } catch (error: any) {
            toast.error(error);
        }
    }

    async function handleClose() {
        setFilesModal([]);
        setPathName("");
        toggleShowSearchFileModal();
    }

    return (
        <>
            {loading && <Loader />}
            <div className='card-header d-flex justify-content-between'>
                <div className='breadCrumb'>
                    <Link to={`/${company.serviceId}/home`} className='breadCrumb-home d-flex align-center'>
                        <AiFillHome className='breadCrumb-home' />
                    </Link>
                    <span className='breadCrumb-separator'>{' > '}</span>
                    <Link to={`/${company.serviceId}/fileManager`}>
                        <span className='breadCrumb-item-actual'>Gerenciador de Documentos</span>
                    </Link>
                    <span className='breadCrumb-separator'>{' > '}</span>
                    {
                        currentPath.id && folderId
                            ?
                            <>
                                <Link to={`/${company.serviceId}/fileManager/files/${path}`}>
                                    <span className='breadCrumb-item-actual'>{path != undefined ? folders[path] : ''}</span>
                                </Link>
                                <span className='breadCrumb-separator'>{' > '}</span>
                                <span className='breadCrumb-item'>{currentPath.pathName}</span>
                            </>
                            :
                            <>
                                <span className='breadCrumb-item'>{path != undefined ? folders[path] : ''}</span>
                            </>
                    }
                </div>
                <div className='d-flex align-items-center'>
                    <button className='btn btn-success me-2' onClick={toggle}>
                        <AiOutlinePlus className='me-1' />
                        Nova Sub-Pasta
                    </button>
                    <button className='btn btn-success' onClick={toggleShowSearchFileModal}>
                        <AiOutlineSearch className='me-1' />
                        Buscador de Arquivos
                    </button>

                </div>
            </div>
            <div className='card-body'>
                <Upload
                    getInputProps={getInputProps}
                    getRootProps={getRootProps}
                    handleSendFiles={handleSendFiles}
                    onRemoveToTheList={onRemoveToTheList}
                    selectedFiles={selectedFiles}
                />
                <Row className='w-100 m-0'>
                    <FileList
                        setFileSearch={setFileSearch}
                        searchFiles={searchFiles}
                        handleToggleCheckAll={handleToggleCheckAll}
                        handleCheckFile={handleCheckFile}
                        changeFilePage={changeFilePage}
                        fileSearch={fileSearch}
                        checkAllFiles={checkAllFiles}
                        checkedFiles={checkedFiles}
                        files={files}
                        hasInternalProjects={false}
                    />
                    <FolderList
                        setPathSearch={setPathSearch}
                        searchPaths={searchPaths}
                        pathSearch={pathSearch}
                        paths={paths}
                        handleSelectSubPath={handleSelectSubPath}
                        changePathPage={changePathPage}
                        context={FileContext}
                    />
                </Row>
                {
                    checkedFiles.length > 0 || checkAllFiles
                        ?
                        <div className='d-flex mt-2'>
                            <button className='btn btn-success w-75 me-4' type='button' onClick={handleDownloadSelectedFiles}>
                                <FaCloudDownloadAlt className='me-1' />
                                Baixar arquivos
                            </button>
                            <button className='btn btn-seocndary w-25 bg-gray' onClick={toggleDelete}>
                                <FaTrash className='cursor-pointer white' />
                            </button>
                        </div>
                        :
                        null
                }
            </div>
            <DeleteModal hide={toggleDelete}
                isShowing={isShowingDelete}
                text='Tem certeza que deseja deletar todos os arquivos selecionados?'
                title='Exclusão de arquivos'
                action={handleDeleteSelectedFiles}
            />
            <ModalSearchFileComponent
                serviceId={company.serviceId}
                handleClose={handleClose}
                pathMain={path ?? ""}
                show={showSearchFileModal}
                searchFile={searchFileModal}
                filesModal={filesModal}
                totalModal={totalModal}
                pathName={pathName}
                setPathName={setPathName}
            />
            <PathModal isShowing={isShowing} hide={toggle} context={FileContext} path={path != undefined ? parseInt(path) : 0} />
        </>
    );
}