import { toast } from "react-toastify";
import ObligationTableRow from "../../components/Obligations/ObligationTableRow"
import { formatCurrencyWithSign } from "../../utils/formatCurrency"
import { useEffect, useState } from "react";
import { api } from "../../services/axios";
import { useCompany } from "../../hooks/useCompany";
import Loader from "../../components/Shared/Loader";
import { AiFillHome } from "react-icons/ai";
import { Link } from "react-router-dom";
import './styles.css';
import { maskCNPJ } from "../../utils/cnpjUtils";
import { SetFileName } from "../../utils/SetFileName";

interface ObligationModalFatherResponse {
    type: string;
    obligationModal: ObligationModalResponse[];
}

export interface ObligationModalResponse {
    name: string,
    cnpj: string,
    sales: number,
    returns: number,
    incentivizedAcquisitions: number,
    total: number,
    obligation: number,
    listChild: ObligationModalChildResponse[]
}

interface ObligationModalChildResponse {
    ncm: string,
    percentage: string,
    sales: number,
    returns: number,
    incentivizedAcquisitions: number,
    total: number,
    obligation: number
}

export default function Obligations() {
    const { company } = useCompany();
    const [loading, setLoading] = useState<boolean>(false);
    const [obligations, setObligations] = useState<ObligationModalResponse[]>([]);
    const [obligationsZFM, setObligationsZFM] = useState<ObligationModalResponse[]>([]);
    const [cnpjSelected, setCnpjSelected] = useState<string>("");
    const [cnpjs, setCnpjs] = useState<string[]>([]);

    async function getAllObligations() {
        try {
            setLoading(true);

            await api.get(`/Obligation/GetObligations?serviceId=${company.serviceId}`).then(resp => {
                if (resp.data.statusCode === 200) {
                    var data = resp.data.data as ObligationModalFatherResponse[];
                    var obligationsLin = data.filter(x => x.type === "lin")[0].obligationModal;
                    var obligationsZfm = data.filter(x => x.type === "zfm")[0].obligationModal;
                    setObligations(obligationsLin);
                    setObligationsZFM(obligationsZfm);
                    getAllCNPJs();
                }
            });
        } catch (error: any) {
            toast.error(error);
        } finally {
            setLoading(false);
        }
    }

    async function downloadExcelConsolidation() {
        try {
            setLoading(true);

            await api.get(`/Obligation/DownloadAllDataConsolidation?serviceId=${company.serviceId}&isConsolidation=${cnpjSelected === "" ? true : false}&cnpjSelected=${cnpjSelected === "" ? "\"\"" : cnpjSelected}`, {
                responseType: 'blob'
            }).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', SetFileName(company.title,company.year.toString(), "ExcelConsolidado", "xlsx"));
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            });
        } catch (error: any) {
            toast.error(error);
        } finally {
            setLoading(false);
        }
    }

    async function getAllCNPJs() {
        try {
            setLoading(true);

            await api.get(`/Obligation/GetCNPJsToDownload?serviceId=${company.serviceId}`).then((resp) => {
                if (resp.data.statusCode === 200) {
                    setCnpjs(resp.data.data);
                }
            });
        } catch (error: any) {
            toast.error(error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        getAllObligations();
    }, []);

    return (
        <>
            {loading && <Loader />}
            <div className='card '>
                <div className='card-header'>
                    <div className='breadCrumb'>
                        <Link to={`/${company.serviceId}/home`} className='breadCrumb-home d-flex align-center'>
                            <AiFillHome className='breadCrumb-home' />
                        </Link>
                        <span className='breadCrumb-separator'>{' > '}</span>
                        <span>Obrigações</span>
                    </div>
                </div>

                <div className='card-body'>
                    <div className="d-flex align-items-center w-100 justify-content-end">
                        <select onChange={e => setCnpjSelected(e.target.value)} className="form-select w-25">
                            <option value="" selected>Consolidado</option>
                            {cnpjs.map((cnpj, index) => {
                                return (
                                    <option value={cnpj} key={index}>{maskCNPJ(cnpj)}</option>
                                )
                            })}
                        </select>
                        <button onClick={downloadExcelConsolidation} className="btn btn-primary ms-2">Baixar Excel</button>
                    </div>
                    {obligations.length > 0 &&
                        <div>
                            <span className='title-LI'>Lei de Informática Nacional</span>
                            <div className='block-LI mb-3 mt-2 d-flex flex-column w-100'>
                                <span className="title-inside">Faturamento Incentivado</span>
                                <table className="table table-responsive table-borderless mt-2">
                                    <thead>
                                        <tr>
                                            <th>CNPJ</th>
                                            <th>Vendas</th>
                                            <th>Devoluções</th>
                                            <th>Total</th>
                                            <th>Obrigação</th>
                                        </tr>
                                    </thead>
                                    {obligations.sort().map((obligation, index) => {
                                        return (
                                            <ObligationTableRow type={"obligation"} key={index} index={index} obligation={obligation} />
                                        )
                                    })}
                                </table>
                                <div className='total-container-obligation'>
                                    <span>Total</span>
                                    <span>{formatCurrencyWithSign(obligations.reduce((a, v) => a = a + v.obligation, 0).toFixed(2))}</span>
                                </div>
                            </div>
                        </div>
                    }
                    {obligationsZFM.length > 0 &&
                        <div>
                            <span className='title-LI'>Lei de Informática ZFM</span>
                            <div className='block-LI mt-2 d-flex flex-column w-100'>
                                <span className="title-inside">Faturamento Incentivado</span>
                                <table className="table table-responsive table-borderless mt-2">
                                    <thead>
                                        <tr>
                                            <th>CNPJ</th>
                                            <th>Vendas</th>
                                            <th>Devoluções</th>
                                            <th>Aquisições Incentivadas</th>
                                            <th>Total</th>
                                            <th>Obrigação</th>
                                        </tr>
                                    </thead>
                                    {obligationsZFM.sort().map((obligation, index) => {
                                        return (
                                            <ObligationTableRow type={"obligationZFM"} key={index} index={index} obligation={obligation} />
                                        )
                                    })}
                                </table>
                                <div className='total-container-obligation'>
                                    <span>Total</span>
                                    <span>{formatCurrencyWithSign(obligationsZFM.reduce((a, v) => a = a + v.obligation, 0).toFixed(2))}</span>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </>
    )
}