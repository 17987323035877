import { useEffect, useState } from "react";
import { AiFillHome, AiOutlinePlus } from "react-icons/ai"
import { Link, useNavigate, useParams } from "react-router-dom"
import { CreditCard } from "../../../components/Credit/CreditCard"
import { useCompany } from "../../../hooks/useCompany";
import { api } from "../../../services/axios";
import './styles.css';
import Loader from "../../../components/Shared/Loader";
import { toast } from "react-toastify";
import { CreditsList } from "../../../models/creditsList";
import { useCan } from '../../../hooks/useCan';

export function Credit() {
    const navigate = useNavigate();
    const { company } = useCompany();
    const [credits, setCredits] = useState<CreditsList[]>();
    const [buttonShow, setButtonShow] = useState(true);
    const { serviceId } = useParams();
    const [loading, setLoading] = useState<boolean>(false);
    //const permissionMaster = useCan({ rolesCan: ["Master"] });
    const permissionFinancial = useCan({ rolesCan: ["Financial"] });

    function verifyCreateCreditButtonCanShow(data: CreditsList[]) {

        if (data?.some(x => x.countings.includes('0')))
            return false;

        if (data?.some(x =>
            x.countings.includes('0')
            && x.countings.includes('1')
            && x.countings.includes('2')
            && x.countings.includes('3')
            && x.countings.includes('4')))
            return false;

        return true;
    }

    async function getCredits() {
        try {
            setLoading(true);

            const result = await api.get(`credit/getbyservice/${serviceId}`);

            const response = await result.data;

            const data = response.data;

            if (response.statusCode === 200) {
                setCredits(data);
                setButtonShow(verifyCreateCreditButtonCanShow(data));
            }
        } catch (error: any) {
            toast.error(error);
        } finally {
            setLoading(false);
        }
        
    }
   
    useEffect(() => {
        getCredits();       
    }, []);

    return (
        <div className='card'>
            {loading && <Loader />}
            <div className='card-header'>
                <div className='breadCrumb'>
                    <Link to={`/${company.serviceId}/home`} className='breadCrumb-home d-flex align-center'>
                        <AiFillHome className='breadCrumb-home' />
                    </Link>
                    <span className='breadCrumb-separator'>{' > '}</span>
                    <span className='breadCrumb-item'>V. Credito financeiro</span>
                </div>
            </div>
            <div className='card-body'>
                <div className='p-4 grid-container w-100'>
                    {credits?.map((credit, index) => {
                        return (
                            <div className='credit-card card p-3' key={index}>
                                <span hidden></span>
                                <CreditCard
                                    id={credit.id}
                                    hasFile={credit.creditReceiptFilePath != undefined && credit.creditCertificateFilePath != undefined}
                                    countings={credit.countings}
                                    creditValue={credit.creditValue}
                                    setLoading={setLoading}
                                    cnpj={credit.cnpj ?? company.cnpj}
                                    validated={credit.validated}
                                    getCredits={getCredits}
                                />
                            </div>
                        )
                    })}
                    {
                        buttonShow  && 
                            <div
                            title='Criar Crédito Financeiro' className='w-100 card credit-card p-3 d-flex align-items-center justify-content-center cursor-pointer'
                            onClick={() => navigate(`/${company.serviceId}/credit/create`)}
                        >
                            <AiOutlinePlus size={30} />
                        </div> }
                </div>
            </div>
        </div>
    )
}