import { TextField } from "@mui/material";
import { UseFormRegister } from 'react-hook-form';
import { EconomicCurrencyInput } from "..";

interface ICustomTextFieldProps {
    hasTecnac?: boolean;
    name: string;
    getValues: any;
    register: UseFormRegister<any>;
}

export default function CustomTextField ({hasTecnac, name, register, getValues}:ICustomTextFieldProps){
    return(
        <TextField
        {...register(name)}
        value={getValues(name)}
        disabled={hasTecnac==true}
        
        InputProps={{
            inputComponent: EconomicCurrencyInput as any
          }}
        />
    )
}