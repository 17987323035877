import { useContext } from "react";
import { FaPen, FaTrash } from "react-icons/fa";
import useModal from "../../../hooks/useModal";
import DeleteModal from "../../Shared/DeleteModal";
import { EditPathModal } from "../EditPathModal";

interface PathActionsProps {
    id: string;
    pathName: string;
    context: React.Context<any>;
    internalProjectsId?: string;
}

export function PathActions({ id, pathName, context, internalProjectsId }: PathActionsProps) {
    const { handleRemovePath } = useContext(context);
    const { isShowing, toggle } = useModal();
    const { isShowing : isShowingEditModal, toggle : toggleEditModal } = useModal();


    return (
        <>
            <FaPen className='cursor-pointer me-2' onClick={toggleEditModal} />
            <FaTrash className='cursor-pointer' onClick={toggle} />
            <DeleteModal title='Excluir Sub-Pasta' text='Tem certeza que deseja excluir esta Sub-Pasta?' isShowing={isShowing} hide={toggle} action={() => handleRemovePath(id)} />
            <EditPathModal oldPathName={pathName} hide={toggleEditModal} id={id} isShowing={isShowingEditModal} context={context} internalProjectsId={internalProjectsId} />
        </>
    )
}