import { Box, Card, CardContent, Button, TextField, Typography } from '@mui/material';
import { register } from "../../../../serviceWorkerRegistration";
import * as yup from 'yup';
import { yupResolver } from "@hookform/resolvers/yup";
import { ModelEconomicDataFormsProps } from '..';
import { useForm } from 'react-hook-form';
import { useCan } from '../../../../hooks/useCan';
import { EconomicCurrencyInput } from '../../../../components/Shared/CustomCurrencyInput';
import { formatBRLCurrencyToFloat } from '../../../../utils/formatCurrency';


const dataSchema = yup.object().shape({
    quantityProduced: yup.number().nullable(true).transform((_, val) => val !== '' && val ? formatBRLCurrencyToFloat(val) : null),
    grossRevenue: yup.number().nullable(true).transform((_, val) => val !== '' && val ? formatBRLCurrencyToFloat(val) : null),
    exports: yup.number().nullable(true).transform((_, val) => val !== '' && val ? formatBRLCurrencyToFloat(val) : null),
    additionalQuantity: yup.number().nullable(true).transform((_, val) => val !== '' && val ? formatBRLCurrencyToFloat(val) : null),
});

const schema = yup.object().shape({
    lastYear: dataSchema.nullable(true),
    baseYear: dataSchema.nullable(true),
    nextYear: dataSchema.nullable(true)
});

interface ProductObservationFormDataProps {
    economicData: ModelEconomicDataFormsProps;
    observationField: string;
    setObservationField: React.Dispatch<React.SetStateAction<string>>;
    handleCreateEconomicData: (data: ModelEconomicDataFormsProps) => Promise<void>;
    handleUpdateEconomicData: (data: ModelEconomicDataFormsProps) => Promise<void>;
}

export function ProductionObservationFormData({ economicData, observationField, setObservationField, handleCreateEconomicData, handleUpdateEconomicData }: ProductObservationFormDataProps) {
    const permissionFinancial = useCan({ rolesCan: ["Financial"] });
    const { register, handleSubmit, control, formState: { errors } } = useForm<ModelEconomicDataFormsProps>({
        resolver: yupResolver(schema),
        defaultValues: economicData
    });
    const { getValues } = useForm<ModelEconomicDataFormsProps>({
        defaultValues: economicData
    });

    return (
        <CardContent>
            {!permissionFinancial ?
                <form onSubmit={handleSubmit(economicData?.nextYear?.id ? handleUpdateEconomicData : handleCreateEconomicData)}>
                    <Box display='flex' justifyContent='flex-end' mb={2}>
                        <Box className='col-2 p-2'>
                            <Typography variant='h4'>Ano Anterior</Typography>
                        </Box>
                        <Box className='col-2 p-2'>
                            <Typography variant='h4'>Ano Corrente</Typography>
                        </Box>
                        <Box className='col-2 p-2'>
                            <Typography variant='h4'>Próximo Ano</Typography>
                        </Box>
                    </Box>
                    <Box display='flex' justifyContent='flex-end' mb={2}>
                        <Box display='flex' justifyContent='flex-end' mb={2}>
                            <Typography className='h1 mt-4'>Quantidade Produzida</Typography>
                        </Box>
                        <Box className='col-2 p-2'>
                            <TextField placeholder='0,00' {...register('lastYear.quantityProduced')}
                                value={getValues('lastYear.quantityProduced')}
                                InputProps={{
                                    inputComponent: EconomicCurrencyInput as any
                                }}/>
                        </Box>
                        <Box className='col-2 p-2'>
                            <TextField placeholder='0,00' {...register('baseYear.quantityProduced')}
                                value={getValues('baseYear.quantityProduced')}
                                InputProps={{
                                    inputComponent: EconomicCurrencyInput as any
                            }}/>
                        </Box>
                        <Box className='col-2 p-2'>
                            <TextField placeholder='0,00' {...register('nextYear.quantityProduced')} 
                                value={getValues('nextYear.quantityProduced')}
                                InputProps={{
                                    inputComponent: EconomicCurrencyInput as any
                            }}/>
                        </Box>
                    </Box>
                    <Box display='flex' justifyContent='flex-end' mb={2}>
                        <Box display='flex' justifyContent='flex-end' mb={2}>
                            <Typography className='h2 mt-4'>Faturamento Bruto (R$)</Typography>
                        </Box>
                        <Box className='col-2 p-2'>
                            <TextField placeholder='0,00' {...register('lastYear.grossRevenue')}
                                value={getValues('lastYear.grossRevenue')}
                                InputProps={{
                                    inputComponent: EconomicCurrencyInput as any
                            }} />
                        </Box>
                        <Box className='col-2 p-2'>
                            <TextField placeholder='0,00' {...register('baseYear.grossRevenue')}
                                value={getValues('baseYear.grossRevenue')}
                                InputProps={{
                                    inputComponent: EconomicCurrencyInput as any
                            }} />
                        </Box>
                        <Box className='col-2 p-2'>
                            <TextField placeholder='0,00' {...register('nextYear.grossRevenue')}
                                value={getValues('nextYear.grossRevenue')}
                                InputProps={{
                                    inputComponent: EconomicCurrencyInput as any
                            }} />
                        </Box>
                    </Box>
                    <Box display='flex' justifyContent='flex-end' mb={2}>
                        <Box display='flex' justifyContent='flex-end' mb={2}>
                            <Typography className='h2 mt-4'>Exportações (U$)</Typography>
                        </Box>
                        <Box className='col-2 p-2'>
                            <TextField placeholder='0,00' {...register('lastYear.exports')}
                                value={getValues('lastYear.exports')}
                                InputProps={{
                                    inputComponent: EconomicCurrencyInput as any
                            }} />
                        </Box>
                        <Box className='col-2 p-2'>
                            <TextField placeholder='0,00' {...register('baseYear.exports')}
                                value={getValues('baseYear.exports')}
                                InputProps={{
                                    inputComponent: EconomicCurrencyInput as any
                            }} />
                        </Box>
                        <Box className='col-2 p-2'>
                            <TextField placeholder='0,00' {...register('nextYear.exports')}
                                value={getValues('nextYear.exports')}
                                InputProps={{
                                    inputComponent: EconomicCurrencyInput as any
                            }} />
                        </Box>
                    </Box>
                    <Box display='flex' justifyContent='flex-end' mb={2}>
                        <Box display='flex' justifyContent='flex-end' mb={2}>
                            <Typography className='h2 mt-4'>Investimentos Adicionais (R$)</Typography>
                        </Box>
                        <Box className='col-2 p-2'>
                            <TextField placeholder='0,00' {...register('lastYear.additionalQuantity')}
                                value={getValues('lastYear.additionalQuantity')}
                                InputProps={{
                                    inputComponent: EconomicCurrencyInput as any
                            }} />
                        </Box>
                        <Box className='col-2 p-2'>
                            <TextField placeholder='0,00' {...register('baseYear.additionalQuantity')}
                                value={getValues('baseYear.additionalQuantity')}
                                InputProps={{
                                    inputComponent: EconomicCurrencyInput as any
                            }} />
                        </Box>
                        <Box className='col-2 p-2'>
                            <TextField placeholder='0,00' {...register('nextYear.additionalQuantity')}
                                value={getValues('nextYear.additionalQuantity')}
                                InputProps={{
                                    inputComponent: EconomicCurrencyInput as any
                            }} />
                        </Box>
                    </Box>

                    <Box mt={4} mb={4}>

                        <TextField
                            label="Observações Sobre a Produção e as Etapas de Cumprimento do PPB"
                            className='form-control'
                            multiline
                            rows={6}
                            defaultValue={observationField == null ? '' : observationField}
                            onChange={(e) => setObservationField(e.target.value)}
                            style={{ height: 'auto' }} />
                    </Box>
                    <div className='row mt-4'>
                        <div className='col d-flex justify-content-center'>
                            <Button variant='contained' className='btn btn-success' type='submit' >Salvar Modelo</Button>
                        </div>
                    </div>

                </form>
                :
                <form onSubmit={handleSubmit(economicData?.nextYear?.id ? handleUpdateEconomicData : handleCreateEconomicData)}>
                    <Box display='flex' justifyContent='flex-end' mb={2}>
                        <Box className='col-2 p-2'>
                            <Typography variant='h4'>Ano Anterior</Typography>
                        </Box>
                        <Box className='col-2 p-2'>
                            <Typography variant='h4'>Ano Corrente</Typography>
                        </Box>
                        <Box className='col-2 p-2'>
                            <Typography variant='h4'>Próximo Ano</Typography>
                        </Box>
                    </Box>
                    <Box display='flex' justifyContent='flex-end' mb={2}>
                        <Box display='flex' justifyContent='flex-end' mb={2}>
                            <Typography className='h1 mt-4'>Quantidade Produzida</Typography>
                        </Box>
                        <Box className='col-2 p-2'>
                            <TextField placeholder='0,00' {...register('lastYear.quantityProduced')} disabled 
                                value={getValues('lastYear.quantityProduced')}
                                InputProps={{
                                    inputComponent: EconomicCurrencyInput as any
                            }}/>
                        </Box>
                        <Box className='col-2 p-2'>
                            <TextField placeholder='0,00' {...register('baseYear.quantityProduced')} disabled
                                value={getValues('baseYear.quantityProduced')}
                                InputProps={{
                                    inputComponent: EconomicCurrencyInput as any
                            }}/>
                        </Box>
                        <Box className='col-2 p-2'>
                            <TextField placeholder='0,00' {...register('nextYear.quantityProduced')} disabled 
                                value={getValues('nextYear.quantityProduced')}
                                InputProps={{
                                    inputComponent: EconomicCurrencyInput as any
                            }}/>
                        </Box>
                    </Box>
                    <Box display='flex' justifyContent='flex-end' mb={2}>
                        <Box display='flex' justifyContent='flex-end' mb={2}>
                            <Typography className='h2 mt-4'>Faturamento Bruto (R$)</Typography>
                        </Box>
                        <Box className='col-2 p-2'>
                            <TextField placeholder='0,00' {...register('lastYear.grossRevenue')} disabled
                                value={getValues('lastYear.grossRevenue')}
                                InputProps={{
                                    inputComponent: EconomicCurrencyInput as any
                            }} />
                        </Box>
                        <Box className='col-2 p-2'>
                            <TextField placeholder='0,00' {...register('baseYear.grossRevenue')} disabled 
                                value={getValues('baseYear.grossRevenue')}
                                InputProps={{
                                    inputComponent: EconomicCurrencyInput as any
                            }}/>
                        </Box>
                        <Box className='col-2 p-2'>
                            <TextField placeholder='0,00' {...register('nextYear.grossRevenue')} disabled 
                                value={getValues('nextYear.grossRevenue')}
                                InputProps={{
                                    inputComponent: EconomicCurrencyInput as any
                            }}/>
                        </Box>
                    </Box>
                    <Box display='flex' justifyContent='flex-end' mb={2}>
                        <Box display='flex' justifyContent='flex-end' mb={2}>
                            <Typography className='h2 mt-4'>Exportações (U$)</Typography>
                        </Box>
                        <Box className='col-2 p-2'>
                            <TextField placeholder='0,00' {...register('lastYear.exports')} disabled 
                                value={getValues('lastYear.exports')}
                                InputProps={{
                                    inputComponent: EconomicCurrencyInput as any
                            }}/>
                        </Box>
                        <Box className='col-2 p-2'>
                            <TextField placeholder='0,00' {...register('baseYear.exports')} disabled 
                                value={getValues('baseYear.exports')}
                                InputProps={{
                                    inputComponent: EconomicCurrencyInput as any
                            }}/>
                        </Box>
                        <Box className='col-2 p-2'>
                            <TextField placeholder='0,00' {...register('nextYear.exports')} disabled 
                                value={getValues('nextYear.exports')}
                                InputProps={{
                                    inputComponent: EconomicCurrencyInput as any
                            }}/>
                        </Box>
                    </Box>
                    <Box display='flex' justifyContent='flex-end' mb={2}>
                        <Box display='flex' justifyContent='flex-end' mb={2}>
                            <Typography className='h2 mt-4'>Investimentos Adicionais (R$)</Typography>
                        </Box>
                        <Box className='col-2 p-2'>
                            <TextField placeholder='0,00' {...register('lastYear.additionalQuantity')} disabled 
                                value={getValues('lastYear.additionalQuantity')}
                                InputProps={{
                                    inputComponent: EconomicCurrencyInput as any
                            }}/>
                        </Box>
                        <Box className='col-2 p-2'>
                            <TextField placeholder='0,00' {...register('baseYear.additionalQuantity')} disabled 
                                value={getValues('baseYear.additionalQuantity')}
                                InputProps={{
                                    inputComponent: EconomicCurrencyInput as any
                            }}/>
                        </Box>
                        <Box className='col-2 p-2'>
                            <TextField placeholder='0,00' {...register('nextYear.additionalQuantity')} disabled 
                                value={getValues('nextYear.additionalQuantity')}
                                InputProps={{
                                    inputComponent: EconomicCurrencyInput as any
                            }}/>
                        </Box>
                    </Box>
                    <div className='row mt-4'></div>
                </form>
            }
        </CardContent>
    );
}