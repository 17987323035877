import { createContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useCompany } from "../hooks/useCompany";
import { api } from "../services/axios";
import { CreatePathProps, UpdatePathProps, PathProps, FileProps, FilesPagination, PathsPagination, AuthProps, FileContextProps } from "../interfaces/Files/IFiles";

export const FileContext = createContext({} as FileContextProps);

export function FileProvider({ children, pathMain }: AuthProps) {
    const [files, setFiles] = useState<FilesPagination>({} as FilesPagination);
    const [checkedFiles, setCheckedFiles] = useState<FileProps[]>([]);

    const [checkAllFiles, setCheckAllFiles] = useState(false);

    const [paths, setPaths] = useState<PathsPagination>({} as PathsPagination);
    const [currentPath, setCurrentPath] = useState<PathProps>({} as PathProps)

    const { company } = useCompany();
    const { path, folderId } = useParams();

    const navigate = useNavigate();

    async function getCurrentPath() {
        if (folderId != undefined && !currentPath.id) {
            const result = await api.get(`/pathManager/GetPath/${folderId}`);

            const response = await result.data;

            if (response.statusCode === 200) {
                setCurrentPath(response.data);
            }
        }
    }

    async function getFiles() {
        const result = await api.get(`/fileManager/GetByPath/${company.serviceId}/${pathMain}`, {
            params: {
                page: 1,
                take: 7,
                word: "all",
                parentFolder: folderId != undefined ? folderId : null,
            }
        });
        const response = await result.data;

        if (response.statusCode === 200) {
            const data = response.data;
            setFiles(data);
        }
    }

    async function getPaths() {
        const result = await api.get(`/pathManager/GetByService/${company.serviceId}/${pathMain}`, {
            params: {
                page: 1,
                take: 7,
                word: "all",
                parentFolder: folderId != undefined ? folderId : null,
            }
        });
        const response = await result.data;

        if (response.statusCode === 200) {
            const data = response.data;
            setPaths(data);
        }
    }

    async function handleToggleCheckAll() {
        setCheckAllFiles(!checkAllFiles);
        setCheckedFiles([]);
    }

    async function handleDownloadMassive() {
        const object = {
            checkedFiles: checkedFiles,
            checkAll: checkAllFiles
        }

        const result = await api.post(`fileManager/DownloadMassive`, object, {
            responseType: 'blob',
            params: {
                serviceId: company.serviceId,
                path: path,
                folderId: folderId
            }
        });

        const response = await result.data;

        var blob = new Blob([response], {
            type: 'application/octet-stream'
        });

        const fileURL = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = fileURL;
        link.setAttribute("download", "download.zip");
        document.body.appendChild(link);
        link.click();
        link.parentNode?.removeChild(link);
    }

    async function handleDeleteMassive() {
        const object = {
            checkedFiles: checkedFiles,
            checkAll: checkAllFiles
        }

        const result = await api.post(`fileManager/DeleteMassive`, object, {
            params: {
                serviceId: company.serviceId,
                path: path,
                folderId: folderId
            }
        });

        const response = await result.data;

        if (response.statusCode === 200) {
            toast.success('Arquivos deletados com sucesso!');
            getFiles();
        }
    }

    async function handleCheckFile(checkedFile: FileProps) {
        if (checkedFiles.some(e => e.id === checkedFile.id)) {
            const filteredList = checkedFiles.filter(item => {
                return item.id != checkedFile.id;
            });

            setCheckedFiles(filteredList);
        }
        else {
            setCheckedFiles(ant => [...ant, checkedFile]);
        }
    }

    async function handleSelectSubPath(item: PathProps) {
        setCurrentPath(item);
        navigate(`/${company.serviceId}/fileManager/files/${pathMain}/${item.id}`);
    }

    async function changeFilePage(page: number) {
        const result = await api.get(`/fileManager/GetByPath/${company.serviceId}/${pathMain}`, {
            params: {
                page: page,
                take: 7,
                word: files.word != "" ? files.word : 'all',
                parentFolder: folderId != undefined ? folderId : null,
            }
        });
        const response = await result.data;

        if (response.statusCode === 200) {
            const data = response.data as FilesPagination;
            data.word = files.word != "" ? files.word : "all";
            setFiles(data);
        }
    }

    async function changePathPage(page: number) {
        const result = await api.get(`/pathManager/GetByService/${company.serviceId}/${pathMain}`, {
            params: {
                page: page,
                take: 7,
                word: paths.word,
                parentFolder: folderId != undefined ? folderId : null,
            }
        });
        const response = await result.data;

        if (response.statusCode === 200) {
            const data = response.data;
            setFiles(data);
        }
    }

    async function createPath(object: CreatePathProps) {
        const result = await api.post(`/pathManager/Post/`, object);
        const response = await result.data;

        if (response.statusCode === 200) {
            const data = response.data;
            toast.success('Sub-Pasta criada com sucesso!');

            const newFiles = paths.items;
            newFiles.push(data);

            const pathsPagination: PathsPagination = {
                currentPage: paths?.currentPage,
                items: newFiles,
                take: paths?.take,
                total: paths?.total,
                word: paths?.word
            };

            setPaths(pathsPagination);
        }
    }

    async function updatePath(object: UpdatePathProps) {
        const result = await api.put(`/pathManager/Put/${company.serviceId}`, object);
        const response = await result.data;

        if (response.statusCode === 200) {
            const data = response.data;
            toast.success('Sub-Pasta editada com sucesso!');

            const newPaths = paths.items;

            const pathIndex = newPaths.findIndex(x => x.id == object.id);

            newPaths[pathIndex].pathName = object.pathName;

            const pathsPagination: PathsPagination = {
                currentPage: paths?.currentPage,
                items: newPaths,
                take: paths?.take,
                total: paths?.total,
                word: paths?.word
            };

            setPaths(pathsPagination);
        }
    }

    async function searchFiles(text: string) {
        const result = await api.get(`/fileManager/GetByPath/${company.serviceId}/${pathMain}`, {
            params: {
                page: files.currentPage,
                take: 7,
                word: text != "" ? text : "all",
                parentFolder: folderId != undefined ? folderId : null,
            }
        });
        const response = await result.data;

        if (response.statusCode === 200) {
            const data = response.data as FilesPagination;
            data.word = text != "" ? text : "all";
            setFiles(data);
        }
    }

    async function searchPaths(text: string) {
        const result = await api.get(`/pathManager/GetByService/${company.serviceId}/${pathMain}`, {
            params: {
                page: paths.currentPage,
                take: 7,
                word: text,
                parentFolder: folderId != undefined ? folderId : null,
            }
        });
        const response = await result.data;

        if (response.statusCode === 200) {
            const data = response.data;
            setPaths(data);

        }
    }

    async function handleRemoveFile(id: string) {

        const object = {
            id: id,
            serviceId: company.serviceId
        }

        const result = await api.delete(`fileManager/delete/`, {
            data: object
        });

        const response = await result.data;

        if (response.statusCode === 200) {
            toast.success('Arquivo deletado com sucesso!');

            if (files) {
                const filesFiltered = files.items.filter(item => {
                    return item.id != id;
                })

                const filesPagination: FilesPagination = {
                    currentPage: files?.currentPage,
                    items: filesFiltered,
                    take: files?.take,
                    total: files?.total - 1,
                    word: files?.word
                };
                setFiles(filesPagination);
            }
        }


    }

    async function handleRemovePath(id: string) {

        const object = {
            id: id,
            serviceId: company.serviceId
        }

        const result = await api.delete(`pathManager/delete/`, {
            data: object
        });

        const response = await result.data;

        if (response.statusCode === 200) {
            toast.success('Sub-Pasta deletado com sucesso!');

            if (paths) {
                const pathsFiltered = paths.items.filter(item => {
                    return item.id != id;
                })

                const pathsPagination: PathsPagination = {
                    currentPage: files?.currentPage,
                    items: pathsFiltered,
                    take: files?.take,
                    total: files?.total - 1,
                    word: files?.word
                };

                setPaths(pathsPagination);
            }
        }


    }

    async function handleUploadFiles(acceptedFiles: any[], serviceId: string, pathMain: number) {
        try {
            const formData = new FormData();

            acceptedFiles.map((file: Blob) => {
                formData.append('files', file);
            });

            const result = await api.post(`/fileManager/postRange/${serviceId}/${pathMain}/${folderId != undefined ? folderId : ''}`, formData);

            const response = result.data;

            if (response.statusCode === 200) {
                toast.success('Upload dos arquivos realizados com sucesso!');

                const data: FileProps[] = response.data;

                if (files) {
                    const filesConcat = files?.items.concat(data);

                    const filesPagination: FilesPagination = {
                        currentPage: files?.currentPage,
                        items: filesConcat,
                        take: files?.take,
                        total: files?.total,
                        word: files?.word
                    };

                    setFiles(filesPagination);
                }
            }

            return response;
        }
        catch (err: any) {

        }
        finally {
        }
    }

    useEffect(() => {
        getCurrentPath();
        getPaths();
        getFiles();
    }, [folderId]);

    return (
        <FileContext.Provider value={{
            handleDeleteMassive, handleToggleCheckAll, handleDownloadMassive, handleCheckFile,
            handleRemoveFile, handleSelectSubPath, handleRemovePath, handleUploadFiles,
            changeFilePage, changePathPage, searchFiles, searchPaths, createPath, updatePath,
            files, paths, currentPath, checkedFiles, checkAllFiles
        }}>
            {children}
        </FileContext.Provider>
    );
}