import { useState } from "react";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import { v4 as uuidv4 } from 'uuid';
import { ProductModelProps } from "../UpdateProductForm";
import { Box, TextField, Button, IconButton } from '@mui/material';
import "./style.css";
import { useCan } from "../../../hooks/useCan";


interface ProductModelAddListProps {
    models: ProductModelProps[];
    addModel: (model: ProductModelProps) => void;
    removeModel: (id: string) => void;
}

export function ProductModelAddList({ models, addModel, removeModel }: ProductModelAddListProps) {
    const [modelName, setModelName] = useState("");
    const permissionfinancial = useCan({ rolesCan: ["Financial"] });


    function handleAddModel() {
        let newModel: ProductModelProps = {
            id: uuidv4(),
            name: modelName
        }
        setModelName("");
        addModel(newModel);
    }

    return (
        <div>

            <div className="d-flex align-items-center mt-4 text-white">
                <TextField
                    label="Modelos de Produtos"
                    disabled={ permissionfinancial}
                    className="form-control"
                    value={modelName}
                    onChange={(e) => setModelName(e.target.value)}
                />
                {!permissionfinancial &&
                <Button
                    variant="contained"
                    className="btn btn-success ms-4 btn-height text-white text-center"
                    style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                    onClick={handleAddModel}
                >
                    <h2 className="text-white text-center mb-0">+</h2>
                </Button>}
            </div>
           
            {models.map((model) => (
                <div className="d-flex align-items-center mt-4 text-white" key={model.id}>
                    <TextField
                        className="form-control "
                        value={model.name}
                        disabled
                    />
                    {!permissionfinancial &&
                    <Button
                        className="btn btn-success ms-4 btn-height"
                        onClick={() => removeModel(model.id)}
                    >
                        <h2 className="text-white text-center mb-0">-</h2>
                    </Button>
                    }
                </div>
            ))}
        </div>
    );
}
