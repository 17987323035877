import { forwardRef } from 'react';
import { NumericFormat } from 'react-number-format';

interface CurrencyInputProps {
    onChange: (event: { target: { name: string; value: string } }) => void;
    value: string,
    name: string;
}
 
export const EconomicCurrencyInput = forwardRef((props: CurrencyInputProps, ref) => {
    const { onChange, value,...other } = props;

    return (
        <NumericFormat
            {...other}
            getInputRef={ref}
            className='form-control'
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}

            value={value}
            decimalScale={2}
            fixedDecimalScale
            thousandSeparator="."
            decimalSeparator=","
            valueIsNumericString
            placeholder="0,00"
            allowNegative={false}
        />
    );
});