import './styles.css'
import { useState, useContext, useCallback, useEffect } from "react";
import { Row } from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import { AiOutlinePlus, AiOutlineSearch } from "react-icons/ai";
import { FaCloudDownloadAlt, FaTrash } from "react-icons/fa";
import { useCompany } from '../../../../hooks/useCompany';
import { Loader } from 'rsuite';
import { FileInternalProjectsContext } from '../../../../contexts/fileInternalProjectsContext';
import { PathModal } from '../../../Path/PathModal';
import DeleteModal from '../../../Shared/DeleteModal';
import { FolderList } from '../../../Shared/UploadFiles/FolderList';
import { Upload } from '../../../Shared/UploadFiles/Upload';
import { FileList } from "../../../Shared/UploadFiles/FileList";
import useModal from '../../../../hooks/useModal';
import { useParams } from 'react-router-dom';
import ModalSearchFileComponent from '../../../Files/ModalSearchFileComponent';
import useLoading from '../../../../hooks/useLoading';
import { FileModel } from '../../../../models/FileModel';
import { toast } from 'react-toastify';
import { SearchFileAPI } from '../../../../API/FilesInternalProjectsAPI';
import { IResponseProps } from '../../../../interfaces/IResponseProps';

const folders: any = {
    4: 'Documentação de Projetos internos',
}

interface IAnexoProps {
    setSubScreenTitle: React.Dispatch<React.SetStateAction<string>>
}

export function AnexoContent(props: IAnexoProps) {
    const { checkAllFiles, files, paths, currentPath, checkedFiles,
        handleToggleCheckAll, handleDeleteMassive, handleDownloadMassive,
        handleCheckFile, handleRemoveFile, handleSelectSubPath, handleUploadFiles,
        changeFilePage, changePathPage, searchFiles, searchPaths } = useContext(FileInternalProjectsContext);

    const { company } = useCompany();
    const [selectedFiles, setSelectedFiles] = useState<any[]>([]);
    const { loading, toggleLoading } = useLoading();
    const { isShowing, toggle } = useModal();
    const [pathSearch, setPathSearch] = useState("");
    const [fileSearch, setFileSearch] = useState("");
    const { isShowing: isShowingDelete, toggle: toggleDelete } = useModal();
    const { isShowing: showSearchFileModal, toggle: toggleShowSearchFileModal } = useModal();
    const [pathName, setPathName] = useState<string>("");
    const [filesModal, setFilesModal] = useState<FileModel[]>([]);
    const [totalModal, setTotalModal] = useState<number>(0);

    const path: string = "4";

    const onDrop = useCallback((acceptedFiles: File[]) => {
        setSelectedFiles([...selectedFiles, ...acceptedFiles])
    }, [selectedFiles]);

    const onRemoveToTheList = (fileId: number) => {
        const filesFilter = [...selectedFiles];
        filesFilter.splice(fileId, 1);
        setSelectedFiles(filesFilter);
    }

    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({ onDrop });

    const { lastYearProject, lastYearProjectImport, id, lastYearId, typology, folderId } = useParams();

    async function handleSendFiles() {
        toggleLoading(true);
        const pathNumber = path != undefined ? parseInt(path) : 4;
        try {
            handleUploadFiles(selectedFiles, company.serviceId, pathNumber).then(response => {
                setSelectedFiles([])
                toggleLoading(false);
            });
        }
        catch (err: any) {
            console.log('ERROR', err);
        }
        finally {
        }
    }

    async function handleDownloadSelectedFiles() {
        toggleLoading(true);
        try {
            await handleDownloadMassive().then(() => toggleLoading(false));
        }
        catch (err: any) {
            console.log('ERROR', err);
        }
        finally {
        }
    }

    async function handleDeleteSelectedFiles() {
        toggleLoading(true);
        try {
            await handleDeleteMassive().then(() => toggleLoading(false));
        }
        catch (err: any) {
            console.log('ERROR', err);
        }
        finally {
        }
    }

    async function searchFileModal(page?: number) {
        toggleLoading(true);
        let text = pathName == null || pathName == "" ? "all" : pathName;
        try {
            if (id)
                await SearchFileAPI(id, path ?? "", page ?? 1, 7, text, folderId)
                    .then((resp: IResponseProps) => {
                        setFilesModal(resp.data.items);
                        setTotalModal(resp.data.total);
                    })
                    .finally(() => toggleLoading(false));

        } catch (error: any) {
            toast.error(error);
        }
    }

    async function handleClose() {
        setFilesModal([]);
        setPathName("");
        toggleShowSearchFileModal();
    }

    useEffect(() => {
        if (currentPath.pathName != undefined)
            props.setSubScreenTitle(currentPath.pathName);
        else
            props.setSubScreenTitle('');
    }, [currentPath])

    return (
        <>
            {loading && <Loader />}
            <div className='card-header d-flex justify-content-end'>
                <div className='d-flex align-items-center align-self-center'>
                    <button className='btn btn-success me-2' onClick={toggle}>
                        <AiOutlinePlus className='me-1' />
                        Nova Sub-Pasta
                    </button>
                    <button className='btn btn-success' onClick={toggleShowSearchFileModal}>
                        <AiOutlineSearch className='me-1' />
                        Buscador de Arquivos
                    </button>
                </div>
            </div>
            <div className='card-body'>
                <Upload
                    getInputProps={getInputProps}
                    getRootProps={getRootProps}
                    handleSendFiles={handleSendFiles}
                    onRemoveToTheList={onRemoveToTheList}
                    selectedFiles={selectedFiles}
                />
                <Row className='w-100 m-0'>
                    <FileList
                        setFileSearch={setFileSearch}
                        searchFiles={searchFiles}
                        handleToggleCheckAll={handleToggleCheckAll}
                        handleCheckFile={handleCheckFile}
                        changeFilePage={changeFilePage}
                        fileSearch={fileSearch}
                        checkAllFiles={checkAllFiles}
                        checkedFiles={checkedFiles}
                        files={files}
                        hasInternalProjects={true}
                    />
                    <FolderList
                        setPathSearch={setPathSearch}
                        searchPaths={searchPaths}
                        pathSearch={pathSearch}
                        paths={paths}
                        handleSelectSubPath={handleSelectSubPath}
                        changePathPage={changePathPage}
                        context={FileInternalProjectsContext}
                        internalProjectsId={id}
                    />
                </Row>
                {
                    checkedFiles.length > 0 || checkAllFiles
                        ?
                        <div className='d-flex mt-2'>
                            <button className='btn btn-success w-75 me-4' type='button' onClick={handleDownloadSelectedFiles}>
                                <FaCloudDownloadAlt className='me-1' />
                                Baixar arquivos
                            </button>
                            <button className='btn btn-seocndary w-25 bg-gray' onClick={toggleDelete}>
                                <FaTrash className='cursor-pointer white' />
                            </button>
                        </div>
                        :
                        null
                }
            </div>
            <DeleteModal hide={toggleDelete}
                isShowing={isShowingDelete}
                text='Tem certeza que deseja deletar todos os arquivos selecionados?'
                title='Exclusão de arquivos'
                action={handleDeleteSelectedFiles}
            />
            <ModalSearchFileComponent
                serviceId={company.serviceId}
                handleClose={handleClose}
                pathMain={path ?? ""}
                show={showSearchFileModal}
                searchFile={searchFileModal}
                filesModal={filesModal}
                totalModal={totalModal}
                pathName={pathName}
                setPathName={setPathName}
            />
            <PathModal
                isShowing={isShowing}
                hide={toggle}
                context={FileInternalProjectsContext}
                path={path != undefined ? parseInt(path) : 4}
                internalProjectsId={id}
            />
        </>
    );
}
