import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { FaArrowRight } from 'react-icons/fa';
import { toast } from 'react-toastify';

interface IModalDeletePathComponentProps {
    show: boolean,
    toggleShow: () => void,
    setResponse: React.Dispatch<React.SetStateAction<boolean>>,
    pathName: string
}

const ModalDeletePathComponent = ({ show, toggleShow, setResponse, pathName }: IModalDeletePathComponentProps) => {
    const [accept, setAccept] = useState<string>("");
    const [canDelete, setCanDelete] = useState<boolean>(false);

    const handleClose = () => {
        setResponse(false);
        setCanDelete(false);
        setAccept("");
        toggleShow();
    }

    const handleCloseWithResponse = () => {
        setResponse(true);
        setCanDelete(false);
        setAccept("");
        toggleShow();
    }

    const handleDelete = () => {
        if (accept.toUpperCase() == "SIM") {
            setCanDelete(true);
        } else {
            toast.warn("Digite 'sim' para excluir!");
        }
    }

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Deletar itens?</Modal.Title>
            </Modal.Header>
            <Modal.Body>Tem certeza de que deseja excluir essa pasta? Ao deletar, todos os arquivos em todas as subpastas que estejam dentro desta pasta serão transferidas para a pasta <b>{pathName}</b></Modal.Body>
            <Modal.Footer>
                {!canDelete ?
                    <div className='w-100 d-flex align-items-end'>
                        <div className='w-100 me-1'>
                            <label>Digite <b>'sim'</b> para deletar:</label>
                            <input value={accept} className="form-control text-center" onChange={(e) => setAccept(e.target.value)} />
                        </div>
                        <Button disabled={accept == ""} className='btn btn-primary w-25 ms-1' onClick={handleDelete}>
                            <FaArrowRight className='white-icon' size={16} />
                        </Button>
                    </div>
                    :
                    <Button className='btn btn-secondary w-100' onClick={handleCloseWithResponse}>
                        Excluir definitivamente
                    </Button>
                }
            </Modal.Footer>
        </Modal>
    )
}

export default ModalDeletePathComponent;