import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { BsTools } from 'react-icons/bs';
import React from "react";

interface ProductionModalProps {
    isOpen: boolean;
    toggle: () => void;
}

export function ProductionModal({ isOpen, toggle }: ProductionModalProps) {
    return (
        <Modal isOpen={isOpen} toggle={toggle} className='modal-lg'>
            <ModalHeader className='d-flex align-center justify-content-center'>
                <BsTools size={70} />
            </ModalHeader>
            <ModalBody className='bg-light'>
                <div className='mb-3 w-100'>
                    <label className='form-label'>Nome</label>
                    <input className='form-control form-control w-100' />
                </div>
                <div className='d-flex align-items-center'>
                    <div className='mb-3 w-100'>
                        <label className='form-label'>Quantidade</label>
                        <input className='form-control form-control w-100' />
                    </div>
                    <div className='mb-3 w-100 ms-4'>
                        <label className='form-label'>PPB importado ou Nacional</label>
                        <select className="form-select">
                            <option value="0">Nacional</option>
                            <option value="1">Importado</option>
                        </select>
                    </div>
                    <div className='mb-3 w-100 ms-4'>
                        <label className='form-label'>Tipos de Portaria</label>
                        <input className='form-control form-control w-100' />
                    </div>
                </div>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" className='w-100'>
                    Salvar Produção
                </Button>
            </ModalFooter>
        </Modal>
    )
}