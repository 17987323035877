import { useEffect, useState } from 'react';
import { AiFillHome } from 'react-icons/ai'
import { Link, useNavigate } from 'react-router-dom'
import { useCompany } from '../../../hooks/useCompany';
import Select from 'react-select'
import Loader from '../../../components/Shared/Loader';
import { api } from '../../../services/axios';
import { toast } from 'react-toastify';
import { formatCurrency } from '../../../utils/formatCurrency';
import { Button, InputAdornment, TextField, Typography } from '@mui/material';
import { BsArrowRightShort } from 'react-icons/bs';

interface OptionsProps {
    value: string;
    label: string;
    disabled: boolean;
}

interface BranchCompanyProps {
    id: string;
    cnpj: string;
    name: string;
    uf: string;
    city: string;
    region: string;
}

const options = [
    { value: '1', label: '1º Trimestre', disabled: false },
    { value: '2', label: '2º Trimestre', disabled: false },
    { value: '3', label: '3º Trimestre', disabled: false },
    { value: '4', label: '4º Trimestre', disabled: false }
]

interface InvoincingBalanceSheetValues {
    invoicingTotalValue: number;
    obligationValue: number;
}

export function CreateCredit() {
    const [counting, setCounting] = useState<OptionsProps[]>(options);
    const [countingsSelecteds, setCountingsSelecteds] = useState<OptionsProps[]>([]);
    const [branchCompaniesToSelect, setbranchCompaniesToSelect] = useState<OptionsProps[]>([]);
    const [balanceSheetValue, setBalanceSheetValue] = useState<InvoincingBalanceSheetValues>();
    const [branchCompanySelected, setBranchCompanySelected] = useState<string>("");
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const { company, updateHeaderValues } = useCompany();

    async function getPeriodsSaved() {
        try {
            setLoading(true);
            const isBranch = branchCompanySelected != company.companyId;
            const result = await api.get(`/Credit/GetCreditPeriodsSaved?serviceId=${company.serviceId}&id=${branchCompanySelected}&isBranch=${isBranch}`);

            const response = await result.data;

            const data: string = response.data;

            if (response.statusCode === 200) {
                const splitCountings = data.split(",");

                const countingsAvailable = options.filter(x => {
                    return !splitCountings.includes(x.value)
                });

                setCounting(countingsAvailable);
            }
        } catch (error: any) {
            toast.error(error);
        } finally {
            setLoading(false);
        }
    }

    async function getCNPJsToCredit() {
        try {
            setLoading(true);
            await api.get(`/Credit/GetCNPJsToCredit?companyId=${company.companyId}&serviceId=${company.serviceId}`).then(resp => {
                if (resp.status === 200) {
                    const data: OptionsProps[] = resp.data.data;

                    setbranchCompaniesToSelect(data);
                }
            });
        } catch (error: any) {
            toast.error(error);
        } finally {
            setLoading(false);
        }
    }

    async function handleCreateCredit() {
        try {
            setLoading(true);

            if (countingsSelecteds.length == 0) {
                if(company.counting == 0){
                    const object = {
                        countings: "0",
                        serviceId: company.serviceId,
                        branchCompanyId: branchCompanySelected != company.companyId ? branchCompanySelected : null
                    }
    
                    const result = await api.post('/Credit/CreateCredit', object);
    
                    const response = await result.data;
    
                    const data = response.data;
    
                    if (response.statusCode === 200) {
                        await updateHeaderValues();
                        toast.success('Credito criado com sucesso!');
                        navigate(`/${company.serviceId}/credit/tabs/${data.id}/${object.countings}`);
                    }
                }else{
                    toast.error('Selecione um período para continuar');
                }
            }
            else {
                const object = {
                    countings: countingsSelecteds.map(x => x.value).join(','),
                    serviceId: company.serviceId,
                    branchCompanyId: branchCompanySelected != company.companyId ? branchCompanySelected : null
                }

                const result = await api.post('/Credit/CreateCredit', object);

                const response = await result.data;

                const data = response.data;

                if (response.statusCode === 200) {
                    await updateHeaderValues();
                    toast.success('Credito criado com sucesso!');
                    navigate(`/${company.serviceId}/credit/tabs/${data.id}/${object.countings}`);
                }
            }
        }
        catch (error: any) {
            toast.error(error);
        }
        finally {
            setLoading(false);
        }
    }

    async function getInvoicingToBalanceSheet(countingsSelecteds: string, branchSelected: string) {
        try {
            setLoading(true);

            const result = await api.get(`/invoicing/GetInvoicingToBalanceSheet`, {
                params: {
                    serviceId: company.serviceId,
                    countingsSelecteds: countingsSelecteds,
                    branchCompanySelected: branchSelected,
                    matrixCnpj: company.cnpj,
                    isBranch: branchCompanySelected != company.companyId
                }
            });

            const response = await result.data;

            if (response.statusCode === 200) {
                setBalanceSheetValue(response.data);
            }
        }
        catch (error: any) {
            toast.error(error);
        }
        finally {
            setLoading(false);
        }
    }

    async function handleChangeCounting(countings: OptionsProps[]) {
        setCountingsSelecteds(countings);

        if (countings.some(x => x.value == '0')) {
            countingsSelecteds.forEach(element => {
                if (parseInt(element.value) > 0) {
                    element.disabled = true;
                    toast.error('Não é possível realizar uma combinação com o período Anual');
                    setCountingsSelecteds([]);
                }
            });
        }

        if (countings.some(x => x.value == '1' || x.value == '2' || x.value == '3' || x.value == '4')) {
            countingsSelecteds.forEach(element => {
                if (parseInt(element.value) == 0) {
                    element.disabled = true;
                    toast.error('Não é possível realizar uma combinação com o período Anual');
                    setCountingsSelecteds([]);
                }
            });
        }

        if (countings.length > 0 && branchCompanySelected) {
            await getInvoicingToBalanceSheet(countings.map(x => x.value).join(","), branchCompanySelected);
        }
    }

    function handleSelectedBranchCompany(branchSelected: string) {
        setBranchCompanySelected(branchSelected);
    }

    useEffect(() => {
        getCNPJsToCredit();
    }, []);

    useEffect(() => {
        if (branchCompanySelected) {
            getPeriodsSaved();
        }
    }, [branchCompanySelected]);

    return (
        <>
            {loading && <Loader />}
            <div className='card '>
                <div className='card-header'>
                    <div className='breadCrumb'>
                        <Link to={`/${company.serviceId}/home`} className='breadCrumb-home d-flex align-center'>
                            <AiFillHome className='breadCrumb-home' />
                        </Link>
                        <span className='breadCrumb-separator'>{' > '}</span>
                        <span className='breadCrumb-item-actual cursor-pointer' onClick={() => navigate(`/${company.serviceId}/credit`)}>II.2 Faturamentos - Credito financeiro</span>
                        <span className='breadCrumb-separator'>{' > '}</span>
                        <span className='breadCrumb-item'>Criar crédito</span>
                    </div>
                </div>
                <div className='card-body d-flex flex-column align-items-center justify-content-between 2-100 m-4'>
                    <div className='mb-3 w-100'>
                        <label className='form-label'>Empresas Habilitadas</label>
                        <Select
                            name="colors"
                            options={branchCompaniesToSelect}
                            className="basic-multi-select"
                            placeholder='Selecione...'
                            noOptionsMessage={obj => obj.inputValue = 'Não há empresas habilitadas cadastradas no momento!'}
                            onChange={(e: any) => { setCountingsSelecteds([]); handleSelectedBranchCompany(e.value); }}
                            styles={{
                                // Estilo para ajustar a altura do dropdown
                                menu: provided => ({ ...provided, maxHeight: '300px' }),
                        
                                // Estilo para ajustar a altura das opções na lista
                                option: provided => ({ ...provided, minHeight: '56px' }),
                        
                                // Estilo para ajustar a altura do valor selecionado
                                singleValue: provided => ({ ...provided, minHeight: '56px' }),
                        
                                // Estilo para ajustar a altura do componente inteiro
                                control: provided => ({ ...provided, minHeight: '56px' }),
                            }}
                        />
                    </div>
                    <div className='d-flex mb-4 w-100 h-100'>
                        <div className='w-100 me-4 mb-5'>
                            <label className='form-label m-0'>Período</label>
                            {company.counting == 0
                                ?
                                <select className='form-select' disabled style={{ height: '56px' }}>
                                    <option value='0'>Anual</option>
                                </select>
                                :
                               
                                    <Select
                                className='w-100 me-4 mb-3 '
                                options={counting}
                                isDisabled={!branchCompanySelected}
                                isMulti
                                placeholder='Selecione um ou mais períodos'
                                onChange={(e) => handleChangeCounting(e.map(x => x))}
                                value={countingsSelecteds}
                                styles={{
                                    // Estilo para ajustar a altura do dropdown
                                    menu: provided => ({ ...provided, maxHeight: '300px' }),
                            
                                    // Estilo para ajustar a altura das opções na lista
                                    option: provided => ({ ...provided, minHeight: '56px' }),
                            
                                    // Estilo para ajustar a altura do valor selecionado
                                    singleValue: provided => ({ ...provided, minHeight: '56px' }),
                            
                                    // Estilo para ajustar a altura do componente inteiro
                                    control: provided => ({ ...provided, minHeight: '56px' }),
                                }}
                            />
                            }
                        </div>
                        <div className='w-100 me-4'>
                            <Typography variant='body1' component='label'>Valor Faturamento Incentivado Período</Typography>
                            <TextField
                                fullWidth
                                type='text'
                                value={formatCurrency(balanceSheetValue?.invoicingTotalValue.toFixed(2))}
                                InputProps={{
                                    startAdornment: <InputAdornment position='start'>R$</InputAdornment>,
                                    readOnly: true,
                                }}
                            />
                        </div>

                        <div className='w-100 me-4'>
                            <Typography variant='body1' component='label'>Valor Obrigação Período</Typography>
                            <TextField
                                fullWidth
                                type='text'
                                value={formatCurrency(balanceSheetValue?.obligationValue.toFixed(2))}
                                InputProps={{
                                    startAdornment: <InputAdornment position='start'>R$</InputAdornment>,
                                    readOnly: true,
                                }}
                            />
                        </div>
                    </div>
                    {(branchCompanySelected && (company.counting == 0 || countingsSelecteds.length > 0)) &&
                        <Button variant="outlined" className=' ' onClick={handleCreateCredit}><BsArrowRightShort size={20} /> Próximo</Button>
                    }
                </div>
            </div>
        </>
    )
}