export const inputValueLabel = {
  C1: {
    Selects: {
      "0": "Há descrição do projeto, mas ela não fornece indícios para decidir pela existência de problema técnico-científico a resolver.",
      "1": "Os indícios apontam que o projeto não tem problema técnico-científico, pois a empresa reproduz ou executa alguma solução conhecida.",
      "2": "Os indícios apontam para um problema técnico-científico explícito cuja solução já existe, mas a empresa precisa criar e aplicar a sua própria solução para o problema, pois existe alguma limitação técnica, legal ou comercial para reproduzir ou executar a solução já existente.",
      "3": "Os indícios apontam para um problema técnico-científico explícito, cuja solução ainda não existe e a empresa precisa criar e aplicar uma solução para o problema.",
      "": "",
    },
    Tooltip:
      "O projeto de TIC deve contemplar no seu escopo, no todo ou em parte, a resolução de um problema técnico-científico.",
  },
  C2: {
    Selects: {
      "0": "Não há indícios para identificar as etapas ou há descrição das etapas, mas elas não estão contextualizadas com o escopo específico do projeto ou ainda não permitem identificar se foram executadas pela empresa, por sua contratada ou pela ICT conveniada, conforme o caso.",
      "1": "Os indícios apontam para uma estrutura de etapas de natureza não técnico-científica, meramente operacionais ou ainda, referentes à aquisição da solução.",
      "2": "Os indícios apontam para uma estrutura de etapas de natureza técnico-científica para atingir o objetivo definido, com as etapas mais relevantes identificadas e contextualizadas com o objetivo e escopo do projeto, demonstrando que as atividades foram executadas pela empresa, pela sua contratada ou pela ICT, quando for o caso.",
      "3": "Os indícios apontam para uma estrutura abrangente de etapas de natureza técnicocientífica para atingir o objetivo definido, com etapas contextualizadas com o objetivo e o escopo do projeto, demonstrando que as atividades foram executadas pela empresa, pela sua contratada ou pela ICT quando for o caso.",
      "": "",
    },
    Tooltip:
      "O projeto de TIC deve ser executado de forma sistemática, com etapas de natureza técnico-científica, contextualizadas com o escopo do projeto, demonstrando que as atividades foram executadas pela empresa, pela sua contratada ou pela ICT quando for o caso.",
  },
  C3: {
    Selects: {
      "0": "Não há atividade investigativa na descrição do projeto.",
      "1": "Os indícios apontam que as etapas relativas a testes, experimentos ou similares se restringem à mera comprovação do correto funcionamento da solução (homologação, teste funcional ou apenas unitário etc.",
      "2": "Os indícios apontam etapas relativas a testes, experimentos ou similares que comprovam não só o correto funcionamento da solução desenvolvida, e também outros testes mais sofisticados (desempenho, simulação, estresse etc), ainda que sem descrição extensiva.",
      "3": "Os indícios apontam etapas relativas a testes, experimentos ou similares que comprovam não só o correto funcionamento da solução desenvolvida, mas também outros testes mais sofisticados para comprovação da solução do problema técnico- científico (desempenho, simulação, estresse etc). Inclui a descrição dos testes ou de seus resultados ou conclusões, relacionando-os ao escopo do projeto específico.",
      "": "",
    },
    Tooltip:
      "O projeto de TIC deve contemplar atividades investigativas, de validação ou experimentais, que contribuam para comprovar o atingimento de seus objetivos e a superação do problema técnicocientífico",
  },
  C4: {
    Selects: {
      "0": "A descrição do projeto não demonstra os resultados esperados ou alcançados com a sua execução.",
      "1": "Os indícios apontam para resultados gerados no âmbito da execução do projeto - produtos, componentes, sistemas etc., mas que não representam acréscimo de conhecimentos ou práticas ao acervo existente.",
      "2": "Os indícios apontam que os resultados gerados no âmbito da execução do projeto incorporam acréscimo de conhecimento ou práticas ao acervo (novos conhecimentos, materiais, produtos, processos etc., ou aperfeiçoamentos significativos), mas não há comparação explícita com outros conhecimentos, materiais, produtos, processos etc. disponíveis.",
      "3": "Os indícios apontam que os resultados gerados no âmbito da execução do projeto incorporam acréscimo de conhecimento ou práticas ao acervo existente (novos conhecimentos, materiais, produtos, processos etc. ou aperfeiçoamentos significativos), por comparação explicita com outros conhecimentos, materiais, produtos, processos etc. disponíveis.",
      "": "",
    },
    Tooltip:
      "O projeto de TIC deve apresentar nos seus resultados algum elemento de novidade (conhecimento, produto, processo, característica ou propriedade do resultado etc.), isto é, acréscimo de conhecimentos ou práticas ao acervo existente. Esse acréscimo se caracteriza pela geração de novos conhecimentos/materiais/produtos/dispositivos/programas de computador/processos/sistemas/serviços ou materiais/produtos/processos etc., ou que tenham sido significativamente aperfeiçoados.",
  },
  C5: {
    Selects: {
      "0": "Não há informações sobre o conteúdo do curso ou conteúdo com descrição genérica.",
      "1": "Conteúdo de técnicas operacionais ou conteúdo indefinido. Os indícios apontam que o curso tem um conteúdo de técnicas meramente operacionais, conteúdo de conhecimentos não técnico científico, ou conteúdo indefinido.",
      "2": "Conteúdo técnico-científico ou técnico com descrição básica. Os indícios apontam para um curso de conteúdo técnico- científico ou de técnicas não operacionais associadas a conteúdo técnico-científico, com descrição das informações básicas sobre o curso.",
      "3": "Conteúdo técnico-científico ou técnico com descrição abrangente. Os indícios apontam para um curso de conteúdo técnico- científico ou de técnicas não operacionais associadas a conteúdo técnico-científico, com descrição abrangente das informações sobre o curso.",
      "": "",
    },
    Tooltip:
      "O conteúdo do projeto tem características técnico-científicas (isto é, conhecimentos, capacidades e práticas embasadas na teoria ou na experimentação científica) ou características técnicas não-operacionais derivadas de fundamentos técnico-científicos. O curso ou treinamento objeto do projeto agrega conhecimentos que podem ser aplicados em projetos de PD&I.",
  },
  C6: {
    Selects: {
      "0": "Não existem informações sobre o nível do curso.",
      "1": "Nível fundamental ou elementar. Os indícios apontam que o nível do curso é fundamental ou elementar",
      "2": "Nível médio/Capacitação técnica. Os indícios apontam que curso é de formação técnica de nível médio, ou é um curso de capacitação técnica específica.",
      "3": "Nível Superior. Os indícios apontam que o curso é de nível superior.",
      "": "",
    },
    Tooltip:
      "O nível do curso de formação ou capacitação profissional para aperfeiçoamento e desenvolvimento de RH em tecnologias da informação e comunicação é de nível médio ou superior.",
  },
  C7: {
    Selects: {
      "0": "Não existem informações sobre o pessoal capacitado/formado ou em capacitação/formação.",
      "1": "Pessoal da empresa ou de fora dela, mas que não aplicará o treinamento em função finalística de TIC. O pessoal formado/capacitado ou em formação/capacitação não poderá aplicar esse conhecimento na execução de função finalística de TIC",
      "2": "Pessoal de fora da empresa. O pessoal não pertence aos quadros da empresa, mas será formado/capacitado pelo projeto numa IES que está de acordo com os incisos I e II do art. 213 da Constituição Federal, para poder executar funções finalísticas de TIC.",
      "3": "Pessoal da própria empresa. O pessoal pertence aos quadros da empresa beneficiária e suas funções são de natureza finalística  em TIC, ou será formado para poder executar funções de natureza finalística em TIC.",
      "": "",
    },
    Tooltip:
      "O pessoal formado/capacitado deve atuar em função finalística de TIC ou estar em processo de formação/capacitação para poder executar função finalística de TIC.",
  },
};
