import { FilterPaginatorRequest } from "../components/Requests/FilterPaginatorRequest";
import { FiPositioningFormProps, InternalProjectsFormProps, ProjectDescriptionFormProps } from "../pages/Projects/InternalProjectsTabs/Interfaces/InternalProjectsFormProps";
import { api } from "../services/axios";
import { toQueryParams } from "../utils/creatingParameter";

const GetByIdAPI = async (id: string) => {
    try {
        var result = await api.get(`internalProjects/getById/${id}`);
        var response = result.data;
        return response;
    } catch (error: any) {
        throw new Error(error.response.data);
    }
};

const GetAllByServiceIdAPI = async (serviceId: string) => {
    try {
        var result = await api.get(`internalProjects/getAllByServiceId/${serviceId}`);
        var response = result.data;
        return response;
    } catch (error: any) {
        throw new Error(error.response.data);
    }
};

const GetAllByCompanyIdAPI = async (companyId: string, year: number) => {
    try {
        var result = await api.get(`internalProjects/getAllByCompanyId/${companyId}/${year}`);
        var response = result.data;
        return response;
    } catch (error: any) {
        throw new Error(error.response.data);
    }
};

const GetAllPagingByServiceIdAPI = async (serviceId: string, request: FilterPaginatorRequest) => {
    const params = toQueryParams(request);
    try {
        var result = await api.get(`/internalProjects/getAllPagingByServiceId/${serviceId}?${params}`);
        var response = result.data;
        return response;
    } catch (error: any) {
        throw new Error(error.response.data);
    }
}

const PostInternalProjectsAPI = async (data: InternalProjectsFormProps) => {
    try {
        var result = await api.post('/internalProjects/Post', data);
        var response = result.data;
        return response;
    } catch (error: any) {
        throw new Error(error.response.data);
    }
}

const PutInternalProjectsAPI = async (data: InternalProjectsFormProps) => {
    try {
        var result = await api.put('/internalProjects/Put', data);
        var response = result.data;
        return response;
    } catch (error: any) {
        throw new Error(error.response.data);
    }
}


const PutFiPositioningAPI = async (data: FiPositioningFormProps) => {
    try {
        var result = await api.put('/internalProjects/PutFiPositioning', data);
        var response = result.data;
        return response;
    } catch (error: any) {
        throw new Error(error.response.data);
    }
}

const PutProjectDescriptionAPI = async (data: ProjectDescriptionFormProps) => {
    try {
        var result = await api.put('/internalProjects/PutProjectDescription', data);
        return result.data;
    } catch (error: any) {
        throw new Error(error.response.data);
    }
}

const DeleteIdAPI = async (id: string) => {
    try {
        var result = await api.delete(`internalProjects/delete/${id}`);
        var response = result.data;
        return response;
    } catch (error: any) {
        throw new Error(error.response.data);
    }
};



// Método API Projetos internos do ano anterior
const GetByIdLastYearAPI = async (id: string) => {
    try {
        var result = await api.get(`internalProjectsLastYear/getById/${id}`);
        var response = result.data;
        return response;

    } catch (error: any) {
        throw new Error(error.response.data);
    }
};

const GetByInternalProjectsIdLastYearAPI = async (internalProjectsId: string) => {
    try {
        var result = await api.get(`internalProjectsLastYear/GetByInternalProjectsLastYearId/${internalProjectsId}`);
        var response = result.data;
        return response;
    } catch (error: any) {
        throw new Error(error.response.data);
    }
};

const GetAllByServiceIdLastYearAPI = async (serviceId: string) => {
    try {
        var result = await api.get(`internalProjectsLastYear/getAllByServiceId/${serviceId}`);
        var response = result.data;
        return response;
    } catch (error: any) {
        throw new Error(error.response.data);
    }
};

const GetCountAllButtonsByServiceIdAPI = async (serviceId: string) => {
    try {
        var result = await api.get(`internalProjects/GetCountAllButtonsByServiceId/${serviceId}`);
        var response = result.data;
        return response;
    } catch (error: any) {
        throw new Error(error.response.data);
    }
};

const PostInternalProjectsLastYearAPI = async (data: InternalProjectsFormProps) => {
    try {
        var result = await api.post('/internalProjectsLastYear/Post', data);
        var response = result.data;
        return response;
    } catch (error: any) {
        throw new Error(error.response.data);
    }
}

const PutInternalProjectsLastYearAPI = async (data: InternalProjectsFormProps) => {
    try {
        var result = await api.put('/internalProjectsLastYear/Put', data);
        var response = result.data;
        return response;
    } catch (error: any) {
        throw new Error(error.response.data);
    }
}

export {
    GetByIdAPI,
    GetAllByServiceIdAPI,
    GetAllByCompanyIdAPI,
    GetAllPagingByServiceIdAPI,
    PostInternalProjectsAPI,
    PutInternalProjectsAPI,
    PutFiPositioningAPI,
    PutProjectDescriptionAPI,
    DeleteIdAPI,

    GetByIdLastYearAPI,
    GetByInternalProjectsIdLastYearAPI,
    GetAllByServiceIdLastYearAPI,
    PostInternalProjectsLastYearAPI,
    PutInternalProjectsLastYearAPI,
    GetCountAllButtonsByServiceIdAPI
}