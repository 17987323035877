import { yupResolver } from "@hookform/resolvers/yup";
import { Card, MenuItem, Select, TextField, FormControl, InputLabel, Button } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { AiFillHome } from "react-icons/ai";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ContractsModel } from "../../../models/ContractsModel";
import { createContractModuleSchema } from "../../../schemas/createContractModuleSchema";
import ContractFilesModule from "../../../components/ContractModule/ContractFilesModule";
import { CEPInput } from "../../../components/Shared/CEPInput";
import UploadFile from "../../../components/Shared/UploadFile";
import { useEffect, useState } from "react";
import { SelectCustom } from "../../../components/Shared/InternalProjects/SelectCustom";
import { InputCellMaskCustom } from "../../../components/InputsMasks/InputCell/Index";
import { phoneTypeList } from "../../../Static/InternalProjectsStatic";
import { toast } from "react-toastify";
import Loader from "../../../components/Shared/Loader";
import { GetContractByIdAPI, PostContractAPI, PutContractAPI } from "../../../API/ContractsAPI";
import { IResponseProps } from "../../../interfaces/IResponseProps";
import { GetContractsFilesByIdAPI, PostContractFilesAPI, PutContractFilesAPI } from "../../../API/ContractsFilesAPI";
import { ContractsFilesModel } from "../../../models/ContractsFilesModel";
import { CustomCNPJInput } from "../../../components/Shared/CustomCNPJInput";
import "./styles.css";
import { InsertDriveFile } from "@mui/icons-material";

export default function ContractModuleInfos() {
    const { serviceId, contractType, contractId } = useParams();
    const contracts = ["de Assunção", "Tripartite - Convênio", "Tripartite - Projetos Próprios"];
    const [contractFile, setContractFile] = useState<File>();
    const [additives, setAdditives] = useState<ContractsFilesModel[]>([{} as ContractsFilesModel]);
    const [Addendum, setAddendum] = useState<ContractsFilesModel[]>([{} as ContractsFilesModel]);
    const [loading, setLoading] = useState<boolean>(false);
    const [isMobileIncubator, setIsMobileIncubator] = useState(false);
    const [isMobileCompany, setIsMobileCompany] = useState(false);
    //valuesToEdit
    const [contract, setContract] = useState<ContractsModel>();
    const [contractFileModel, setContractFileModel] = useState<ContractsFilesModel>();

    const navigate = useNavigate();

    const { register, getValues, control, reset, setValue } = useForm<ContractsModel>({
        defaultValues: {
            id: "",
            serviceId: serviceId ?? "",
            titleContract: "",
            numberContract: "",
            contractType: parseInt(contractType ?? "0")
        },
        resolver: yupResolver(createContractModuleSchema)
    });

    function addMoreAdditive() {
        setAdditives(prev => [...prev, {} as ContractsFilesModel]);
    }

    function addMoreAddendum() {
        setAddendum(prev => [...prev, {} as ContractsFilesModel]);
    }

    function updateAdditives(position: number, key: string, value: any) {
        var obj = additives;

        obj[position] = {
            ...obj[position],
            [key]: value
        };

        if (value != undefined && obj[position].docType == undefined) {
            obj[position].docType = 1
        }

        setAdditives(obj);
    }

    function updateAddendum(position: number, key: string, value: any) {
        var obj = Addendum;

        obj[position] = {
            ...obj[position],
            [key]: value
        };

        if (value != undefined && obj[position].docType == undefined) {
            obj[position].docType = 2
        }

        setAddendum(obj);
    }

    async function phoneTypeIncubatorChange() {
        setIsMobileIncubator(getValues('typePhoneIncubator') == 0 ? false : true);
    }

    async function phoneTypeCompanyChange() {
        setIsMobileCompany(getValues('typePhoneCompany') == 0 ? false : true);
    }

    async function createContract(contract: ContractsModel) {
        try {
            setLoading(true);

            await PostContractAPI(contract).then((resp: IResponseProps) => {
                if (resp.statusCode == 200) {
                    submitFilesToContract(resp.data.id);
                }
            })
        } catch (error: any) {
            toast.error(error);
        }
    }

    async function submitFilesToContract(contractId: string) {
        try {
            setLoading(true);
            var uploads: ContractsFilesModel[] = [];

            let additivesObj = additives.filter(x => x.file != undefined);
            let AddendumObj = Addendum.filter(x => x.file != undefined);

            if (contractFile) {
                let obj = {
                    file: contractFile,
                    docType: 0,
                } as ContractsFilesModel;

                uploads.push(obj);
            }

            additivesObj.forEach(item => {
                uploads.push(item);
            });

            AddendumObj.forEach(item => {
                uploads.push(item);
            });

            var length = uploads.filter(x => x.file == undefined).length;

            if(length > 0) {
                toast.warning("existem aditivos ou adendos sem arquivo inserido, verifique e tente novamente!");
                return;
            }

            var arrToPromise = await uploads.map((item) => {
                PostContractFilesAPI(item, contractId, serviceId ?? "");
            });

            await Promise.all(arrToPromise);

            toast.success("Contrato criado com sucesso!");
            navigate(`/${serviceId}/contractModule`);

        } catch (error: any) {
            toast.error(error);
        } finally {
            setLoading(false);
        }
    }

    async function editContract(contract: ContractsModel) {
        try {
            setLoading(true);

            await PutContractAPI(contract).then((resp: IResponseProps) => {
                if (resp.statusCode == 200) {
                    updateContractFiles();
                }
            })
        } catch (error: any) {
            toast.error(error);
        }
    }

    async function updateContractFiles() {
        try {
            setLoading(true);
            var uploads: ContractsFilesModel[] = [];

            let additivesObj = additives.filter(x => x.docType != undefined);
            let AddendumObj = Addendum.filter(x => x.docType != undefined);

            additivesObj.forEach(item => {
                uploads.push(item);
            });

            AddendumObj.forEach(item => {
                uploads.push(item);
            });

            var length = uploads.filter(x => x.file == undefined && x.id == undefined).length;

            if(length > 0) {
                toast.warning("existem aditivos ou adendos sem arquivo inserido, verifique e tente novamente!");
                return;
            }

            var arrToPromise = await uploads.map((item) => {
                if(item.id != undefined) {
                    PutContractFilesAPI(item);
                } else {
                    PostContractFilesAPI(item, contract!.id, serviceId ?? "");
                }
            });

            await Promise.all(arrToPromise);

            toast.success("Contrato atualizado com sucesso!");
            navigate(`/${serviceId}/contractModule`);

        } catch (error: any) {
            toast.error(error);
        } finally {
            setLoading(false);
        }
    }

    async function getContract() {
        try {
            setLoading(true);

            await GetContractByIdAPI(contractId ?? "").then((resp: IResponseProps) => {
                if (resp.statusCode == 200) {
                    var data = resp.data as ContractsModel;
                    setContract(data);
                    reset(data);
                    setValue("companyCNPJ", data.companyCNPJ?.toString() ?? "");
                    getContractsFiles();
                    phoneTypeIncubatorChange();
                    phoneTypeCompanyChange();
                }
            })
        } catch (error: any) {
            toast.error(error);
        } finally {
            setLoading(false);
        }
    }

    async function getContractsFiles() {
        try {
            setLoading(true);

            await GetContractsFilesByIdAPI(contractId ?? "").then((resp: IResponseProps) => {
                if (resp.statusCode == 200) {
                    var data = resp.data as ContractsFilesModel[];

                    var fileContract = data.find(x => x.docType == 0);
                    var additivesArray = data.filter(x => x.docType == 1);
                    var addendumArray = data.filter(x => x.docType == 2);

                    setContractFileModel(fileContract);
                    setAdditives([...additivesArray, {} as ContractsFilesModel]);
                    setAddendum([...addendumArray, {} as ContractsFilesModel]);
                }
            })
        } catch (error: any) {
            toast.error(error);
        } finally {
            setLoading(false);
        }
    }

    function createOrUpdate(contract: ContractsModel) {
        //Removing masks
        contract.companyCNPJ = contract.companyCNPJ?.replace(".", "").replace("/", "").replace("-", "") ?? undefined;
        contract.cnpjPartnerCompany = contract.cnpjPartnerCompany?.replace(".", "").replace("/", "").replace("-", "") ?? undefined;
        contract.telephoneOfTheResponsibleIncubator = contract.telephoneOfTheResponsibleIncubator?.replace("(", "").replace(")", "").replace("-", "").replace(" ", "") ?? undefined;
        contract.telephoneOfTheResponsibleCompany = contract.telephoneOfTheResponsibleCompany?.replace("(", "").replace(")", "").replace("-", "").replace(" ", "") ?? undefined;
        contract.incubatorLocation = contract.incubatorLocation?.replace("-", "") ?? undefined;
        contract.partnerCompanyLocation = contract.partnerCompanyLocation?.replace("-", "") ?? undefined;
        contract.typePhoneCompany = parseInt(contract.typePhoneCompany?.toString() ?? "0");
        contract.typePhoneIncubator = parseInt(contract.typePhoneIncubator?.toString() ?? "0");

        if (contract.id != undefined && contract.id != "") {
            editContract(contract);
        } else {
            createContract(contract);
        }
    }

    const submit = () => createOrUpdate(getValues());

    useEffect(() => {
        if (contractId) {
            getContract();
        }
    }, [contractId]);

    return (
        <>
            {loading && <Loader />}
            <div className='card'>
                <div className='card-header'>
                    <div className='breadCrumb'>
                        <Link to={`/${serviceId}/home`} className='breadCrumb-home d-flex align-center'>
                            <AiFillHome className='breadCrumb-home' />
                        </Link>
                        <span className='breadCrumb-separator'>{' > '}</span>
                        <Link to={`/${serviceId}/contractModule`} className='breadCrumb-home d-flex align-center'>
                            <span className="text-bold blue">Módulo de Contratos</span>
                        </Link>
                        <span className='breadCrumb-separator'>{' > '}</span>
                        <span>{contractId != undefined ? "Editar" : "Criar"} Contrato {contractType != undefined ? contracts[parseInt(contractType)] : contract != undefined ? contracts[contract.contractType ?? 0] : ""}</span>
                    </div>
                </div>
                <div className='card-body'>
                    <Card>
                        <div className="card">
                            <div className="card-header">
                                Contrato {contractType != undefined ? contracts[parseInt(contractType)] : contract != undefined ? contracts[contract.contractType ?? 0] : ""}
                            </div>
                            <div className="card-body">
                                <div className="d-flex align-items-center w-100 mb-2">
                                    <Controller
                                        name="titleContract"
                                        control={control}
                                        render={({ field: { ref, ...field } }) => (
                                            <TextField
                                                {...field}
                                                inputRef={ref}
                                                label="Título do Contrato"
                                                variant="outlined"
                                                className="w-75 me-2"
                                            />
                                        )}
                                    />
                                    <Controller
                                        name="numberContract"
                                        control={control}
                                        render={({ field: { ref, ...field } }) => (
                                            <TextField
                                                {...field}
                                                inputRef={ref}
                                                label="Número do Contrato"
                                                variant="outlined"
                                                className="w-25 ms-2"
                                            />
                                        )}
                                    />
                                </div>
                                {contractType == "0" || contract?.contractType == 0 ?
                                    <>
                                        <div className="w-100 d-flex align-items-center mt-3 mb-2">
                                            <FormControl className="w-50 me-2">
                                                <InputLabel id="contractType-label">Tipo de Contrato de Assunção</InputLabel>
                                                <Controller
                                                    render={({ field: { onChange, value } }) => (
                                                        <Select label="Tipo de Contrato de Assunção" onChange={onChange} value={value?.toString() ?? ""}>
                                                            <MenuItem value={0}>Contratada</MenuItem>
                                                            <MenuItem value={1}>Contratante</MenuItem>
                                                        </Select>
                                                    )}
                                                    control={control}
                                                    name="typeContractAsuncion"
                                                />
                                            </FormControl>
                                            <FormControl className="w-50 ms-2">
                                                <InputLabel id="companyProfile-label">Perfil da Empresa no Contrato</InputLabel>
                                                <Controller
                                                    render={({ field: { onChange, value } }) => (
                                                        <Select label="Perfil da Empresa no Contrato" onChange={onChange} value={value?.toString() ?? ""}>
                                                            <MenuItem value={0}>Assunção de Obrigação</MenuItem>
                                                            <MenuItem value={1}>Repasse de Obrigação</MenuItem>
                                                        </Select>
                                                    )}
                                                    control={control}
                                                    name="companyProfile"
                                                />
                                            </FormControl>
                                        </div>
                                        <div className="d-flex align-items-center w-100 mb-3 mt-3">
                                            <Controller
                                                name="corporateName"
                                                control={control}
                                                render={({ field: { ref, ...field } }) => (
                                                    <TextField
                                                        {...field}
                                                        inputRef={ref}
                                                        label="Razão Social da Empresa"
                                                        variant="outlined"
                                                        className="w-75 me-2"
                                                    />
                                                )}
                                            />
                                            <Controller
                                                name="companyCNPJ"
                                                control={control}
                                                render={({ field: { ref, ...field } }) => (
                                                    <TextField
                                                        {...field}
                                                        inputRef={ref}
                                                        InputLabelProps={{ shrink: field.value != undefined || getValues("companyCNPJ") != null }}
                                                        label="CNPJ da Empresa"
                                                        value={getValues("companyCNPJ")}
                                                        variant="outlined"
                                                        className="w-25 ms-2"
                                                        onChange={(e) => { setValue("companyCNPJ", e.target.value) }}
                                                        InputProps={{
                                                            inputComponent: CustomCNPJInput as any
                                                        }}
                                                    />
                                                )}
                                            />
                                        </div>
                                    </>
                                    :
                                    <>
                                        <div className="d-flex align-items-center w-100 mt-3 mb-2">
                                            <Controller
                                                name="nameAffiliatedIncubator"
                                                control={control}
                                                render={({ field: { ref, ...field } }) => (
                                                    <TextField
                                                        {...field}
                                                        inputRef={ref}
                                                        label="Nome da Incubadora Conveniada"
                                                        InputLabelProps={{ shrink: field.value != undefined }}
                                                        variant="outlined"
                                                        className={contract?.contractType == 1 || contractType == "1" ? "w-75 me-2" : "w-100"}
                                                    />
                                                )}
                                            />
                                            {(contract?.contractType == 1 || contractType == "1") &&
                                                <Controller
                                                    name="catiNumber"
                                                    control={control}
                                                    render={({ field: { ref, ...field } }) => (
                                                        <TextField
                                                            {...field}
                                                            inputRef={ref}
                                                            label="Número do CATI"
                                                            variant="outlined"
                                                            className="w-25 ms-2"
                                                        />
                                                    )}
                                                />
                                            }
                                        </div>
                                        <div className="w-100 d-flex align-items-center mt-3 mb-2">
                                            <FormControl className="w-75 me-2">
                                                <InputLabel id="institution-label">Instituição Pública ou Privada?</InputLabel>
                                                <Controller
                                                    render={({ field: { onChange, value } }) => (
                                                        <Select label="Instituição Pública ou Privada?" onChange={onChange} value={value?.toString() ?? ""}>
                                                            <MenuItem value="0">Instituição Pública</MenuItem>
                                                            <MenuItem value="1">Instituição Privada</MenuItem>
                                                        </Select>
                                                    )}
                                                    control={control}
                                                    name="institution"
                                                />
                                            </FormControl>
                                            <Controller
                                                name="incubatorLocation"
                                                control={control}
                                                render={({ field: { ref, ...field } }) => (
                                                    <TextField
                                                        {...field}
                                                        inputRef={ref}
                                                        onChange={(e) => { setValue("incubatorLocation", e.target.value) }}
                                                        label="Localização da Incubadora"
                                                        variant="outlined"
                                                        className="w-25 ms-2"
                                                        InputProps={{
                                                            inputComponent: CEPInput as any
                                                        }}
                                                    />
                                                )}
                                            />
                                        </div>
                                        <div className="d-flex align-items-center w-100 mt-3 mb-3">
                                            <Controller
                                                name="partnerCompanyName"
                                                control={control}
                                                render={({ field: { ref, ...field } }) => (
                                                    <TextField
                                                        {...field}
                                                        inputRef={ref}
                                                        InputLabelProps={{ shrink: field.value != undefined }}
                                                        label="Nome da Empresa Parceira do Projeto"
                                                        variant="outlined"
                                                        className="w-50 me-2"
                                                    />
                                                )}
                                            />
                                            <Controller
                                                name="cnpjPartnerCompany"
                                                control={control}
                                                render={({ field: { ref, ...field } }) => (
                                                    <TextField
                                                        {...field}
                                                        inputRef={ref}
                                                        InputLabelProps={{ shrink: field.value != undefined }}
                                                        label="CNPJ da Empresa Parceira do Projeto"
                                                        variant="outlined"
                                                        className="w-50 ms-2 me-2"
                                                        onChange={(e) => setValue("cnpjPartnerCompany", e.target.value)}
                                                        InputProps={{
                                                            inputComponent: CustomCNPJInput as any
                                                        }}
                                                    />
                                                )}
                                            />
                                            {(contractType == "1" || contract?.contractType == 1) &&
                                                <Controller
                                                    name="partnerCompanyLocation"
                                                    control={control}
                                                    render={({ field: { ref, ...field } }) => (
                                                        <TextField
                                                            {...field}
                                                            inputRef={ref}
                                                            InputLabelProps={{ shrink: field.value != undefined }}
                                                            label="Localização Empresa Parceira"
                                                            variant="outlined"
                                                            className="w-25 ms-2"
                                                            onChange={(e) => setValue("partnerCompanyLocation", e.target.value)}
                                                            InputProps={{
                                                                inputComponent: CEPInput as any
                                                            }}
                                                        />
                                                    )}
                                                />
                                            }
                                        </div>
                                        <h2 className="text-bold responsable-text">Responsável Técnico - Incubadora</h2>
                                        <div className="d-flex align-items-center w-100 mt-3 mb-3">
                                            <Controller
                                                name="responsibleForTheProjectIncubator"
                                                control={control}
                                                render={({ field: { ref, ...field } }) => (
                                                    <TextField
                                                        {...field}
                                                        inputRef={ref}
                                                        InputLabelProps={{ shrink: field.value != undefined }}
                                                        label="Responsável pelo Projeto"
                                                        variant="outlined"
                                                        className="w-50 me-2"
                                                    />
                                                )}
                                            />
                                            <Controller
                                                name="emailOfTheResponsibleIncubator"
                                                control={control}
                                                render={({ field: { ref, ...field } }) => (
                                                    <TextField
                                                        {...field}
                                                        inputRef={ref}
                                                        InputLabelProps={{ shrink: field.value != undefined }}
                                                        label="Email do Responsável"
                                                        variant="outlined"
                                                        className="w-50 ms-2"
                                                    />
                                                )}
                                            />
                                        </div>
                                        <div className="d-flex align-items-center w-100 mt-3 mb-3">
                                            <div className='w-75 me-2'>
                                                <Controller
                                                    name="telephoneOfTheResponsibleIncubator"
                                                    control={control}
                                                    render={({ field: { ref, ...field } }) => (
                                                        <TextField
                                                            {...field}
                                                            inputRef={ref}
                                                            InputLabelProps={{ shrink: field.value != undefined }}
                                                            label="Telefone do Responsável"
                                                            variant="outlined"
                                                            className="w-100 me-2"
                                                            onChange={(e) => setValue("telephoneOfTheResponsibleIncubator", e.target.value)}
                                                            InputProps={{
                                                                inputComponent: InputCellMaskCustom as any,
                                                                inputProps: {
                                                                    isMobile: isMobileIncubator
                                                                },
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </div>
                                            <div className='w-25 ms-2'>
                                                <SelectCustom
                                                    label="Tipo de telefone"
                                                    field="typePhoneIncubator"
                                                    control={control}
                                                    register={register}
                                                    defaultValue={getValues('typePhoneIncubator')?.toString() ?? null}
                                                    list={phoneTypeList}
                                                    haschange={phoneTypeIncubatorChange}
                                                    hasFormControl={false}
                                                />
                                            </div>
                                        </div>
                                        <h2 className="text-bold responsable-text">Responsável Técnico - Empresa</h2>
                                        <div className="d-flex align-items-center w-100 mt-3 mb-3">
                                            <Controller
                                                name="responsibleForTheProjectCompany"
                                                control={control}
                                                render={({ field: { ref, ...field } }) => (
                                                    <TextField
                                                        {...field}
                                                        inputRef={ref}
                                                        InputLabelProps={{ shrink: field.value != undefined }}
                                                        label="Responsável pelo Projeto"
                                                        variant="outlined"
                                                        className="w-50 me-2"
                                                    />
                                                )}
                                            />
                                            <Controller
                                                name="emailOfTheResponsibleCompany"
                                                control={control}
                                                render={({ field: { ref, ...field } }) => (
                                                    <TextField
                                                        {...field}
                                                        inputRef={ref}
                                                        InputLabelProps={{ shrink: field.value != undefined }}
                                                        label="Email do Responsável"
                                                        variant="outlined"
                                                        className="w-50 ms-2"
                                                    />
                                                )}
                                            />
                                        </div>
                                        <div className="d-flex align-items-center w-100 mt-3 mb-3">
                                            <div className='w-75 me-2'>
                                                <Controller
                                                    name="telephoneOfTheResponsibleCompany"
                                                    control={control}
                                                    render={({ field: { ref, ...field } }) => (
                                                        <TextField
                                                            {...field}
                                                            inputRef={ref}
                                                            InputLabelProps={{ shrink: field.value != undefined }}
                                                            label="Telefone do Responsável"
                                                            variant="outlined"
                                                            className="w-100 me-2"
                                                            onChange={(e) => setValue("telephoneOfTheResponsibleCompany", e.target.value)}
                                                            InputProps={{
                                                                inputComponent: InputCellMaskCustom as any,
                                                                inputProps: {
                                                                    isMobile: isMobileCompany
                                                                },
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </div>
                                            <div className='w-25 ms-2'>
                                                <SelectCustom
                                                    label="Tipo de telefone"
                                                    field="typePhoneCompany"
                                                    control={control}
                                                    register={register}
                                                    defaultValue={getValues('typePhoneCompany')?.toString() ?? null}
                                                    list={phoneTypeList}
                                                    haschange={phoneTypeCompanyChange}
                                                    hasFormControl={false}
                                                />
                                            </div>
                                        </div>
                                    </>
                                }
                                <div className="w-100 my-3">
                                    {contractFileModel != undefined ?
                                        <div className="d-flex align-items-center my-4">
                                            <InsertDriveFile />
                                            <span className="ms-2">{contractFileModel.fileName}</span>
                                        </div>
                                        :
                                        <UploadFile disabled={contract?.id != undefined} file={contractFile} setFile={setContractFile} title="Anexo do contrato" />
                                    }
                                </div>
                                <ContractFilesModule
                                    Addendum={Addendum}
                                    addMoreAddendum={addMoreAddendum}
                                    addMoreAdditive={addMoreAdditive}
                                    additives={additives}
                                    updateAdditives={updateAdditives}
                                    updateAddendum={updateAddendum}
                                />
                            </div>
                        </div>
                    </Card>
                    <div className="d-flex w-100 align-items-center justify-content-end mt-3">
                        <Button onClick={submit} color="primary" variant="contained">Salvar</Button>
                    </div>
                </div>
            </div>
        </>
    )
}