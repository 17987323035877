import { Box, Tab, Tabs, Typography } from '@mui/material';
import React, { useState } from 'react';

interface TabsProps {
    tabs: { label: string, content: any, isDisabled: boolean }[];
}

export const TabComponent: React.FC<TabsProps> = ({ tabs }) => {
    const [page, setPage] = useState(0);
    const action: string[] = [];

    return (
        <Box>
            <Tabs value={page} onChange={(e, value) => setPage(value)} >
                {tabs.map((tab, index) => (
                    <Tab key={index} label={tab.label} disabled={tab.isDisabled} />
                ))}
            </Tabs>
            {tabs.map((tab, index) => (
                <div key={index} hidden={index != page}>
                    {index == page && (
                        <Typography component={'span'} variant={'body2'}>{tab.content}</Typography>
                    )}
                </div>
            ))}
        </Box>
    );
}