import { DropzoneInputProps, DropzoneRootProps } from "react-dropzone";
import { FaFileImport, FaTimes } from "react-icons/fa";

interface IUploadProps {
    getRootProps: <T extends DropzoneRootProps>(props?: T | undefined) => T,
    getInputProps: <T extends DropzoneInputProps>(props?: T | undefined) => T,
    selectedFiles: any[],
    onRemoveToTheList: (fileId: number) => void,
    handleSendFiles(): Promise<void>
}

export function Upload(props: IUploadProps) {
    const { getRootProps, getInputProps, selectedFiles, onRemoveToTheList, handleSendFiles } = props;

    return (
        <div className='w-100 mb-4'>
            <div {...getRootProps({ className: 'dropzone mb-4' })}>
                <input {...getInputProps()} />
                <div>
                    {selectedFiles.length == 0 ?
                        <div className='d-flex flex-column align-items-center'>
                            <p>Arraste e solte os arquivos aqui ou clique para selecionar os arquivos</p>
                        </div>
                        :
                        <div id='files'>
                            {
                                selectedFiles.map((file: any, index: number) => {
                                    return (
                                        <div className="card file" title={file.path} key={file.path} >
                                            <div className="card-header d-flex align-items-center justify-content-center flex-column">
                                                <FaFileImport size={60} />
                                                <div className="fileOverflow mt-2">
                                                    <strong className="ms-2">
                                                        {file.path}
                                                    </strong>
                                                </div>
                                                <FaTimes onClick={(e) => { onRemoveToTheList(index); e.stopPropagation() }} className="cardRemoveFileIcon" title="Remover arquivo" />
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    }
                </div>
            </div>
            <div className='d-flex justify-content-end'>
                <button className='btn btn-success' onClick={handleSendFiles} disabled={selectedFiles.length == 0}>Carregar Documentos</button>
            </div>
        </div>
    )
}