import { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import './styles.css';
import Loader from '../../components/Shared/Loader';
import { Link } from 'react-router-dom';
import { AiFillHome } from 'react-icons/ai';
import { CompanyContext } from '../../contexts/companyContext';
import PaginationTabHeader from '../../components/Shared/PaginationTabHeader';
import ActionLogsComponent from '../../components/Logs/ActionLogsComponent';
import XMLLogsComponent from '../../components/Logs/XMLLogsComponent';
import { UsabilityLogsModel } from '../../models/usabilityLogsModel';
import { api } from '../../services/axios';
import { IXMLLogsProps } from '../../interfaces/IXMLLogsProps';
import 'rsuite/dist/rsuite.min.css';
import { SetFileName } from '../../utils/SetFileName';
import { useCan } from '../../hooks/useCan';

interface IUsabilityPagination {
    items: UsabilityLogsModel[];
    total: number;
    currentPage: number;
    word: string;
    take: number;
}

interface IXMLPagination {
    items: IXMLLogsProps[];
    total: number;
    currentPage: number;
    word: string;
    take: number;
}

export default function Logs() {
    const { company } = useContext(CompanyContext);
    const [loading, setLoading] = useState<boolean>(false);
    const [logs, setLogs] = useState<IUsabilityPagination>();
    const [xmlLogs, setXMLLogs] = useState<IXMLPagination>();
    const [logsType, setLogsType] = useState<number>(0);
    const [key, setKey] = useState<number>(0);
    //filters to ActionLogs
    const [severity, setSeverity] = useState<string>("");
    const [action, setAction] = useState<string>("");
    const [screen, setScreen] = useState<string>("");
    const [actionDateStart, setActionDateStart] = useState<Date>();
    const [actionDateEnd, setActionDateEnd] = useState<Date>();
    //filters to XMLLogs
    const [userEmailSelected, setUserEmailSelected] = useState<string>("");
    const [batchNameSelected, setBatchNameSelected] = useState<string>("");
    const [statusSelected, setStatusSelected] = useState<string>("");
    const [allUserEmails, setAllUserEmails] = useState<string[]>([]);
    const [allBatchNames, setAllBatchNames] = useState<string[]>([]);
    const [actionXMLStart, setActionXMLStart] = useState<Date>();
    const [actionXMLEnd, setActionXMLEnd] = useState<Date>();
    //update state
    const [isFiltering, setIsFiltering] = useState<boolean>(false);
    //roles
    const canSee = useCan({ rolesCan: ["Master","Financial", "Technician"] });

    async function GetAllLogs(page: number) {
        try {
            setLoading(true);

            await api.get(`Logs/GetAllLogsFiltered?serviceId=${company.serviceId}&severity=${severity}&action=${action}&screen=${screen}&startDate=${actionDateStart?.toJSON() ?? ""}&endDate=${actionDateEnd?.toJSON() ?? ""}&page=${page}`).then((resp) => {
                var response = resp.data;
                if (response.statusCode == 200) {
                    setLogs(response.data);
                }
            });
        } catch (error: any) {
            toast.error(error);
        } finally {
            setLoading(false);
            setIsFiltering(false);
        }
    }

    async function GetAllXMLLogs(page: number) {
        try {
            setLoading(true);

            await api.get(`Logs/GetAllInvoicingLogsFiltered?serviceId=${company.serviceId}&userEmail=${userEmailSelected}&batchName=${batchNameSelected}&status=${statusSelected}&startDate=${actionXMLStart?.toJSON() ?? ""}&endDate=${actionXMLEnd?.toJSON() ?? ""}&page=${page}`).then((resp) => {
                var response = resp.data;
                if (response.statusCode == 200) {
                    setXMLLogs(response.data);
                }
            });
        } catch (error: any) {
            toast.error(error);
        } finally {
            setLoading(false);
            setIsFiltering(false);
        }
    }

    async function GetAllFilters() {
        try {
            setLoading(true);

            await api.get(`Logs/GetAllFiltersInvoicingLogs?serviceId=${company.serviceId}`).then((resp) => {
                var response = resp.data;
                if (response.statusCode == 200) {
                    var data = response.data;
                    setAllUserEmails(data.userEmails);
                    setAllBatchNames(data.batchNames);
                }
            })
        } catch (error: any) {
            toast.error(error);
        } finally {
            setLoading(false);
        }
    }

    async function GetExcelUsabilityLog() {
        try {
            setLoading(true);

            await api.get(`Logs/DownloadRelatoryUsability?serviceId=${company.serviceId}&severity=${severity}&action=${action}&screen=${screen}&startDate=${actionDateStart?.toJSON() ?? ""}&endDate=${actionDateEnd?.toJSON() ?? ""}`, {
                responseType: 'blob'
            }).then((resp) => {
                const url = window.URL.createObjectURL(new Blob([resp.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'Relatorio-Usabilidade.xlsx');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            })
        } catch(error: any) {
            toast.error(error);
        } finally {
            setLoading(false);
        }
    }

    async function GetExcelXMLLog() {
        try {
            setLoading(true);

            await api.get(`Logs/DownloadRelatoryXML?serviceId=${company.serviceId}&userEmail=${userEmailSelected}&batchName=${batchNameSelected}&status=${statusSelected}&startDate=${actionXMLStart?.toJSON() ?? ""}&endDate=${actionXMLEnd?.toJSON() ?? ""}`, {
                responseType: 'blob'
            }).then((resp) => {
                const url = window.URL.createObjectURL(new Blob([resp.data]));
                const link = document.createElement('a');
                link.href = url;

                link.setAttribute('download', SetFileName(company.title,company.year.toString(), "relatorio-XML", "xlsx"));
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            })
        } catch(error: any) {
            toast.error(error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (logsType == 0) {
            setIsFiltering(true);
            GetAllLogs(1);
        }
        else {
            setIsFiltering(true);
            GetAllFilters();
            GetAllXMLLogs(1);
        }
    }, [logsType]);

    useEffect(() => {
        if ((severity != "" || action != "" || screen != "" || actionDateStart != undefined || actionDateEnd != undefined) && !isFiltering) {
            GetAllLogs(1);
        } else if (!isFiltering) {
            GetAllLogs(1);
        }
    }, [severity, action, screen, actionDateStart, actionDateEnd]);

    useEffect(() => {
        if ((userEmailSelected != "" || batchNameSelected != "" || statusSelected != "" || actionXMLStart != undefined || actionXMLEnd != undefined) && !isFiltering) {
            GetAllXMLLogs(1);
        } else if (!isFiltering) {
            GetAllXMLLogs(1);
        }
    }, [userEmailSelected, batchNameSelected, statusSelected, actionXMLStart, actionXMLEnd]);

    return (
        <>
            {loading && <Loader />}
            <div className='card '>
                <div className='card-header'>
                    <div className='breadCrumb'>
                        <Link to={`/${company.serviceId}/home`} className='breadCrumb-home d-flex align-center'>
                            <AiFillHome className='breadCrumb-home' />
                        </Link>
                        <span className='breadCrumb-separator'>{' > '}</span>
                        <span className='breadCrumb-item-actual'>Relatório de Logs</span>
                    </div>
                </div>
                <div className='card-body'>                    
                    <PaginationTabHeader setPag={setLogsType} pag={logsType} setKey={setKey} keyDefault={key} tabs={!canSee? ["Logs de Ação", "Relatório de Uploads XML"] : ["Relatório de Uploads XML"]} />
                    <div className='log-container'>
                        {logsType == 0 && !canSee ?
                            <ActionLogsComponent
                                key={key}
                                getAllLogs={GetAllLogs}
                                setSeverity={setSeverity}
                                setAction={setAction}
                                setScreen={setScreen}
                                logs={logs?.items}
                                total={logs?.total}
                                setActionDateStart={setActionDateStart}
                                setActionDateEnd={setActionDateEnd}
                                GetExcelUsabilityLog={GetExcelUsabilityLog}
                            />
                            :
                            <XMLLogsComponent
                                key={key}
                                getAllXMLLogs={GetAllXMLLogs}
                                allUserEmails={allUserEmails}
                                setUserEmail={setUserEmailSelected}
                                setBatchName={setBatchNameSelected}
                                setStatus={setStatusSelected}
                                xmlLogs={xmlLogs?.items}
                                total={xmlLogs?.total}
                                allBatchNames={allBatchNames}
                                setActionXMLStart={setActionXMLStart}
                                setActionXMLEnd={setActionXMLEnd}
                                GetExcelXMLLog={GetExcelXMLLog}
                            />
                        }
                    </div>
                </div>
            </div>
        </>
    )
}