function padTo2Digits(num: number) {
    return num.toString().padStart(2, '0');
}

export function formatDate(date: Date, showTime: boolean = true) {
    const dateComplete = [
        padTo2Digits(date.getDate()),
        padTo2Digits(date.getMonth() + 1),
        date.getFullYear(),
    ].join('/');

    return showTime
        ? `${dateComplete} ${padTo2Digits(date.getHours() - 3)}: ${padTo2Digits(date.getMinutes())}`
        : `${dateComplete}`
}

export function formatDateWithNormalHour(date: Date, showTime: boolean = true) {
    const dateComplete = [
        padTo2Digits(date.getDate()),
        padTo2Digits(date.getMonth() + 1),
        date.getFullYear(),
    ].join('/');

    return showTime
        ? `${dateComplete} ${padTo2Digits(date.getHours())}: ${padTo2Digits(date.getMinutes())}`
        : `${dateComplete}`
}

export function formatISODate(dateInput: string) {
    const date = new Date(dateInput)

    const day = date.getDate().toString().padStart(2, '0')
    const month = (date.getMonth() + 1).toString().padStart(2, '0')
    const year = date.getFullYear()

    const formatted = `${day}/${month}/${year}`

    return formatted;
}

export function formatDateMonth(date: Date) {
    return [
        padTo2Digits(date.getMonth() + 1)
    ]
}