import { TextField } from "@mui/material"
import { Control, Controller, FieldError, UseFormGetValues, UseFormRegister, UseFormSetValue } from "react-hook-form"

interface ITextTabProps {
    label?: string,
    field: string,
    control: Control<any, any>,
    fieldErrors?: FieldError | undefined,
    register: UseFormRegister<any>,
    typeMask?: any,
    isDisabled?: boolean,
    isMobile?: boolean,
    multiline?: boolean,
    rows?: string | number | undefined,
    getValues: UseFormGetValues<any>,
    hasFormControl?: boolean,
    onChangeValue?: (e: string) => void,
    value?: string
}

export const TextFieldCustom: React.FC<ITextTabProps> = ({ label, field, control, fieldErrors, register, typeMask, isDisabled = false, isMobile = false, multiline = false, rows, getValues, hasFormControl = true, onChangeValue, value }) => {
    return (
        <Controller
            name="projectName"
            control={control}
            defaultValue=""
            render={() => (
                <TextField
                    label={label}
                    variant="outlined"
                    className={`${hasFormControl ? "form-control" : ""} ${multiline ? 'h-100' : ''} w-100`}
                    multiline={multiline}
                    rows={rows}
                    {...register(field)}
                    onChange={(e) => {onChangeValue != undefined && onChangeValue(e.target.value)}}
                    value={value != undefined ? value : getValues(field)}
                    error={!!fieldErrors}
                    helperText={fieldErrors?.message}
                    InputLabelProps={hasFormControl == true ? {
                        shrink: getValues(field) != "" ? true : false
                    } : undefined}
                    InputProps={{
                        inputComponent: typeMask,
                        inputProps: {
                            isMobile: isMobile
                        },
                    }}
                    disabled={isDisabled}
                />
            )}
        />
    )
}