import { api } from "../../../services/axios";
import { useEffect, useState } from "react";
import Loader from "../../../components/Shared/Loader";
import UpdateProductModelForm, { UpdateProductModelProps } from "../../../components/ProductsModel/UpdateProductModelForm";
import { useParams } from "react-router-dom";


export default function UpdateProductModel() {
    const [loading, setLoading] = useState(false);
    const { id } = useParams();

    const [productModel, setProductModel] = useState({} as UpdateProductModelProps);

    useEffect(() => {
        async function getProductModel() {
            setLoading(true);
            try {
                const result = await api.get(`productmodel/getById/${id}`);
                const response = await result.data;

                const productModelResult = response.data as UpdateProductModelProps;

                productModelResult.createdDate = productModelResult.createdDate != undefined ? productModelResult.createdDate?.toString().split("T")[0] : productModelResult.createdDate;
                productModelResult.approvalDate = productModelResult.approvalDate != undefined ? productModelResult.approvalDate?.toString().split("T")[0] : productModelResult.approvalDate;

                setProductModel(response.data);
            }
            catch (Error: any) {
                console.log('GetProductModelError', Error);
            }
            finally {
                setLoading(false);
            }
        }

        getProductModel();
    }, []);


    return (
        productModel.name ? <UpdateProductModelForm productModel={productModel} /> : <Loader />
    );
}