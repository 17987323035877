import React from 'react';
import { IMaskInput } from 'react-imask'

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
  isMobile: boolean;
}

export const InputCellMaskCustom = React.forwardRef<HTMLInputElement, CustomProps>(
  function TextMaskCustom(props, ref) {
    const { onChange, isMobile, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask={isMobile ? "(00) 0 0000-0000" : "(00) 0000-0000"}
        definitions={{
          '#': /[1-9]/,
        }
        }
        inputRef={ref}
        onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
        overwrite
      />
    );
  },
);