import { useState } from "react";
import { maskCNPJ } from "../../../utils/cnpjUtils"
import { formatCurrencyWithSign } from "../../../utils/formatCurrency"
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import { ObligationModalResponse } from "../../../pages/Obligations";

interface IObligationTableRowProps {
    obligation: ObligationModalResponse;
    index: number;
    type: string;
}

export default function ObligationTableRow({ index, obligation, type }: IObligationTableRowProps) {
    const [show, setShow] = useState<boolean>(false);

    return (
        <tbody className="cursor-pointer">
            {type === "obligationZFM" ?
                <>
                    <tr onClick={() => setShow(!show)}>
                        <td className="d-flex align-items-center">
                            {show ? <BsChevronUp className="me-2" /> : <BsChevronDown className="me-2" />}
                            {maskCNPJ(obligation.cnpj)}
                        </td>
                        <td>{formatCurrencyWithSign(obligation.sales.toFixed(2))}</td>
                        <td>{formatCurrencyWithSign(obligation.returns.toFixed(2))}</td>
                        <td>{formatCurrencyWithSign(obligation.incentivizedAcquisitions.toFixed(2))}</td>
                        <td>{formatCurrencyWithSign(obligation.total.toFixed(2))}</td>
                        <td>{formatCurrencyWithSign(obligation.obligation.toFixed(2))}</td>
                    </tr>
                    {show &&
                        <>
                            <tr className="font-sub-table">
                                <span className="subtitle-inside">Detalhamento por NCM</span>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            {obligation.listChild.map((child, i) => {
                                return (
                                    <tr key={i} className={`${type}-${index} font-sub-table`}>
                                        <td>{child.ncm}</td>
                                        <td>{formatCurrencyWithSign(child.sales.toFixed(2))}</td>
                                        <td>{formatCurrencyWithSign(child.returns.toFixed(2))}</td>
                                        <td>{formatCurrencyWithSign(child.incentivizedAcquisitions.toFixed(2))}</td>
                                        <td>{formatCurrencyWithSign(child.total.toFixed(2))}</td>
                                        <td>{formatCurrencyWithSign(child.obligation.toFixed(2))}</td>
                                    </tr>
                                )
                            })}
                        </>
                    }
                </>
                :
                <>
                    <tr onClick={() => setShow(!show)}>
                        <td className="d-flex align-items-center">
                            {show ? <BsChevronUp className="me-2" /> : <BsChevronDown className="me-2" />}
                            {maskCNPJ(obligation.cnpj)}
                        </td>
                        <td>{formatCurrencyWithSign(obligation.sales.toFixed(2))}</td>
                        <td>{formatCurrencyWithSign(obligation.returns.toFixed(2))}</td>
                        <td>{formatCurrencyWithSign(obligation.total.toFixed(2))}</td>
                        <td>{formatCurrencyWithSign(obligation.obligation.toFixed(2))}</td>
                    </tr>
                    {show &&
                        <>
                            <tr className="font-sub-table">
                                <span className="subtitle-inside">Detalhamento por NCM</span>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            {obligation.listChild.map((child, i) => {
                                return (
                                    <tr key={i} className={`${type}-${index} font-sub-table`}>
                                        <td>{child.ncm}</td>
                                        <td>{formatCurrencyWithSign(child.sales.toFixed(2))}</td>
                                        <td>{formatCurrencyWithSign(child.returns.toFixed(2))}</td>
                                        <td>{formatCurrencyWithSign(child.total.toFixed(2))}</td>
                                        <td>{formatCurrencyWithSign(child.obligation.toFixed(2))}</td>
                                    </tr>
                                )
                            })}
                        </>
                    }
                </>
            }
        </tbody>
    )
}