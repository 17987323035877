import { ReactNode, useState } from "react";

interface FillTableProps {
    columns: number;
    rows: number;

}

export function FillTable({ columns, rows }: FillTableProps) {

    function getRows() {
        if (rows === 7) {
            return null;
        } else if (rows === 0) {
            const tableRows = [];
            tableRows.push(
                <tr key={0}>
                    <td colSpan={columns} className='text-center'>Não há dados para serem visualizados no momento!</td>
                </tr>
            );
            for (let i = 1; i < 7; i++) {
                const rowCells = [];
                for (let j = 0; j < columns; j++) {
                    rowCells.push(<td key={j}><span className='invisible'>Row</span></td>);
                }
                tableRows.push(<tr key={i}>{rowCells}</tr>);
            }
            return (
                <>
                    {tableRows}
                </>
            );
        } else {
            const tableRows = [];
            let rowsToGenerate = 7 - rows;
            for (let i = 0; i < rowsToGenerate; i++) {
                const rowCells = [];
                for (let j = 0; j < columns; j++) {
                    rowCells.push(<td key={j}><span className='invisible'>Row</span></td>);
                }
                tableRows.push(<tr key={i}>{rowCells}</tr>);
            }
            return(
            <>
                {tableRows}
            </>
            )
        }
    }

    return (
        <>
            {getRows()}
        </>
    )
}