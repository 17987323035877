import React, { Fragment, useRef } from 'react';
import Routers from './routes';
import { ToastContainer } from 'react-toastify';
import { Notistack, ToastIcons } from './assets/GlobalStyles';

import './assets/colors.css';
import './assets/global.css';
import './assets/ColorPalette.css';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import { AuthProvider } from 'oidc-react';
import { setSession } from './utils/storageSession';

import { Button, IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';


function App() {
    const notistackRef: any = useRef();
    const oidcConfig = {
        onSignIn: (User: any) => {
            console.log('on sign in');

            window.location.hash = '';
        },
        authority: process.env.REACT_APP_IDENTITY_SERVER_URL,
        clientId: process.env.REACT_APP_CLIENT_ID,
        responseType: 'id_token token',
        redirectUri: window.location.href,
        automaticSilentRenew: true,
    };

    return (
        <Notistack
            iconVariant={{
                success: <ToastIcons.TaskAltOutlined />,
                error: <ToastIcons.ErrorOutline />,
                info: <ToastIcons.InfoOutlined />,
                warning: <ToastIcons.WarningAmberOutlined />,
            }}
            maxSnack={5}
            ref={notistackRef}
            autoHideDuration={null}
            action={(key) => (
                <IconButton size="small" onClick={() => notistackRef.current?.closeSnackbar(key)}>
                    <Close fontSize="small" />
                </IconButton>
            )}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
            <AuthProvider {...oidcConfig}>
                <div className='app'>
                    <Routers />
                </div>
                <ToastContainer autoClose={false} closeOnClick theme='colored' />
            </AuthProvider >
        </Notistack>
    );
}

export default App;
