import { Controller, useForm } from "react-hook-form";
import { DatePickerCustom } from "../../../Shared/InternalProjects/DatePickerCustom";
import UploadFile from "../../../Shared/UploadFile";
import { TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { ContractsFilesModel } from "../../../../models/ContractsFilesModel";
import { createContractFileModuleSchema } from "../../../../schemas/createContractFilesModuleSchema";
import { yupResolver } from "@hookform/resolvers/yup";
import { InsertDriveFile } from "@mui/icons-material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { formatISODate } from "../../../../utils/formatDate";

interface IAdditiveInfosProps {
    item: ContractsFilesModel,
    position: number,
    updateAdditives(position: number, key: string, value: any): void,
}

export default function AdditiveInfos(props: IAdditiveInfosProps) {
    const [file, setFile] = useState<File | undefined>(props.item.file);

    const { control, reset, setValue } = useForm<ContractsFilesModel>({
        resolver: yupResolver(createContractFileModuleSchema)
    });

    useEffect(() => {
        props.updateAdditives(props.position, "file", file);
    }, [file]);

    useEffect(() => {
        if (props.item) {
            reset(props.item)
        }
    }, [props.item]);

    return (
        <div className="w-100 pt-4 card-header">
            <div className="d-flex w-100 align-items-center mb-2">
                <Controller
                    name="additiveIdentification"
                    control={control}
                    render={({ field: { ref, ...field } }) => (
                        <TextField
                            {...field}
                            inputRef={ref}
                            InputLabelProps={{ shrink: field.value != undefined }}
                            label="Identificação do Adendo"
                            variant="outlined"
                            className="w-75 me-2"
                            onChange={(e) => { props.updateAdditives(props.position, "additiveIdentification", e.target.value); setValue("additiveIdentification", e.target.value) }}
                        />
                    )}
                />
                <Controller
                    name="additiveNumber"
                    control={control}
                    render={({ field: { ref, ...field } }) => (
                        <TextField
                            {...field}
                            inputRef={ref}
                            InputLabelProps={{ shrink: field.value != undefined }}
                            label="Nº do Aditivo"
                            variant="outlined"
                            className="w-25 ms-2"
                            onChange={(e) => { props.updateAdditives(props.position, "additiveNumber", e.target.value); setValue("additiveNumber", e.target.value) }}
                        />
                    )}
                />
            </div>
            <div className={`d-flex w-100 ${props.item.file == undefined && props.item.fileName != undefined ? "align-items-center" : "align-items-start"} mt-3 mb-2`}>
                <div className="w-25 me-2">
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <Controller
                            name="dateSubscription"
                            control={control}
                            render={({ field: { ref, value } }) => (
                                <DatePicker
                                    label="Data de Assinatura"
                                    className="w-100 h-100"
                                    format="dd/MM/yyyy"
                                    inputRef={ref}
                                    onChange={(e: any) => props.updateAdditives(props.position, "dateSubscription", e != null ? new Date(e) : undefined)}
                                    value={value != undefined ? new Date(value) : undefined}
                                />
                            )}
                        />
                    </LocalizationProvider>
                </div>
                <div className="w-25 ms-2">
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <Controller
                            name="dateValidity"
                            control={control}
                            render={({ field: { ref, value } }) => (
                                <DatePicker
                                    label="Data de Vigência"
                                    className="w-100 h-100"
                                    format="dd/MM/yyyy"
                                    inputRef={ref}
                                    onChange={(e: any) => props.updateAdditives(props.position, "dateValidity", e != null ? new Date(e) : undefined)}
                                    value={value != undefined ? new Date(value) : undefined}
                                />
                            )}
                        />
                    </LocalizationProvider>
                </div>
                <div className="w-50 ms-2">
                    {props.item.file == undefined && props.item.fileName != undefined ?
                        <div className="d-flex align-items-center">
                            <InsertDriveFile className="me-2" />
                            <span className="ms-2">{props.item.fileName}</span>
                        </div>
                        :
                        <UploadFile disabled={props.item.id != undefined} file={file} setFile={setFile} title="Anexo de Adendo" />
                    }
                </div>
            </div>
        </div>
    )
}