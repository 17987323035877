export interface InternalProjectsModel {
    id: string;
    serviceId: string;
    projectName: string;
    startDate: Date | null;
    endDate: Date | null;
    identification: string;
    acronym: string;
    typology: number;
    other: string | null;
    projectToExchangePPBforRD: number;
    ranges: number;
    coverage: number;
    development: number;
    economicActivity: string;
    hasPublications: boolean | null;
    hasIntellectualProperty: boolean | null;
    nature: number;
    name: string;
    cpf: string | null;
    hasCpf: boolean;
    telephone: string;
    phoneType: number,
    projectScope: string;
    developmentBaseYear: string;
    stepsForeseenProject: string;
    scientificTechnicalProblem: string;
    projectIsTic: boolean;
    technicalScientificProblem: number;
    contextualizationOfTheSteps: number;
    investigativeActivity: number;
    noveltyElement: number;
    content: number | null;
    level: number | null;
    trainedOrQualifiedPersonnel: number | null;
    criterionCFrameworkAnalysis: number;
    observation: string | null;
    lastYearProject: number;
    lastYearProjectId: string | null;
    lastYearProjectImport: number;
    isLastYearProjectImport: boolean;
}

export const ProjectTypology = [
    'Hardware',
    'Hardware com software embarcado',
    'Software',
    'Software aplicado',
    'Melhoria de Processo Produtivo Básico',
    'Formação e Capacitação Profissional',
    'Outro',
]

export const ProjectCriterionCFrameworkAnalysis = [
    "Enquadrado",
    "Não Enquadrado",
    "Talvez",
    "Pendente"
]