import { useContext } from "react";
import { FaTrash } from "react-icons/fa";
import { FileInternalProjectsContext } from "../../../../contexts/fileInternalProjectsContext";
import useModal from "../../../../hooks/useModal";
import DeleteModal from "../../../Shared/DeleteModal";

interface AnexoProps {
    id: string;
}

export function AnexoActions({ id }: AnexoProps) {
    const { handleRemoveFile } = useContext(FileInternalProjectsContext);
    const { isShowing, toggle } = useModal();

    return (
        <>
            <FaTrash className='cursor-pointer' onClick={toggle} />
            <DeleteModal title='Excluir Arquivo' text='Tem certeza que deseja excluir este arquivo?' isShowing={isShowing} hide={toggle} action={() => handleRemoveFile(id)} />
        </>
    )
}