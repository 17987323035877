import { useAuth, User } from 'oidc-react';
import { getSession, setSession } from '../utils/storageSession';
import { UserSessionName } from '../Static/TokenNames';

export function useOAuthUser() {
    const { userData } = useAuth();

    if (userData != null){
        setSession(UserSessionName, JSON.stringify(userData));
    }
    else{
       if(getSession(UserSessionName) != null){
            return  JSON.parse(getSession(UserSessionName) || '{}');
       }
    }

    return userData && userData;
}