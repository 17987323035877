import { useForm } from 'react-hook-form';
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from 'react';
import { api } from '../../../services/axios';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { Box, Card, CardContent, Button, TextField, Typography } from '@mui/material';
import { useCompany } from '../../../hooks/useCompany';
import { useCan } from '../../../hooks/useCan';
import Loader from '../../../components/Shared/Loader';
import { ProductionObservationFormData } from './FormData';


interface ModelEconomicDataProps {
  id: string;
  quantityProduced: number | null;
  grossRevenue: number | null;
  exports: number | null;
  additionalQuantity: number | null;
  yearBased: number;
}

export interface ModelEconomicDataFormsProps {
  id: string | null;
  serviceId: string;
  modelId: string;
  lastYear: ModelEconomicDataProps;
  baseYear: ModelEconomicDataProps;
  nextYear: ModelEconomicDataProps;
  ppbObservation: string;
}

interface PreLoadModelEconomicDataProps {
  // economicData: ModelEconomicDataFormsProps;
  // observation: string;
}

export function ProductionAndObservation({  }: PreLoadModelEconomicDataProps) {
  const [loading, setLoading] = useState(false);
  const [observationField, setObservationField] = useState("");
  const [economicEntity, setEconomicEntity] = useState<ModelEconomicDataFormsProps>({} as ModelEconomicDataFormsProps);
  const { modelId } = useParams();
  const { company } = useCompany();

  async function handleCreateEconomicData(data: ModelEconomicDataFormsProps) {
    setLoading(true);
    data.modelId = modelId != undefined ? modelId : '';
    data.serviceId = company.serviceId;

    data.lastYear.yearBased = 0;
    data.baseYear.yearBased = 1;
    data.nextYear.yearBased = 2;
    data.ppbObservation = observationField;

    try {
      const result = await api.post('modeleconomicdata/post', data);
      const response = result.data;

      if (response.statusCode === 200) {
        toast.success('Dados Econômicos Lançados com Sucesso!');
        setEconomicEntity(response.data);
      }
    }
    catch (error: any) {
      toast.error(error);
    }
    finally {
      setLoading(false);
    }
  }

  async function handleUpdateEconomicData(data: ModelEconomicDataFormsProps) {
    setLoading(true);
    data.lastYear.yearBased = 0;
    data.baseYear.yearBased = 1;
    data.nextYear.yearBased = 2;
    data.ppbObservation = observationField;
    data.modelId = modelId != undefined ? modelId : '';
    data.serviceId = company.serviceId;

    try {
      const result = await api.put('modeleconomicdata/put', data);
      const response = result.data;

      if (response.statusCode === 200) {
        toast.success('Dados Econômicos Lançados com Sucesso!');
        setEconomicEntity(response.data);
      }
    }
    catch (error: any) {
      toast.error(error);
    }
    finally {
      setLoading(false);
    }
  }

  async function getObservation() {
    const result = await api.get(`productModel/getObservation/${modelId}`);
    const response = await result.data;

    if (response.statusCode === 200) {
      setObservationField(response.data.ppbObservation);
    }
  }

  async function getEconomicData() {
    setLoading(true);

    try {
      const result = await api.get(`modeleconomicdata/GetByModel/${modelId}`);
      const response = result.data;
      if (response.statusCode === 200) {

        setEconomicEntity(response.data);
      }
    }
    catch (error: any) {
      toast.error(error);
    }
    finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getEconomicData();
    getObservation();
  }, [])

  return (
    <>
      {loading && <Loader />}
      <Box className='bg-light w-100 h-100 p-4 border'>
        <Card>
          {
            economicEntity.modelId &&
            <ProductionObservationFormData
              economicData={economicEntity}
              handleCreateEconomicData={handleCreateEconomicData}
              handleUpdateEconomicData={handleUpdateEconomicData}
              observationField={observationField}
              setObservationField={setObservationField}
            />
          }
        </Card>
      </Box>
    </>
  )
}