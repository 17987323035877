import { useEffect, useState } from "react";
import { AiFillFileExcel, AiFillHome } from "react-icons/ai";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import AcquistionAction from "../../../components/Acquisitions/AcquisitionAction";
import { UpdateProductFormProps } from "../../../components/Products/UpdateProductForm";
import Loader from "../../../components/Shared/Loader";
import { TablePaginationFooter } from "../../../components/Shared/Pagination";
import { useCompany } from "../../../hooks/useCompany";
import useModal from "../../../hooks/useModal";
import { IInconsistencyDetailsProps } from "../../../interfaces/IInconsistencyDetailsProps";
import { AcquisitionWithCancellingCorrectionModel } from "../../../models/acquisitionsModel";
import { api } from "../../../services/axios";
import { formatDate, formatDateMonth } from "../../../utils/formatDate";
import './styles.css';
import Form from 'react-bootstrap/Form';
import { AcquisitionsInconsistenciesModal } from "../../../components/Acquisitions/AcquisitionsInconsistenciesModal";
import { UploadModal } from "../../../components/Shared/UploadModal";
import { BsExclamationLg, BsFillCloudArrowDownFill, BsFillCloudArrowUpFill } from "react-icons/bs";
import { FillTable } from "../../../components/Shared/FillTable";
import { sumAcquisitionTotalValue, sumAcquisitionTotalValueWithTaxes } from "../../../utils/acquisitionCalcs";
import Select from 'react-select';
import { IInvoicingFiltersVO } from "../../../interfaces/IInvoicingFiltersVO";
import { ISelectProps } from "../../../interfaces/ISelectProps";
import { maskCNPJ } from "../../../utils/cnpjUtils";
import { Button } from "@mui/material";

interface IAcquisitionsPaginationProps {
    items: AcquisitionWithCancellingCorrectionModel[];
    total: number;
    currentPage: number;
    word: string;
    take: number;
}

export interface IJustificationAcquisitionProps {
    acquisitionId: string,
    justification: string,
    considerBilling: boolean,
    inconsistencyId: number
}

export default function AcquisitionsByProduct() {
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const [searchText, setSearchText] = useState("");
    const [acquisitionsPagination, setAcquisitionsPagination] = useState<IAcquisitionsPaginationProps>();
    const [inconsistencyDetails, setInconsistencyDetails] = useState<IInconsistencyDetailsProps[]>([]);
    const { isShowing: isShowingInconsistency, toggle: toggleInconsistency } = useModal();
    const [isVisible, setIsVisible] = useState(false);
    const [selectedValue, setSelectedValue] = useState(0);
    const [checkedInconsistencies, setCheckedInconsistencies] = useState<string[]>([]);
    const [checkAllInconsistencies, setCheckAllInconsistencies] = useState(false);
    const [justification, setJustification] = useState<IJustificationAcquisitionProps>();
    const [filterByPeriod, setFilterByPeriod] = useState<string>("0");
    const [product, setProduct] = useState({} as UpdateProductFormProps);
    const { isShowing, toggle } = useModal();
    const [file, setFile] = useState<Blob>();
    const { productId, isNotLinked } = useParams();
    const { company } = useCompany();
    const [ncmsSelect, setNcmsSelect] = useState<ISelectProps[]>([]);
    const [cnpjsSelect, setCnpjsSelect] = useState<ISelectProps[]>([]);
    const [periodsSelect, setPeriodsSelect] = useState<ISelectProps[]>([]);
    const [ncmsSelected, setNcmsSelected] = useState<string[]>([]);
    const [cnpjsSelected, setCnpjsSelected] = useState<string[]>([]);
    const [periodsSelected, setPeriodsSelected] = useState<string[]>([]);
    const [alreadyStarted, setAlreadyStarted] = useState<boolean>(false);

    async function handleClick() {
        setIsVisible(true);
        await getAllWithInconsistency();
    };

    function onClose() {
        toggleInconsistency();
        getAllByInconsistency();
        setJustification(undefined);
        setCheckAllInconsistencies(false);
        setCheckedInconsistencies([]);
    }

    async function getProduct() {
        setLoading(true);
        try {
            const result = await api.get(`product/getById/${productId}`);
            const response = await result.data;

            setProduct(response.data);

        }
        catch (error: any) {
            toast.error(error);
        }
        finally {
            setLoading(false);
        }
    }

    async function getAllWithInconsistency(page?: number) {
        setLoading(true);
        try {
            let text = searchText == null || searchText == "" ? "\"\"" : searchText;

            await api.get(`acquisitions/GetAllWithInconsistency/productId=${productId}&serviceId=${company.serviceId}&page=${page ?? 1}&take=7&word=${encodeURIComponent(text)}`).then(resp => {
                const response = resp.data;

                setAcquisitionsPagination(response.data);
    
                if (page) {
                    setCurrentPage(page);
                }
            });
        }
        catch (error: any) {
            toast.error(error);
        }
        finally {
            setLoading(false);
        }
    }

    async function getAllByInconsistency(page?: number) {
        setLoading(true);
        try {
            let text = searchText == null || searchText == "" ? "\"\"" : searchText;

            const result = await api.get(`acquisitions/GetAllByInconsistencyType/productId=${productId}&serviceId=${company.serviceId}&inconsistencyType=${selectedValue}&page=${page ?? 1}&take=7&word=${encodeURIComponent(text)}`);
            const response = await result.data;

            setAcquisitionsPagination(response.data);

            if (page) {
                setCurrentPage(page);
            }
        }
        catch (error: any) {
            toast.error(error);
        }
        finally {
            setLoading(false);
        }
    }

    async function handleDeleteInvoicing(id: string) {
        setLoading(true);
        try {
            const result = await api.delete('acquisitions/delete', {
                data: {
                    id: id,
                    serviceId: company.serviceId
                }
            });

            const response = await result.data;

            if (response.statusCode === 200) {
                toast.success('Faturamento deletado com sucesso!');

                filterAll(currentPage);
            }

        }
        catch (error: any) {
            toast.error(error);
        }
        finally {
            setLoading(false);
        }
    }

    async function getIncosistency() {
        setLoading(true);
        try {
            const result = await api.get(`inconsistency/GetToAcquisition`);
            const response = await result.data;

            const data: IInconsistencyDetailsProps[] = response.data;

            setInconsistencyDetails(data);
        } catch (error: any) {
            toast.error(error);
        } finally {
            setLoading(false);
        }
    }

    async function getAllNotLinked(page?: number) {
        setLoading(true);
        try {
            let text = searchText == null || searchText == "" ? "\"\"" : searchText;
            const result = await api.get(`acquisitions/GetAllNotLinked/serviceId=${company.serviceId}&page=${page ?? 1}&take=7&word=${encodeURIComponent(text)}`);
            const response = await result.data;
            setAcquisitionsPagination(response.data);
            if (page) {
                setCurrentPage(page);
            }
        }
        catch (error: any) {
            toast.error(error);
        }
        finally {
            setLoading(false)
        }
    }

    function handleSelection(inconsistencyId: number) {
        setSelectedValue(inconsistencyId);
    }

    async function getJustificationByAcquisitionId(acquisitionId: string) {
        try {
            setLoading(true);

            await api.get(`acquisitions/GetJustificationByAcquisitionId/acquisitionId=${acquisitionId}&inconsistencyType=${selectedValue}`).then((resp) => {
                if (resp.status == 200) {
                    setJustification(resp.data.data);
                }
            });
        } catch (error: any) {
            toast.error(error);
        } finally {
            setLoading(false);
        }
    }

    async function getFilters() {
        try {
            setLoading(true);

            await api.get(`Acquisitions/GetAllFilters/productId=${productId}&serviceId=${company.serviceId}`).then((resp) => {
                if (resp.data.statusCode === 200) {
                    var data = resp.data.data as IInvoicingFiltersVO;

                    var ncmList = [] as ISelectProps[];
                    var cnpjList = [] as ISelectProps[];
                    var periodList = [] as ISelectProps[];

                    data.ncMs.forEach(x => {
                        ncmList.push({ label: x, value: x } as ISelectProps);
                    });

                    data.cnpJs.forEach(x => {
                        cnpjList.push({ label: maskCNPJ(x), value: x } as ISelectProps);
                    });

                    data.periods.forEach(x => {
                        periodList.push({ label: x, value: x } as ISelectProps);
                    });

                    setNcmsSelect(ncmList);
                    setCnpjsSelect(cnpjList);
                    setPeriodsSelect(periodList);
                }
            });
        } catch (error: any) {
            toast.error(error);
        } finally {
            setLoading(false);
        }
    }

    function handleToggleCheckAll() {
        var isTrue = checkAllInconsistencies;

        var items = acquisitionsPagination ? acquisitionsPagination.items.filter(x => x.acquisition.alreadyJustificated == false).map(x => x.acquisition.id) : [];

        setCheckedInconsistencies(isTrue ? [] : items);
    }

    function handleCheckInconsistency(checkedInconsistency: string) {
        if (checkedInconsistencies.some(e => e === checkedInconsistency)) {
            const filteredList = checkedInconsistencies.filter(item => {
                return item != checkedInconsistency;
            });

            setCheckedInconsistencies(filteredList);
        }
        else {
            setCheckedInconsistencies(ant => [...ant, checkedInconsistency]);
        }
    }

    async function filterAll(page?: number) {
        try {
            setLoading(true);
            let text = searchText == null || searchText == "" ? "\"\"" : searchText;

            await api.post('/Acquisitions/FilterAll', {
                productId: productId,
                serviceId: company.serviceId,
                ncmList: ncmsSelected,
                periodList: periodsSelected,
                cnpjList: cnpjsSelected,
                page: page ?? 1,
                take: 7,
                word: text
            }).then((resp) => {
                const response = resp.data;
                setAcquisitionsPagination(response.data);
                setCurrentPage(page ?? 1);
                setAlreadyStarted(false);
            });
        } catch (error: any) {
            toast.error(error);
        } finally {
            setLoading(false);
        }
    }

    const handleSelectCNPJ = (e: ISelectProps[]) => {
        var values = e.map((info: ISelectProps) => info.value);
        setCnpjsSelected(values);
    }

    const handleSelectNCM = (e: ISelectProps[]) => {
        var values = e.map((info: ISelectProps) => info.value);
        setNcmsSelected(values);
    }

    const handleSelectPeriod = (e: ISelectProps[]) => {
        var values = e.map((info: ISelectProps) => info.value);
        setPeriodsSelected(values);
    }

    useEffect(() => {
        if (!isNotLinked) {
            getProduct();
            getFilters();
            getIncosistency();
        } else if (isNotLinked == "true") {
            getAllNotLinked();
        }
    }, []);

    useEffect(() => {
        if (ncmsSelected.length >= 0 || cnpjsSelected.length >= 0 || periodsSelected.length >= 0) {
            if (!alreadyStarted) {
                setAlreadyStarted(true);
            }
        }
    }, [ncmsSelected, cnpjsSelected, periodsSelected]);

    useEffect(() => {
        if (alreadyStarted) {
            filterAll();
        }
    }, [alreadyStarted]);

    useEffect(() => {
        if (selectedValue > 0) {
            getAllByInconsistency();
        }
    }, [selectedValue]);

    useEffect(() => {
        var length = acquisitionsPagination?.items.filter(x => x.acquisition.alreadyJustificated == false).length ?? 0;

        if (checkedInconsistencies.length > 0 && checkedInconsistencies.length == length) {
            setCheckAllInconsistencies(true);
        } else {
            setCheckAllInconsistencies(false);
        }
    }, [checkedInconsistencies]);

    useEffect(() => {
        if (justification != undefined) {
            toggleInconsistency();
        }
    }, [justification]);

    async function uploadMassiveAcquisitions() {
        setLoading(true);
        try {
            let formData = new FormData();

            if (file != undefined) {
                formData.append('file', file);
            }

            const result = await api.post(`JustificationAcquisition/ReadExcel/2/${company.serviceId}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            const response = await result.data;

            const data = response.data;

            if (response.statusCode === 200) {
                toast.success(`As inconsistências foram tratadas com sucesso. ${data.notSaved} linhas foram desconsideradas por serem inconsistências não justificáveis.`);

            }
        }
        catch (err: any) {
            toast.error(err);
        }
        finally {
            setLoading(false);
        }
    }

    async function handleDownloadExcelMassiveJustificationAcquisition() {
        setLoading(true)
        try {
            await api.get(`justification/DownloadExcel/2/${company.serviceId}/${productId}/${filterByPeriod}`, {
                responseType: 'blob'
            })
                .then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'Aquisicoes-Inconsistencias.xlsx');
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                });
        }
        catch (error: any) {
            toast.error(error);
        }
        finally {
            setLoading(false);
        }
    }

    return (
        <>
            {loading && <Loader />}
            <div className='card'>
                <div className='card-header'>
                    <div className='breadCrumb'>
                        <Link to={`/${company.serviceId}/home`} className='breadCrumb-home d-flex align-center'>
                            <AiFillHome className='breadCrumb-home' />
                        </Link>
                        <span className='breadCrumb-separator'>{' > '}</span>
                        <Link to={`/${company.serviceId}/${company.companyId}/acquisitions`}><span className=''>II.2. Aquisições</span></Link>
                        <span className='breadCrumb-separator'>{' > '}</span>
                        <span className='breadCrumb-item-actual'>{isNotLinked && isNotLinked == "true" ? 'Itens não Faturados' : `Produto ${product.name}`}</span>
                    </div>
                </div>
                <div className='card-body'>
                    <div className='card-body bg-light w-100 h-100 p-4 border'>
                        {!isNotLinked &&
                            <>
                                <div className="d-flex justify-content-end aling-items-center">
                                    {isVisible ?
                                        <select
                                            className={`${isVisible ? 'set-block' : 'set-hidden'} form-select w-100 mb-4 mr-2 `}
                                            defaultValue={selectedValue}
                                            onChange={(e) => {
                                                handleSelection(parseInt(e.target.value));
                                                parseInt(e.target.value) == 0 && setCheckedInconsistencies([]);
                                                parseInt(e.target.value) == 0 && setCheckAllInconsistencies(false);
                                            }}
                                        >
                                            <option value="0">Filtro de Inconsistências</option>
                                            {inconsistencyDetails?.map(model => {
                                                return (
                                                    <option value={model.id} key={model.id}>{model.inconsistencyText}</option>
                                                );
                                            })}
                                        </select>
                                        :
                                        <div className="w-100 d-flex flex-column">
                                            <div className="mb-4 d-flex justify-content-end" >
                                                <Button variant="contained" className="btn me-2 fw-bold" >Extração Excel</Button>
                                                <Button variant="contained" className="btn  fw-bold" onClick={handleClick}>Inconsistências</Button>
                                            </div>
                                            <div className="w-100 d-flex align-items-center mb-4">
                                                <div className="w-100 me-2">
                                                    <label>Buscar Por NCM:</label>
                                                    <Select
                                                        isMulti
                                                        onChange={(e: any) => handleSelectNCM(e)}
                                                        options={ncmsSelect}
                                                        className="basic-multi-select"
                                                        classNamePrefix="select"
                                                    />
                                                </div>
                                                <div className="w-100 me-2">
                                                    <label>Buscar Por Período:</label>
                                                    <Select
                                                        isMulti
                                                        onChange={(e: any) => handleSelectPeriod(e)}
                                                        options={periodsSelect}
                                                        className="basic-multi-select"
                                                        classNamePrefix="select"
                                                    />
                                                </div>
                                                <div className="w-100">
                                                    <label>Buscar Por CNPJ</label>
                                                    <Select
                                                        isMulti
                                                        onChange={(e: any) => handleSelectCNPJ(e)}
                                                        options={cnpjsSelect}
                                                        className="basic-multi-select"
                                                        classNamePrefix="select"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                                <div className={`${isVisible ? 'd-block' : 'd-none'} mb-4 d-flex justify-content-end`} >
                                    <div className='d-flex align-items-center justify-content-end w-100'>
                                        {company.counting === 1 &&
                                            <select className="form-select w-25 me-2" value={filterByPeriod} onChange={e => setFilterByPeriod(e.target.value)}>
                                                <option value="0">Consolidado</option>
                                                <option value="1">1º trimestre</option>
                                                <option value="2">2º trimestre</option>
                                                <option value="3">3º trimestre</option>
                                                <option value="4">4º trimestre</option>
                                            </select>
                                        }
                                        <Button variant="contained" className='btn  me-2 fw-bold' onClick={handleDownloadExcelMassiveJustificationAcquisition}>
                                            <BsFillCloudArrowDownFill className='me-2' size={20} />
                                            Modelo de Excel
                                        </Button>
                                        <Button variant="contained" className='btn  fw-bold' onClick={toggle}>
                                            <BsFillCloudArrowUpFill className='me-2' size={20} />
                                            Upload Modelo
                                        </Button>
                                    </div>
                                </div>
                            </>
                        }
                        <table className='table table-bordered table-striped table-hover mb-4'>
                            <thead>
                                <tr>
                                    <th scope="col">Nº da Nota</th>
                                    <th scope="col">NCM</th>
                                    <th scope="col">Descrição do Item da Nota Fiscal</th>
                                    <th scope="col">Mês de Emissão</th>
                                    <th scope="col">Data de Emissão</th>
                                    <th scope="col">Valor Total + Impostos</th>
                                    <th scope="col">Valor de base de calculo</th>
                                    <th scope="col">{(isVisible && selectedValue != 0 && (acquisitionsPagination?.items.filter(x => x.acquisition.alreadyJustificated == null || x.acquisition.alreadyJustificated == false).length ?? 0) > 0) ? <Form.Check readOnly={true} className='cursor-pointer d-flex align-items-center justify-content-center mb-1' checked={checkAllInconsistencies} onClick={handleToggleCheckAll} /> : "Ações"}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {acquisitionsPagination?.items.map((model, index) => {
                                    return (
                                        <tr key={index}>
                                            <th scope="row">
                                                {model.acquisition.acquisitionNum}
                                                {model.hasCancelling && <BsExclamationLg className='me-2 ms-2' title='Essa nota foi cancelada e seu faturamento não será considerado' />}
                                                {model.hasCorrection && <BsExclamationLg title='Essa nota fiscal possui uma carta de correção vinculada' />}
                                            </th>
                                            <td>{model.acquisition.itemNCM}</td>
                                            <td>{model.acquisition.itemName}</td>
                                            <td>{model.acquisition.acquisitionDate != null ? formatDateMonth(new Date(model.acquisition.acquisitionDate)) : ""}</td>
                                            <td>{model.acquisition.acquisitionDate != null ? formatDate(new Date(model.acquisition.acquisitionDate)) : ""}</td>
                                            <td>{sumAcquisitionTotalValueWithTaxes(model.acquisition)}</td>
                                            <td>{sumAcquisitionTotalValue(model.acquisition)}</td>
                                            <AcquistionAction
                                                selectedValue={selectedValue}
                                                isVisible={isVisible}
                                                id={model.acquisition.id}
                                                isLinked={!!isNotLinked}
                                                action={() => handleDeleteInvoicing(model.acquisition.id)}
                                                checkAllInconsistencies={checkAllInconsistencies}
                                                checkedInconsistencies={checkedInconsistencies}
                                                handleCheckInconsistency={handleCheckInconsistency}
                                                alreadyJustificated={model.acquisition.alreadyJustificated ?? false}
                                                getJustificationByAcquisitionId={getJustificationByAcquisitionId}
                                            />
                                        </tr>
                                    );
                                })}
                                {acquisitionsPagination?.items && <FillTable columns={8} rows={acquisitionsPagination?.items.length} />}
                            </tbody>
                        </table>
                        {checkedInconsistencies.length > 0 &&
                            <div className="d-flex justify-content-center align-items-center">
                                <Button variant="contained" className={`${(isVisible && selectedValue != 0) ? 'set-block' : 'set-hidden'} btn  p-2 mr-2 mb-4 text-center`} onClick={toggleInconsistency}>Justificar</Button>
                            </div>
                        }
                        {acquisitionsPagination?.items &&
                            <TablePaginationFooter
                                totalItems={acquisitionsPagination?.total}
                                change={isNotLinked ? getAllNotLinked : filterAll}
                            />
                        }
                        {isShowingInconsistency &&
                            <AcquisitionsInconsistenciesModal
                                toggle={onClose}
                                justificationProp={justification}
                                isShowing={isShowingInconsistency}
                                inconsistencyId={selectedValue}
                                checkedInconsistencies={checkedInconsistencies}
                            />
                        }
                        {isShowing && <UploadModal
                            isShowing={isShowing}
                            toggle={toggle}
                            icon={AiFillFileExcel}
                            setFile={setFile}
                            action={uploadMassiveAcquisitions}
                        />}
                    </div>
                </div>
            </div>
        </>
    );
}