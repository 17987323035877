export const ContractColumns = [
    {
        name: "Tipo de Contrato",
        id: "contractTypeText"
    },
    {
        name: "Número de Contrato",
        id: "numberContract"
    },
    {
        name: "Título do Contrato",
        id: "titleContract"
    },
    {
        name: "Empresa Parceira",
        id: "partnerCompanyName"
    },
    {
        name: "Contrato Anexado",
        id: "hasContractText"
    },
];