import { FaEdit, FaTrash } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useCompany } from "../../../hooks/useCompany";
import useModal from "../../../hooks/useModal";
import DeleteModal from "../../Shared/DeleteModal";
import { useCan } from "../../../hooks/useCan";

interface ProductModelActionProps {
    id: string;
    action: any;
    isTecnacPage: boolean;
    isEnabled: boolean;
}

export default function ProductModelAction({ id, isTecnacPage, action, isEnabled }: ProductModelActionProps) {
    const { isShowing, toggle } = useModal();
    const { company } = useCompany();
    const permissionFinancial = useCan({ rolesCan: ["Financial"] });

    return (
        <>{!permissionFinancial &&
            <>
                <td>
                    <Link to={`/${company.serviceId}/models/${id}/${isTecnacPage}/characterization`}><FaEdit className='ms-2 cursor-pointer' size={20} /></Link>
                    {isEnabled && <FaTrash className='ms-2 cursor-pointer' onClick={toggle} />}
                </td><DeleteModal title='Excluir Produto' text='Tem certeza que deseja excluir este modelo de produto?' isShowing={isShowing} hide={toggle} action={action} />
            </>

        }

        </>
    )
}