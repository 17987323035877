import { useContext, useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { toast } from "react-toastify";
import { CompanyContext } from "../../../contexts/companyContext";
import { ProductsBarChartHomeModel } from "../../../models/productsBarChartHomeModel";
import { api } from "../../../services/axios";
import { maskCNPJ } from "../../../utils/cnpjUtils";

interface IBarChartProps {
    cnpjs: string[],
    setLoading: React.Dispatch<React.SetStateAction<boolean>>
}

export default function BarChart(props: IBarChartProps) {
    const { company } = useContext(CompanyContext);
    const [quarterSelected, setQuarterSelected] = useState<boolean>(false);
    const [productsBarChart, setProductsBarChart] = useState<ProductsBarChartHomeModel[]>([]);
    const [productsBarChartQuarterly, setProductsBarChartQuarterly] = useState<ProductsBarChartHomeModel[][]>([]);
    const [cnpj, setCnpj] = useState<string>("");

    async function getAllInvoicingByProducts() {
        try {
            props.setLoading(true);

            await api.get(`/Home/GetAllInvoicingByProducts?serviceId=${company.serviceId}&cnpj=${cnpj == "" ? "\"\"" : cnpj}&isQuarter=${quarterSelected}`).then((resp) => {
                if (resp.status == 200) {
                    if(quarterSelected) {
                        setProductsBarChartQuarterly(resp.data.data ?? []);
                    } else {
                        setProductsBarChart(resp.data.data ?? []);
                    }
                }
            });
        } catch (error: any) {
            toast.error(error);
        } finally {
            props.setLoading(false);
        }
    }

    const optionsBar = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false,
            },
        },
    };

    const optionsQuarterBar = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false,
            },
        },
        scales: {
            x: {
                stacked: true,
            },
            y: {
                stacked: true,
            },
        },
    };

    const dataBar = {
        labels: productsBarChart.map(x => x.productName),
        datasets: [
            {
                label: 'R$',
                data: productsBarChart.map(x => x.invoicing),
                backgroundColor: '#0100A4',
                barThickness: 110
            },
        ],
    };

    const dataQuarterBar = {
        labels: productsBarChartQuarterly.map(x => x[0].productName),
        datasets: [
            {
                label: '1º Trimestre (R$)',
                data: productsBarChartQuarterly.map(x => x[0].invoicing),
                backgroundColor: '#0000a0',
                barThickness: 110
            },
            {
                label: '2º Trimestre (R$)',
                data: productsBarChartQuarterly.map(x => x[1].invoicing),
                backgroundColor: '#0079f6',
                barThickness: 110
            },
            {
                label: '3º Trimestre (R$)',
                data: productsBarChartQuarterly.map(x => x[2].invoicing),
                backgroundColor: '#00c6de',
                barThickness: 110
            },
            {
                label: '4º Trimestre (R$)',
                data: productsBarChartQuarterly.map(x => x[3].invoicing),
                backgroundColor: '#00e9bf',
                barThickness: 110
            },
        ],
    };

    useEffect(() => {
        if (props.cnpjs.length > 0) {
            getAllInvoicingByProducts();
        }
    }, [props.cnpjs, cnpj, quarterSelected]);

    return (
        <div className='w-100 me-2'>
            <div className='w-100 mt-2 font-size-title d-flex align-items-center justify-content-between mb-4'>
                <div>
                    <select onChange={e => setCnpj(e.target.value)} className="form-select w-100">
                        <option value="" selected>Todos</option>
                        {props.cnpjs.map((cnpj, index) => {
                            return (
                                <option value={cnpj} key={index}>{maskCNPJ(cnpj)}</option>
                            )
                        })}
                    </select>
                </div>
                <span className="text-bold">Faturamento por Produto Habilitado</span>
                {company.counting == 1 ?
                    <div className="d-flex align-items-center">
                        <span className="me-2 mb-1 text-bold">Filtro Trimestral</span>
                        <div className="form-check form-switch">
                            <input className="form-check-input" onChange={e => setQuarterSelected(e.target.checked)} type="checkbox" role="switch" />
                        </div>
                    </div>
                    :
                    <div className="w-25"></div>
                }
            </div>
            <Bar className='chart-body' options={quarterSelected ? optionsQuarterBar : optionsBar} data={quarterSelected ? dataQuarterBar : dataBar} />
        </div>
    )
}