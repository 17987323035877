import { AiOutlineClose } from "react-icons/ai"
import { FaTrash } from "react-icons/fa";
import useModal from "../../../hooks/useModal";
import DeleteModal from "../../Shared/DeleteModal";
import { ProductModelProps } from "../../../interfaces/ProductModel/IProductModel";

interface ComponentsTableActions {
    model?: ProductModelProps | null;
    action: ((id: string) => void) | ((id: string) => Promise<void>);
    id: string;
}

export function ComponentsTableActions({ model, id, action }: ComponentsTableActions) {
    const { isShowing, toggle } = useModal();
    return (
        <>
            <td width='100px'>
                {
                   model && model.habilitationStatus != 2 &&
                    <div className='icon-center' onClick={toggle}>
                        <FaTrash />
                    </div>
                }
            </td>
            <DeleteModal
                title={"Excluir Componente"}
                text={"Tem certeza que deseja excluir o componente selecionado?"}
                buttonSaveTitle='Deletar'
                isShowing={isShowing}
                hide={toggle}
                action={() => action(id)}
            />
        </>
    );
}