import { FaCloudUploadAlt, FaArrowCircleRight, FaFileDownload } from "react-icons/fa";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Product } from "../../models/productModel";
import { api } from "../../services/axios";
import { AiFillHome } from "react-icons/ai";
import { toast } from "react-toastify";
import { TablePaginationFooter } from "../../components/Shared/Pagination";
//import { Button } from "react-bootstrap";
import { useCompany } from "../../hooks/useCompany";
import { BsArrowRightCircle, BsDownload, BsFillCloudArrowUpFill } from "react-icons/bs";
import { FillTable } from "../../components/Shared/FillTable";
import { IoCodeSlash } from "react-icons/io5";
import { UploadModal } from "../../components/Shared/UploadModal";
import ModalUpload from "../../components/Invoicing/UploadXMLInvoicingModal";
import Loader from "../../components/Shared/Loader";
import useModal from "../../hooks/useModal";
import './styles.css';
import UploadXMLAcquisitionsModal from "../../components/Acquisitions/UploadXMLAcquisitionsModal";
import { HiOutlineUpload } from "react-icons/hi";
import { Button } from "@mui/material";

interface IProductsProps {
    items: Product[];
    total: number;
    currentPage: number;
    word: string;
    take: number;
}

export default function Acquisitions() {
    const [file, setFile] = useState<Blob>();
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(0);
    const [searchText, setSearchText] = useState("");
    const [productsPagination, setProductsPagination] = useState<IProductsProps>();
    const [showOptions, setShowOptions] = useState<boolean>(false);
    const { company, getObligationTotalValue } = useCompany();
    const { companyId } = useParams();
    const { isShowing, toggle } = useModal();
    const { isShowing: isShowingExcelModal, toggle: toggleExcelModal } = useModal();

    const toggleOptions = () => {
        setShowOptions(!showOptions);
    }

    async function handleUploadAcquisition() {
        toggleExcelModal();
        setLoading(true);
        try {
            const formData = new FormData();

            if (file != undefined) {
                formData.append('file', file);
            }

            const result = await api.post(`acquisitions/UploadExcel/${company.taxNumber}/${company.serviceId}/${company.year}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            const response = await result.data;

            if (response.statusCode == 200) {
                getObligationTotalValue();
                toast.success("Upload do Excel Concluído!");
            }
        }
        catch (err: any) {
            toast.error(err);
        }
        finally {
            setLoading(false);
        }
    }

    async function handleDownloadExcelModelBase() {
        setLoading(true);
        try {
            await api.get(`/Acquisitions/Download/${company.serviceId}`, {
                responseType: 'blob'
            }).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'Acquisitions.xlsx');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            });
        }
        catch (error: any) {
            toast.error(error);
        }
        finally {
            setLoading(false);
        }
    }

    async function getProducts(page?: number) {
        if (page) {
            setCurrentPage(page);
        }

        setLoading(true);
        try {
            let text = searchText == null || searchText == "" ? "\"\"" : searchText;

            const result = await api.get(`/product/GetAllEligible/companyId=${companyId}&page=${page ?? 1}&take=7&word=${encodeURIComponent(text)}`);
            const response = await result.data;

            if (response.statusCode == 200) {
                const data: IProductsProps = response.data;
                setProductsPagination(data);
            } else {
                setProductsPagination({} as IProductsProps);
            }
        } catch (error: any) {
            toast.error(error);
        } finally {
            setLoading(false);
        }
    }

    async function screenAccess() {
        try {
            setLoading(true);

            await api.get(`Acquisitions/ScreenAccessAcquistion/serviceId=${company.serviceId}`);
        } catch (error: any) {
            toast.error(error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        screenAccess();
        getProducts();
    }, []);

    return (
        <>
            <div className='card '>
                {loading && <Loader />}
                <div className='card-header'>
                    <div className='breadCrumb'>
                        <Link to={`/${company.serviceId}/home`} className='breadCrumb-home d-flex align-center'>
                            <AiFillHome className='breadCrumb-home' />
                        </Link>
                        <span className='breadCrumb-separator'>{' > '}</span>
                        <span className='breadCrumb-item-actual'>II.Aquisições</span>
                    </div>
                </div>

                <div className='card-body divAbsolut'>
                    <div className="d-flex justify-content-end aling-items-center mb-4">
                        <div>
                            <Button variant="contained" className='btn  p-2 mx-2  justify-content-around' onClick={handleDownloadExcelModelBase}>
                                Download Modelo
                                <BsDownload className='cursor-pointer  mx-2' />
                            </Button>
                            <Button variant="contained" className='btn   mx-2  justify-content-around' onClick={toggleExcelModal}>
                                Upload Modelo
                                <HiOutlineUpload className='' size={20} />
                            </Button>
                            <Button variant="contained" className='btn mx-2 ' onClick={() => { toggle(); toggleOptions() }}>
                                Upload de Arquivos
                                <HiOutlineUpload className='cursor-pointer mx-2' />
                            </Button>
                        </div>
                    </div>
                    <table className='table table-bordered table-striped table-hover mb-4'>
                        <thead>
                            <tr>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th scope="row" className="card-6">Itens não atrelados a modelos/produtos</th>
                                <td className="ms-2 cursor-pointer text-end">
                                    <Link to={`/${company.serviceId}/acquisitions/list/isNotLinked=true`}>
                                        <BsArrowRightCircle className='cursor-pointer' />
                                    </Link>
                                </td>
                            </tr>
                            {productsPagination?.items.map(model => {
                                return (
                                    <tr key={model.id}>
                                        <th scope="row" className="card-6">{model.name}</th>
                                        <td className="ms-2 cursor-pointer text-end">
                                            <Link to={`/${company.serviceId}/acquisitions/list/${model.id}`}>
                                                <BsArrowRightCircle className='cursor-pointer' />
                                            </Link>
                                        </td>
                                    </tr>
                                );
                            })}
                            {productsPagination?.items && <FillTable columns={2} rows={productsPagination.items.length} />}
                        </tbody>
                    </table>
                    {productsPagination?.items &&
                        <TablePaginationFooter
                            totalItems={productsPagination?.total}
                            change={getProducts}
                        />
                    }

                </div>
            </div>
            {isShowing && <UploadXMLAcquisitionsModal title='Upload das Aquisições' isShowing={isShowing} hide={toggle} />}
            {isShowingExcelModal &&
                <UploadModal
                    action={handleUploadAcquisition}
                    icon={IoCodeSlash}
                    isShowing={isShowingExcelModal}
                    toggle={toggleExcelModal}
                    setFile={setFile}
                />
            }
        </>
    );
}