import './styles.css';
import { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import { FaEye, FaFileDownload, FaPencilAlt, FaSearch, FaTrash } from 'react-icons/fa';
import { BsFillArrowRightCircleFill } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import { IColumnTableProps } from '../../../interfaces/IColumnTableProps';
import { useCan } from '../../../hooks/useCan';
import useModal from '../../../hooks/useModal';
import TablePaginationFooter from './Pagination';
import { formatDate } from '../../../utils/formatDate';
import { PathsInFileManager } from '../../../Static/PathsInFileManager';
import ModalDelete from '../ModalDelete';
import ModalDeletePathComponent from '../../Path/ModalDeletePathComponent';
import { ExternalRoles } from '../../../Static/Roles';
import { humanFileSize } from '../../../utils/formatBytes';

interface ITableDefaultProps {
    columns: IColumnTableProps[],
    rows: any[],
    take?: number,
    isAllText?: boolean,
    firstColumnIsLink?: boolean,
    linkToFirstColumn?: string,
    lastColumnIsAction?: boolean,
    total?: number,
    changePage?: (page: number) => Promise<void>,
    linkToEdit?: string,
    functionToDelete?: (id: string) => Promise<void>,
    ColumnWithButton?: number,
    columnActionToButton?: (object: any) => void,
    hasHeader: boolean,
    hasDelete?: boolean,
    hasEdit?: boolean,
    hasGoTo?: boolean,
    hasSeeMore?: boolean,
    linkToSeeMore?: string,
    linkGoTo?: string,
    isNews?: boolean,
    isFiles?: boolean,
    hasPagination: boolean,
    firstColumnIsCheck?: boolean,
    editIsButton?: boolean,
    functionToEdit?: (object: any) => void,
    setCheckedState?: React.Dispatch<React.SetStateAction<any[]>>,
    checkedState?: any[],
    pathMain?: string,
    hasTitleOnLines?: boolean,
    hasModalInfo?: boolean,
    toggleShowModalInfo?: React.Dispatch<React.SetStateAction<void>>,
    setModalInfo?: any,
    isNotMonetary?: number[],
    hasDownload?: boolean,
    functionToDownload?: (object: any) => void,
    canDelete?: boolean
    isProjectInformation?: boolean
}

const TableDefault = (props: ITableDefaultProps) => {
    const canNotDelete = useCan({ rolesCan: [...ExternalRoles] });
    const canNotView = useCan({ rolesCan: ["Internal", "TechnicianLimited", "Technician", "Dossie", "Financial"] });
    const { isShowing, toggle } = useModal();
    const { isShowing: showModalPath, toggle: toggleShowModalPath } = useModal();
    const [deleteResponse, setDeleteResponse] = useState<boolean>(false);
    const [itemToDelete, setItemToDelete] = useState<string>("");
    const [checkAll, setCheckAll] = useState<boolean>(false);
    var qtdColumns = props.columns.length;


    const setChecked = (position: number) => {
        const updatedCheckedState = props.checkedState?.map((item, index) =>
            index === position ? !item : item
        ) ?? [];

        if (updatedCheckedState.includes(false) && checkAll) {
            setCheckAll(false);
        }

        if (props.setCheckedState) {
            props.setCheckedState(updatedCheckedState);
        }
    }

    const setAllChecked = (value: boolean) => {
        const updatedCheckedState = props.checkedState?.map((item, index) => value) ?? [];

        if (props.setCheckedState) {
            props.setCheckedState(updatedCheckedState);
        }
    }

    useEffect(() => {
        if (deleteResponse && itemToDelete !== "") {
            if (props.functionToDelete) {
                props.functionToDelete(itemToDelete);
            }
            setItemToDelete("");
            setDeleteResponse(false);
        } else if (itemToDelete != "") {
            setItemToDelete("");
        }
    }, [deleteResponse]);

    return (
        <div>
            <ModalDelete show={isShowing} toggleShow={toggle} setResponse={setDeleteResponse} />
            <ModalDeletePathComponent pathName={PathsInFileManager.find(x => x.id == props.pathMain)?.name ?? ""} show={showModalPath} toggleShow={toggleShowModalPath} setResponse={setDeleteResponse} />
            <Table striped bordered hover className='back-white'>
                {props.hasHeader &&
                    <thead>
                        <tr>
                            {props.firstColumnIsCheck &&
                                <th>
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        checked={checkAll}
                                        onChange={() => { setAllChecked(!checkAll); setCheckAll(!checkAll) }}
                                    />
                                </th>
                            }
                            {props.columns.map((column, index) => {
                                return (
                                    <th key={index}>{column.name}</th>
                                )
                            })}
                            {props.lastColumnIsAction &&
                                <th className='last-column-action'>Ação</th>
                            }
                        </tr>
                    </thead>
                }
                <tbody className='table-tds'>
                    {props.rows.map((row, index) => {
                        return (
                            <tr key={index} title={props.hasTitleOnLines == false ? "" : `Criado por: ${row["createdBy"]} em ${formatDate(new Date(row["created"]))}, Modificado Por: ${row["modifiedBy"]} em ${formatDate(new Date(row["modified"]))}`}>
                                {props.firstColumnIsCheck &&
                                    <td>
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            checked={props.checkedState ? props.checkedState[index] : false}
                                            onChange={() => setChecked(index)}
                                        />
                                    </td>
                                }
                                {props.columns.map((column, i) => {
                                    return (
                                        <td key={i} className={`w-${qtdColumns == 3 && i == 0 ? 50 : qtdColumns == 0 ? 100 : qtdColumns == 3 ? 25 : 100 / qtdColumns}`}>

                                            {props.isAllText ?
                                                <span>{row[column.id]}</span>
                                                : 
                                                props.isNews && i == 0 ?
                                                    <a target="_blank" href={row["imageLink"]}>Visualizar</a>
                                                    :
                                                    props.isFiles && i == 0 ?
                                                        <a href={row["fileAzurePath"]}>{row[column.id]}</a>
                                                        :
                                                        props.firstColumnIsLink && i == 0 ?
                                                            props.isProjectInformation ?
                                                                <Link to={props.linkToEdit ? props.linkToEdit + row.id : ""}>
                                                                    {row[column.id]}
                                                                </Link>
                                                                :
                                                                <Link to={props.linkToFirstColumn + row.id}>
                                                                    {row[column.id]}
                                                                </Link>
                                                            :
                                                            (props.ColumnWithButton && !canNotView && props.ColumnWithButton == i)
                                                                ?
                                                                <a type='button' onClick={() => props.columnActionToButton && props.columnActionToButton(row)} className='link'>
                                                                    {humanFileSize(row[column.id], true)}</a>
                                                                :
                                                                !isNaN(row[column.id]) && humanFileSize(row[column.id], true)
                                            }
                                        </td>
                                    )
                                })}
                                {props.lastColumnIsAction &&
                                    <td className='d-flex align-item-center h-100 w-100 last-column-action'>
                                        <div className='d-flex align-items-center justify-content-center'>
                                            {props.hasModalInfo &&
                                                <button type="button" onClick={() => { props.setModalInfo && props.setModalInfo(row); props.toggleShowModalInfo && props.toggleShowModalInfo() }} className='btn p-0'>
                                                    <FaEye className="cursor-pointer me-3" />
                                                </button>
                                            }
                                            {props.hasSeeMore &&
                                                <Link to={props.linkToSeeMore ? props.linkToSeeMore + (row.idLine ? row.idLine : row.id) : ""}>
                                                    <FaSearch className="cursor-pointer me-3" />
                                                </Link>
                                            }
                                            {(props.hasEdit && !props.editIsButton) &&
                                                <Link to={props.linkToEdit ? props.linkToEdit + row.id : ""}>
                                                    <FaPencilAlt className="cursor-pointer me-3" />
                                                </Link>
                                            }
                                            {(props.hasEdit && props.editIsButton) &&
                                                <button type="button" onClick={() => { props.functionToEdit && props.functionToEdit(row) }} className='btn p-0'>
                                                    <FaPencilAlt className="cursor-pointer me-3" />
                                                </button>
                                            }
                                            {props.hasDownload &&
                                                <button type="button" onClick={() => { props.functionToDownload && props.functionToDownload(row) }} className='btn p-0'>
                                                    <FaFileDownload className="cursor-pointer me-3" />
                                                </button>
                                            }
                                            {(props.hasDelete && !canNotDelete && props.canDelete === undefined) &&
                                                <button type="button" onClick={() => { setItemToDelete(row.id); props.pathMain ? toggleShowModalPath() : toggle() }} className='btn p-0'>
                                                    <FaTrash className="cursor-pointer" />
                                                </button>
                                            }
                                            {(props.hasDelete && props.canDelete) &&
                                                <button type="button" onClick={() => { setItemToDelete(row.id); props.pathMain ? toggleShowModalPath() : toggle() }} className='btn p-0'>
                                                    <FaTrash className="cursor-pointer" />
                                                </button>
                                            }
                                            {props.hasGoTo &&
                                                <Link to={props.linkGoTo ? props.linkGoTo + row.id : ""}>
                                                    <BsFillArrowRightCircleFill className="cursor-pointer me-3" />
                                                </Link>
                                            }
                                        </div>
                                    </td>
                                }
                            </tr>
                        )
                    })}
                    {(props.take ? props.rows.length < props.take : props.rows.length < 7) &&
                        [...Array((props.take ? props.take : 7) - props.rows.length)].map((value, index) =>
                            <tr key={index}>
                                {index == 0 && props.rows.length === 0 ?
                                    <td colSpan={(props.lastColumnIsAction && props.firstColumnIsCheck) ? props.columns.length + 2 : (props.lastColumnIsAction || props.firstColumnIsCheck) ? props.columns.length + 1 : props.columns.length} className="text-center">Não há informações no momento!</td>
                                    :
                                    [...Array((props.lastColumnIsAction && props.firstColumnIsCheck) ? props.columns.length + 2 : (props.lastColumnIsAction || props.firstColumnIsCheck) ? props.columns.length + 1 : props.columns.length)].map((column, i) => {
                                        return (
                                            <td key={i} className={`column-empty ${props.lastColumnIsAction && "last-column-action"}`}></td>
                                        )
                                    })
                                }
                            </tr>
                        )
                    }
                </tbody>
            </Table>
            {props.hasPagination &&
                <div className='d-flex align-items-center justify-content-end'>
                    {props.rows.length > 0 &&
                        <TablePaginationFooter
                            totalItems={props.total ?? 0}
                            change={props.changePage ?? (async (page: number) => { })}
                        />
                    }
                </div>
            }
        </div>
    )
}

export default TableDefault;