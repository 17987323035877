import { useContext, useEffect, useState } from "react";
import { FillTable } from "../../Shared/FillTable";
import { toast } from "react-toastify";
import { api } from "../../../services/axios";
import { CompanyContext } from "../../../contexts/companyContext";
import { ProductTableViewModel } from "../../../models/productTableViewModel";
import { TablePaginationFooter } from "../../Shared/Pagination";
import { maskCNPJ } from "../../../utils/cnpjUtils";

interface ITableHomeProps {
    cnpjs: string[],
    setLoading: React.Dispatch<React.SetStateAction<boolean>>
}

export default function TableHome(props: ITableHomeProps) {
    const { company } = useContext(CompanyContext);
    const [tableView, setTableView] = useState<ProductTableViewModel[]>([]);
    const [total, setTotal] = useState<number>(0);
    const [cnpj, setCnpj] = useState<string>("");

    async function getValuesToTable(page?: number) {
        try {
            props.setLoading(true);

            await api.get(`/Home/GetValuesToTableByServiceId?serviceId=${company.serviceId}&cnpj=${encodeURIComponent(cnpj == "" ? "\"\"" : cnpj)}&page=${page ?? 1}&take=7&word=${encodeURIComponent("\"\"")}`).then(resp => {
                if (resp.status == 200) {
                    setTableView(resp.data.data.items ?? []);
                    setTotal(resp.data.data.total);
                }
            });
        } catch (error: any) {
            toast.error(error);
        } finally {
            props.setLoading(false);
        }
    }

    useEffect(() => {
        getValuesToTable();
    }, [cnpj]);

    return (
        <div className="card-body card-shadow-background w-100 ms-2">
            <div className='w-100 mt-2 font-size-title d-flex align-items-center justify-content-between mb-4'>
                <div>
                    <select onChange={e => setCnpj(e.target.value)} className="form-select w-100">
                        <option value="" selected>Todos</option>
                        {props.cnpjs.map((cnpj, index) => {
                            return (
                                <option value={cnpj} key={index}>{maskCNPJ(cnpj)}</option>
                            )
                        })}
                    </select>
                </div>
                <div>
                    <span className="text-bold text-center ms-2">Tabela de Produtos</span>
                </div>
                <div className="w-25"></div>
            </div>

            <div className='d-flex flex-column justify-content-center'>
                <table className='table table-bordered table-striped table-hover mb-4'>
                    <thead>
                        <tr>
                            <th scope="row">Nome do Produto </th>
                            <th scope="row">NCM</th>
                            <th scope="row">Habilitação do Produto</th>
                            <th scope="row">Habilitação do Modelo</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tableView?.map((model, index) => {
                            return (
                                <tr key={index}>
                                    <td>{model.name}</td>
                                    <td>{model.ncm}</td> 
                                    <td>{model.productEnable}</td>
                                    <td>{model.productModelEnable}</td>
                                </tr>
                            );
                        })}
                        {tableView && <FillTable columns={4} rows={tableView.length} />}
                    </tbody>
                </table>
                {tableView.length > 0 &&
                    <TablePaginationFooter
                        totalItems={total}
                        change={getValuesToTable}
                    />
                }
            </div>
        </div>
    )
}