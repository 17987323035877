import { useState, useEffect, createRef } from "react";
import { GiSevenPointedStar } from "react-icons/gi";
import { maskCNPJ } from "../../../utils/cnpjUtils";
import { BranchCompanyProps } from "../BranchCompany";
import { BranchCompanyTableActions } from "../BranchCompanyTableActions";
import './styles.css';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";

interface BranchCompanyTableProps {
    newBranchCompany: BranchCompanyProps[];
    savedBranchCompany: BranchCompanyProps[];
    removeBranchCompany: (id: string) => void;
}

export function BranchCompanyTable({ newBranchCompany, savedBranchCompany, removeBranchCompany }: BranchCompanyTableProps) {

    const BranchCompanyRef = createRef<HTMLDivElement>();
    const [colunmOrder, setColunmOrder] = useState<keyof BranchCompanyProps>('name');
    const [order, setOrder] = useState(1);

    useEffect(() => {
        if (BranchCompanyRef && BranchCompanyRef.current) {
            const { scrollHeight, clientHeight } = BranchCompanyRef.current;

            BranchCompanyRef.current.scrollTo({
                left: 0, top: scrollHeight - clientHeight,
                behavior: 'smooth'
            });
        }

    }, [newBranchCompany]);


    const handleOrder = (fieldName: any) => {
        setOrder(-order);
        setColunmOrder(fieldName);
    }

    savedBranchCompany = savedBranchCompany.sort((a, b) => {
        return a[colunmOrder] < b[colunmOrder] ? -order : order
    });

    newBranchCompany = newBranchCompany.sort((a, b) => {
        return a[colunmOrder] < b[colunmOrder] ? -order : order
    });

    return (
        <>
            <div className="d-flex justify-content-center">
                <TableContainer className="table-responsive w-50  table-max-height border border-primary rounded" ref={BranchCompanyRef}>
                    <Table className="table table-borderless table-white border-primary rounded justify-content-center">
                        <TableBody className="justify-content-center">
                            <TableRow className=" row">
                                <TableCell className="col-4 fs-3 text-primary text-center" onClick={() => handleOrder('name')}>
                                    Razão Social - Filial
                                </TableCell>
                                <TableCell className="col-4 fs-3 text-primary text-center" onClick={() => handleOrder('cnpj')}>
                                    CNPJ
                                </TableCell>
                                <TableCell className="col-4 fs-3 text-primary text-center" onClick={() => handleOrder('region')}>
                                    Região
                                </TableCell>
                            </TableRow>

                            {newBranchCompany.length === 0 && savedBranchCompany.length === 0 ? (
                                <TableRow>
                                    <TableCell colSpan={12} className="text-center">
                                        Não há empresas filiais cadastradas no momento
                                    </TableCell>
                                </TableRow>
                            ) : null}

                            {savedBranchCompany.map((Component, index) => (
                                <TableRow key={index}>
                                    <TableCell className="text-center" width="100px" title={Component.name}>
                                        {Component.name}
                                    </TableCell>
                                    <TableCell className="text-center">{maskCNPJ(Component.cnpj)}</TableCell>
                                    <TableCell className="text-center">{Component.region}</TableCell>
                                    {/* <BranchCompanyTableActions action={removeSavedBranchCompany} id={Component.id} /> */}
                                </TableRow>
                            ))}

                            {newBranchCompany.map((Component, index) => (
                                <TableRow key={index}>
                                    <TableCell width="100px">
                                        <div className="new-Branch" title={Component.name}>
                                            {Component.name}
                                            <GiSevenPointedStar size={10} />
                                        </div>
                                    </TableCell>
                                    <TableCell>{maskCNPJ(Component.cnpj)}</TableCell>
                                    <TableCell>{Component.region}</TableCell>
                                    <BranchCompanyTableActions action={removeBranchCompany} id={Component.id} />
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>

        </>
    )
}