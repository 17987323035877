import { Button, Modal as ModalContainer } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import UploadFileComponent from '../UploadAcquisitionsFileComponent';
import { useState } from 'react';
import { useAuth } from 'oidc-react';
import UploadAcquisitionsFileComponent from '../UploadAcquisitionsFileComponent';


interface ModalProps {
    title: string;
    isShowing: boolean;
    hide: () => void;
    buttonSaveTitle?: string;
    buttonCloseModalTitle?: string;
    action?: any;
}


interface ModalUploadProps {
    file: any;
}

export default function UploadXMLAcquisitionsModal({ title, isShowing, hide, buttonSaveTitle = 'Salvar', buttonCloseModalTitle = 'Fechar', action }: ModalProps) {

    const [loading, setLoading] = useState(false);
    let userData = useAuth()?.userData;


    const { register, handleSubmit, formState: { errors } } = useForm<ModalUploadProps>();


    return (
        isShowing
            ?
            (
                <ModalContainer show={isShowing} onHide={hide}>
                    <form className='form' onSubmit={handleSubmit(action)}>
                        <ModalContainer.Header closeButton>
                            <ModalContainer.Title>{title}</ModalContainer.Title>
                        </ModalContainer.Header>
                        <ModalContainer.Body>
                            <UploadAcquisitionsFileComponent
                                toggleLoading={setLoading}
                                userEmail={userData?.profile.email ?? ''}
                            />
                        </ModalContainer.Body>
                    </form>
                </ModalContainer >
            )
            :
            null
    )
}