import React from 'react';
import ReactDOM from 'react-dom';
import { Button, Modal as ModalContainer } from 'react-bootstrap';
interface ModalProps {
    title: string;
    text: string;
    isShowing: boolean;
    hide: () => void;
    buttonSaveTitle?: string;
    buttonCloseModalTitle?: string;
    action?: any;
}

export default function Modal({ title, text, isShowing, hide, buttonSaveTitle = 'Salvar', buttonCloseModalTitle = 'Fechar', action }: ModalProps) {
    return (
        isShowing
            ?
            (
                <ModalContainer show={isShowing} onHide={hide}>
                    <ModalContainer.Header closeButton>
                        <ModalContainer.Title>{title}</ModalContainer.Title>
                    </ModalContainer.Header>
                    <ModalContainer.Body>{text}</ModalContainer.Body>
                    <ModalContainer.Footer>
                        <Button className='btn btn-secondary' onClick={hide}>
                            {buttonCloseModalTitle}
                        </Button>
                        <Button className='btn btn-primary' onClick={action}>
                            {buttonSaveTitle}
                        </Button>
                    </ModalContainer.Footer>
                </ModalContainer >
            )
            :
            null
    )
}