import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { Button, Modal as ModalContainer } from 'react-bootstrap';
import Loader from '../../Shared/Loader';
import { api } from '../../../services/axios';
import { toast } from 'react-toastify';
import { EconomicTable } from '../EconomicModalTable';

interface EconomicDataModalProps {
    isShowing: boolean;
    hide: () => void;
    productId: string;
}

interface EconomicDataProps {
    id: string;
    quantityProduced: number | null;
    grossRevenue: number | null;
    exports: number | null;
    importedComponents: number | null;
    additionalInvestments: number | null;
    additionalQuantity: number | null;
    yearBased: number;
}

export interface EconomicDataForms {
    productId: string;
    serviceId: string;
    lastYear: EconomicDataProps;
    baseYear: EconomicDataProps;
    nextYear: EconomicDataProps;
}


export default function EconomicDataModal({ isShowing, hide, productId }: EconomicDataModalProps) {
    const [economicData, setEconomicData] = useState<EconomicDataForms>();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        async function getEconomicData() {
            setLoading(true);
            try {
                const result = await api.get(`economicdata/getByProduct/${productId}`);
                const response = await result.data;

                const data = response.data;
                setEconomicData(data);
            }
            catch (error: any) {
                console.log('GET ECONOMIC DATA', error);
            }
            finally {
                setLoading(false);
            }
        }

        getEconomicData();
    }, []);


    return (
        isShowing
            ?
            (
                <>
                    {loading && <Loader />}
                    {economicData && <EconomicTable economicData={economicData} isShowing={isShowing} hide={hide} />}
                </>
            )
            :
            null
    )
}