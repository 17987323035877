import { Button } from "@mui/material";
import { VisuallyHiddenInput } from "../../../assets/GlobalStyles";

interface IUploadFileProps {
    title: string,
    setFile: React.Dispatch<React.SetStateAction<File | undefined>>,
    file: File | undefined,
    disabled: boolean
}

export default function UploadFile(props: IUploadFileProps) {
    
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files[0]) {
            props.setFile(event.target.files[0]);
        } else {
            props.setFile(undefined);
        }
    }

    return (
        <Button
            className="w-100 h-100 p-0"
            component="label"
            variant="text"
            disabled={props.disabled}
            color="info"
            tabIndex={-1}
        >
            <VisuallyHiddenInput onChange={handleChange} type="file" />
            <div className="w-25 block-blue text-nowrap">
                {props.title}
            </div>
            <div className="w-75 block-gray">
                {props.file ? props.file.name : props.disabled ? "Não é possível adicionar um arquivo no momento..." : "Escolha um arquivo..."}
            </div>
        </Button>
    )
}