import { FaPaste } from "react-icons/fa";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { FillTable } from "../../Shared/FillTable";
import { useEffect, useState } from "react";
import { uploadInvoiceMassiveRegisterModel } from "../../../models/uploadInvoiceMassiveRegisterModel";
import Loader from "../../Shared/Loader";
import { toast } from "react-toastify";
import { DeleteMassiveAPI, DownloadAPI, GetAllByServiceIdAPI } from "../../../API/UploadInvoiceMassiveRegisterAPI";
import { IResponseProps } from "../../../interfaces/IResponseProps";
import { TablePaginationFooter } from "../../Shared/Pagination";
import { IconButton } from "@mui/material";
import { Delete, Download } from "@mui/icons-material";
import { CompanyTenantsProps } from "../../../contexts/companyContext";
import { formatDateWithNormalHour } from "../../../utils/formatDate";
import ModalDelete from "../../Shared/ModalDelete";
import useModal from "../../../hooks/useModal";

interface IDeleteInvoicingMassiveModalProps {
    isShowing: boolean;
    toggle: () => void;
    serviceId: string;
    company: CompanyTenantsProps;
}

export interface IRegistersPaginationProps {
    items: uploadInvoiceMassiveRegisterModel[];
    total: number;
    currentPage: number;
    word: string;
    take: number;
}

export function DeleteInvoicingMassiveModal({ isShowing, toggle, serviceId, company }: IDeleteInvoicingMassiveModalProps) {
    const { isShowing: isShowingModalToDelete, toggle: toggleModalToDelete } = useModal();
    const [loading, setLoading] = useState(false);
    const [registers, setRegisters] = useState<IRegistersPaginationProps>();
    const [deleteRegisterCommand, setDeleteRegisterCommand] = useState<boolean>(false);
    const [idToDelete, setIdToDelete] = useState<string>("");

    async function getAllByServiceId(page: number) {
        try {
            setLoading(true);

            await GetAllByServiceIdAPI(serviceId, page, 7, "\"\"").then((resp: IResponseProps) => {
                if (resp.statusCode == 200) {
                    var data = resp.data as IRegistersPaginationProps;
                    setRegisters(data);
                }
            });
        } catch (error: any) {
            toast.error(error);
        } finally {
            setLoading(false);
        }
    }

    async function deleteRegister() {
        try {
            setLoading(true);

            await DeleteMassiveAPI(idToDelete, serviceId).then((resp: IResponseProps) => {
                if (resp.statusCode == 200) {
                    toast.success("Faturamentos deletados com sucesso!")
                    getAllByServiceId(1);
                }
            });
        } catch (error: any) {
            toast.error(error);
        } finally {
            setLoading(false);
            setDeleteRegisterCommand(false);
            setIdToDelete("");
        }
    }

    async function downloadRegister(registerId: string, batchId: string) {
        try {
            setLoading(true);

            await DownloadAPI(serviceId, registerId, company.title, company.year.toString(), batchId);
        } catch (error: any) {
            toast.error(error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (isShowing) {
            getAllByServiceId(1);
        }
    }, [isShowing]);

    useEffect(() => {
        if (deleteRegisterCommand && idToDelete != "") {
            deleteRegister();
        } else if (idToDelete != "" && !deleteRegisterCommand) {
            toggleModalToDelete();
        }
    }, [deleteRegisterCommand, idToDelete]);

    useEffect(() => {
        if (!isShowingModalToDelete && !deleteRegisterCommand) {
            setIdToDelete("");
        }
    }, [isShowingModalToDelete]);

    return (
        <>
            {loading && <Loader />}
            <Modal isOpen={isShowing} toggle={toggle} className='modal-lg width rounded'>
                <div className="w-100 pt-4 pe-4 d-flex justify-content-end">
                    <div className="cursor-pointer" onClick={toggle}>x</div>
                </div>
                <ModalHeader className="w-100 d-flex flex-column">
                    <div className='d-flex flex-column align-items-center justify-content-center'>
                        <FaPaste size={80} />
                        <h2 className="mt-2">Histórico de Upload</h2>
                        <span>Gerenciar os relatórios de upload de faturamento</span>
                    </div>
                </ModalHeader>
                <ModalBody className='bg-light'>
                    <table className='table table-bordered table-striped table-hover mb-4'>
                        <tbody>
                            <tr>
                                <th>Usuário</th>
                                <th>Email</th>
                                <th>Data</th>
                                <th>Lote</th>
                                <th>Ações</th>
                            </tr>
                            {registers?.items.map((model, index) => {
                                return (
                                    <tr key={index}>
                                        <td>{model.userName}</td>
                                        <td>{model.userEmail}</td>
                                        <td>{formatDateWithNormalHour(new Date(model.dateUpload))}</td>
                                        <td>{model.batchId}</td>
                                        <td className="w-25">
                                            <IconButton onClick={() => downloadRegister(model.id, model.batchId)} size="small" title="Baixar faturamentos deste upload">
                                                <Download />
                                            </IconButton>
                                            <IconButton onClick={() => setIdToDelete(model.id)} size="small" title="Deletar faturamentos deste upload">
                                                <Delete />
                                            </IconButton>
                                        </td>
                                    </tr>
                                );
                            })}
                            {registers?.items && <FillTable columns={5} rows={registers.items.length} />}
                        </tbody>
                    </table>
                    {registers?.items &&
                        <TablePaginationFooter
                            totalItems={registers?.total}
                            change={getAllByServiceId}
                        />
                    }
                </ModalBody>
            </Modal>
            <ModalDelete show={isShowingModalToDelete} toggleShow={toggleModalToDelete} setResponse={setDeleteRegisterCommand} />
        </>
    )
}