import './styles.css';
import { AiOutlineApartment } from 'react-icons/ai'
import { Link } from 'react-router-dom';
import { FaClipboardCheck, FaUser } from 'react-icons/fa';

export function Managment() {
    return (
        <>
            <div className='card'>
                <div className='card-body p-4'>
                    <div className='cards-container'>
                        <Link to='/companies' className='card-link'>
                            <div className='manager-card'>
                                <div className='manager-card-logo'>
                                    <div className='manager-card-logo-container'>
                                        <AiOutlineApartment size={40} />
                                    </div>
                                </div>
                                <div className='manager-card-text'>
                                    <span className='manager-card-title'>Gestão de Empresas Identity</span>
                                </div>
                                <div className='manager-card-footer'>
                                    <strong className='text-white'>Clique para visualizar</strong>
                                </div>
                            </div>
                        </Link>
                        <Link to='/qualifiedCompanies' className='card-link'>
                            <div className='manager-card'>
                                <div className='manager-card-logo'>
                                    <div className='manager-card-logo-container'>
                                        <FaClipboardCheck size={40} />
                                    </div>
                                </div>
                                <div className='manager-card-text'>
                                    <span className='manager-card-title'>Atualizar Empresas Habilitadas</span>
                                </div>
                                <div className='manager-card-footer'>
                                    <strong className='text-white'>Clique para visualizar</strong>
                                </div>
                            </div>
                        </Link>
                        <Link to='/users' className='card-link'>
                            <div className='manager-card'>
                                <div className='manager-card-logo'>
                                    <div className='manager-card-logo-container'>
                                        <FaUser size={40} />
                                    </div>
                                </div>
                                <div className='manager-card-text'>
                                    <span className='manager-card-title'>Usuários</span>
                                </div>
                                <div className='manager-card-footer'>
                                    <strong className='text-white'>Clique para visualizar</strong>
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        </>
    );
}