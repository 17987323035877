import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { v4 as uuidV4 } from 'uuid';
import { toast } from "react-toastify";
import { Modal as ModalContainer } from 'react-bootstrap';
import { IoCodeSlash } from 'react-icons/io5';
import { Button, TextField } from "@mui/material";
import DownloadIcon from '@mui/icons-material/Download';
import UploadIcon from '@mui/icons-material/Upload';
import { CodesTable } from "../../../../components/ProductsModel/CodesTable";
import { UploadModal } from "../../../../components/Shared/UploadModal";
import { useCan } from "../../../../hooks/useCan";
import { useCompany } from "../../../../hooks/useCompany";
import useModal from "../../../../hooks/useModal";
import { ProductProps } from "../../../../interfaces/Product/IProduct";
import { api } from "../../../../services/axios";
import { showErrors } from "../../../../utils/showErrors";
import Loader from "../../../../components/Shared/Loader";
import { CreateModelForm } from "./CreateModelForm";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { createProductModelSchema } from "../../../../schemas/createProductModelSchema";

export interface CodeProps {
    id: string;
    code: string;
    description: string;
}

interface CreateModelCharacterizationProps {
    product?: ProductProps | undefined;
}

export interface CreateProductModelForm {
    name: string;
    status: boolean | null;
    description: string | null;
    createdDate: Date | null;
    approvalDate: Date | null;
    isRequistred: boolean | null;
    companyId: string;
    productId: string | null;
    serviceId: string;
    isTecnac: boolean;
    codes: CodeProps[];
}

export function CreateModelCharacterization({ product }: CreateModelCharacterizationProps) {
    const [file, setFile] = useState<Blob>();
    const [loading, setLoading] = useState(false);
    const [newCodes, setNewCodes] = useState<CodeProps[]>([]);
    const { isShowing, toggle } = useModal();
    const [codeNumber, setCodeNumber] = useState('');
    const [codeDescription, setCodeDescription] = useState('');
    const { productId, tecnacId } = useParams();
    const { company } = useCompany();
    const { isShowing: isShowingDeleteModal, toggle: toggleDeleteModal } = useModal();
    const { updateHeaderValues } = useCompany();
    const permissionFinancial = useCan({ rolesCan: ["Financial"] });
    const navigate = useNavigate();

    const { register, handleSubmit, control, formState: { errors } } = useForm<CreateProductModelForm>({
        resolver: yupResolver(createProductModelSchema)
    });

    function isValidCode(code: CodeProps): boolean {

        if (!code.code) {
            return false;
        }

        if (!code.description) {
            return false;
        }

        if (newCodes.some(x => x.code == code.code)) {
            toast.error(`Não é possível inserir o código ${code.code} pois ele já existe!`);
            return false;
        }

        return true;
    }

    async function handleDownloadCodeModel() {
        setLoading(true);
        try {
            await api.get(`codes/download/${company.serviceId}`, {
                responseType: 'blob'
            })
                .then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'Codes.xlsx');
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                });
        }
        catch (err: any) {
            console.log('Download Error', err);
        }
        finally {
            setLoading(false);
        }
    }

    async function handleUploadCodes() {
        toggle();
        setLoading(true);
        try {
            const formData = new FormData();

            if (file != undefined) {
                formData.append('file', file);
            }

            const result = await api.post('codes/ReadCodeExcel', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            const response = await result.data;

            if (response.statusCode === 200) {
                addNewCodesRange(response.data.result);
                showErrors(response.data.errors);
            }
        }
        catch (err: any) {

        }
        finally {
            setLoading(false);
        }

    }

    async function handleCreateProductModel(data: CreateProductModelForm) {
        setLoading(true);

        data.codes = newCodes;
        let productModel = data;

        // productModel.createdDate = createdDate != null ? new Date(createdDate) : null;
        // productModel.approvalDate = approvalDate != null ? new Date(approvalDate) : null;
        productModel.productId = productId ? productId : '';
        productModel.codes = data.codes;
        productModel.isTecnac = tecnacId != undefined ? true : false;
        data.status = typeof data.status == "string" && data.status != "" ? data.status === "true" ? true : false : null;
        data.serviceId = company.serviceId;
        try {
            const result = await api.post('productmodel/post', productModel);
            const response = await result.data;
            
            if (response.statusCode === 200) {
                toast.success('Modelo de Produto Cadastrado com Sucesso!');
                navigate(`/${company.serviceId}/models/${response.data.id}/false/characterization/${1}`);
                await updateHeaderValues();
            }

        }
        catch (error: any) {
            console.log('ERROR', error);
        }
        finally {
            setLoading(false);
        }
    }

    function addCode(code: CodeProps) {
        if (isValidCode(code)) {
            setNewCodes(codes => [...codes, code]);
        }
    }

    function addNewCodesRange(codes: CodeProps[]) {
        codes.forEach(element => {
            if (isValidCode(element)) {
                setNewCodes(codes => [...codes, element]);
            }
        });
    }

    function removeCode(id: string) {
        const codesFiltered = newCodes.filter(code => {
            return code.id != id;
        });

        toast.success('Código removido com sucesso!');
        setNewCodes(codesFiltered);
    }

    function removeAllNewCodes() {
        setNewCodes([]);
        toast.success('Códigos deletados com sucesso!');
        toggleDeleteModal();
    }

    function handleAddLine() {

        if (codeNumber && codeDescription) {
            const object = {
                id: uuidV4(),
                code: codeNumber,
                description: codeDescription
            }
            addCode(object);
            setCodeNumber('');
            setCodeDescription('');
        }
    }

    return (
        <>
            {loading && <Loader />}

            <form className='bg-light w-100 h-100 p-4 border' onSubmit={handleSubmit(handleCreateProductModel)}>

                <CreateModelForm
                    register={register}
                    errors={errors}
                    product={product}
                />

                <CodesTable
                    newCodes={newCodes}
                    savedCodes={[]}
                    removeCode={removeCode}
                />
                {!permissionFinancial &&
                    <div className='mt-4  d-flex justify-content-end'>
                        <Button variant='contained' color='secondary' className='me-2 fw-bold' onClick={handleDownloadCodeModel}>
                            Modelo de Excel
                            <DownloadIcon className='ms-2' />
                        </Button>
                        <Button variant='contained' color='secondary' className='fw-bold' onClick={toggle}>
                            Upload Modelo
                            <UploadIcon className='ms-2' />
                        </Button>
                    </div>
                }

                <div className='mt-4'>
                    {!permissionFinancial &&
                        <><div className='d-flex'>
                            <TextField
                                type="text"
                                className='form-control me-4'
                                label='Código do Produto'
                                onChange={(e) => setCodeNumber(e.target.value)}
                                value={codeNumber} />
                            <TextField
                                type="text"
                                className='form-control'
                                label='Descrição da NF'
                                onChange={(e) => setCodeDescription(e.target.value)}
                                value={codeDescription} />
                        </div><Button
                            className='cursor-pointer btn btn-success mt-3 text-white'
                            onClick={handleAddLine}
                        >
                                Adicionar Linha
                            </Button></>
                    }
                </div>

                <div className='mt-3'>
                    {!permissionFinancial &&
                        <>
                            <label className='label'></label>
                            <TextField
                                label="Descrição e Características Básicas"
                                className={`form-control`}
                                multiline
                                rows={6}
                                placeholder='Descrição'
                                style={{ height: 'auto' }}
                                {...register('description')}
                                error={!!errors.description}
                                helperText={errors.description?.message}
                            />
                        </>
                    }
                </div>
                <div className='row mt-4'>
                    <div className='col d-flex justify-content-center'>
                        {!permissionFinancial &&
                            <Button variant='contained' className='btn btn-success' type='submit'>
                                Salvar Modelo
                            </Button>}
                    </div>
                </div>
            </form >
            <UploadModal
                action={handleUploadCodes}
                icon={IoCodeSlash}
                isShowing={isShowing}
                toggle={toggle}
                setFile={setFile}
            />
            <ModalContainer show={isShowingDeleteModal} onHide={toggleDeleteModal}>
                <ModalContainer.Header closeButton>
                    <ModalContainer.Title>Exclusão massiva</ModalContainer.Title>
                </ModalContainer.Header>
                <ModalContainer.Body>
                    <div className=' d-flex align-items-center justify-content-center'>
                        <button
                            className='btn btn-success me-2'
                            onClick={removeAllNewCodes}
                        >
                            Códigos novos
                        </button>
                    </div>
                </ModalContainer.Body>
            </ModalContainer >
        </>
    )
}