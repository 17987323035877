import { AiFillHome } from "react-icons/ai";
import { Link, useParams } from "react-router-dom";
import PaginationTabHeader from "../../../../components/Shared/PaginationTabHeader";
import { useCompany } from "../../../../hooks/useCompany";
import { useState, useEffect } from "react";
import { ProductProps } from "../../../../interfaces/Product/IProduct";
import { CreateModelCharacterization } from "../CreateModelCharacterization";
import { api } from "../../../../services/axios";

export function CreateModelTabs() {
    const [pag, setPag] = useState(0);
    const [key, setKey] = useState(0);
    const [product, setProduct] = useState<ProductProps>();
    const { company } = useCompany();
    const { productId, tecnacId } = useParams();

    useEffect(() => {
        async function getProduct() {
            const result = await api.get(`product/getById/${productId}`);
            const response = await result.data;

            if (response.statusCode === 200) {
                setProduct(response.data);
            }
        }

        getProduct();

    }, [])

    function getTabs() {
        switch (pag) {
            case 0:
                return <CreateModelCharacterization product={product} />;
            // case 1:
            //     return (modelEconomicData ? <ProductionAndObservation observation={observationField} economicData={modelEconomicData} /> : '')
            // case 2:
            //     return <ModelComponents />
            // case 3:
            //     return <HabilitationControl productModel={model} />;
            default:
                return <></>;
        }
    }

    return (
        <div className='card'>
            <div className='card-header'>
                <div className='breadCrumb'>
                    <Link to={`/${company.serviceId}/home`} className='breadCrumb-home d-flex align-center'>
                        <AiFillHome className='breadCrumb-home' />
                    </Link>
                    <span className='breadCrumb-separator'>{' > '}</span>
                    <span className='breadCrumb-item-actual'>
                        <Link to={`/${company.serviceId}/${company.companyId}/products`}>
                            I.1 Produtos e Modelos
                        </Link>
                    </span>
                    <span className='breadCrumb-separator'>{' > '}</span>
                    <Link className='breadCrumb-item-actual' to={`/${company.serviceId}/products/${productId}/models`}>
                        Modelos de Produto teste
                    </Link>
                    <span className='breadCrumb-separator'>{' > '}</span>
                    <span className='breadCrumb-item'>Criar Modelo de Produto</span>
                </div>
            </div>
            <div className='card-body'>
                <div>
                    <PaginationTabHeader
                        setPag={setPag}
                        pag={pag}
                        setKey={setKey}
                        keyDefault={key}
                        tabs={
                            [
                                "Caracterização do Modelo",
                                "Produção e Observações",
                                "Composição Média do Modelo",
                                "Controle de Habilitação"
                            ]
                        }
                        disableds={[1, 2, 3]}
                    />
                    {getTabs()}
                </div>
            </div>
        </div>
    )
}