import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { OpenRoute } from './components/Auth/ProtectedRoute/openRoute';
import { AuthProvider } from './contexts/authContext';
import Home from './pages/Home';
import MainLayout from './Layouts/MainLayout';
import CreateProduct from './pages/Product/CreateProduct';
import CommoditiesList from './pages/Commodities/CommoditiesList';
import ProductionList from './pages/Production/ProductionList';
import ByPass from './pages/ByPass';
import Products from './pages/Product/ProductList';
import UpdateProduct from './pages/Product/UpdateProduct';
import CreateProductModel from './pages/ProductModels/CreateProductModel';
import UpdateProductModel from './pages/ProductModels/UpdateProductModel';
import InvoicingProducts from './pages/Invoicing/InvoicingProducts';
import InvoicingTabs from './pages/Invoicing/InvoicingTabs';
import InvoicingDetails from './pages/Invoicing/InvoicingDetails';
import InvoicingNotLinked from './pages/Invoicing/InvoicingNotLinked';
import EconomicDataProduct from './pages/Product/EconomicDataProductTabs';
import { useOAuthUser } from './hooks/useOAuthUser';
import { api, CommonHeaderProperties } from './services/axios';
import { Managment } from './pages/Managment';
import { TenantCompanies } from './pages/TenantCompanies';
import { Credit } from './pages/Credit/CreditList';
import { CreateCredit } from './pages/Credit/CreateCredit';
import CreditTabs from './pages/Credit/CreditTabs';
import { AdditionalBillingDataFinanceCredits } from './pages/Credit/AdditionalBillingDataFinanceCredits';
import ProductsModelsByProduct from './pages/ProductModels/ModelsList';
import { ModelTabs } from './pages/ProductModels/ModelTabs';
import ProductTecnac from './pages/Product/ProductTecnac';
import { EditCompany } from './components/Company/EditCompany';
import { FileManager } from './pages/FileManager';
import { Files } from './pages/FileManager/Files';
import Acquisitions from './pages/Acquisitions';
import AcquisitionsByProduct from './pages/Acquisitions/AcquisitionsByProduct';
import AcquisitionsDetails from './pages/Acquisitions/AcquisitionsDetails';
import QualifiedCompanies from './pages/QualifiedCompanies';
import { CreditProvider } from './contexts/creditContext';
import AcquisitionsNotLinked from './pages/Acquisitions/AcquisitionsNotLinked';
import CFOPCompanies from './pages/CFOPCompanies';
import Obligations from './pages/Obligations';
import InternalProjects from './pages/Projects/InternalProjectsList'
import { Users } from './pages/Users';
import Logs from './pages/Logs';
import InternalProductsTabs from './pages/Projects/InternalProjectsTabs';
import { TokenSessionName, UserSessionName } from './Static/TokenNames';
import { setSession } from './utils/storageSession';
import { CreateModelTabs } from './pages/ProductModels/CreateProductModel/CreateTabs';
import ContractModule from './pages/ContractModule';
import ContractModuleInfos from './pages/ContractModule/ContractModuleInfos';

function Routers() {
    const user = useOAuthUser();

    useEffect(() => {

        if (user) {
            setSession(UserSessionName, JSON.stringify(user));
            setSession(TokenSessionName, user.access_token);

            api.defaults.headers = {
                Authorization: `Bearer ${user?.access_token}`,
            } as CommonHeaderProperties;
        }
    }, [user]);


    return (
        <AuthProvider>
            <BrowserRouter>
                <CreditProvider>
                    <Routes>
                        <Route element={<MainLayout />}>
                            <Route path='/:serviceId/home' element={<Home />} />
                            {/* PRODUCTS */}
                            <Route path='/:serviceId/:companyId/products' element={<Products />} />
                            <Route path='/:serviceId/products/create' element={<CreateProduct />} />
                            <Route path='/:serviceId/products/update/:id' element={<UpdateProduct />} />
                            <Route path='/:serviceId/products/tecnac/:id' element={<ProductTecnac />} />
                            <Route path='/:serviceId/products/:id/models/' element={<ProductsModelsByProduct />} />
                            <Route path='/:serviceId/products/:id/:tecnacId/models' element={<ProductsModelsByProduct />} />
                            <Route path='/:serviceId/products/commodities' element={<CommoditiesList />} />
                            <Route path='/:serviceId/products/productions' element={<ProductionList />} />
                            <Route path='/:serviceId/products/economic/:id' element={<EconomicDataProduct />} />
                            <Route path='/:serviceId/products/economic/:id/:tecnacId' element={<EconomicDataProduct />} />
                            {/* PRODUCTS MODELS */}
                            <Route path='/:serviceId/models/:productId/create' element={<CreateModelTabs />} />
                            <Route path='/:serviceId/models/:productId/:tecnacId/create' element={<CreateProductModel />} />
                            <Route path='/:serviceId/models/update/:id' element={<UpdateProductModel />} />
                            <Route path='/:serviceId/models/:modelId/:isTecnac/characterization' element={<ModelTabs />} />
                            <Route path='/:serviceId/models/:modelId/:isTecnac/characterization/:tab' element={<ModelTabs />} />
                            {/* INVOICING */}
                            <Route path='/:serviceId/:companyId/invoicing' element={<InvoicingProducts />} />
                            <Route path='/:serviceId/:companyId/invoicing/list/:productId/:isTecnac' element={<InvoicingTabs />} />
                            <Route path='/:serviceId/:companyId/invoicing/:productId/details/:id' element={<InvoicingDetails />} />
                            <Route path='/:serviceId/:companyId/invoicing/details/:id/:isLinked' element={<InvoicingDetails />} />
                            <Route path='/:serviceId/:companyId/invoicingNotLinked' element={<InvoicingNotLinked />} />
                            {/* ACQUISITIONS */}
                            <Route path='/:serviceId/:companyId/acquisitions' element={<Acquisitions />} />
                            <Route path='/:serviceId/acquisitions/list/:productId' element={<AcquisitionsByProduct />} />
                            <Route path='/:serviceId/acquisitions/list/isNotLinked=:isNotLinked' element={<AcquisitionsNotLinked />} />
                            <Route path='/:serviceId/acquisitions/:productId/details/:id' element={<AcquisitionsDetails />} />
                            <Route path='/:serviceId/acquisitions/details/:productId/:id/:isLinked' element={<AcquisitionsDetails />} />
                            {/* CREDIT */}
                            <Route path='/:serviceId/credit' element={<Credit />} />
                            <Route path='/:serviceId/credit/create' element={<CreateCredit />} />
                            <Route path='/:serviceId/credit/tabs/:creditId/:countings' element={<CreditTabs />} />
                            <Route path='/:serviceId/credit/products/financecredits/:creditId/:countings/:productId/:isTecnac' element={<AdditionalBillingDataFinanceCredits />} />
                            {/* COMPANY */}
                            <Route path='/:serviceId/company/edit' element={<EditCompany />} />
                            {/* FILE MANAGER */}
                            <Route path='/:serviceId/fileManager' element={<FileManager />} />
                            <Route path='/:serviceId/fileManager/files/:path' element={<Files />} />
                            <Route path='/:serviceId/fileManager/files/:path/:folderId' element={<Files />} />
                            {/* Assumption Contract */}
                            {/* <Route path='/:serviceId/assumptionContract' element={<AssumptionContract />} /> */}
                            {/* CFOP Companies */}
                            <Route path='/:companyId/CFOPCustom' element={<CFOPCompanies />} />
                            <Route path='/:serviceId/Obligations' element={<Obligations />} />
                            {/* Logs */}
                            <Route path='/:serviceId/Logs' element={<Logs />} />
                            {/* Projects */}
                            <Route path='/:serviceId/internalProjects' element={<InternalProjects />} />
                            <Route path='/:serviceId/internalProjects/tabs/:lastYearProject/:lastYearProjectImport'>
                                {/* <Route path='' element={<InternalProductsTabs />} /> */}
                                <Route path=':lastYearId' element={<InternalProductsTabs />} />
                                <Route path=':id/:typology' element={<InternalProductsTabs />} />
                                <Route path=':id/:typology/:path/:folderId' element={<InternalProductsTabs />} />
                                <Route path=':lastYearId/:id/:typology' element={<InternalProductsTabs />} />
                                <Route path=':lastYearId/:id/:typology/:path/:folderId' element={<InternalProductsTabs />} />
                            </Route>
                            <Route path='/:serviceId/contractModule' element={<ContractModule />} />
                            <Route path='/:serviceId/contractModule/create/:contractType' element={<ContractModuleInfos />} />
                            <Route path='/:serviceId/contractModule/edit/:contractId' element={<ContractModuleInfos />} />
                        </Route>
                        <Route element={<OpenRoute />}>
                            <Route path='/' element={<ByPass />} />
                            <Route path='/:alternancy' element={<ByPass />} />
                            <Route path='/managment' element={<Managment />} />
                            <Route path='/companies' element={<TenantCompanies />} />
                            <Route path='/qualifiedCompanies' element={<QualifiedCompanies />} />
                            <Route path='/users' element={<Users />} />
                        </Route>
                    </Routes>
                </CreditProvider>
            </BrowserRouter>
        </AuthProvider >
    );
}

export default Routers;