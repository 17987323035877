import { Button } from '@mui/material';
import React from 'react';
import {  Modal } from 'react-bootstrap';

interface ShowModalProps {
    show: boolean,
    toggleShow: () => void,
    setResponse: React.Dispatch<React.SetStateAction<boolean>>
}

const ModalDelete = ({ show, toggleShow, setResponse }: ShowModalProps) => {
    const handleClose = () => {
        setResponse(false);
        toggleShow();
    }
    const handleCloseWithResponse = () => {
        setResponse(true);
        toggleShow();
    }

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Deletar item?</Modal.Title>
            </Modal.Header>
            <Modal.Body>Tem certeza de que deseja excluir este item?</Modal.Body>
            <Modal.Footer>
                <Button color='info' variant='contained' className='btn btn-secondary' onClick={handleCloseWithResponse}>
                    Excluir definitivamente
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default ModalDelete;