import { useForm } from "react-hook-form";
import { api } from "../../../services/axios";
import { AiFillHome } from 'react-icons/ai';
import { useEffect, useState } from "react";
import Loader from "../../Shared/Loader";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { useCompany } from "../../../hooks/useCompany";
import { FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { useCan } from "../../../hooks/useCan";


export interface ProductTecnacProps {
    product: ProductProps;
    tecnac: TecnacProps | null;
}

export interface ProductProps {
    id: string;
    name: string;
    cnpj: string | null;
    hasPatents: boolean | null;
    hasAccessory: boolean | null;
    hasContract: boolean | null;
    isMicroComputer: boolean | null;
    ncm: string | null;
    origin: string | null;
    technologyOwner: string | null;
    technicianDescription: string | null;
    ppbDescription: string | null;
    manufacturingDescription: string | null;
    observation: string | null;
    ordinanceNumber: string | null;
    accessoryDescription: string | null;
    manufacturingType: number | null;
    habilitation: number | null;
    technologyOrigin: number | null;
    ordinanceType: number | null;
    status: number | null;
    pleitoDate: string | null;
    licenseDate: string | null;
    startManufacturingDate: Date | null;
    publishDate: string | null;
    models: ProductModelProps[];
    ppbOrdinance: string | null;
}

interface TecnacProps {
    id: string;
    date: string;
    status: number;
    number: string;
}

interface ProductModelProps {
    name: string;
    id: string;
}

interface PreloadProductForm {
    productWithTecnac: ProductTecnacProps;
}


export default function ProductTecnacValues({ productWithTecnac }: PreloadProductForm) {
    const [loading, setLoading] = useState(false);
    const [models, setModels] = useState<ProductModelProps[]>([]);
    const [status, setStatus] = useState<string>(productWithTecnac.tecnac?.status.toString() ?? "0");
    const { company } = useCompany();
    const navigation = useNavigate();
    const permissionfinancial = useCan({ rolesCan: ["Financial"] });


    useEffect(() => {
        setValue('isMicroComputer', productWithTecnac.product.isMicroComputer);
        setValue('hasAccessory', productWithTecnac.product.hasAccessory);
        setValue('hasContract', productWithTecnac.product.hasContract);
        setValue('hasPatents', productWithTecnac.product.hasPatents);

        async function GetModelsByProduct() {
            setLoading(true);
            try {
                const result = await api.get(`productmodel/getbyproduct/${productWithTecnac.product.id}`);
                const response = await result.data;

                if (response.statusCode === 200) {
                    setModels(response.data);
                }
            }
            catch (error: any) {
                console.log('GET MODELS ERROR', error);
            }
            finally {
                setLoading(false);
            }
        }


        GetModelsByProduct();
    }, []);

    const { register, setValue } = useForm<ProductProps>({
        defaultValues: productWithTecnac.product,
        shouldUnregister: false
    });

    const { register: registerTecnac, setValue: setValueTecnac, handleSubmit } = useForm<TecnacProps>({
        defaultValues: (productWithTecnac.tecnac ? productWithTecnac.tecnac : {} as TecnacProps),
        shouldUnregister: false
    });

    async function handleSubmitTecnac(data: TecnacProps) {
        setLoading(true);
        let date = data.date != null ? new Date(data.date) : null
        try {
            const object = {
                id: productWithTecnac?.tecnac?.id,
                date: date,
                status: parseInt(status),
                number: data.number,
                serviceId: company.serviceId
            }

            const result = await api.put(`ProductTecnac/Put`, object);
            const response = await result.data;

            if (response.statusCode === 200) {
                toast.success('Produto Tecnac Alterado com sucesso!');
                navigation(`/${company.serviceId}/${company.companyId}/products`);
            }
        }
        catch (err: any) {
            toast.error(err);
        }
        finally {
            setLoading(false);
        }
    }


    return (
        <>
            {loading && <Loader />}
            <div className='card'>
                <div className='card-header'>
                    <div className='breadCrumb'>
                        <Link to={`/${company.serviceId}/home`} className='breadCrumb-home d-flex align-center'>
                            <AiFillHome className='breadCrumb-home' />
                        </Link>
                        <span className='breadCrumb-separator'>{' > '}</span>
                        <span className='breadCrumb-item-actual'>
                            <Link to={`/${company.serviceId}/${company.companyId}/products`}>
                                I.Produtos
                            </Link>
                        </span>
                        <span className='breadCrumb-separator'>{' > '}</span>
                        <span className='breadCrumb-item-actual'>
                            <Link to={`/${company.serviceId}/${company.companyId}/products`}>
                                I.1 Produtos e Modelos
                            </Link>
                        </span>
                        <span className='breadCrumb-separator'>{' > '}</span>
                        <span className='breadCrumb-item'>Produto {productWithTecnac.product.name} - TECNAC</span>
                    </div>
                </div>
                <div className='card-body'>
                    <div className='font-size'>
                        <div className="row mb-3">
                            <div className='col-8'>
                                <label className='form-label required '></label>
                                <TextField label="Nome do Produto" className='form-control form-control' defaultValue={`${productWithTecnac.product.name} - TECNAC`} disabled />
                            </div>
                            <div className='col'>
                                <label className='form-label'></label>
                                <TextField label="NCM" className='form-control form-control' disabled placeholder="Número de NCM..." {...register('ncm')} />
                            </div>
                        </div>
                        <div className='d-flex row mb-3'>
                            <div className='w-100  col'>
                                <FormControl className="w-100">
                                    <InputLabel>Tipo de Fabricação</InputLabel>
                                    <Select
                                        label="Tipo de Fabricação"
                                        id="manufacturingType"
                                        className="forms-control"
                                        disabled
                                        {...register('manufacturingType')}
                                        defaultValue={productWithTecnac.product.manufacturingType} // Definir valor selecionado anteriormente ou "0" (Própria)
                                    >
                                        <MenuItem value="">Selecione uma opção</MenuItem>
                                        <MenuItem value="0">Própria</MenuItem>
                                        <MenuItem value="1">Terceirizada com Contrato de Assunção</MenuItem>
                                        <MenuItem value="2">Terceirizada sem Contrato de Assunção</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                            <div className='w-100 col'>
                                <TextField
                                    label="Início da Fabricação"
                                    disabled
                                    className='form-control'
                                    defaultValue={productWithTecnac.product.startManufacturingDate?.toLocaleDateString()}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}></TextField>
                            </div>
                            <div className='col'>
                                <label className='form-label required'> </label>
                                <TextField
                                    label="Portaria de PPB" variant="outlined"
                                    defaultValue={productWithTecnac.product.ppbOrdinance}
                                    className='form-control forms-control'
                                    disabled
                                />
                            </div>
                            <div className='w-100 col'>
                                <FormControl fullWidth >
                                    <InputLabel>Empresa Cumprirá o PPB?</InputLabel>
                                    <Select label="Empresa Cumprirá o PPB?" className="forms-control" {...register('isMicroComputer')}
                                        defaultValue={productWithTecnac.product.isMicroComputer}
                                        disabled fullWidth>
                                        <MenuItem value="true">Sim</MenuItem>
                                        <MenuItem value="false">Não</MenuItem>
                                    </Select>
                                    <span className='error-field'></span>
                                </FormControl>
                            </div>
                            <div className='w-100 col'>
                                <FormControl fullWidth>
                                    <InputLabel>Origem da Tecnologia</InputLabel>
                                    <Select label="Origem da Tecnologia" className="forms-control" {...register('technologyOrigin')}
                                        defaultValue={productWithTecnac.product.technologyOrigin} disabled fullWidth>

                                        <MenuItem value="0">Nacional</MenuItem>
                                        <MenuItem value="1">Estrangeira</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                        <div className="d-flex mb-3 row">
                            <div className='col-12 w-100'>
                                <TextField
                                    label="Nome do detentor de tecnologia que autorizou sua produção no Brasil" variant="outlined"
                                    className='form-control forms-control'
                                    placeholder="Nome..."
                                    {...register('technologyOwner')}
                                    disabled
                                />
                            </div>
                        </div>
                        <div className=' d-flex row'>
                            <div className='w-100  col'>
                                <FormControl fullWidth>
                                    <InputLabel>O produto é patenteado?</InputLabel>
                                    <Select label="O produto é patenteado?" className="forms-control" {...register("hasPatents")}
                                        defaultValue={productWithTecnac.product.hasPatents} disabled fullWidth>
                                        <MenuItem value="true">Sim</MenuItem>
                                        <MenuItem value="false">Não</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                            <div className='mb-3 w-100 ms-2 col'>
                                <FormControl fullWidth >
                                    <InputLabel>O produto é microcomputador?</InputLabel>
                                    <Select label="O produto é microcomputador?" className="forms-control"  {...register('isMicroComputer')}
                                        defaultValue={productWithTecnac.product.isMicroComputer} disabled fullWidth>
                                        <MenuItem value="true">Sim</MenuItem>
                                        <MenuItem value="false">Não</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                            <div className='w-100 col'>
                                <FormControl fullWidth>
                                    <InputLabel>Contrato de Assunção</InputLabel>
                                    <Select label="Contrato de Assunção" className="forms-control" {...register('hasContract', {

                                    })} defaultValue={productWithTecnac.product.hasContract} disabled
                                        fullWidth>
                                        <MenuItem value="true">Sim</MenuItem>
                                        <MenuItem value="false">Não</MenuItem>
                                    </Select>

                                </FormControl>
                            </div>
                        </div>
                        <div className='mb-3 d-flex  row'>
                            <label className='form-label'></label>
                            {models.map(model => {
                                return (
                                    <div className='d-flex align-items-center mt-3 mb-1' key={model.id}>
                                        <TextField label="Modelos de Produtos" className='form-control form-control readonly ' value={model.name} disabled />
                                    </div>
                                );
                            })}
                        </div>
                        <div className='d-flex align-items-center'>
                        </div>
                        <div className='mb-3'>
                            <label className=' form-label'></label>
                            <TextField label="Descrição das Características Técnicas do produto" minRows={5} placeholder="Descrição..." disabled className='form-control form-control h-100' {...register('technicianDescription')} />
                        </div>
                        <div className='mb-3'>
                            <label className='form-label'></label>
                            <TextField label="Descrição de PPB aplicável ao produto" minRows={5} placeholder="Descrição..." disabled className='form-control form-control' {...register('ppbDescription')} />
                        </div>
                        <div className='mb-3'>
                            <label className='form-label'></label>
                            <TextField label="Descrição das instalações fabris e principais equipamentos de produção" minRows={5} placeholder="Descrição..." disabled className='form-control form-control' {...register('manufacturingDescription')} />
                        </div>
                        <div className='d-flex align-items-center row'>
                            <div className='mb-3 w-100 col'>
                                <FormControl fullWidth>
                                    <InputLabel>Possui Acessórios?</InputLabel>
                                    <Select label="Possui Acessórios?" className="forms-control" {...register('hasAccessory')} defaultValue={productWithTecnac.product.hasAccessory} disabled fullWidth>
                                        <MenuItem value="true">Sim</MenuItem>
                                        <MenuItem value="false">Não</MenuItem>
                                    </Select>

                                </FormControl>

                            </div>
                            <div className='mb-3 col col-10'>
                                <label className='form-label'></label>
                                <TextField label="Quais acessórios?" placeholder="Lista acessorios" disabled className='form-control form-control' {...register('accessoryDescription')} />
                            </div>
                        </div>
                        <div className='mb-3 w-100'>
                            <label className='form-label'></label>
                            <TextField label="Outras Observações" minRows={5} placeholder="Descrição..." disabled className='form-control form-control' {...register('observation')} />
                        </div>
                        <form onSubmit={handleSubmit(handleSubmitTecnac)}>
                            <div className='d-flex align-items-center mb-3 row'>
                                <div className='mb-3 col'>
                                    <FormControl fullWidth>
                                        <InputLabel>Status da Habilitação - TECNAC</InputLabel>
                                        <Select label="Status da Habilitação - TECNAC" className="forms-control" onChange={(e: any) => setStatus(e.target.value)} defaultValue={status} disabled={permissionfinancial} fullWidth>
                                            <MenuItem value="0">Elaboração</MenuItem>
                                            <MenuItem value="1">Submetido</MenuItem>
                                            <MenuItem value="2">Habilitado</MenuItem>
                                        </Select>

                                    </FormControl>

                                </div>
                                <div className='mb-3 w-100 col'>
                                    <TextField
                                        fullWidth
                                        className="forms-control"
                                        label='Portaria Habilitação - TECNAC'
                                        {...registerTecnac('number')}
                                        disabled={permissionfinancial}
                                    />
                                </div>
                                <div className='mb-3 w-100 col'>
                                    <TextField
                                        label="Data da Publicação - TECNAC"
                                        className='form-control'
                                        {...registerTecnac('date')} type='date'

                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        disabled={permissionfinancial}
                                    />

                                </div>
                            </div>
                            {!permissionfinancial &&
                            <div className="row d-flex align-items-center justify-content-center">
                                <div className="col-md-4">
                                    <Link to={`/${company.serviceId}/products/economic/${productWithTecnac.tecnac?.id}`}
                                        state={{ hasTecnac: true }}
                                        type="button"
                                        className="btn btn-secondary mb-3  w-100 ">
                                        Dados Econômicos
                                    </Link>
                                </div>
                            </div>}
                            {!permissionfinancial? <button  className='btn btn-success mb-3 mt-4 w-100 ' type='submit'>Salvar Produto</button> : <div></div>}
                            
                        </form>
                    </div>
                </div>
            </div >
        </>
    );
}