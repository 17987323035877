import { FormEvent, useEffect, useState } from 'react';
import { Modal as ModalContainer } from 'react-bootstrap';
import { BsFillCalendarFill } from 'react-icons/bs';
import { toast } from 'react-toastify';
import { useCompany } from '../../../hooks/useCompany';
import { api } from '../../../services/axios';
import Loader from '../../Shared/Loader';
import { CurrencyInput } from '../../Shared/CurrencyInput';
import { TextField } from '@mui/material';
import { convertCurrencyToFloat } from '../../../utils/formatCurrency';

interface ServiceProps {
    counting: number;
    year: number;
    tenantCompanyId: number;
    incentivizedBillingForecastZFM: number;
    incentivizedBillingForecastLINacional: number;
    id: string;
}

interface ServiceHeaderModalProps {
    toggle: () => void;
    isShowing: boolean;
}

export function ServiceHeaderModal({ toggle, isShowing }: ServiceHeaderModalProps) {
    const [loading, setLoading] = useState(false);
    const [year, setYear] = useState(0);
    const [counting, setCounting] = useState(0);
    const [incentivizedBillingForecastZFM, setIncentivizedBillingForecastZFM] = useState(0);
    const [incentivizedBillingForecastLINacional, setIncentivizedBillingForecastLINacional] = useState(0);
    const [service, setService] = useState<ServiceProps>();
    const { company, handleUpdateServiceByHeader } = useCompany();

    async function getService() {
        setLoading(true);
        try {
            const result = await api.get(`serviceYear/Get/${company.serviceId}`);
            const response = await result.data;


            if (response.statusCode === 200) {
                const data: ServiceProps = response.data;
                setYear(data.year);
                setCounting(data.counting);
                setIncentivizedBillingForecastZFM(data.incentivizedBillingForecastZFM);
                setIncentivizedBillingForecastLINacional(data.incentivizedBillingForecastLINacional);
                setService(data);
            }
        }
        catch (err: any) {

        }
        finally {
            setLoading(false);
        }
    }

    async function saveChanges(e: FormEvent) {
        e.preventDefault();

        setLoading(true);
        try {
            const object = {
                tenantCompanyId: company.companyId,
                id: service?.id,
                counting: counting,
                year: year,
                incentivizedBillingForecastZFM: incentivizedBillingForecastZFM,
                incentivizedBillingForecastLINacional: incentivizedBillingForecastLINacional
            }

            const result = await api.put(`serviceYear/putHeaderData`, object);
            const response = await result.data;

            if (response.statusCode === 200) {
                handleUpdateServiceByHeader(counting, year, service?.id ?? "", incentivizedBillingForecastLINacional, incentivizedBillingForecastZFM);
                toast.success('Dados alterados com sucesso!');
                toggle();
            }
        }
        catch (err: any) {

        }
        finally {
            setLoading(false);
        }
    }

    function convertToZFMOrLiNacional(value: string, isZFM: boolean) {
        var valueConverted = 0;

        if (value.includes("R$")) {
            valueConverted = convertCurrencyToFloat(value);
        } else {
            valueConverted = parseFloat(value);
        }

        if(isZFM) {
            setIncentivizedBillingForecastZFM(valueConverted);
        } else {
            setIncentivizedBillingForecastLINacional(valueConverted);
        }
    }

    useEffect(() => {
        getService();
    }, []);

    return (
        <>
            {loading && <Loader />}
            <ModalContainer onHide={toggle} className='modal-lg' show={isShowing}>
                <ModalContainer.Header>
                    <div className='modal-custom-header'>
                        <BsFillCalendarFill size={70} />
                        <h3 className='mt-3'>
                            <strong>
                                Ano Fiscal
                            </strong>
                        </h3>
                        <p>Para editar o ano fiscal, insira as devidas informações</p>
                    </div>
                </ModalContainer.Header>
                <ModalContainer.Body className='p-4'>
                    <form onSubmit={saveChanges}>
                        <div className='row mb-4'>
                            <div className='col-6'>
                                <label className='form-label'>Ano-base</label>
                                <input value={service?.year} className='form-control' disabled />
                            </div>
                            <div className='col-6'>
                                <label className='form-label'>Regime de Apuração</label>
                                <select className='form form-select' defaultValue={service?.counting} onChange={e => setCounting(parseInt(e.target.value))}>
                                    <option value='0'>Anual</option>
                                    <option value='1'>Trimestral</option>
                                    <option value='2'>Mensal</option>
                                </select>
                            </div>
                        </div>
                        <div className='row mb-4'>
                            <div>
                                <p className='text-bold'>Previsão de faturamento incentivado</p>
                                <label className='mb-1'>Lei de Informática Nacional</label>
                                
                                <TextField
                                    value={incentivizedBillingForecastLINacional.toString().replace(".", ",")}
                                    InputProps={{
                                        inputComponent: CurrencyInput as any,
                                    }}
                                    onChange={e => convertToZFMOrLiNacional(e.target.value, false)}
                                    className="w-100 b-0"
                                    size='small'
                                />
                                <label className='mb-1 mt-2'>Lei de Informática ZFM</label>
                                <TextField
                                    value={incentivizedBillingForecastZFM.toString().replace(".", ",")}
                                    InputProps={{
                                        inputComponent: CurrencyInput as any,
                                    }}
                                    onChange={e => convertToZFMOrLiNacional(e.target.value, true)}
                                    className="w-100 b-0"
                                    size='small'
                                />
                            </div>
                        </div>
                        <button className='btn btn-success w-100' type='submit'>
                            Salvar Informações
                        </button>
                    </form>
                </ModalContainer.Body>
            </ModalContainer>
        </>
    );
}