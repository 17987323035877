import * as yup from 'yup';

export const createProductModelSchema = yup.object().shape({
    name: yup.string()
        .required("O Campo Nome do Modelo é obrigatório").trim()
        .max(250, 'Por favor, insira um valor entre 5 e 250 caracteres')
        .min(5, 'Por favor, insira um valor entre 5 e 250 caracteres'),

    status: yup.string()
        .nullable(true)
        .when('publishDate', {
            is: (publishDate: string | null) => publishDate != null && publishDate != "",
            then: yup.string()
                .required('O Campo Status do Modelo é obrigatório')
        }),

    description: yup.string()
        .required("O Campo Descrição do Modelo e características básicas é obrigatório").trim()
        .max(400, 'Por favor, insira um valor entre 5 e 400 caracteres')
        .min(5, 'Por favor, insira um valor entre 5 e 400 caracteres'),

    createdDate: yup.string().nullable(true)
        .when('publishDate', {
            is: (publishDate: string | null) => publishDate != null && publishDate != "",
            then: yup.string()
                .required('O Campo Data de Criação de Modelo é obrigatório')
                .nullable(true)
        }).transform((_, val) => val !== '' ? val : null),

    isRequistred: yup.string().nullable(true)
        .when('publishDate', {
            is: (publishDate: string | null) => publishDate != null && publishDate != "",
            then: yup.string()
                .required('O Campo Modelo Cadastrado é obrigatório')
        }),

    approvalDate: yup.string().nullable(true)
        .when('publishDate', {
            is: (publishDate: string | null) => publishDate != null && publishDate != "",
            then: yup.string()
                .required('O Campo Data de Aprovação é obrigatório')
                .nullable(true)
        }).transform((_, val) => val !== '' ? val : null),
});
