import "./styles.css";
import { UseFormReturn } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useCompany } from "../../../hooks/useCompany";
import { toast } from "react-toastify";
import { InternalProjectsFormProps } from "../../../pages/Projects/InternalProjectsTabs/Interfaces/InternalProjectsFormProps";
import { IResponseProps } from "../../../interfaces/IResponseProps";
import { GetByIdLastYearAPI, PostInternalProjectsLastYearAPI, PutInternalProjectsLastYearAPI } from "../../../API/InternalProjectsAPI";
import { ProjectTable } from "../ProjectTable";
import { InternalProjectsModel } from "../../../models/projectModel";
import { useEffect, useState } from "react";

interface IInternalProjectsFormProps {
    InternalProjectsLastYearForm: UseFormReturn<InternalProjectsFormProps, any>,
    isDisabledField: boolean
}

export function ProjectLastYearTab({ InternalProjectsLastYearForm }: IInternalProjectsFormProps) {

    const { lastYearProject, lastYearProjectImport, id, lastYearId, typology } = useParams();
    const { company } = useCompany();
    const navigate = useNavigate();
    const [disabledField, setDisabledField] = useState<boolean>((lastYearProjectImport === "1" ? true : false));

    const isEdition: boolean = (id && lastYearId !== "0" && lastYearProjectImport === "0") ? true : false;

    async function GetByProjectLastYearById(projectLastId: string) {
        await GetByIdLastYearAPI(projectLastId).then((resp: IResponseProps) => {
            if (resp.statusCode == 200) {
                var data = resp.data as InternalProjectsModel

                if (data != null)
                    setDisabledField(data.isLastYearProjectImport);
            }
        });
    }

    async function Put(data: InternalProjectsFormProps) {
        data.id = lastYearId ? lastYearId : null;
        await PutInternalProjectsLastYearAPI(data).then((resp: IResponseProps) => {
            (resp.statusCode === 200)
                ? toast.success('Projeto atualizado com sucesso')
                : toast.error('Erro ao atualizar projeto.');
        });
    }

    async function Post(data: InternalProjectsFormProps) {
        data.id = lastYearProjectImport === "1" ? null : data.id;
        await PostInternalProjectsLastYearAPI(data).then((resp: IResponseProps) => {
            if (resp.statusCode === 200) {
                toast.success('Projeto criado com sucesso');
                if (lastYearProject === "1")
                    navigate(`/${company.serviceId}/internalProjects/tabs/${lastYearProject}/0/${resp.data.id}/${id}/${typology}`)

            } else
                toast.error('Erro ao criar projeto.');

        });
    }

    useEffect(() => {
        if (lastYearId && lastYearId != "0")
            GetByProjectLastYearById(lastYearId)
    }, [])

    return (
        <ProjectTable
            InternalProjects={InternalProjectsLastYearForm}
            Post={Post}
            Put={Put}
            isButtonSave={id != undefined}
            isEdition={isEdition}
            isProjectLastYear={true}
            isDisabledField={disabledField} />
    )
}