import { useCallback, useState } from "react";
import { Button, Card } from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import { FaFileImport, FaFileUpload, FaTimes } from "react-icons/fa";
import { api } from "../../../services/axios";
import './styles.css';
import { toast } from "react-toastify";
import { IResponseProps } from "../../../interfaces/IResponseProps";
import { useCompany } from "../../../hooks/useCompany";


interface IUploadFileComponentProps {
    toggleLoading: (data: boolean) => void,
    userEmail: string,
}

const UploadAcquisitionsFileComponent = (props: IUploadFileComponentProps) => {
    const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
    const {company, getObligationTotalValue} = useCompany();
    
    const onDrop = useCallback((acceptedFiles: File[]) => {
        setSelectedFiles([...selectedFiles, ...acceptedFiles])
    }, [selectedFiles]);

    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({ onDrop });

    const MassiveUploadFile = async (files: Blob[]) => {
        try {
            var UploadFileCommand = new FormData()
    
            files.map((file: Blob) => {
                UploadFileCommand.append('files', file);
            });
    
            const result = await api.post(`/acquisitions/uploadXML/${company.year}/${company.taxNumber}/${company.serviceId}`, UploadFileCommand, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            const dataFiles = await result.data;
            return dataFiles;
        } catch (error: any) {
            toast.error(error);
        }
    }

    const handleUploadFile = async () => {
        try {
            props.toggleLoading(true);
            await MassiveUploadFile(acceptedFiles).then((resp: IResponseProps) => {
                if(resp.statusCode == 200) {
                    removeAllFiles();
                    getObligationTotalValue();
                    toast.success("Upload Concluído!");
                }
            }).finally(() => {
                props.toggleLoading(false);
            });
        } catch (error: any) {
            toast.error(error);
            props.toggleLoading(false);
        }
    }

    const handleRemoveFile = (file: any) => {
        const newFiles = [...selectedFiles];
        newFiles.splice(newFiles.indexOf(file), 1);
        setSelectedFiles(newFiles);
    }

    const removeAllFiles = () => {
        setSelectedFiles([]);
    }

    return (
        <div className="dropzoneSection">
            <section className="dropzoneContainer">
                <div {...getRootProps({ className: 'dropzone w-100 h-100 d-flex flex-column align-items-center justify-content-center p-2' })}>
                    <input {...getInputProps()} />
                    {selectedFiles.length == 0 ?
                        <span className="text-center">Arraste e solte os arquivos aqui ou clique para selecionar os arquivos</span>
                        :
                        <div className="fileContainer">
                            <div className="fileContent">
                                {selectedFiles.map((file: any, index: any) => {
                                    return (
                                        <Card className="cardFile" title={file.path} key={file.path} >
                                            <Card.Body className="fileComponent d-flex align-items-center justify-content-center flex-column">
                                                <FaFileImport size={60} />
                                                <div className="fileOverflow mt-2">
                                                    <strong className="ms-2">
                                                        {file.path}
                                                    </strong>
                                                </div>
                                                <FaTimes onClick={(e) => { handleRemoveFile(index); e.stopPropagation() }} className="cardRemoveFileIcon" title="Remover arquivo" />
                                            </Card.Body>
                                        </Card>
                                    )
                                })}
                            </div>
                        </div>
                    }
                </div>
            </section>
            <Button disabled={selectedFiles.length <= 0 ? true : false} onClick={handleUploadFile} variant="primary" className="mt-2 w-100">
                <FaFileUpload className="svgWhite" />
                <strong className="ms-2 textWhite">
                    Upload
                </strong>
            </Button>
        </div>
    )
}

export default UploadAcquisitionsFileComponent;