import { useState } from "react";
import { ICFOPCompaniesProps } from "../../pages/CFOPCompanies";
import { api } from "../../services/axios";
import { toast } from "react-toastify";
import { v4 } from 'uuid';
import { Button, TextField } from "@mui/material";
import { BsPlus } from "react-icons/bs";

interface ICreateCFOPProps {
    companyId: string;
    serviceId: string;
    type: number;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
    setCreateCFOP: React.Dispatch<React.SetStateAction<boolean>>;
    GetCFOPsByCompanyAndInvoiceType(): Promise<void>;
}

export default function CreateCFOP(props: ICreateCFOPProps) {
    const [newCFOPs, setNewCFOPs] = useState<ICFOPCompaniesProps[]>([]);
    const [newCode, setNewCode] = useState<string>("");
    const [newDescription, setNewDescription] = useState<string>("");

    async function CreateCFOPs() {
        try {
            props.setLoading(true);

            await api.post('/CFOPCompanies/CreateCFOPs', {NewCFOPs: newCFOPs, CompanyId: props.companyId, Type: props.type, serviceId: props.serviceId}).then(resp => {
                if(resp.data.statusCode === 200) {
                    toast.success(`${newCFOPs.length > 1 ? "CFOPs Criados" : "CFOP Criado"} com sucesso!`);
                    props.setCreateCFOP(false);
                    setNewDescription("");
                    setNewCode("");
                    setNewCFOPs([]);

                    props.GetCFOPsByCompanyAndInvoiceType();
                }
            })
        } catch(error: any) {
            toast.error(error);
        } finally {
            props.setLoading(false);
        }
    }

    function addCFOPToList() {
        var cfop = {
            code: newCode,
            description: newDescription,
            isChecked: false,
            id: v4()
        } as ICFOPCompaniesProps;

        setNewCFOPs((last) => [...last, cfop]);
        setNewCode("");
        setNewDescription("");
    }

    function removeCFOPToList(id: string) {
        var filtered = newCFOPs.filter(x => x.id !== id);
        setNewCFOPs(filtered);
    }

    return (
        <div className='mt-4'>
            <hr />
            <h2>Adicionar CFOP</h2>
            <div>
                <table className='table table-bordered table-striped table-hover mb-4'>
                    <thead>
                        <tr>
                            <th scope="col">CFOP</th>
                            <th scope="col">Justificativa</th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th className='col-md-1'>
                                <TextField
                                    value={newCode}
                                    onChange={(e) => setNewCode(e.target.value)}
                                    className='w-100 form-control'
                                    maxRows={4}
                                />
                            </th>
                            <td>
                                <TextField
                                    value={newDescription}
                                    onChange={(e) => setNewDescription(e.target.value)}
                                    className='w-100 form-control'
                                    maxRows={250}
                                />
                            </td>
                            <td className='text-center col-md-1'>
                                <Button variant="contained" onClick={addCFOPToList} className='btn mt-1 btn-primary'style={{ height: '50px' }}>
                                <BsPlus style={{ fontSize: '1.5rem' }} /> 
                                </Button>
                            </td>
                        </tr>
                        
                    </tbody>
                </table>
            </div>
            <button disabled={newCFOPs.length === 0} onClick={CreateCFOPs} className='btn btn-primary w-100'>Salvar</button>
        </div>
    )
}