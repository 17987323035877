import { useEffect, useState } from "react";
import { BiArrowBack } from "react-icons/bi";
import { MdCached } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "../../components/Shared/Loader";
import { TablePaginationFooter } from "../../components/Shared/Pagination";
import { api } from "../../services/axios";

interface IQualifiedCompaniesProps {
    id: string;
    name: string;
    cnpj: string;
    socialName: string;
}

interface IQualifiedCompaniesPaginationProps {
    items: IQualifiedCompaniesProps[];
    currentPage: number;
    itemsPerPage: number;
    skip: number;
    take: number;
    total: number;
    word: string | null;
}

export default function QualifiedCompanies() {
    const [loading, setLoading] = useState(false);
    const [qualifiedCompanies, setQualifiedCompanies] = useState<IQualifiedCompaniesPaginationProps>();
    const [currentPage, setCurrentPage] = useState(0);
    const [searchText, setSearchText] = useState("");
    const navigate = useNavigate();

    async function getAll(page?: number) {
        try {
            setLoading(true);
            let text = searchText == null || searchText == "" ? "\"\"" : searchText;

            await api.get(`/QualifiedCompanies/GetAllQualifiedCompanies/page=${page ?? 1}&take=30&word=${encodeURIComponent(text)}`).then((resp) => {
                if (resp.status == 200) {
                    setQualifiedCompanies(resp.data.data);
                }
            });

            if (page) {
                setCurrentPage(page);
            }

        } catch (error: any) {
            toast.error(error);
        } finally {
            setLoading(false);
        }
    }

    async function updateAll() {
        try {
            setLoading(true);

            await api.post("/QualifiedCompanies/UpdateQualifiedCompanies").then((resp) => {
                if (resp.status == 200) {
                    setCurrentPage(0);
                    getAll();
                }
            });
        } catch (error: any) {
            toast.error(error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        getAll();
    }, []);

    return (
        <>
            {loading && <Loader />}
            <div className='card'>
                <div className='card-header d-flex justify-content-between'>
                    <h2></h2>
                    <div className='d-flex'>
                        <button
                            className='btn btn-primary text-white d-flex align-items-center'
                            onClick={() => navigate('/managment')}
                        >
                            <BiArrowBack className='me-2' />
                            Voltar
                        </button>
                        <button
                            className='btn btn-primary text-white d-flex align-items-center ms-2'
                            onClick={() => updateAll()}
                        >
                            <MdCached className='me-2' />
                            Atualizar Empresas Habilitadas
                        </button>
                    </div>
                </div>
                <div className='card-body p-4'>
                    <table className='table table-bordered table-striped mb-4'>
                        <thead>
                            <tr>
                                <th scope="col">Empresa</th>
                                <th scope="col">CNPJ</th>
                                <th scope="col">Nome Social</th>
                            </tr>
                        </thead>
                        {qualifiedCompanies?.items?.map(company => {
                            return (
                                <tr key={company.id}>
                                    <td>{company.name}</td>
                                    <td>{company.cnpj}</td>
                                    <td>{company.socialName}</td>
                                </tr>
                            )
                        })}
                    </table>
                    {qualifiedCompanies?.items &&
                        <TablePaginationFooter
                            totalItems={qualifiedCompanies.total}
                            change={getAll}
                        />
                    }
                </div>
            </div>
        </>
    )
}