import React, { useContext } from 'react';
import { useAuth } from '../../../hooks/useAuth';
import { useOAuthUser } from '../../../hooks/useOAuthUser';
import './styles.css';

const Footer = () => {
    const now = new Date().getFullYear();
    const user = useOAuthUser();

    return (
        <div className='d-flex align-items-center justify-content-between footer-style bg-light'>
            <div>
                <span><b>Direitos autorais</b> FI-Group © {now}</span>
            </div>
            <div>
                <span>{user?.profile.email}</span>
            </div>
        </div>
    );
}

export default Footer;