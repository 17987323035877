import { useState, useEffect } from "react";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/Shared/Loader";
import { TablePaginationFooter } from "../../components/Shared/Pagination";
import { api } from "../../services/axios";
import { FillTable } from "../../components/Shared/FillTable";
import { FaSync } from "react-icons/fa";
import { toast } from "react-toastify";

interface UserProps {
    userId: string;
    email: string;
    firstName: string;
}

interface UsersPaginationProps {
    items: UserProps[];
    currentPage: number;
    itemsPerPage: number;
    skip: number;
    take: number;
    total: number;
    word: string | null;

}

export function Users() {
    const [users, setUsers] = useState<UsersPaginationProps>();
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const [searchText, setSearchText] = useState("");

    let navigate = useNavigate();

    useEffect(() => {
        async function getAllUsers() {
            setLoading(true);
            try {
                const result = await api.get(`/User/GetAll/page=1&take=7&word=all`);

                const response = await result.data;

                const data = response.data;

                setUsers(data);
            }
            catch (error: any) {
                console.log('getAllUsers error', error);
            }
            finally {
                setLoading(false);
            }
        }

        getAllUsers();
    }, []);

    async function search() {
        setLoading(true);
        setUsers(undefined);
        try {
            let text = searchText == null || searchText == "" ? 'all' : searchText;

            const result = await api.get(`/User/GetAll/page=1&take=7&word=${encodeURIComponent(text)}`);

            const response = await result.data;

            const data = response.data;

            setUsers(data);

        }
        catch (error: any) {
            console.log('ERROR', error);
        }
        finally {
            setLoading(false)
        }
    }

    async function updateUserRelation(userId: string, userEmail: string, firstName: string) {
        setLoading(true);

        try {
            let data = {
                email: userEmail,
                userId: userId,
                name: firstName
            }

            const response = await api.post(`/TenantsCompanies/SyncFiConnectUserCompanies`, data);

            const result = await response.data;

            if (result.statusCode === 200) {
                toast.success('Usuário sincronizado com sucesso!');
            }
        }
        catch (error: any) {

        }
        finally {
            setLoading(false);
        }
    }


    async function changePage(page: number) {
        setLoading(true);
        try {
            let text = searchText == null || searchText == "" ? 'all' : searchText;

            const result = await api.get(`/User/GetAll/page=${page}&take=7&word=${encodeURIComponent(text)}`);

            const response = await result.data;

            const data = response.data;

            setCurrentPage(page);

            setUsers(data);

        }
        catch (error: any) {
            console.log('CHANGE PAGE', error);
        }
        finally {
            setLoading(false)
        }
    }


    return (
        <>
            {loading && <Loader />}
            <div className='card'>
                <div className='card-header d-flex justify-content-between'>
                    <h2></h2>
                    <div className='d-flex'>
                        <button
                            className='btn btn-primary text-white d-flex align-items-center'
                            onClick={() => navigate('/')}
                        >
                            <BiArrowBack className='me-2' />
                            Voltar
                        </button>
                    </div>
                </div>
                <div className='card-body p-4'>
                    <div className='mb-4 d-flex'>
                        <input placeholder='Pesquisar por usuários' className='form-control me-4' type='text' onChange={(e) => setSearchText(e.target.value)} />
                        <button className='btn btn-success' onClick={() => search()}>Pesquisar</button>
                    </div>
                    <table className='table table-bordered table-striped table-hover mb-4'>
                        <thead>
                            <tr>
                                <th scope="col">Email</th>
                                <th scope="col"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {users?.items?.map(user => {
                                return (
                                    <tr key={user.userId}>
                                        <td>{user.email}</td>
                                        <td>
                                            <FaSync className="cursor-pointer" onClick={() => updateUserRelation(user.userId, user.email, user.firstName)} />
                                        </td>
                                    </tr>
                                )
                            })}
                            {users?.items && <FillTable columns={2} rows={users?.items.length} />}
                        </tbody>
                    </table>

                    {users?.items &&
                        <TablePaginationFooter
                            totalItems={users.total}
                            change={changePage}
                        />}
                </div>
            </div>
        </>
    );
}