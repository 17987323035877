
import CurrencyInput from 'react-currency-input-field';
import { UseFormGetValues, UseFormRegister, UseFormSetValue } from 'react-hook-form';

interface CurrencyInputProps {
  name: string;
  getValue: any;
  register: UseFormRegister<any>;
  hasTecnac?: boolean;
}

export function CustomControlledCurrencyInput({ name, register, getValue, hasTecnac = true }: CurrencyInputProps) {
  return (
    <CurrencyInput
      className='form-control'
      intlConfig={{ locale: 'pt-BR' }}
      placeholder="0,00"
      decimalsLimit={2}
      allowNegativeValue={false}
      defaultValue={getValue(name)}
      {...register(name)}
      disabled={hasTecnac}
    />
  )
}