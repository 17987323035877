import { api } from "../../../services/axios";
import { useEffect, useState } from "react";
import Loader from "../../../components/Shared/Loader";
import UpdateProductForm, { UpdateProductFormProps } from "../../../components/Products/UpdateProductForm";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

export default function UpdateProduct() {
    const [loading, setLoading] = useState(false);
    const { id } = useParams();
    const [product, setProduct] = useState({} as UpdateProductFormProps);

    async function getProduct() {
        setLoading(true);
        try {
            await api.get(`product/getById/${id}`).then(resp => {
                const response = resp.data;
                
                const productResult = response.data as UpdateProductFormProps;
                productResult.startManufacturingDate = productResult.startManufacturingDate != undefined ? new Date(new Date(productResult.startManufacturingDate?.toString().split("T")[0]).setHours(24)) : null;
                productResult.pleitoDate = productResult.pleitoDate != undefined ? productResult.pleitoDate?.toString().split("T")[0] : productResult.pleitoDate;
                productResult.publishDate = productResult.publishDate != undefined ? productResult.publishDate?.toString().split("T")[0] : productResult.publishDate;
                productResult.licenseDate = productResult.licenseDate != undefined ? productResult.licenseDate?.toString().split("T")[0] : productResult.licenseDate;

                setProduct(productResult);
            });
        }
        catch (error: any) {
            toast.error(error);
        }
        finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        getProduct();
    }, []);

    return (
        loading ? <Loader /> : <UpdateProductForm product={product} />
    );
}