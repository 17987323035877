import { AiFillHome } from "react-icons/ai"
import { Link, useLocation, useNavigate, useParams } from "react-router-dom"
import './styles.css';
import { AdditionalBillingDataFinanceCreditsForm } from "../../../components/Credit/AdditionalBillingDataFinanceCreditsForm";
import { useEffect, useState } from "react";
import Loader from "../../../components/Shared/Loader";
import { api } from "../../../services/axios";
import { useCompany } from "../../../hooks/useCompany";
import { toast } from "react-toastify";

interface AdditionalBillingDataFinanceCredits {
    export: number;
    produtionQuantity: number;
    valueSalesWithIPISuspension: number;
    valueSalesZFM: number;
    earnedICMS: number;
    productId: string | null;
    id: string | null;
    serviceId: string | null;
}

export interface InvoincingConsolidationByProducts {
    totalIPI: number;
    totalICMS: number;
    totalDevolutions: number;
    totalInvoicing: number;
}

interface IAdditionalBillingDataFinanceCreditsProps {
    productName: string
}

export function AdditionalBillingDataFinanceCredits() {
    const navigate = useNavigate();
    const infos = useLocation().state as IAdditionalBillingDataFinanceCreditsProps;
    const [financeCredit, setFinanceCredit] = useState<AdditionalBillingDataFinanceCredits>();
    const { company } = useCompany();
    const [loading, setLoading] = useState(false);
    const { productId, countings, creditId, isTecnac } = useParams();
    const [creditIsValidated, setCreditIsValidated] = useState(false);
    const [invoicingConsolidationValues, setInvoicingConsolidationValues] = useState<InvoincingConsolidationByProducts>();

    async function getFinanceCredit() {
        try {
            setLoading(true);
            await api.get(`/AdditionalBillingDataFinanceCredits/GetByProduct/${creditId}/${company.serviceId}/${productId}/${isTecnac == "1" ? true : false}`).then(resp => {
                const response = resp.data;

                const data = response.data;
    
                if (response.statusCode === 200 && data != null) {
                    setFinanceCredit(data);
                }
                else {
                    setFinanceCredit({
                        earnedICMS: 0,
                        export: 0,
                        produtionQuantity: 0,
                        valueSalesWithIPISuspension: 0,
                        valueSalesZFM: 0,
                        id: null,
                        productId: null,
                        serviceId: null
                    })
                }
            });
        }
        catch (error: any) {
            toast.error(error);
        }
        finally {
            setLoading(false);
        }
    }

    async function canEditByCreditValidated() {
        try {
            setLoading(true);
            await api.get(`/credit/GetCreditIsValidated/${creditId}`).then(resp => {
                const response = resp.data;

                if (response.statusCode === 200) {
                    setCreditIsValidated(response.data);
                }
            });
        } catch (error: any) {
            toast.error(error);
        } finally {
            setLoading(false);
        }
    }

    async function getInvoincingConsolidationValues() {
        try {
            setLoading(true);

            await api.get(`/Invoicing/GetInvoicingConsolidationByProduct`, {
                params: {
                    productId: productId,
                    serviceId: company.serviceId,
                    creditId: creditId,
                    matrixCnpj: company.cnpj,
                    isTecnac: isTecnac == "1" ? true : false
                }
            }).then((resp) => {
                if (resp.data.statusCode == 200) {
                    const data = resp.data.data;
                    setInvoicingConsolidationValues(data);
                }
            });
        }
        catch (error: any) {
            toast.error(error);
        }
        finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        getInvoincingConsolidationValues();
        canEditByCreditValidated();
        getFinanceCredit();
    }, []);

    return (
        <>
            {loading && <Loader />}
            <div className='card'>
                <div className='card-header'>
                    <div className='breadCrumb'>
                        <Link to={`/${company.serviceId}/home`} className='breadCrumb-home d-flex align-center'>
                            <AiFillHome className='breadCrumb-home' />
                        </Link>
                        <span className='breadCrumb-separator'>{' > '}</span>
                        <span className='breadCrumb-item-actual cursor-pointer' onClick={() => navigate(`/${company.serviceId}/credit`)}>II.2 Crédito Financeiro</span>
                        <span className='breadCrumb-separator'>{' > '}</span>
                        <span className='breadCrumb-item-actual cursor-pointer' onClick={() => navigate(`/${company.serviceId}/credit/tabs/${creditId}/${countings}`)}>Faturamento do produto</span>
                        <span className='breadCrumb-separator'>{' > '}</span>
                        <span className='breadCrumb-item'>{infos.productName} {isTecnac === "1" && " - TECNAC"}</span>
                    </div>
                </div>
                <div className='card-body m-4'>
                    {financeCredit
                        &&
                        <AdditionalBillingDataFinanceCreditsForm
                            creditIsValidated={creditIsValidated}
                            invoicingConsolidationValues={invoicingConsolidationValues}
                            {...financeCredit}
                        />
                    }
                </div>
            </div>
        </>
    )
}