import React from 'react';
import './styles.css';

const Loader = () => {
    return (
        <div className="spinner-screen" id="loading-screen">
        <div className="spinner-triple">
            <div className="spinner-triple">
                <div className="spinner-triple"></div>
            </div>
        </div>
    </div>
    );
}

export default Loader;