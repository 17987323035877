import { useState } from 'react';
import { AiFillFile, AiOutlineClose } from 'react-icons/ai';
import Modal from '../../Shared/Modal';
import './styles.css';

interface FileProps {
    name: string;
    url: string;
    deleteFunction: () => Promise<void>;
}

export function File({ name, url, deleteFunction }: FileProps) {
    const [modalIsShowing, setModalIsShowing] = useState(false);


    async function deleteFile(e: any) {
        e.stopPropagation();

        await deleteFunction();
        
        setModalIsShowing(false);
    }

    function downloadFile() {
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
    }

    function hideModal(){
        setModalIsShowing(false);
    }

    function showModal(){
        setModalIsShowing(true);
    }


    return (
        <>
            <div className='file-container d-flex align-items-center justify-content-between w-100 mb-2' >
                <div className='me-2 d-flex align-items-center file-name-container' onClick={downloadFile}>
                    <AiFillFile className='me-2' id='file' size={20} />
                    <strong className='file-name' title={name}>{name}</strong>
                </div>
                <AiOutlineClose id='removeIcon' onClick={showModal} />
            </div>
            <Modal 
                title='Deletar arquivo'
                text='Tem certeza que deseja deletar este arquivo?'
                isShowing={modalIsShowing}
                hide={hideModal}
                action={deleteFile}
            />
        </>
    )
}