import { forwardRef } from "react";
import { IMaskInput } from "react-imask";

interface ICEPInputProps {
    onChange: (event: { target: { name: string; value: string } }) => void;
    value: string,
    name: string;
}

export const CEPInput = forwardRef((props: ICEPInputProps, ref) => {
    const { onChange, ...other } = props;

    return (
        <IMaskInput
            {...other}
            mask="00000-000"
            ref={ref}
            onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
            overwrite
        />
    );
});