import { AcquisitionsModel } from "../models/acquisitionsModel";

export function sumAcquisitionTotalValueWithTaxes(acquisition: AcquisitionsModel) {
    var result = ((Number(acquisition?.itemUniValue?.replace(",", ".")) * Number(acquisition?.itemQuantity)) + Number(acquisition?.itemIPI?.replace(",", ".")) + Number(acquisition?.itemICMSSTDIFAL?.replace(",", "."))).toFixed(2).toString().replace(".", ",");

    return result == 'NaN' ? 'R$ 0,00' : `${result}`;
}

export function sumAcquisitionTotalValue(acquisition: AcquisitionsModel): string {
    var result = (Number(acquisition?.itemUniValue?.replace(",", ".")) * Number(acquisition?.itemQuantity)).toFixed(2).toString().replace(".", ",");

    return result == 'NaN' ? 'R$ 0,00' : `${result}`;
}