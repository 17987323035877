import "./style.css"
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Loader from "../../../components/Shared/Loader";
import { Col, InputGroup, Row } from "react-bootstrap";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useModal from "../../../hooks/useModal";
import { useCompany } from "../../../hooks/useCompany";
import { Button, FormControl, InputLabel, MenuItem, Select, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { FileDownloadOutlined, FileUploadOutlined } from "@mui/icons-material";
import { FillTable } from "../../../components/Shared/FillTable";
import { CreateProductsModal } from "../../../components/Projects/CreateProjectsModal";
import { InternalProjectsModel, ProjectCriterionCFrameworkAnalysis, ProjectTypology } from "../../../models/projectModel";
import { useForm } from "react-hook-form";
import { TablePaginationFooter } from "../../../components/Shared/Pagination";
import { formatDate } from "../../../utils/formatDate";
import ProjectAction from "../../../components/Projects/ProjectsActions";
import { toast } from "react-toastify";
import { AiFillHome } from "react-icons/ai";
import { IResponseProps } from "../../../interfaces/IResponseProps";
import { DeleteIdAPI, GetAllPagingByServiceIdAPI, GetByInternalProjectsIdLastYearAPI, GetCountAllButtonsByServiceIdAPI } from "../../../API/InternalProjectsAPI";
import { FilterPaginatorRequest, IButtonsFilters } from "../../../components/Requests/FilterPaginatorRequest";
import { CountAllButtons, InternalProjectsFormProps } from "../InternalProjectsTabs/Interfaces/InternalProjectsFormProps";
interface ProjectsPagination {
    items: InternalProjectsModel[];
    total: number;
    currentPage: number;
    word: string;
    take: number;
}
interface SearchProjectProps {
    search: string;
}

export default function InternalProjectsList() {
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const [searchText, setSearchText] = useState("");
    const [projectsPagination, setProjectsPagination] = useState<ProjectsPagination>();
    const { isShowing, toggle } = useModal();
    const { company } = useCompany();

    const [buttons, setButtons] = useState<IButtonsFilters[]>([
        { id: 0, label: 'Enquadrado', isSelected: false, quantity: 0 },
        { id: 1, label: 'Não enquadrado', isSelected: false, quantity: 0 },
        { id: 2, label: 'Talvez', isSelected: false, quantity: 0 },
        { id: 3, label: 'Pendente', isSelected: false, quantity: 0 },
    ]);

    const { register, handleSubmit, control, formState: { errors } } = useForm<SearchProjectProps>();

    useEffect(() => {
        getAllCountButtons();
    }, []);

    const getAllCountButtons = async () => {
        await GetCountAllButtonsByServiceIdAPI(company.serviceId).then(async (resp: IResponseProps) => {
            if (resp.statusCode == 200) {
                let btns = buttons;
                let dataButtons = resp.data as CountAllButtons[];

                btns.map((p, i) => {
                    p.quantity = dataButtons.find(d => d.id == i)?.quantity ?? 0;
                });
                setButtons(btns);

                console.log('count buttons => ', buttons);
            }
        })
    }

    useEffect(() => {
        getAllPaginator();
    }, [buttons]);

    const getAllPaginator = async (page: number = 1) => {
        setLoading(true);

        let text = searchText == null || searchText == "" ? "\"\"" : searchText;
        let filters: any[] = [];

        buttons.map(p => {
            if (p.isSelected)
                filters.push({ id: p.id, isSelected: p.isSelected });
        });

        let request: FilterPaginatorRequest = {
            page: page,
            take: 7,
            word: encodeURIComponent(text),
            buttonsFilters: ''
        }

        if (filters.length > 0) {
            let filter: string = '';

            filters.forEach(p => {
                filter = filter.concat(encodeURIComponent(JSON.stringify({ id: p.id, isSelected: p.isSelected })) + ',');
            });

            filter = filter.slice(0, -1);
            request.buttonsFilters = filter;
        }

        await GetAllPagingByServiceIdAPI(company.serviceId, request).then(async (resp: IResponseProps) => {
            if (resp.statusCode == 200) {
                var data = resp.data as ProjectsPagination;

                if (data != null) {
                    const promises = data.items.map(async project => {
                        project.lastYearProjectImport = 0;

                        const respChildren = await GetByInternalProjectsIdLastYearAPI(project.id);
                        if (resp.statusCode == 200 && (respChildren.data as InternalProjectsFormProps) != null) {
                            project.lastYearProjectId = (respChildren.data as InternalProjectsFormProps).id;
                            project.lastYearProjectImport = 1;
                        }
                    });

                    await Promise.all(promises)

                    // let btns = buttons;
                    // btns.map((p, i) => {
                    //     p.quantity = data.items.filter(d => d.criterionCFrameworkAnalysis == i).length;
                    // });
                    // setButtons(btns);
                    setProjectsPagination(data);
                }
            }
        });

        setLoading(false);
    }

    const handlerClick = async (id: number) => {
        let btns = buttons.map(p => {
            if (p.id == id)
                p.isSelected = !p.isSelected;

            return p;
        });

        setButtons(btns);
    }

    const deleteRegister = async (id: string) => {
        await DeleteIdAPI(id).then((resp: IResponseProps) => {
            if (resp.statusCode == 200) {
                toast.success('Projeto deletado com sucesso!');
                let projectsFilter = projectsPagination?.items.filter(project => project.id != id);

                const filterProducts = {
                    items: projectsFilter,
                    currentPage: projectsPagination?.currentPage,
                    take: projectsPagination?.take,
                    total: projectsPagination?.total,
                    word: projectsPagination?.word
                } as ProjectsPagination;

                setProjectsPagination(filterProducts);
            }
        })
    }

    async function search(data: SearchProjectProps) {
        setLoading(true);
        setProjectsPagination(undefined);
        try {
            getAllPaginator();
        }
        catch (error: any) {
            console.log('ERROR', error);
        }
        finally {
            setLoading(false)
        }
    }

    async function changePage(page: number) {
        setLoading(true);
        try {
            getAllPaginator(page);
            setCurrentPage(page);
        }
        catch (error: any) {
            console.log('CHANGE PAGE', error);
        }
        finally {
            setLoading(false)
        }
    }

    async function handleDeleteProject(id: string) {
        setLoading(true);
        try {
            deleteRegister(id);
        }
        catch (Error: any) {
            console.log('Handle Delete Project Error', Error);
        }
        finally {
            setLoading(false);
        }
    }

    return (
        <>
            {loading && <Loader />}
            <div className='card '>
                <div className='card-header header-backgroud'>
                    <div className='breadCrumb'>
                        <Link to={`/${company.serviceId}/home`} className='breadCrumb-home d-flex align-center font-color-notSelect'>
                            <AiFillHome className='breadCrumb-home' />
                        </Link>
                        <span className='breadCrumb-separator'>
                            <Link to={`/${company.serviceId}/home`}>
                                III.Identificação Técnica
                            </Link>
                        </span>
                        <span className='breadCrumb-item-actual'>{' > '}
                            Análise de Elegibilidade Projetos Internos
                        </span>
                    </div>
                </div>

                <div className='card-body'>
                    <div className="d-flex justify-content-between aling-items-center w-100">
                        <div className="d-flex aling-items-center w-100 mb-2">
                            {buttons.length > 0 && buttons.map((btn, index) => {
                                return (
                                    <Button
                                        key={index}
                                        variant="contained"
                                        className='p-2 mb-4 text-center me-2 justify-content-around'
                                        color={btn.isSelected ? 'primary' : 'info'}
                                        onClick={() => handlerClick(btn.id)}
                                    >
                                        <span className='statusValues me-2'>{btn.quantity}</span>
                                        {btn.label}
                                    </Button>
                                )
                            })}
                        </div>
                        <div className="d-flex aling-items-center justify-content-end w-100">
                            <div className="mr-2 me-2  w-25">
                                <FormControl fullWidth size="small">
                                    <InputLabel>Download Modelo</InputLabel>
                                    <Select label="Download Modelo" className="forms-control h-100" fullWidth>
                                        <MenuItem value="0">Consolidado</MenuItem>
                                        <MenuItem value="1">Em Branco</MenuItem>
                                        <MenuItem value="2">Filtrada</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                            <Button variant="contained" className='p-2 mb-4 text-center me-2 justify-content-around' startIcon={
                                <FileDownloadOutlined width={20} className="icon-color" />
                            }>
                                Download Projetos
                            </Button>
                            <Button variant="contained" className='p-2 mb-4 text-center justify-content-around' startIcon={
                                <FileUploadOutlined width={20} className="icon-color" />
                            }>
                                Upload Projetos
                            </Button>
                        </div>
                    </div>
                    <Row className='w-100 p-0 m-0'>
                        <Col xs={12} className='p-0 divforms d-flex align-items-center justify-content-end'>
                            <form className="w-25" onSubmit={handleSubmit(search)}>
                                <InputGroup className="mb-2 ">
                                    <input className='form-control' {...register("search")} placeholder="Pesquisar..." aria-label="Pesquisar" aria-describedby="basic-addon2" onChange={(e: any) => setSearchText(e.target.value)} />
                                    <InputGroup.Text id="basic-addon2">
                                        <button type='submit' className='p-0 m-0 border-0 bg-transparent'>
                                            <FontAwesomeIcon icon={faMagnifyingGlass} className="cursor-pointer" />
                                        </button>
                                    </InputGroup.Text>
                                </InputGroup>
                                <p className='error-message'></p>
                            </form>
                        </Col>
                    </Row>
                    <Table sx={{ minWidth: 650 }} className="table table-bordered table-striped table-hover mb-4 black">
                        <TableHead>
                            <TableRow>
                                <TableCell className="col-3"><b>Identificação</b></TableCell>
                                <TableCell className="col-1"><b>Sigla</b></TableCell>
                                <TableCell className="col-2"><b>Nome do projeto</b></TableCell>
                                <TableCell className="col-2"><b>Tipo</b></TableCell>
                                <TableCell className="col-1"><b>Data de Início</b></TableCell>
                                <TableCell className="col-1"><b>Data de Final</b></TableCell>
                                <TableCell className="col-4"><b>Enquadramento</b></TableCell>
                                <TableCell className="col-2"><b>Ações</b></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {projectsPagination?.items.map((project) => {

                                return (
                                    <>
                                        <TableRow key={project.id}>
                                            <TableCell component="th" scope="row">{project.identification}</TableCell>
                                            <TableCell>{project.acronym}</TableCell>
                                            <TableCell>{project.projectName}</TableCell>
                                            <TableCell> {project.typology != null ? ProjectTypology[project.typology] : ''}</TableCell>
                                            <TableCell>{project.startDate != null ? formatDate(new Date(project.startDate)) : ""}</TableCell>
                                            <TableCell>{project.endDate != null ? formatDate(new Date(project.endDate)) : ""}</TableCell>
                                            <TableCell>{project.criterionCFrameworkAnalysis != null
                                                ? ProjectCriterionCFrameworkAnalysis[project.criterionCFrameworkAnalysis]
                                                : ""
                                            }</TableCell>
                                            <ProjectAction
                                                canDelete={true}
                                                lastYearProject={project.lastYearProjectImport}
                                                lastYearProjectImport={0}
                                                lastYearId={project.lastYearProjectId ?? "0"}
                                                id={project.id}
                                                typology={project.typology ?? null}
                                                action={() => handleDeleteProject(project.id)} />
                                        </TableRow>
                                    </>
                                )
                            })}
                            {projectsPagination?.items && (
                                <FillTable columns={8} rows={projectsPagination?.items.length} />
                            )}
                        </TableBody>
                    </Table>
                    {projectsPagination?.items && <TablePaginationFooter totalItems={projectsPagination?.total} change={changePage} />}
                    <div className='d-flex justify-content-end align-items-center mt-4'>
                        <Button variant="contained" className='p-2 mb-4 text-center justify-content-around' onClick={toggle}>
                            Inserir Projeto
                        </Button>
                    </div>
                    <div className='d-flex justify-content-center align-items-center mb-3'>
                    </div>
                </div>
            </div>
            {isShowing && <CreateProductsModal toggle={toggle} isShowing={isShowing} />}
        </>
    );
}