import { ReactNode, useEffect, useState } from 'react';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';

interface TablePaginationFooterProps {
  totalItems: number;
  change: (page: number) => Promise<void>;
}

const StyledPagination = styled(Pagination)({
  color: '#0100A4',
});

export function TablePaginationFooter({ totalItems, change }: TablePaginationFooterProps) {
  const [itemsPerPage, setItemsPerPage] = useState(7);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    if (totalItems > itemsPerPage) {
      const pages = Math.ceil(totalItems / itemsPerPage);
      setTotalPages(pages);
    } else {
      setTotalPages(1);
    }

    setCurrentPage(1);
  }, [totalItems]);

  async function getPage(page: number) {
    if (page !== 0 && page >= 1 && page <= totalPages) {
      change(page).then(() => {
        setCurrentPage(page);
      });
    }
  }

  return (
    <ThemeProvider theme={createTheme()}>
      <Stack direction="row" justifyContent="flex-end">
        <StyledPagination
          count={totalPages}
          page={currentPage}
          onChange={(event, page) => getPage(page)}
          showFirstButton
          showLastButton
          siblingCount={1}
        />
      </Stack>
    </ThemeProvider>
  );
}


