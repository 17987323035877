import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom"
import { InvoicingProductInput } from "../InvoicingProductInput";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from 'yup';
import { useEffect, useState } from "react";
import Loader from "../../Shared/Loader";
import { api } from "../../../services/axios";
import { toast } from "react-toastify";
import { useCompany } from "../../../hooks/useCompany";
import { InvoincingConsolidationByProducts } from "../../../pages/Credit/AdditionalBillingDataFinanceCredits";
import { formatCurrency } from "../../../utils/formatCurrency";
import { Button } from "@mui/material";

const schema = yup.object().shape({
    exports: yup.number(),
    quantityProduced: yup.number(),
    ipiInvoicing: yup.number(),
    zfmSales: yup.number(),
    icms: yup.number(),

});

interface AdditionalBillingDataFinanceCreditsFormProps {
    export: number;
    produtionQuantity: number;
    valueSalesWithIPISuspension: number;
    valueSalesZFM: number;
    earnedICMS: number;
}

interface DataFinanceCreditsFormProps {
    creditIsValidated: boolean;
    invoicingConsolidationValues: InvoincingConsolidationByProducts | undefined;
    produtionQuantity: number;
    valueSalesWithIPISuspension: number;
    valueSalesZFM: number;
    earnedICMS: number;
    export: number;
    id: string | null;
    productId: string | null;
    serviceId: string | null;
}

export function AdditionalBillingDataFinanceCreditsForm(props: DataFinanceCreditsFormProps) {
    const [loading, setLoading] = useState(false);
    const [financeId, setFinanceId] = useState(props.id);
    const { creditId, productId, isTecnac } = useParams();
    const { company, updateHeaderValues } = useCompany();
    var defaultValues = {} as AdditionalBillingDataFinanceCreditsFormProps;

    if (!props.creditIsValidated) {
        defaultValues = {
            export: props.export,
            valueSalesZFM: props.valueSalesZFM,
            earnedICMS: props.earnedICMS,
            produtionQuantity: props.produtionQuantity,
            valueSalesWithIPISuspension: props.valueSalesWithIPISuspension
        }
    }

    const { register, handleSubmit, control, formState: { errors } } = useForm<AdditionalBillingDataFinanceCreditsFormProps>({
        resolver: yupResolver(schema),
        defaultValues: defaultValues
    });

    async function submitForm(data: AdditionalBillingDataFinanceCreditsFormProps) {
        try {
            setLoading(true);

            if (financeId == null) {
                const object = {
                    produtionQuantity: data.produtionQuantity,
                    valueSalesWithIPISuspension: data.valueSalesWithIPISuspension,
                    valueSalesZFM: data.valueSalesZFM,
                    earnedICMS: data.earnedICMS,
                    export: data.export,
                    productId: productId,
                    creditId: creditId,
                    serviceId: company.serviceId,
                    isTecnac: isTecnac == "1" ? true : false
                }

                const result = await api.post(`/AdditionalBillingDataFinanceCredits/Post`, object);

                const response = await result.data;

                if (response.statusCode === 200) {
                    toast.success('Faturamento criado com sucesso!');
                    setFinanceId(response.data.id);
                }
            }
            else {
                const object = {
                    id: financeId,
                    produtionQuantity: data.produtionQuantity,
                    valueSalesWithIPISuspension: data.valueSalesWithIPISuspension,
                    valueSalesZFM: data.valueSalesZFM,
                    earnedICMS: data.earnedICMS,
                    export: data.export,
                    productId: productId,
                    creditId: creditId,
                    serviceId: company.serviceId
                }

                const result = await api.put(`/AdditionalBillingDataFinanceCredits/Put`, object);

                const response = await result.data;

                if (response.statusCode === 200) {
                    toast.success('Faturamento editado com sucesso!');
                }
            }
            await updateHeaderValues();
        }
        catch (error: any) {
            toast.error(error);
        }
        finally {
            setLoading(false);
        }
    }

    return (
        <>
            {loading && <Loader />}
            <form className='' onSubmit={handleSubmit(submitForm)}>
                <h4 className='mb-4'><strong>Faturamento do produto</strong></h4>
                <div>
                    <strong className='d-block mb-4'>Faturamento, Exportações, Quantidade Produzida e Tributos</strong>
                    <div className='row'>
                        <div className='col-md-6 col-sm-12'>
                            <InvoicingProductInput
                                label='Faturamento Bruto no Mercado Interno (R$)'
                                value={formatCurrency(props.invoicingConsolidationValues?.totalInvoicing.toFixed(2))}
                                isDisabled={true}
                                control={control}
                            />
                        </div>
                        <div className='col-md-6 col-sm-12'>
                            <InvoicingProductInput
                                label='Exportações (R$)'
                                name='export'
                                control={control}
                                value={props.creditIsValidated ? formatCurrency(props.export.toFixed(2)) : props.export.toString()}
                                isDisabled={props.creditIsValidated}
                            />
                        </div>
                    </div>
                    <div className='row invoincing-cards'>
                        <div className='col-md-6 col-sm-12'>
                            <InvoicingProductInput
                                label='Vendas Para ZFM (Zona Franca de Manaus x Equiparadas a Exportações) (R$)'
                                value={props.creditIsValidated ? formatCurrency(props.valueSalesZFM.toFixed(2)) : props.valueSalesZFM.toString()}
                                name='valueSalesZFM'
                                control={control}
                                isDisabled={props.creditIsValidated}
                            />
                        </div>
                        <div className='col-md-6 col-sm-12'>
                            <InvoicingProductInput
                                label='Quantidade Produzida'
                                name='produtionQuantity'
                                value={props.creditIsValidated ? formatCurrency(props.produtionQuantity.toFixed(2)) : props.produtionQuantity.toString()}
                                control={control}
                                isDisabled={props.creditIsValidated}
                            />
                        </div>
                    </div>
                    <div className='row invoincing-cards'>
                        <div className='col-md-6 col-sm-12'>
                            <InvoicingProductInput
                                label='Faturamento com Suspensão de IPI - Lei 10.637/2002 (R$)'
                                name='valueSalesWithIPISuspension'
                                value={props.creditIsValidated ? formatCurrency(props.valueSalesWithIPISuspension.toFixed(2)) : props.valueSalesWithIPISuspension.toString()}
                                control={control}
                                isDisabled={props.creditIsValidated}
                            />
                        </div>
                        <div className='w-100 input-container-no-bg'></div>
                    </div>
                </div>
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-4 border-bottom"></div>
                <div>
                    <strong className='d-block mb-4'>Recolhimento Sobre o Faturamento Bruto</strong>
                    <div className='invoincing-cards mb-4 d-flex aling-items-center justify-content-between w-100'>
                        <div>
                            <InvoicingProductInput
                                label='IPI (R$)'
                                value={formatCurrency(props.invoicingConsolidationValues?.totalIPI.toFixed(2))}
                                isDisabled={true}
                                control={control}
                            />
                        </div>
                        <div className='me-2 ms-2'>
                            <InvoicingProductInput
                                label='Devoluções'
                                value={formatCurrency(props.invoicingConsolidationValues?.totalDevolutions.toFixed(2))}
                                isDisabled={true}
                                control={control}
                            />
                        </div>
                        <div>
                            <InvoicingProductInput
                                label='ICMS (ST) (R$)'
                                value={formatCurrency(props.invoicingConsolidationValues?.totalICMS.toFixed(2))}
                                isDisabled={true}
                                control={control}
                            />
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <strong className='d-block mb-4'>Incentivo Auferido</strong>
                    <div className='mb-4 d-flex align-items-center justify-content-between'>
                        <div className="width-alone">
                            <InvoicingProductInput
                                label='ICMS (R$)'
                                name='earnedICMS'
                                value={props.creditIsValidated ? formatCurrency(props.earnedICMS.toFixed(2)) : props.earnedICMS.toString()}
                                control={control}
                                isDisabled={props.creditIsValidated}
                            />
                        </div>
                        <div className="width-without"></div>
                        <div className="width-without"></div>
                    </div>
                </div>
                <div className="text-center">
                    { !props.creditIsValidated && (
                        <Button variant="contained" className="btn btn-success" type="submit">
                            Salvar
                        </Button>
                    )}
                </div>
            </form>
        </>
    )
}