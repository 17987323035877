import { api } from "../services/axios";

const SearchFileAPI = async (serviceId: string, pathMain: string, page: number = 1, take: number = 7, word: string = "", folderId: string | undefined) => {
    try {
        const result = await api.get(`/fileManager/GetByPath/${serviceId}/${pathMain}`, {
            params: {
                page: page,
                take: take,
                word: word != "" ? word : "all",
                parentFolder: folderId != undefined ? folderId : null,
            }
        });
        return result.data;

    } catch (error: any) {
        throw new Error(error.response.data);
    }
}

export {
    SearchFileAPI,
}