import { AiOutlineClose } from "react-icons/ai"
import { FaTrash } from "react-icons/fa";
import useModal from "../../../hooks/useModal";
import DeleteModal from "../../Shared/DeleteModal";
import { useCan } from "../../../hooks/useCan";

interface CodeTableActionsProps {
    action: ((id: string) => void) | ((id: string) => Promise<void>);
    id: string;
}

export function CodeTableActions({ id, action }: CodeTableActionsProps) {
    const { isShowing, toggle } = useModal();
    const permissionFinancial = useCan({ rolesCan: ["Financial"] });
    return (
        <>
            {!permissionFinancial &&
                <>
                    <td width='10%'>
                        <div className='icon-center' onClick={toggle}>
                            <FaTrash />
                        </div>
                    </td>
                    <DeleteModal
                        title={"Excluir Códigos"}
                        text={"Tem certeza que deseja excluir todos os novos códigos inseridos?"}
                        buttonSaveTitle='Deletar'
                        isShowing={isShowing}
                        hide={toggle}
                        action={() => action(id)}
                    />
                </>
            }
        </>
    );
}