import { DateValidationError, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import { Control, Controller, FieldError, UseFormRegister } from "react-hook-form"
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { PickerChangeHandlerContext } from "@mui/x-date-pickers/internals/hooks/usePicker/usePickerValue.types";


interface IDatePickerTabProps {
    label: string,
    field: string,
    control: Control<any, any>,
    fieldErrors?: FieldError | undefined,
    onChange?: ((value: any, context: PickerChangeHandlerContext<DateValidationError>) => void) | undefined
    isDisabled?: boolean,
    hasFormsControl?: boolean,
}

export const DatePickerCustom: React.FC<IDatePickerTabProps> = ({ label, field, control, fieldErrors, onChange, isDisabled = false, hasFormsControl = true }) => {
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Controller
                name={field}
                control={control}
                render={({ field: { ref, value } }) => (
                    <DatePicker
                        label={label}
                        className={`${hasFormsControl == true && "forms-control"} w-100 h-100`}
                        format="dd/MM/yyyy"
                        inputRef={ref}
                        // onChange={(e: any) => setValue('startDate', e != null ? new Date(e) : null)}
                        onChange={onChange}
                        value={value != null ? new Date(value) : null}
                        disabled={isDisabled}
                    />
                )}
            />
            <span className="error-field">{fieldErrors?.message}</span>
        </LocalizationProvider>
    )
}