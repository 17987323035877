import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { BsTools } from 'react-icons/bs';

interface CommoditieModalProps {
    isOpen: boolean;
    toggle: () => void;
}

export function CommoditieModal({ isOpen, toggle }: CommoditieModalProps) {
    return (
        <Modal isOpen={isOpen} toggle={toggle} className='modal-lg'>
            <ModalHeader className='d-flex align-center justify-content-center'>
                <BsTools size={70} />
            </ModalHeader>
            <ModalBody className='bg-light'>
                <div className='mb-3 w-100'>
                    <label className='form-label'>Nome do Insumo</label>
                    <input className='form-control form-control w-100' />
                </div>
                <div className='mb-3 w-100'>
                    <label className='form-label'>Origem</label>
                    <input className='form-control form-control w-100' />
                </div>
                <div className='d-flex align-items-center'>
                    <div className='mb-3 w-100'>
                        <label className='form-label'>Quantidade</label>
                        <input className='form-control form-control w-100' />
                    </div>
                    <div className='mb-3 w-100 ms-4'>
                        <label className='form-label'>Valor Unitário</label>
                        <input className='form-control form-control w-100' />
                    </div>
                </div>
                <div className='d-flex align-items-center'>
                    <div className='mb-3 w-100'>
                        <label className='form-label'>Valor Total</label>
                        <input className='form-control form-control w-100' />
                    </div>
                    <div className='mb-3 w-100 ms-4'>
                        <label className='form-label'>Percentual de Composição</label>
                        <input className='form-control form-control w-100' />
                    </div>
                </div>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" className='w-100'>
                    Salvar Insumo de Composição
                </Button>
            </ModalFooter>
        </Modal>
    )
}