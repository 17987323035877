export interface ProductModel {
    id: string;
    name: string;
    status: boolean | null;
    description: string | null;
    createdDate: Date | null;
    approvalDate: Date | null;
    isRegistered: boolean;
    isTecnac: boolean;
    companyId: string;
    isCreatedByProduct: boolean;
    publishDate: Date | null;
    habilitationStatus: number | null;
}

export const ProductHabilitationStatus = [
    'Elaboração',
    'Submetido',
    'Habilitado',
]

