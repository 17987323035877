import { ProductTecnac } from "./productTecnacModel";

export interface Product{
    id: string;
    name: string;
    cnpj: string | null;
    hasPatents: boolean | null;
    hasAccessory: boolean | null;
    hasContract: boolean | null;
    isMicroComputer: boolean | null;
    ncm: string | null;
    origin: string | null;
    technologyOwner: string | null;
    technicianDescription: string | null;
    ppbDescription: string | null;
    manufacturingDescription: string | null;
    observation: string | null;
    ordinanceNumber: string | null;
    accessoryDescription: string | null;
    manufacturingType: number | null;
    habilitation: number | null;
    technologyOrigin: number | null;
    ordinanceType: number | null;
    status: number | null;
    pleitoDate: Date | null;
    licenseDate: Date | null;
    startManufacturingDate: Date | null;
    publishDate: Date | null;
    tecnacProductId: string | null;
    ppbOrdinance: string | null;
    tecnacProduct: ProductTecnac | null;
}

export const ProductStatus = [
    'Elaboração',
    'Submetido',
    'Habilitado',
]