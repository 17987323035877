import "./styles.css";
import { FileInternalProjectsProvider } from "../../../contexts/fileInternalProjectsContext";
import { AnexoContent } from "./AnexoContent";

interface IAnexoProps {
    setSubScreenTitle: React.Dispatch<React.SetStateAction<string>>
}

export function AnexoTab(props: IAnexoProps) {

    return (
        <>
            <div className='card'>
                <FileInternalProjectsProvider pathMain={4}>
                    <AnexoContent setSubScreenTitle={props.setSubScreenTitle}/>
                </FileInternalProjectsProvider>
            </div>
        </>
    )
}