// Projetos de P&D Strictu Sensu:
 
// 1. Quais problemas técnicos o projeto visa solucionar com seu desenvolvimento? 
// 1.1 - Houve alguma dificuldade em pensar numa solução técnica para essa resolução?
// 1.2 - Se houve uma dificuldade em pensar essa solução, o conceito utilizado para resolvê-lo é próprio?
// 1.3 - Se o conceito utilizado para resolver os problemas for próprio, existe paralelo no mercado ou na área de conhecimento?
 
// 2. O que foi desenvolvido para resolver esses problemas? 
// 2.1. Explicar o conceito dessa resolução.
// 2.2 Explicar com maiores detalhes cada etapa referente ao conceito.
// 2.3 Entre as etapas descritas anteriormente, houve alguma etapa de testes para validar a resolução do problema? Se sim, quais? Como foram feitas? 
 
// 3. Qual(is) o(s) resultado(s) esperado(s) para o desenvolvimento do projeto? Ele resolverá o problema técnico existente hoje? 
// 3.1 Qual(is) o(s) resultado(s) alcançado(s) com o desenvolvimento do projeto até o final do ano anterior?
// 3.2 Qual(is) o(s) próximo(s) passo(s) estão sendo trabalhados neste ano?

// TELA : PARECER FI Group > ALL TYPES (Stricto Sensu)
// a.	Objetivo
// b.	Campo 1: Houve alguma dificuldade em pensar numa solução técnica para essa resolução?
// c.	Campo 2: Se houve uma dificuldade em pensar essa solução, o conceito utilizado para resolvê-lo é próprio? 
// d.	Campo 3: Se o conceito utilizado para resolver os problemas for próprio, existe paralelo no mercado ou na área de conhecimento? 

// b.	Descrição das Atividades
// b.	Campo 1: Explicar o conceito dessa resolução.  
// c.	Campo 2: Explicar com maiores detalhes cada etapa referente ao conceito. 
// d.	Campo 3: Entre as etapas descritas anteriormente, houve alguma etapa de testes para validar a resolução do problema? Se sim, quais? Como foram feitas? 

// c.	Resultados Obtidos
// b.	Campo 1: Qual(is) o(s) resultado(s) esperado(s) para o desenvolvimento do projeto? Ele resolverá o problema técnico existente hoje?   
// c.	Campo 2: Qual(is) o(s) resultado(s) alcançado(s) com o desenvolvimento do projeto até o final do ano anterior? 
// d.	Campo 3: Qual(is) o(s) próximo(s) passo(s) estão sendo trabalhados neste ano? 
export const fieldObjectiveProjectItTics = [
    { id: 0, field: 'fieldObjectiveOne', label: 'Houve alguma dificuldade em pensar numa solução técnica para essa resolução?' },
    { id: 1, field: 'fieldObjectiveTwo', label: 'Se houve uma dificuldade em pensar essa solução, o conceito utilizado para resolvê-lo é próprio?' },
    { id: 2, field: 'fieldObjectiveThree', label: 'Se o conceito utilizado para resolver os problemas for próprio, existe paralelo no mercado ou na área de conhecimento?' },
]
export const fieldDescriptionOfActivitiesProjectItTics = [
    { id: 0, field: 'fieldDescriptionOfActivitiesOne', label: 'Explicar o conceito dessa resolução.' },
    { id: 1, field: 'fieldDescriptionOfActivitiesTwo', label: 'Explicar com maiores detalhes cada etapa referente ao conceito.' },
    { id: 2, field: 'fieldDescriptionOfActivitiesThree', label: 'Entre as etapas descritas anteriormente, houve alguma etapa de testes para validar a resolução do problema? Se sim, quais? Como foram feitas?' },
]
export const fieldResultsObtainedProjectItTics = [
    { id: 0, field: 'fieldResultsObtainedOne', label: 'Qual(is) o(s) resultado(s) alcançado(s) com o desenvolvimento do projeto até o final do ano anterior?' },
    { id: 1, field: 'fieldResultsObtainedTwo', label: 'Qual(is) o(s) próximo(s) passo(s) estão sendo trabalhados neste ano?' },
]

// TELA : PARECER FI Group > ALL TYPES (Stricto Sensu)
// a.	Objetivo
// a.	Subtítulo: Quais problemas técnicos o projeto visa solucionar com seu desenvolvimento?

// b.	Descrição das Atividades
// a.	Subtítulo: O que foi desenvolvido para resolver esse problema?

// c.	Resultados Obtidos
// a.	Subtítulo: O que foi desenvolvido para resolver esse problema?
export const fieldsAllTypes = [
    { id: 0, title: 'Objetivo', subTitle: 'Quais problemas técnicos o projeto visa solucionar com seu desenvolvimento?', list: fieldObjectiveProjectItTics  },
    { id: 1, title: 'Descrição das Atividades', subTitle: 'O que foi desenvolvido para resolver esses problemas?', list: fieldDescriptionOfActivitiesProjectItTics },
    { id: 2, title: 'Resultados Obtidos', subTitle: 'Qual(is) o(s) resultado(s) esperado(s) para o desenvolvimento do projeto? Ele resolverá o problema técnico existente hoje?', list:fieldResultsObtainedProjectItTics  },
]

// Formação e Capacitação Profissional
 
// 1. Qual(is) o(s) curso(s) será(ão) ministrado(s)? Com qual(is) finalidade(s)? 
// 1.1. Descreva com detalhes os módulos do(s) curso(s).
// 1.2  Qual o período total desse(s) curso(s)? (Descrever se será em caráter contínuo e por quantos anos).
// 1.3 Quais módulos foram efetivamente ministrados no ano anterior? Por quanto tempo?
 
// 2. Qual o nível mínimo de escolaridade requerido pelos alunos que estão matriculados no(s) curso(s)?
// 2.1 Quais conhecimentos são considerados pré-requisitos para que a(o) aluna(o) consiga progredir no(s) curso(s)?
 
// 3. Quantos alunos foram matriculados no ano anterior? 
// 3.1 Houve conclusão do curso pelos alunos no ano anterior?
// 3.2 Esses alunos são funcionários da empresa beneficiária?

// TELA : PARECER FI Group > ALL TYPES (Formação e Capacitação Profissional)
// a.	Objetivo
// b.	Campo 1: Descreva com detalhes os módulos do(s) curso(s).   
// c.	Campo 2: Qual o período total desse(s) curso(s)? (Descrever se será em caráter contínuo e por quantos anos). 
// d.	Campo 3: Quais módulos foram efetivamente ministrados no ano anterior? Por quanto tempo? 

// b.	Descrição das Atividades
// b.	Campo 1: Quais conhecimentos são considerados pré-requisitos para que a(o) aluna(o) consiga progredir no(s) curso(s)?    

// c.	Resultados Obtidos
// b.	Campo 1: Houve conclusão do curso pelos alunos no ano anterior?  
// c.	Campo 2: Esses alunos são funcionários da empresa beneficiária? 
export const fieldObjective = [
    { id: 0, field: 'fieldObjectiveOne', label: 'Descreva com detalhes os módulos do(s) curso(s).' },
    { id: 1, field: 'fieldObjectiveTwo', label: 'Qual o período total desse(s) curso(s)? (Descrever se será em caráter contínuo e por quantos anos).' },
    { id: 2, field: 'fieldObjectiveThree', label: 'Quais módulos foram efetivamente ministrados no ano anterior? Por quanto tempo?' },
]
export const fieldDescriptionOfActivities = [
    { id: 0, field: 'fieldDescriptionOfActivitiesOne', label: 'Quais conhecimentos são considerados pré-requisitos para que a(o) aluna(o) consiga progredir no(s) curso(s)?' },
]
export const fieldResultsObtained = [
    { id: 0, field: 'fieldResultsObtainedOne', label: 'Houve conclusão do curso pelos alunos no ano anterior?' },
    { id: 1, field: 'fieldResultsObtainedTwo', label: 'Esses alunos são funcionários da empresa beneficiária?' },
]

// TELA : PARECER FI Group > ALL TYPES (Formação e Capacitação Profissional)
// a.	Objetivo
// a.	Subtítulo: Qual(is) curso(s) será(ão) ministrado(s)? Com qual(is) finalidade(s)?

// b.	Descrição das Atividades
// a.	Subtítulo: Qual o nível mínimo de escolaridade requerido pelos alunos que estão matrículados no(s) curso(s)?

// c.	Resultados Obtidos
// a.	Subtítulo: Qual(is) o(s) resultado(s) esperado(s) para o desenvolvimento do projeto? Ele resolverá o problema técnico existente hoje?
export const fieldsProfessionalTrainingAndQualification = [
    { id: 0, title: 'Objetivo', subTitle: 'Qual(is) o(s) curso(s) será(ão) ministrado(s)? Com qual(is) finalidade(s)?', list: fieldObjective },
    { id: 1, title: 'Descrição das Atividades', subTitle: 'Qual o nível mínimo de escolaridade requerido pelos alunos que estão matriculados no(s) curso(s)?', list: fieldDescriptionOfActivities  },
    { id: 2, title: 'Resultados Obtidos', subTitle: 'Quantos alunos foram matriculados no ano anterior?', list: fieldResultsObtained },
]
