import { FilterPaginatorRequest } from "../components/Requests/FilterPaginatorRequest";
import { ContractsModel } from "../models/ContractsModel";
import { api } from "../services/axios";
import { toQueryParams } from "../utils/creatingParameter";

const GetAllPaginatingByServiceIdAPI = async (serviceId: string, request: FilterPaginatorRequest) => {
    const params = toQueryParams(request);
    try {
        var result = await api.get(`/Contracts/GetAllPagingByServiceId/${serviceId}?${params}`);
        var response = result.data;
        return response;
    } catch (error: any) {
        throw new Error(error.response.data);
    }
}

const GetContractByIdAPI = async (contractId: string) => {
    try {
        var result = await api.get(`/Contracts/GetById/${contractId}`);
        var response = result.data;
        return response;
    } catch (error: any) {
        throw new Error(error.response.data);
    }
}

const GetCountAllButtonsByServiceIdAPI = async (serviceId: string) => {
    try {
        var result = await api.get(`/Contracts/GetCountAllButtonsByServiceId/${serviceId}`);
        var response = result.data;
        return response;
    } catch (error: any) {
        throw new Error(error.response.data);
    }
}

const RemoveContractAPI = async (contractId: string) => {
    try {
        var result = await api.delete(`/Contracts/Delete/${contractId}`);
        var response = result.data;
        return response;
    } catch (error: any) {
        throw new Error(error.response.data);
    }
}

const PostContractAPI = async (contract: ContractsModel) => {
    try {
        var result = await api.post('/Contracts/Post/', contract);
        var response = result.data;
        return response;
    } catch (error: any) {
        throw new Error(error.response.data);
    }
}

const PutContractAPI = async (contract: ContractsModel) => {
    try {
        var result = await api.put('/Contracts/Put/', contract);
        var response = result.data;
        return response;
    } catch (error: any) {
        throw new Error(error.response.data);
    }
}

export {
    GetAllPaginatingByServiceIdAPI,
    GetContractByIdAPI,
    GetCountAllButtonsByServiceIdAPI,
    RemoveContractAPI,
    PostContractAPI,
    PutContractAPI
}