import { FaTrash, FaMoneyBillAlt, FaLaptop, FaSearch } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useCompany } from "../../../hooks/useCompany";
// import { MdModeEdit  } from 'react-icons/md';
import useModal from "../../../hooks/useModal";
import DeleteModal from "../../Shared/DeleteModal";
import EconomicDataModal from "../EconomicDataModal";
import { GoPencil } from "react-icons/go";
import { BsCoin } from "react-icons/bs";
import { MdOutlineLaptop } from "react-icons/md";
import { BiTrashAlt } from "react-icons/bi";
import { useCan } from "../../../hooks/useCan";

interface ProductActionProps {
    productId: string;
    tecnacId: string;
    action: any;
    canDelete: boolean;
}

export default function ProductTecnacAction({ productId, action, tecnacId, canDelete }: ProductActionProps) {
    const { isShowing, toggle } = useModal();
    const { isShowing: isShowingEconomic, toggle: toogleEconomic } = useModal();
    const { company } = useCompany();
    const permissionFinancial = useCan({ rolesCan: ["Financial"] });
    //const permissionTechnician = useCan({ rolesCan: ["Technician"] });
    const permissionMaster = useCan({ rolesCan: ["Master"] });

    var hasPermition = false

    if (permissionMaster) {
        hasPermition = true
    }
    return (
        <>
            {hasPermition ?
             <td className="col">
                <div className="d-flex justify-content-between align-items-center">
                    <Link title="Modelos cadastrados" to={`/${company.serviceId}/products/${productId}/${tecnacId}/models/`}>
                        <MdOutlineLaptop className='ms-2 cursor-pointer' size={22} />
                    </Link>
                    <Link to={`/${company.serviceId}/products/economic/${productId}/${tecnacId}`} state={{ hasTecnac: true }} >
                        <BsCoin title="Dados econômicos e composição média" className='ms-2 cursor-pointer' size={20} />
                    </Link>
                    
                </div>
            </td>
                :
                !permissionFinancial ?
                <td className="col">
                    <div className="d-flex justify-content-between align-items-center">
                        <Link title="Modelos cadastrados" to={`/${company.serviceId}/products/${productId}/${tecnacId}/models/`}>
                            <MdOutlineLaptop className='ms-2 cursor-pointer' size={22} />
                        </Link>
                        <Link to={`/${company.serviceId}/products/economic/${productId}/${tecnacId}`} state={{ hasTecnac: true }} >
                            <BsCoin title="Dados econômicos e composição média" className='ms-2 cursor-pointer' size={20} />
                        </Link>
                        <Link title="Consultar/Editar" to={`/${company.serviceId}/products/tecnac/${productId}`}><GoPencil className='ms-2 cursor-pointer' size={20} /></Link>
                        {canDelete ? <BiTrashAlt title="Excluir" size={20} className='ms-2 cursor-pointer mr-2' onClick={toggle} /> : <div className="ms-2 div-trash-size"></div>}
                    </div>
                </td>:
                <td className="col">
                <div className="d-flex justify-content-between align-items-center">
                    <Link title="Modelos cadastrados" to={`/${company.serviceId}/products/${productId}/${tecnacId}/models/`}>
                        <MdOutlineLaptop className='ms-2 cursor-pointer' size={22} />
                    </Link>
                </div>
            </td>}
               {!permissionFinancial &&
            <DeleteModal title='Excluir Produto' text='Tem certeza que deseja excluir este produto?' isShowing={isShowing} hide={toggle} action={action} />
               }
        </>
    )
}